import React from "react"
import { formatEvaluacion, getEvaluacionBg, getEvaluacionFg, getEvaluacionInitials } from "./HistorialEvaluacionesUtils"

const tableStyle = {
    tableLayout: "fixed",
}

const tdStyle = {
    wordWrap: "break-word",
}

export function HistorialEvaluacionesDetailsTable({details, nivelesCumplimiento}) {
    return (
        <table style={tableStyle} className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th style={{ backgroundColor: "#99cc00" }}>Objetivo</th>
                    <th style={{ backgroundColor: "#99cc00" }}>Indicador</th>
                    <th style={{ backgroundColor: "#99cc00" }} width="10%">Comentario</th>
                    <th style={{ backgroundColor: "#0000ff", color: "white" }} width="10%"><abbr title="Sobre meta">SM</abbr></th>
                    <th style={{ backgroundColor: "#339966", color: "black" }} width="10%"><abbr title="Meta">M</abbr></th>
                    <th style={{ backgroundColor: "#ffff00", color: "black" }} width="10%"><abbr title="Bajo meta">BM</abbr></th>
                    <th style={{ backgroundColor: "#ff0000", color: "black" }} width="10%"><abbr title="Inaceptable">I</abbr></th>
                    <th style={{ backgroundColor: "#99cc00" }}>%</th>
                    <th style={{ backgroundColor: "#ffc000" }} width="15%">Variable</th>
                    <th style={{ backgroundColor: "#ffc000" }} width="10%">Comentario de la evaluación</th>
                    <th style={{ backgroundColor: "#ffc000" }} width="10%">Obtenido</th>
                </tr>
            </thead>
            <tbody>
                {details.map((row, idx) => {
                    const evaluacion = getEvaluacionInitials(row.nivel_cumplimiento_desc);
                    const tdHighlightStyle = {
                        backgroundColor: getEvaluacionBg(evaluacion),
                        color: getEvaluacionFg(evaluacion),
                        fontWeight: "bold",
                        wordWrap: "break-word",
                    }
                    const nivelesCumplimientoStyle = {
                        background: "#d3d3d3",
                        display: "block",
                        width: "95%",
                        marginTop: "5px",
                        marginBottom: "5px",
                    }
                    return (
                        <tr key={idx}>
                            <td style={{wordWrap: "break-word"}}>{row.objetivo}</td>
                            <td style={{wordWrap: "break-word"}}>{row.indicador}</td>
                            <td style={{wordWrap: "break-word"}}>{row.comentario}</td>
                            <td style={evaluacion === "SM" ? tdHighlightStyle : {wordWrap: "break-word"}}>{row.sobre_meta}</td>
                            <td style={evaluacion === "M" ? tdHighlightStyle : {wordWrap: "break-word"}}>{row.meta}</td>
                            <td style={evaluacion === "BM" ? tdHighlightStyle : {wordWrap: "break-word"}}>{row.bajo_meta}</td>
                            <td style={evaluacion === "I" ? tdHighlightStyle : {wordWrap: "break-word"}}>{row.inaceptable}</td>
                            <td>{row.nivel_cumplimiento_desc.toUpperCase()}</td>
                            <td style={{wordWrap: "break-word"}}>
                                { nivelesCumplimiento.map(nivel => {
                                    return <><span style={nivelesCumplimientoStyle}>
                                        <abbr title={nivel.descripcion}>{getEvaluacionInitials(nivel.descripcion)}: {nivel.porcentaje}</abbr>
                                    </span></>
                                })}
                            </td>
                            <td style={{wordWrap: "break-word"}}>{row.comentario_evaluacion}</td>
                            <td style={{wordWrap: "break-word"}} className="has-text-right">{row.obtenido}</td>
                        </tr>)
                })}
            </tbody>
        </table>
    )
}