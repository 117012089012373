import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class SolicitudesCompensacion {
    static getAll(gerenciaArr=null) {
        return middleware.hasAuth(() => request.get('solicitud_compensacion/'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/solicitud_compensacion/${id}`));
    }

    static create(data) {
        return middleware.hasAuth(() => request.post('solicitud_compensacion/', data));
    }

    static getFilters(filters={}) {
        return middleware.hasAuth(() => request.get(`/solicitud_compensacion?${stringify(filters)}`));
    }

    static terminar(id) {
        return middleware.hasAuth(() => request.put(`/solicitud_compensacion/${id}/terminar`));
    }

    static terminarMulti(solicitudes) {
        return middleware.hasAuth(() => request.put('/solicitud_compensacion/terminar/multi/', solicitudes));
    }

    static rechazar(id) {
        return middleware.hasAuth(() => request.put(`/solicitud_compensacion/${id}/rechazar`));
    }

    static rechazarMulti(solicitudes) {
        return middleware.hasAuth(() => request.put('/solicitud_compensacion/rechazar/multi/', solicitudes));
    }

}