import React from 'react';

/**
 * Componente de utilería encargado de mostrar un Modal en el cual es
 * posible insertar cualquier otro elemento como componentes hijos del mismo.
 */

export default class Modal extends React.Component{

    // Función que se efectuará cuando el usuario presione alguna tecla
    escFunction = (event) => {
        if(event.keyCode === 27) {
          this.props.cancel();
        }
      }

      // Se registra un escuchador en caso de que el usuario presione alguna tecla
      componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
      }
      // Se elimina el escuchador.
      componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render(){
        return(
            <div className={this.props.showModal ? "modal is-active" : "modal"}>
                <div className="modal-background" onClick={() => this.props.cancel()}/>
                <div className="modal-card">
                    <header className="modal-card-head">
                    <p className="modal-card-title">{this.props.modalTitle}</p>
                    <button onClick={() => this.props.cancel()} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {/*
                            En esta parte se dibujan todos los elementos o componentes que se
                            hayan puesto como hijos del Modal que vienen en la prop children
                        */}
                        {this.props.children}
                    </section>
                </div>
            </div>
        )
    }
}