import React from "react";

export function formatMonth(month) {
    switch (month) {
        case 1: return 'Ene';
        case 2: return 'Feb';
        case 3: return 'Mar';
        case 4: return 'Abr';
        case 5: return 'May';
        case 6: return 'Jun';
        case 7: return 'Jul';
        case 8: return 'Ago';
        case 9: return 'Sep';
        case 10: return 'Oct';
        case 11: return 'Nov';
        case 12: return 'Dic';
        default: return '-';
    }
}

export function formatYear(year) {
    const yearStr = String(year);
    return yearStr.slice(-2)
}

export function getEvaluacionFg(initials) {
    switch (initials) {
        case "SM": return "white";
        case "M": return "black";
        case "BM": return "black";
        case "I": return "black";
        default: return "black"
    }
}

export function getEvaluacionBg(initials) {
    switch (initials) {
        case "SM": return "#0000ff";
        case "M": return "#339966";
        case "BM": return "#ffff00";
        case "I": return "#ff0000";
        default: return "transparent"
    }
}

export function getEvaluacionInitials(evaluacion) {
    return evaluacion.split(" ").map((n)=>n[0]).join("");
}

export function formatEvaluacion(evaluacion) {
    const initials = getEvaluacionInitials(evaluacion);
    let style = {
        fontWeight: "bold",
        backgroundColor: getEvaluacionBg(initials),
        color: getEvaluacionFg(initials),
        display: "block",
        width: "95%",
        margin: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        lineHeight: "30px"
    }
    return <span style={style}>
        { initials }
    </span>;
}
