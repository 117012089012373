//Constantes a utilizar en la aplicación

/** DESARROLLO */
/*export const caffenioAuthURL = "http://auth.vasopinguino.com";
export const  restablecerPass = `${caffenioAuthURL}/change-password`;
export const caffenioAuthId = "75311C31-3411-4209-BD0D-7FC0F9F9D24C";
export const apiUrlBsc = `http://127.0.0.1:8000/api/bsc/`;
export const apiUrlIndicadores = `http://127.0.0.1:8000/api/indicadores_variables/`;
export const apiUrlBase = `http://127.0.0.1:8000/api/`;
export const backUrl = `http://127.0.0.1:8000`;*/

// PRUEBAS-PRODUCCION
export const caffenioAuthURL = "https://auth.caffenio.com";
export const  restablecerPass = `${caffenioAuthURL}/change-password`;
export const caffenioAuthId = "75311C31-3411-4209-BD0D-7FC0F9F9D24C";
export const apiUrlBsc = `https://apibsc.caffenio.com/api/bsc/`;
export const apiUrlIndicadores = `https://apibsc.caffenio.com/api/indicadores_variables/`;
export const apiUrlBase = `https://apibsc.caffenio.com/api/`;
export const backUrl = `https://apibsc.caffenio.com`;

// PRUEBAS 
/*export const caffenioAuthURL = "http://auth.vasopinguino.com";
export const restablecerPass = `${caffenioAuthURL}/change-password`;
export const caffenioAuthId = "75311C31-3411-4209-BD0D-7FC0F9F9D24C";
export const apiUrlBsc = `http://198.168.20.16:8007/api/bsc/`;
export const apiUrlIndicadores = `http://198.168.20.16:8007/api/indicadores_variables/`;
export const apiUrlBase = `http://198.168.20.16:8007/api/`;
export const backUrl = `http://198.168.20.16:8007`;*/

// PRUEBAS .12
/*export const caffenioAuthURL = "http://auth.vasopinguino.com";
export const restablecerPass = `${caffenioAuthURL}/change-password`;
export const caffenioAuthId = "75311C31-3411-4209-BD0D-7FC0F9F9D24C";
export const apiUrlBsc = `http://198.168.20.12:9020/api/bsc/`;
export const apiUrlIndicadores = `http://198.168.20.12:9020/api/indicadores_variables/`;
export const apiUrlBase = `http://198.168.20.12:9020/api/`;
export const backUrl = `http://198.168.20.12:9020`;*/