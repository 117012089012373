import { apiUrlBsc, apiUrlBase, apiUrlIndicadores } from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

let request = () => {
    return axios.create({
        baseURL: apiUrlBsc,
        // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })
}

let requestfast = () => {
    return axios.create({
        baseURL: 'https://apibsc.caffenio.com/api/bsc/',
        // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })
}

let request_usuarios = () => {
    return axios.create({
        baseURL: apiUrlBase,
        // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })
}

let requestKpi = () => {
    return axios.create({
        baseURL: apiUrlIndicadores,
        // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })
}

export default class UsuarioAPI {
    //GENERAL
    static postSincronizarUsuarios() {
        return middleware.hasAuth(() => requestKpi().post('usuarios/sincronizar'));
    }
    //KPIS
    static getEvaluadoresKPIs() {
        return middleware.hasAuth(() => requestKpi().get('usuarios/evaluadores'));
    }

    static getUsuariosFSAP(filters) {
        return middleware.hasAuth(() => requestKpi().get('usuarios/FSAP',{"params": filters}));
    }

    static getUsuariosAllIdicadores(filters) {
        return middleware.hasAuth(() => requestKpi().get('usuarios/',{"params": filters}));
    }

    static getJefesDirectosKPIs() {
        return middleware.hasAuth(() => requestKpi().get('usuarios/jefes_directos'));
    }    

    //BSC
    static getAll() {
        return middleware.hasAuth(() => requestfast().get('usuarios/'));
    }    

    static getList() {
        return middleware.hasAuth(() => request().get('usuarios/list/'));
    }

    static get(id) {
        return middleware.hasAuth(() => request().get(`/usuariobsc/${id}/`));
    }

    static getUserFromToken() {
        return middleware.hasAuth(() => request().get(`/usuario/token/`));
    }

    static find(data) {
        return middleware.hasAuth(() => request().post('usuario/auth/', data))
    }

    static save(data) {
        return middleware.hasAuth(() => request().post('usuario/', data))
    }

    static edit(id, data) {
        return middleware.hasAuth(() => request().put(`usuario/${id}/`, data))
    }

    static editPartial(id, data) {
        return middleware.hasAuth(() => request().put(`usuario/partial/${id}/`, data))
    }

    static upload(data) {
        return middleware.hasAuth(() => request().put(`usuario/load/`, data))
    }

    static retrieve() {
        return middleware.hasAuth(() => request().post('usuariobsc/retrieve/'))
    }

    static authCreate() {
        return middleware.hasAuth(() => request_usuarios().post('usuario/auth/'))
    }

    /**
     * Carga el layout para crear/editar usuarios en el sistema.
     * 
     * @param {Array} data 
     */
    static loadLayout(data = []) {
        return middleware.hasAuth(() => request().post('usuario/masive/', data))
    }

    static getUsersAmounts(filters={}) {
        return middleware.hasAuth(() => request().get(`usuario/montos/?${stringify(filters)}`));
    }

    static updateUsersAmounts(data={}) {
        return middleware.hasAuth(() => request().put('usuario/montos/descuento/',data));
    }
}