import React, { Fragment, Component } from 'react'

// Services
import UsersAPI from '../../../services/Usuario'
import GerenciasAPI from '../../../services/Gerencia'
import AreaAPI from '../../../services/Area'
import ConvocatoriaAPI from '../../../services/Convocatorias'
import PropuestasAPI from '../../../services/Propuestas'

// Components
import { Card } from '../../util/Card/Card'
import { Table } from '../../util/Table/Table'
import Pagination from '../../util/pagination/Pagination'
import { FiltersWrapper } from '../../util/FiltersWrapper/FiltersWrapper'
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import { toast } from 'react-toastify'
import { Button } from '../../util/Button/Button'
import Select from '../../util/select/Selct'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import Loader from '../../util/loading/Loading'

// Utils
import { generateBasicExcel } from '../../../util/ExcelFunctions'
import moment from 'moment'


/** Componente para ver reporte de plan estratégico. */
class EstatusPlanEstrategico extends Component {

  state = {
    currentPageItems: [],
    areas: [],
    areasOriginal: [],
    selectedAreas: [],
    currentPeriod: undefined,
    selectedPeriod: undefined,
    listUsers: [],
    listUsersFiltered: [],
    selectedUsers: [],
    listGerencias: [],
    selectedGerencias: [],
    loadingExcel: false,
    listEstatus: LIST_ESTATUS_PLAN,
    listEstatusUsuario: LIST_ESTATUS_USUARIO,
    selectedEstatus: [],
    selectedEstatusUsuario:undefined,
    listPlans: [],
    listPeriodos: [],
    filteredListPlans: [],
    filters: {},
    loadingTable: true
  }

  toastFileDownloadSuccess = null
  toastFileDownloadError = null

  /**
   * Carga de los usuarios de la aplicación. 
   * 
   * @param {Object} filters
  */
  loadPlans = (filters = {}) => {
    let {
      areas, currentPeriod, selectedPeriod,
      selectedEstatus
    } = this.state

    if (!filters.periodo) {
      filters.periodo = selectedPeriod !== 0
        ? selectedPeriod
        : currentPeriod
    }   

    PropuestasAPI.statusPlanReport(filters)
      .then(res => {
        this.setState({
          loadingTable: false,
          listPlans: res.data,
          filteredListPlans: res.data.filter(plan =>
            areas.map(area => area.id).includes(plan.area)
          )
        }, () => {
          
          this.setState({
            listEstatus: this.getStatusFromListPlans(res.data)
          })

          if (selectedEstatus.length !== 0)
            this.filterByStatus()
        })
      })
      .catch(err => {
        toast.error('Usuarios no cargados.')
        this.setState({
          loadingTable: false
        })
      })
  }

  /** Carga de la lista de estatus de los planes iniciales. */
  loadInitialMovements = () => {
    const filters = { periodo: this.state.currentPeriod }
    PropuestasAPI.statusPlanReport(filters)
      .then(res => {
        this.setState({
          listEstatus: this.getStatusFromListPlans(res.data)
        })
      })
      .catch(err => {
        console.log(err)
        toast.error('La lista de estatus no se a cargado.')
      })
  }

  /**
   * Obtiene la lista de estatus del plan desde las propuestas.
   * 
   * @param {Array} plans
   * @returns listStatus
   */
  getStatusFromListPlans = (plans = []) => {
    let listStatus = [...new Set(plans.map(item => item.estatus_plan))]
    let finalList = listStatus.map((item, index) => {
      return {
        id: index,
        value: item
      }
    })

    return finalList
  }


  /**
   * Filtra los usuarios segun las areas seleccionadas,
   * en caso de no tener seleccionada ningun area se
   * devuelven todos los usuarios.
   * 
   * @param {Array} plans
   * 
   * @returns filteredUsuarios
   */
  filteredUsuarios = (plans = []) => {
    let state = { ...this.state }
    let filteredUsuarios = []
    let selectedAreas
    let usuarios = this.getUsers(plans)

    state.filters.area
      ? selectedAreas = state.filters.area
      : selectedAreas = state.areas.map(area => area.id)

    filteredUsuarios = usuarios.filter(usuario =>
      selectedAreas.includes(usuario.area)
    )

    return filteredUsuarios
  }

  /**
   * Obtiene a los usuarios, con propuestas activas.
   * 
   *@param {Array} plans
   * 
   * @returns usuarios
   */
  getUsers(plans = []) {
    let usuarios = []

    usuarios = plans.map(plan => {
      return {
        area: plan.area,
        ...plan.usuario
      }
    })

    return usuarios
  }

  /**Carga de los usuarios de la aplicación. */
  loadUsers = () => {
    UsersAPI.getAll()
      .then(res =>
        this.setState({
          listUsers: res.data,
          listUsersFiltered: res.data
        })
      )
      .catch(err => toast.error('Usuarios no cargados.'))
  }

  /**Carga de las áreas registradas en la aplicación. */
  loadGerencias = () => {
    let listGerencias = []
    let areas = []
    let areasOriginal = []

    GerenciasAPI.getAll()
      .then(res => {
        listGerencias = res.data
        return AreaAPI.getAll()
      })
      .then(({data}) => {
        areas = data
        areasOriginal = data
        this.setState({listGerencias, areas, areasOriginal}, () => this.loadPlans())
      })
      .catch(err => toast.error('Gerencias no cargadas.'))
  }

  /**
   * Applicación de los filtros seleccionados.
   * 
   * @param {Object} filters
   */
  applyFilters = (filters = {}) => {
    this.setState({
      loadingTable: true
    })
    this.loadPlans(filters)
  }

  /**
   * Manjea los cambios en la lista de areas.
   * 
   * @param {Array} listAreas
   */
  handleChangeArea = (listAreas = []) => {
    let filters = { ...this.state.filters }
    filters.area = listAreas.map(area => area.id)
    let listUsersFiltered = []
    let areasIds = listAreas.map(area => area.id)

    delete filters.usuario

    if (listAreas.length === 0) {
      delete filters.area
      listUsersFiltered = this.state.listUsers
    } else {
      listUsersFiltered = this.state.listUsers.filter(user => {
        if (areasIds.includes(user.area))
          return user
      })
      filters.area = listAreas.map(area => area.id).join(",")
    }

    this.setState({
      filters,
      loadingTable: true,
      listUsersFiltered
    })

    this.applyFilters(filters)
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de gerencia.
   * 
   * @param {Array} gerenciaList
   */
  handleChangeGerencia = (gerenciaList = []) => {
    let filters = { ...this.state.filters }
    let gerenciasIds = []
    let listAreas = []

    delete filters.area
    delete filters.usuario

    if (gerenciaList.length === 0) {
      delete filters.gerencia
      listAreas = this.state.areasOriginal
    } else {
      gerenciasIds = gerenciaList.map(gerencia => gerencia.id)
      listAreas = this.state.areasOriginal.filter(area => gerenciasIds.includes(area.gerencia))
      filters.gerencia = gerenciaList.map(gerencia => gerencia.id).join(",")
    }

    this.setState({
      filters,
      loadingTable: true,
      areas: listAreas
    }, () => this.applyFilters(filters))
  }

  /**
   * Maneja el cambio al seleccionar un periodo de las lista.
   * 
   * @param {Event}
   */
  handleChangePeriod = ({ target }) => {
    const period = Number(target.value) === 0
      ? this.state.currentPeriod
      : Number(target.value)
    let filters = {
      ...this.state.filters,
      periodo: period
    }
    this.setState({
      filters,
      selectedPeriod: period
    }, () => this.applyFilters(filters))
  }

  /**
   * Maneja el cambio al seleccionar un estatus de usuario de las lista.
   * 
   * @param {Event}
   */
   handleChangeEstatusUsuario = ({ target }) => {
    let filters = { ...this.state.filters }
    const status = target.value     

    if (status != '' && status != undefined) {
      filters.estatus = status
    }

    this.setState({
      selectedEstatusUsuario: status
    }, () => this.applyFilters(filters))
  }

  /**
   * Manjea los cambios en la lista de usuarios.
   * 
   * @param {Array} listUsers
   */
  handleChangeUser = (listUsers = []) => {
    let filters = { ...this.state.filters }

    if (listUsers.length === 0) {
      delete filters.usuario
    } else {
      filters.usuario = listUsers.map(user => user.id).join(",")
    }

    this.setState({
      filters,
      // selectedUsers: listUsers,
      loadingTable: true
    })

    this.applyFilters(filters)
  }

  /**
   * Manjea los cambios en la lista de estatus.
   * 
   * @param {Array} listEstatus
   */
  handleChangeEstatus = (listEstatus = []) => {
    this.setState({
      selectedEstatus: listEstatus
    }, () => this.filterByStatus())
  }

  /** Filtra los planes por el estatus en el que se encuentran. */
  filterByStatus = () => {
    let { listPlans, selectedEstatus } = this.state
    let coincidences = []
    if (selectedEstatus.length !== 0) {
      selectedEstatus.map(status => {
        listPlans.map(plan => {
          if (status.value === plan.estatus_plan)
            coincidences.push(plan)
        })
      })

      this.setState({
        filteredListPlans: coincidences,
        loadingTable: false
      })
    } else {
      this.setState({
        filteredListPlans: listPlans,
        loadingTable: false
      })
    }
  }

  /**Descarga de Excel de plan estratégico. */
  handleDownloadReport = () => {
    this.setState({ loadingExcel: true })
    const { filteredListPlans } = this.state
    try {
      const excelMeta = {
        excelName: "Estatus de plan estratégico",
        sheetName: "Planes estratégicos"
      }

      let [rows, columns] = this.formattedInfo(filteredListPlans)

      generateBasicExcel(excelMeta, rows, columns)
      if (!toast.isActive(this.toastFileDownloadSuccess))
        this.toastFileDownloadSuccess = toast.success('Excel descargado!')
      this.setState({ loadingExcel: false })
    } catch (err) {
      if (!toast.isActive(this.toastFileDownloadError))
        this.toastFileDownloadError = toast.success('No se descargó archivo Excel!')
      this.setState({ loadingExcel: false })
    }
  }

  /**
   * Obtiene la información de los renglones procesados
   * para dale formato al archivo Excel.
   * 
   * @param {Array} infoRows 
   * 
   * @returns Array
   */
  formattedInfo(infoRows = []) {
    let rows = []
    let columns = []

    rows = infoRows.map(item => {
      return {
        nombre_usuario: item.usuario.nombre,
        estatus_de_plan: item.estatus_plan,
        correo: item.usuario.email,
        area: item.area_nombre,
        gerencia: item.gerencia_nombre,
        puesto: item.puesto,
        monto: item.monto,
        politica: item.politica,
        jefe_directo: item.jefe_directo_nombre,
        grupo: item.grupo_nombre
      }
    })

    columns = Object.keys(rows[0])

    return [rows, columns]
  }

  /** Carga de periodos registrados en la aplicación. */
  loadPeriods = () => {
    this.setState({
      loadingTable: true
    })
    Promise.all([
      ConvocatoriaAPI.getAll()
        .then(res =>
          this.setState({
            listPeriodos: res.data.sort(function (a, b) {
              return a.id - b.id
            })
          })
        )
        .catch(err => toast.error('Periodos no cargados')),
      ConvocatoriaAPI.getCurrent()
        .then(res =>
          this.setState({
            currentPeriod: res.data.convocatoria.id,
            selectedPeriod: res.data.convocatoria.id
          }, () => {
            this.loadInitialMovements()
            this.loadGerencias()
          })
        )
        .catch(err => toast.error('Periodos no cargados'))
    ])
  }

  componentDidMount() {
    this.loadUsers()
    this.loadPeriods()
  }

  render() {
    const {
      currentPageItems, areas, listGerencias, listEstatus,listEstatusUsuario,
      filteredListPlans, loadingExcel, loadingTable,
      listPeriodos, listUsersFiltered, selectedPeriod, currentPeriod, selectedEstatusUsuario
    } = this.state
    const COLUMNS = [
      {
        id: 1,
        title: "Nombre usuario",
        render: row => row.item.usuario.nombre,
        size: "25%"
      },
      {
        id: 2,
        title: "Área",
        value: "area_nombre",
        size: "25%"
      },
      {
        id: 3,
        title: "Gerencia",
        value: "gerencia_nombre",
        size: "25%"
      },
      {
        id: 4,
        title: "Estatus",
        value: "estatus_plan",
        size: "25%"
      }
    ]

    return (
      <Fragment>
        <Card
          title="Reporte de estatus de plan estratégico"
          extraActions={
            <Button
              text="Descargar reporte"
              icon="fa-file-excel-o"
              onClick={this.handleDownloadReport}
              type="secondary"
              title="Descargar Excel de Reporte de estatus de plan estratégico"
              disabled={loadingExcel}
            />
          }
        >
          <FiltersWrapper>
            <MultiSelect
              label="Gerencia"
              name="gerencia"
              items={listGerencias.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={listGerencias.length === 0}
              onChangeValue={itemsList => this.handleChangeGerencia(itemsList)}
            />
            <MultiSelect
              label="Área"
              name="area"
              items={areas.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={areas.length === 0}
              onChangeValue={itemsList => this.handleChangeArea(itemsList)}
            />
            <MultiSelect
              label="Nombre/No. Empleado"
              name="usuario"
              items={listUsersFiltered.map(item => {
                let newItem = { ...item }
                newItem.value = `${item.usuario.id_empleado} - ${item.usuario.nombre}`

                return newItem
              })}
              disabled={listUsersFiltered.length === 0}
              onChangeValue={itemsList => this.handleChangeUser(itemsList)}
            />
            <Wrapper label="Periodo">
              <Select
                classes="input"
                name="periodo"
                value={selectedPeriod || currentPeriod}
                change={this.handleChangePeriod}
                items={listPeriodos}
                disabled={listPeriodos.length === 0}
                optionProps={{ value: "id", text: "nombre" }}
              />
            </Wrapper>
            <MultiSelect
              label="Estatus Plan"
              name="estatus_plan"
              items={listEstatus.map(item => item)}
              disabled={listEstatus.length === 0}
              onChangeValue={itemsList => this.handleChangeEstatus(itemsList)}
            /> 
            <Wrapper label="Estatus Usuario">
              <Select
                label="Estatus Usuario"
                classes="input"
                name="estatus_usuario"
                value={selectedEstatusUsuario}
                change={this.handleChangeEstatusUsuario}
                items={listEstatusUsuario}
                disabled={listEstatusUsuario.length === 0}
                optionProps={{ value: "id", text: "value" }}
              />
            </Wrapper>     
          </FiltersWrapper>
        </Card>
        <br />
        <Card>
          <Table
            columns={COLUMNS}
            dataSet={currentPageItems}
            noDataText="No se encontraron planes estratégicos"
            loading={loadingTable}
          />
          <Pagination
            items={filteredListPlans}
            pageSize={10}
            onChangePage={items => this.setState({ currentPageItems: items })}
          />
        </Card>
        {
          loadingTable &&
          <Loader
            isFullscreen={true}
            isLoading={loadingTable}
            width="100px"
            height="100px"
          />
        }
      </Fragment>
    )
  }
}

export default EstatusPlanEstrategico

const LIST_ESTATUS_PLAN = [
  {
    id: 1,
    value: 'Sin iniciar propuesta'
  },
  {
    id: 2,
    value: 'Propuesta en revisión'
  },
  {
    id: 3,
    value: 'Propuesta validada'
  },
  {
    id: 4,
    value: 'Propuesta en edición'
  },
  {
    id: 5,
    value: 'Autoevaluación en validación'
  },
  {
    id: 6,
    value: 'Autoevaluación en edición'
  },
  {
    id: 7,
    value: 'Autoevaluación autorizada'
  },
  {
    id: 8,
    value: 'Compensación real calculada'
  }
]

const LIST_ESTATUS_USUARIO = [
  {
    id: true,
    value: 'Activo'
  },
  {
    id: false,
    value: 'Inactivo'
  }
]
