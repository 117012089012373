import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

// Services
import AreaAPI from '../../services/Area';
import UsuarioAPI from '../../services/Usuario';
import RazonSocialAPI from '../../services/RazonSocial';

// Components
import MontosDetalle from '../montos/montoDetalle'
import MontosDescuento from './montoDescuento';
import TablaUsuarios from '../montos/tablaUsuarios'
import Loading from '../util/loading/Loading'
import MultiSelect from '../util/MultiSelect/MultiSelect';
import Pagination from '../util/pagination/Pagination';
import HasAccess from '../util/hasAccess/HasAccess';
import { Filtros } from '../propuestas/ObjetivoTurbo/FiltrosObjetivosTurbo';
import { Button } from '../util/Button/Button';
import { Card } from '../util/Card/Card';

// Utils
import { Modal } from '../util/modal/ModalLayout';
import { result } from 'lodash';

class MontosUsuarios extends Component {
  constructor(props) {
    super(props);


    this.state = {
      usuario: {
        ...this.usuarioEmpty
      },
      filtroUsuarios: [],
      selectedUsers: [],
      selectedAreas: [],
      usuarios: [],
      usuarioSelected: {},
      usuariosFiltrados: [],
      listaUsuarios: [],
      puestos: [],
      areas: [],
      razones_sociales: [],
      currentPageItems: [],
      formTitle: '',
      isModalDetailVisible: false,
      isModalAgusteMontoVisible: false,
      isLoading: false,
      filtros: {}
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    this.handleLoadInitialData()
  }

  /**Realiza la carga inicial de los datos. */
  handleLoadInitialData = () => {
    Promise.all([
      RazonSocialAPI.getAll()
        .then( response => {
          this.setState({
            razones_sociales: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las razones');
        }),      
      AreaAPI.getAll()
        .then( response => {
          this.setState({
            areas: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las áreas');
        }),
      UsuarioAPI.getAll().then(response => {
              
        var usuarios = response.data
        var puestos = []
        usuarios.map((usuario) => {
            if(!puestos.includes(usuario.puesto)){
                puestos.push(usuario.puesto)
            }
        })
        this.setState({
            puestos: puestos,
            listaUsuarios: response.data
        })
      }).catch(err => {
          if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Lista de usuarios no cargada')
      }),
      UsuarioAPI.getUsersAmounts().then(response => {              
        this.setState({
            usuariosFiltrados: response.data
        })
      }).catch(err => {
          if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Lista de usuarios no cargada')
      }),
    ]).finally(() => {
      this.setState({ isLoading: false })
      toast.success('Carga de información completada')
    });
  }

  /**
  * Maneja los cambios al seleccionar un item de la
  * lista de Areas.
  * 
  * @param {Array} areasList
  */
  handleChangeArea = (areasList = []) => {
    let filtros = { ...this.state.filtros }

    if (areasList.length === 0) {
        delete filtros.area
    } else {
        filtros.area = areasList.map(area => area.id).join(",")
    }

    this.setState({ filtros: filtros })
    this.applyFilters(filtros)
  }

  /**
     * Maneja los cambios al seleccionar un item de la
     * lista de usuarios.
     * 
     * @param {Array} userList
     */
  handleChangeUser = (userList = []) => {
    let filtros = { ...this.state.filtros }

    if (userList.length === 0) {
        delete filtros.usuario
    } else {
        filtros.usuario = userList.map(user => user.id).join(",")
    }

    this.setState({ filtros: filtros })
    this.applyFilters(filtros)
  }

  /**
  * Maneja los cambios al seleccionar un item de la
  * lista de razon social.
  * 
  * @param {Array} razonList
  */
  handleChangeRazon = (razonList = []) => {
    let filtros = { ...this.state.filtros }

    if (razonList.length === 0) {
        delete filtros.razon_social
    } else {
        filtros.razon_social = razonList.map(razon_social => razon_social.id).join(",")
    }

    this.setState({ filtros: filtros })
    this.applyFilters(filtros)
  }

  /**
  * Maneja los cambios al seleccionar un item de la
  * lista de puesto.
  * 
  * @param {Array} puestosList
  */
  handleChangePuestos = (puestosList = []) => {
    let filtros = { ...this.state.filtros }

    if (puestosList.length === 0) {
        delete filtros.puesto
    } else {
        filtros.puesto = puestosList.map(puesto => puesto.value).join(",")
    }

    this.setState({ filtros: filtros })
    this.applyFilters(filtros)
  }

  applyFilters = (filtros) => {  
    this.showLoading(true)

    UsuarioAPI.getUsersAmounts(filtros)
    .then(res => {
        let usuarios = res.data
        
        this.setState({
          usuariosFiltrados: usuarios,
          currentPage: 1 
        })
        this.setPage()
        this.showLoading(false)
    })
    .catch(err => {      
      this.showLoading(false)
      if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al filtrar usuarios')
    })
  }

  saveDescount = (usuario, descuento) => {
    let {usuariosFiltrados} = this.state
    this.showLoading(true)

    let data = {
      'numero_empleado' : usuario.usuario.id_empleado,
      'monto_descuento' : descuento
    }

    Promise.all([
      UsuarioAPI.updateUsersAmounts(data)
      .then(res => {
        
        let response = res.data

        usuariosFiltrados = usuariosFiltrados.map(usuario => {
          if(usuario.id == response.id){
            usuario.montos = response.montos
            usuario.monto_total = response.monto_total
          }
          return usuario
        })
        this.setState({
          usuariosFiltrados: usuariosFiltrados,
          isModalAgusteMontoVisible: false
        })
        
        toast.success('El monto se a modificado con exito')
      })
      .catch(err => {      
        this.showLoading(false)
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al modificar monto')
      })
    ]).finally(() => {
      
      this.showLoading(false)
      this.applyFilterChangeSave(usuariosFiltrados)
    });

    
    

  }

  applyFilterChangeSave = (usuarios) => {
    let {usauriosFiltrados} = this.state

    usauriosFiltrados = usuarios.filter(item => item.monto_total > 0)

    usauriosFiltrados = usauriosFiltrados.sort(function (a, b) {
      if (a.estatus === b.estatus){
        if(a.id > b.id)
          return -1
        if(a.id < b.id)
          return 1
        return 0
      }
      if (a.estatus > b.estatus) {
        return 1;
      }
      if (a.estatus < b.estatus) {
        return -1;
      }                  
    });   

    this.setPage(usauriosFiltrados)

}

  /**
     * Muestra/Oculta loader.
     * 
     * @param {Boolean} isLoading
     */
   showLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  }

  /**
    * Se selecciona la página y se actualizan los elementos de la lista/tabla
  */ 
  setPage = (listUsuarios = []) => {
    let usuarios = listUsuarios.length > 0 ? listUsuarios : this.state.usuariosFiltrados;
    let page = this.state.currentPage;
    let pageSize = this.state.pageSize

    let pager = this.getPager(usuarios.length, page, pageSize);
    let currentPageItems = usuarios.slice(pager.startIndex, pager.endIndex + 1);

    this.setState({ 
        currentPageItems: currentPageItems
    });

  }

  /**
     * Se obtiene el paginador, es decir, cuantas páginas habrán
     * Se asigna la primera página como la actual
     * Se obtienen los elementos que serán mostrados en la primera página
     */
   getPager = (totalItems, currentPage, pageSize) =>  {
    currentPage = currentPage || 1;

    pageSize = pageSize || 10;

    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  render() {

    return (
      <div>
        {/** Filtros */}
        <div className="card">
          <header className="card-header">
            <p className="card-header-title is-size-4">
              Consulta de montos
            </p>
          </header>
          <div className="card-content">
            <div className="content">
              <div className="columns">
                <MultiSelect
                  label="Usuario"
                  name="usuario"
                  items={this.state.listaUsuarios.map(usuario => {
                    let newUsuario = { ...usuario }
                    newUsuario.id = usuario.usuario.id
                    newUsuario.value = usuario.usuario.id_empleado + " - " +usuario.usuario.nombre

                    return newUsuario
                  })}
                  onChangeValue={userList => this.handleChangeUser(userList)}
                />
                <MultiSelect
                  label="Razón social"
                  name="razonSocial"
                  items={this.state.razones_sociales.map(razon => {
                    let newRazon = { ...razon }
                    newRazon.value = razon.nombre
                    newRazon.id = razon.id
                    return newRazon
                  })}
                  onChangeValue={razonList => this.handleChangeRazon(razonList)}
                />
                <MultiSelect
                  label="Área"
                  name="area"
                  items={this.state.areas.map(area => {
                    let newArea = { ...area }
                    newArea.value = area.nombre

                    return newArea
                  })}
                  onChangeValue={areasList => this.handleChangeArea(areasList)}
                />
                <MultiSelect
                  label="Puesto"
                  name="puesto"
                  items={this.state.puestos.map((puesto, index) => {
                    let newPuesto = { ...puesto }
                    newPuesto.value = puesto
                    newPuesto.id = index

                    return newPuesto
                  })}
                  onChangeValue={puestosList => this.handleChangePuestos(puestosList)}
                />
              </div>
            </div>
          </div>
          <footer className="card-footer">

          </footer>
        </div>

        {/** Lista de usuarios */}
        <Card extraClasses="mt-10" >
        {
          <Fragment>
              <div>
                  <h5 className="title is-5 has-text-grey">Lista de usuarios con montos disponibles</h5>
              </div>
              <br />
              <TablaUsuarios
                usuarios={this.state.currentPageItems}
                detalle = {(usuario) => {
                  this.setState({
                    isModalDetailVisible: true,
                    usuarioSelected: usuario
                  })
                }}
                descuento={(usuario) => {
                  this.setState({
                    isModalAgusteMontoVisible: true,
                    usuarioSelected: usuario
                  })
                }}
              />
              <Pagination
                  items={this.state.usuariosFiltrados}
                  pageSize={this.state.pageSize}
                  initialPage= {this.state.currentPage}
                  onChangePage={(items, currentPage) => {
                      this.setState({ 
                          currentPageItems: items,
                          currentPage: currentPage 
                      })}
                  }
              />
          </Fragment>
        }
          
        </Card>

        {
          this.state.isModalDetailVisible &&
          <MontosDetalle
            usuario={this.state.usuarioSelected}
            cerrar= {() => {
              this.setState({
                  isModalDetailVisible : false
              })
            }}
          />
        }

        {
          this.state.isModalAgusteMontoVisible &&

          <MontosDescuento
            usuario={this.state.usuarioSelected}
            save = {(usuario, descuento) => {
              this.saveDescount(usuario, descuento)
            }}
            cerrar= {() => {
              this.setState({
                isModalAgusteMontoVisible: false
              })
            }}
          />
        }

        <Loading
          isFullscreen={true}
          isLoading={this.state.isLoading}
          width="100px"
          height="100px"
        />

      </div>
    )
  }
}

export default MontosUsuarios;