import React, { Component } from 'react'
import { Document, Page } from 'react-pdf';

class Presentacion extends Component {

    state = {
        numPages: null,
        pageNumber: 1,
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    handlePageChange = (page) => {
        if (page === 0 || page > this.state.numPages) {
            return
        }

        this.setState({ pageNumber: page })
    }

    render() {
        const { pageNumber, numPages } = this.state;

        return (

            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content" style={{ width: "60%" }}>
                    <div className="box">
                        <h3 className="title is-3 text-center">Presentación</h3>

                        <div style={{ textAlign: "center" }}>
                            <Document
                                file='https://drive.google.com/file/d/1WLn2gwNZQzcNclFUlyTIXTxi66FcrsbE/view'
                                // file='https://1drv.ms/b/s!Av7qFwpQkvZJjBbdaZfLdBJwQOtj'
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                loading="Cargando presentación..."
                                className="text-center"
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <div>
                                <p style={{ display: "inline-flex" }}>
                                    <span className="mr-10">Página</span>
                                    <input className="input"
                                        type="number"
                                        placeholder="Página"
                                        value={this.state.pageNumber}
                                        onChange={(event) => this.handlePageChange(Number(event.target.value))}
                                    />
                                    <span className="ml-10 mr-10">de</span> {numPages}
                                </p>
                            </div>
                            <button
                                className="button"
                                onClick={() => { this.handlePageChange(this.state.pageNumber - 1) }}
                            >
                                <span>Anterior</span>
                            </button>
                            <button
                                className="button"
                                onClick={() => { this.handlePageChange(this.state.pageNumber + 1) }}
                            >
                                <span>Siguiente</span>
                            </button>
                        </div>

                        <br />
                        <div className="buttons are-medium">
                            <button className="button" onClick={this.props.close}>
                                <span>Cerrar</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>




        );
    }

}

export default Presentacion;