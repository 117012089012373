import React from 'react';
import { Button } from '../../util/Button/Button';
import { formatMonth, formatYear } from './HistorialEvaluacionesUtils';

export function HistorialEvaluacionesTableTitle({authId, user, year, month, loading, setDetails, row, isMain=false}) {
    const tagStyle = {
        backgroundColor: isMain ? "#6aa84f" : "#ccc",
        lineHeight: "1em",
    }

    function formatTag() {
        if (isMain) {
            return <span className="is-size-7" style={tagStyle}>Indicadores en curso</span>
        } else {
            return <span className="is-size-7" style={tagStyle}>Evaluación {formatMonth(month)}-{formatYear(year)}</span>
        }
    }

    return (
        <div className="columns">
            <div className="column is-6">
                <span className="is-size-6 has-text-weight-semibold">
                    {authId} - {user} {formatTag()}
                </span>
            </div>

            <div className="column is-6" style={{ textAlign: "right" }}>
                {
                    isMain ? '' : <Button
                        icon="fa-check-circle"
                        type="is-success"
                        title="Ver variables"
                        text="Ver variables"
                        disabled={loading}
                        onClick={() => setDetails(row)}
                    />
                }
            </div>
        </div>
    )
}