import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { MessageError } from '../../util/Notifications/MessageError'
import { Card } from '../../util/Card/Card'
import { InputSearch } from '../../util/Input/InputSearch'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading'
import NoItems from "../../util/noitems/NoItems";
import Confirmar from '../../util/propuestas/Confirmar';
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import ReactPaginate from 'react-paginate';

import GrupoIndicadores from '../IndicadoresAdministracion/Grupo_indicadores'
import TablaGrupos from '../IndicadoresAdministracion/Indicadores_tabla'
import AsignacionIndicadores from '../IndicadoresAdministracion/Indicadores_asignacion'
import GrupoIndicadoresAPI from '../../../services/indicadores_variables/GrupoIndicadores'
import UsuariosAPI from '../../../services/Usuario'

class IndicadoresAdministracion extends Component {    

    toastSuccessId = null
    toastErrorId = null
    toastWarnId = null
    toastInfoId = null

    constructor(props) {
        super(props);

        this.grupo_indicadores_selected = {
            nombre : '',
            indicadores : []
        }
        
        this.state = {
            name_filter : '',
            grupo_indicadores : [],
            grupo_indicadores_filtered : [],
            grupo_indicadores_edit: {},
            grupo_indicadores_delete: {},
            listUsers : [],
            listUsersJefesDirectos : [],
            selectedUsers : [],
            listUsersByAsign: [],
            filtros : {},
            showCapturaIndicadores: false,
            showEditIndicadores : false,
            showEliminarIndicadores : false,
            showAsignacionUsuarios : false,
            isLoading : true,
            pagination : null
        }
    }

    handleLoadData = () => {
        this.showLoading(true)
        const state = {...this.state}
   
        Promise.all([
            UsuariosAPI.getEvaluadoresKPIs().then(res => {
                this.setState({
                    listUsers: res.data,
                    agregarDisabled: false,
                })
                }).catch(err => {
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
                }),
            UsuariosAPI.getJefesDirectosKPIs().then(res => {
                this.setState({
                    listUsersJefesDirectos: res.data,
                    agregarDisabled: false,
                })
                }).catch(err => {
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
            }),
            GrupoIndicadoresAPI.get_group_list_by_users()
            .then(responseList => {
                let grupo_indicadores = responseList.data.results
                this.setState({
                    grupo_indicadores : grupo_indicadores,
                    grupo_indicadores_filtered : grupo_indicadores,
                    pagination : responseList.data.pagination
                })
            }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })
        ]).then( res => {
            this.setState({ isLoading: false });
        })
      }

    componentWillMount() {
        this.handleLoadData()
    }

    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    save = (data, isDraft) => {
        this.showLoading(true);

        let grupo_indicadores_to_save = data

        grupo_indicadores_to_save.borrador = isDraft

        if(grupo_indicadores_to_save.id && grupo_indicadores_to_save.id != 0){

            GrupoIndicadoresAPI.update(grupo_indicadores_to_save, grupo_indicadores_to_save.id)
            .then(response => {
                    this.grupo_indicadores_selected = {
                        nombre : '',
                        indicadores : []
                    }

                    this.setState({
                        grupo_indicadores_filtered : []
                    }, () => {
                        GrupoIndicadoresAPI.get_group_list_by_users()
                        .then(responseList => {
                            let grupo_indicadores = responseList.data.results
                            this.setState({                            
                                showEditIndicadores : false,
                                isLoading : false,
                                grupo_indicadores : grupo_indicadores,
                                grupo_indicadores_filtered : grupo_indicadores,
                                pagination : responseList.data.pagination
                            })
                            
                            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
                        
                        }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })

                    })

                    
                   
            })
            .catch(err => {
                this.showLoading(false);
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
            })

        }
        else{
            GrupoIndicadoresAPI.create(grupo_indicadores_to_save)
            .then(response => {
                this.setState({
                    grupo_indicadores_filtered : []
                    }, () => {
                        GrupoIndicadoresAPI.get_group_list_by_users()
                        .then(responseList => {
                            let grupo_indicadores = responseList.data.results
                            this.setState({                            
                                showEditIndicadores : false,
                                isLoading : false,
                                grupo_indicadores : grupo_indicadores,
                                grupo_indicadores_filtered : grupo_indicadores,
                                pagination : responseList.data.pagination
                            })
                            
                            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
                        
                        }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })

                    }
                )
            })
            .catch(err => {
                this.showLoading(false);
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
            })
        }

        
    };

    delete = () => {
        this.showLoading(true);
        let state = {...this.state}

        let grupo_indicadores_delete = state.grupo_indicadores_delete

        GrupoIndicadoresAPI.delete(grupo_indicadores_delete.id)
        .then(response => {
            this.setState({
                grupo_indicadores_filtered : []
                }, () => {
                    GrupoIndicadoresAPI.get_group_list_by_users()
                    .then(responseList => {
                        let grupo_indicadores = responseList.data.results
                        this.setState({                            
                            showEditIndicadores : false,
                            isLoading : false,
                            grupo_indicadores : grupo_indicadores,
                            grupo_indicadores_filtered : grupo_indicadores,
                            pagination : responseList.data.pagination
                        })
                        
                        if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
                    
                    }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })

                }
            )
        })
        .catch(err => {
            this.showLoading(false);
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
        })

        

        
    };
    
    asignar = (id_grupo, list_usuarios, list_usuarios_no_asignados) => {
        
        this.showLoading(true);

        GrupoIndicadoresAPI.asignacion(id_grupo, list_usuarios)
        .then(response => {            
            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
            this.setState({
                showAsignacionUsuarios:false,
                listUsersByAsign: list_usuarios_no_asignados,
                isLoading : false
            })
        })
        .catch(err => { 
            if (!toast.isActive(this.toastErrorId)) 
                this.toastErrorId = toast.error('Error al actualizar la asignacion de usuarios')
            this.showLoading(false); 
        })
        
    }

    handleChangeInputUsuarios = userList => {
        if ( userList.length !== 0 ) {
            this.setState({ selectedUsers: userList });
        }
        else
            this.setState({ selectedUsers: [] });
    }

    handleApplyFilters = (page) => {
        
        this.showLoading(true)

        let filters = {}

        if(this.state.selectedUsers.length > 0)        
            filters = {
                'usuario_ids' : this.state.selectedUsers.map(elemen => {return elemen.id})
            }
        
        let pageFilter = {
            'page' : page
        }
        
        Promise.all([
            GrupoIndicadoresAPI.get_group_list_by_users(filters, pageFilter)
            .then(responseList => {
                let grupo_indicadores = responseList.data.results
                this.setState({
                    grupo_indicadores : grupo_indicadores,
                    grupo_indicadores_filtered : grupo_indicadores,
                    pagination : responseList.data.pagination
                })
            }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    handleAssignation = (usuario_id, grupo_indicadores) => {
        
        this.showLoading(true)
        
        let filter = {
            'usuario_id' : usuario_id
        }

        Promise.all([
            GrupoIndicadoresAPI.get_usuarios_por_asignar_administrador(filter).then(res => {
                this.setState({
                listUsersByAsign: res.data,
                showAsignacionUsuarios: true,
                grupo_indicadores_to_assign : grupo_indicadores
                })
                
              }).catch(err => {                
                console.log('Error al cargar usuarios para asignar: ', err)
                toast.error("Usuarios para asignar no cargados")
              })            
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    render(){
        const isAdmin = localStorage.role.split(',').includes('Administrador KPIs')

        let selectedUsers = this.state.selectedUsers.map(usuario => {
            let user = {
                id: usuario.id,
                value: usuario.nombre
            }
            return user
        })

        return (
            <Fragment>
                <Card title="Indicadores Variables" >
                    <div>
                        <div className="columns">
                            <MultiSelect
                                name="usuarios"
                                label="Usuarios"
                                items={this.state.listUsersJefesDirectos.map(usuario => {
                                    let newUsuario = { ...usuario }
                                    newUsuario.value = `${usuario.id_empleado} - ${usuario.nombre}`

                                    return newUsuario
                                })}
                                selectedItems={selectedUsers}
                                onChangeValue={usuariosList => this.handleChangeInputUsuarios(usuariosList)}
                            />
                            <div className="column is-6" style={{ textAlign: 'right' }} >
                                <Button
                                    icon="fa-search"
                                    type="is-success"
                                    onClick={() => {this.handleApplyFilters(1)}}
                                    title="Aplicar filtros"
                                    text="Aplicar filtros"
                                />
                            </div>
                        </div>
                    </div>

                </Card>

                <br />

                {
                    this.state.pagination 
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleApplyFilters(page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }

                <Card >
                {   
                    this.state.grupo_indicadores_filtered.length > 0 ?
                    this.state.grupo_indicadores_filtered.map(obj => {
                        return(
                            <div className="card" key={obj.id}>
                                <div className="card-content CardProposal">
                                    <div className="mb-10">
                                        <div className="Row">
                                            <div className="columns">
                                                <div className='column is-4'><span className="title is-5" style={{ marginBottom: "0" }}>{obj.id_empleado} - {obj.nombre}</span></div>
                                                {
                                                    obj.grupo_indicadores.length > 0 &&
                                                    <div className="column is-8" style={{ textAlign: 'right' }} >
                                                        <Button
                                                            icon="fa-user"
                                                            type="is-info"
                                                            onClick={() => this.handleAssignation(obj.id, obj.grupo_indicadores)}
                                                            title="Asignación de usuarios"
                                                            text="Asignación de usuarios"
                                                        />
                                                    </div>

                                                }
                                                
                                            </div>                                
                                        </div>
                                    
                                    </div>
                                    {obj.grupo_indicadores.map(grupoObj => {
                                        return(
                                            <div className="card" key={grupoObj.id}>
                                                <div className="card-content CardProposal">
                                                    <div className="mb-10">
                                                        <div className="Row">
                                                            <span className="title is-5" style={{ marginBottom: "0" }}>{grupoObj.nombre}</span>
                                                            
                                                            {grupoObj.borrador ?
                                                                <span
                                                                className={`tag is-light ml-10`}
                                                                style={{
                                                                    background: 'rgb(255, 152, 152)',
                                                                    color: 'white',
                                                                    border: '1px solid #ff5757'
                                                                }}
                                                                >
                                                                <span
                                                                    className={`icon is-small`}
                                                                    style={{ color: 'white !important' }}
                                                                >
                                                                    <i className={`fa fa-info-circle`} />
                                                                </span>
                                                                <span style={{ marginLeft: '3px' }}>Borrador</span>
                                                                </span>:
                                                                <span className={`tag ml-10`}>
                                                                    Indicadores en curso
                                                                </span>
                                                            }
                                                        
                                                        </div>
                            
                                                        <div className="is-pulled-right is-large Row">  
  
                                                            <Button
                                                                text="Editar"
                                                                onClick={() => this.setState({ showEditIndicadores: true, grupo_indicadores_edit: grupoObj })}
                                                                className="is-small"
                                                                icon="fa-edit"
                                                                type="is-warning"
                                                            /> 
                                                            <Button
                                                                text="Eliminar"
                                                                onClick={() => this.setState({ showEliminarIndicadores: true, grupo_indicadores_delete: grupoObj })}
                                                                className="is-small"
                                                                icon="fa-solid fa-trash"
                                                                type="is-danger"
                                                            />
                                                        
                                                        </div>
                                                    </div>
                            
                                                    <TablaGrupos
                                                        grupo_indicador={grupoObj}
                                                        usuarios = {this.state.listUsers}
                                                        isAdmin={isAdmin}
                                                    />
                        
                                                </div> {/* div card-content */}
                                            </div>
                                        )})
                                    }
                                    
                                </div>
                            </div>
                            
                        )
                    
                    }): (
                        !this.state.isLoading &&
                        <Fragment>
                          <div style={{ textAlign: "center" }}>
                            <span className="tag is-warning is-large mt-10">
                              <NoItems itemName={" resultados"} />
                            </span>
                          </div>
                        </Fragment>
                      )
                }

                {
                    this.state.isLoading &&
                    <Loading
                      isFullscreen={true}
                      isLoading={this.state.isLoading}
                      width="100px"
                      height="100px"
                    />
                    
                }
                
                </Card>

                <br />

                {
                    this.state.pagination 
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleApplyFilters(page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }

                

                {
                    this.state.showEliminarIndicadores &&
                    <Confirmar
                        yes={this.delete}
                        no={() => this.setState({   
                            showEliminarIndicadores: false 
                        })}>
                        
                        <h4>¿Desea eliminar el indicador?</h4>
                        <p>Los indicadores borraran, perdera sus cambios realizados</p>
                    </Confirmar>
                    }

                {/** Elaborar grupo de indicadores */}
                {
                    this.state.showCapturaIndicadores &&
                        <GrupoIndicadores show={this.state.showCapturaIndicadores}
                            cancel={() => this.setState({ showCapturaIndicadores: false }, () => {
                                this.grupo_indicadores_selected = {
                                    nombre : '',
                                    indicadores : []
                                }
                            })}
                            save={(indicators, isDraft) => this.save(indicators, isDraft)}
                            grupo_indicadores={this.grupo_indicadores_selected}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsers}>
                            
                        </GrupoIndicadores>
                }

                {
                    this.state.showEditIndicadores &&
                        <GrupoIndicadores show={this.state.showEditIndicadores}
                            cancel={() => this.setState({ showEditIndicadores: false }, () => {
                                this.grupo_indicadores_edit = {}
                            })}
                            save={(indicators, isDraft) => this.save(indicators, isDraft)}
                            grupo_indicadores={this.state.grupo_indicadores_edit}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsers}>
                            
                        </GrupoIndicadores>
                }

                {
                    this.state.showAsignacionUsuarios &&
                        <AsignacionIndicadores show={this.state.showAsignacionUsuarios}
                            cancel={() => this.setState({ showAsignacionUsuarios: false })}
                            save={(id_grupo, list_usuarios, list_usuarios_no_asignados) => this.asignar(id_grupo, list_usuarios, list_usuarios_no_asignados)}
                            grupos_indicadores={this.state.grupo_indicadores_to_assign}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsersByAsign}>
                            
                        </AsignacionIndicadores>
                }
                
            </Fragment>
        )

    }

}

export default IndicadoresAdministracion;