import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import { toast } from 'react-toastify';
import ConsultaEvaluacionesAPI from '../../../services/ConsultaEvaluaciones';
import { HistorialEvaluacionesTable } from './HistorialEvaluacionesTable';
import Loading from '../../util/loading/Loading'
import NoItems from '../../util/noitems/NoItems';
import { debounce } from 'lodash';
import { HistorialEvaluacionesDetails } from './HistorialEvaluacionesDetails';
import ReactPaginate from 'react-paginate';
import HasAccess from '../../util/hasAccess/HasAccess';


class HistorialEvaluaciones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: undefined,
            details: undefined,
            pagination: undefined,
            modalOpen: false,
            nivelesCumplimiento: [],
            filters: {
                anio: String(new Date().getFullYear()),
                mes: "",
                page: 1,
            },
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.setState({loading: true});
        ConsultaEvaluacionesAPI.getNivelCumplimiento().then(response => {
            this.setState({nivelesCumplimiento: response.data});
            return ConsultaEvaluacionesAPI.consultar(this.state.filters);
        }).then(response => {
            this.setState({
                data: response.data.results,
                pagination: response.data.pagination,
            });
        }).catch(err => {
            let message = "Error al cargar las evaluaciones";
            if (err && err.response && err.response.data) {
                message = err.response.data.message;
            }
            toast.error(message);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    handleChangeFilter(key, value) {
        const filters = {...this.state.filters};
        filters[key] = value;
        this.setState({filters: filters}, () => {
            this.getData();
        });
    }

    handleDownloadData = () => {
        this.setState({loading: true});
        ConsultaEvaluacionesAPI.downloadExcel(this.state.filters).then(response => {
            const now = new Date()
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.data);
            a.download = `Historial de Evaluaciones - ${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
            a.click();
            toast.success("Archivo descargado con éxito.");
        }).catch(err => {
            toast.error("No se pudo descargar el archivo.");
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    handleDownloadEstatusData = () => {
        this.setState({loading: true});
        ConsultaEvaluacionesAPI.downloadEstatusExcel(this.state.filters).then(response => {
            const now = new Date()
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.data);
            a.download = `Historial de Evaluaciones - ${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
            a.click();
            toast.success("Archivo descargado con éxito.");
        }).catch(err => {
            toast.error("No se pudo descargar el archivo.");
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    handleDebouncedOnChangeFilter = debounce(this.handleChangeFilter, 1500, {maxWait: 2000});

    closeModal = () => {
        this.setState({modalOpen: false});
    }

    setDetails = (details) => {
        this.setState({
            details: details,
            modalOpen: true,
        });
    }

    render() {
        return (
            <Fragment>
                <Card title="Historial de evaluaciones">
                    
                    <div className="columns">
                        <div className="column is-3">
                            <div className="field">
                                <label className="label">Año:</label>
                                <div className="control">
                                    <input className={"input"} type="text" pattern="\d*" placeholder="Año"
                                        name="anio"
                                        maxLength="4"
                                        defaultValue={new Date().getFullYear()}
                                        disabled={this.state.loading}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g,'');
                                            this.handleDebouncedOnChangeFilter("anio", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="column is-3">
                            <div className="field">
                                <label className="label">Mes:</label>
                                <div className="select is-fullwidth">
                                    <select placeholder="Mes"
                                        name="mes"
                                        disabled={this.state.loading}
                                        value={this.state.filters.mes}
                                        onChange={(e) => this.handleChangeFilter("mes", e.target.value)}
                                    >
                                        <option value="">-</option>
                                        <option value="1">Enero</option>
                                        <option value="2">Febrero</option>
                                        <option value="3">Marzo</option>
                                        <option value="4">Abril</option>
                                        <option value="5">Mayo</option>
                                        <option value="6">Junio</option>
                                        <option value="7">Julio</option>
                                        <option value="8">Agosto</option>
                                        <option value="9">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="column is-flex" style={{ alignItems: 'flex-end' }} >                        
                        <Button
                            icon="fa-save"
                            type="is-success"
                            onClick={this.handleDownloadData}
                            title="Descargar historial"
                            text="Descargar historial"
                            disabled={this.state.loading || (this.state.data && this.state.data.length === 0)}
                        />
                        </div>
                    </div>
                </Card>
                <br />
                {
                    this.state.pagination && this.state.pagination.pages > 1
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleChangeFilter('page', page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }
                <Card>
                    { this.state.data && this.state.data.length === 0
                        ? <div style={{ textAlign: "center" }}><span className="tag is-warning is-large mt-10"><NoItems itemName={"evaluaciones"}/></span></div>
                        : <HistorialEvaluacionesTable data={this.state.data} loading={this.state.loading} setDetails={this.setDetails} nivelesCumplimiento={this.state.nivelesCumplimiento}/>
                    }
                </Card>

                <br />

                {
                    this.state.pagination && this.state.pagination.pages > 1
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleChangeFilter('page', page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.loading}
                    width="100px"
                    height="100px"
                />

            {
                this.state.details
                    ? <HistorialEvaluacionesDetails
                        open={this.state.modalOpen}
                        closeModal={this.closeModal}
                        details={this.state.details}
                        nivelesCumplimiento={this.state.nivelesCumplimiento}
                    />
                    : ''
            }

            </Fragment>
        )
    }
}

export default HistorialEvaluaciones;