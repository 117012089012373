import React, { Fragment } from 'react'

/**
 * Componente Input para busqueda.
 * 
 * @param {Object} props
 * 
 * @returns InputSearch
 */
export const InputSearch = ({
  name,
  type,
  maxLength,
  placeholder,
  value,
  disabled,
  changeInput,
  list
}) => {
  return (
    <input
      className="input"
      autoComplete="off"
      name={name}
      type={type}
      maxLength={maxLength}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={changeInput}
      list={list}
    />
  )
}