import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../util/Button/Button';
import { Modal } from '../util/modal/ModalLayout';
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import Select from '../usuario/Selct';
import SolicitudesCompensacionApi from '../../services/SolicitudesCompensacion';
import Loading from '../util/loading/Loading'


/**Nueva Segmento. */
export class SegmentoCreate extends Component {
    state = {
        Segmento: this.props.Segmento,
        isLoading: false,
        formValid: true,
        error: {
            nombre: false,
            nomenclatura: false
        },
    }

    validateForm = () => {
        let [ valid, error ] = this.handleValidateData();
        let formValid = this.state.formValid;

        formValid = valid;

        this.setState({ formValid });

        if ( formValid ) {
            this.props.save(this.state.Segmento)
        } else {
            toast.error('El formulario es incorrecto.');
        }
    }

    handleValidateData = () => {
        let {Segmento} = this.state
        let valid = { ...this.state.valid };
        let error = { ...this.state.error };

        valid = true

        if (Segmento.nombre == null || Segmento.nombre.trim().length === 0) {
            valid = false
            error.nombre = true
        }
        else{error.nombre = false}

        if (Segmento.nomenclatura == null || Segmento.nomenclatura.trim().length === 0) {
            valid = false
            error.nomenclatura = true
        }
        else{error.nomenclatura = false}

        

        this.setState({ valid, error });
        return [ valid, error ];
    }

    render() {
        let Segmento = this.state.Segmento
        return (
            <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card">

                    <header className="modal-card-head">
                        {
                            Segmento.id > 0 &&
                            <p className="modal-card-title">Editar Segmento</p>
                            
                        } 
                        {
                            Segmento.id == 0 &&
                            <p className="modal-card-title">Crear Segmento</p>
                            
                        }
                        
                        <button onClick={this.props.cancel} className="delete" title="Cerrar" aria-label="close"></button>
                    </header>

                    <section className="modal-card-body">

                        <div className="column">
                            <div className="field">
                                <label className="label">Nombre</label>
                                <div className="control">
                                    <input className={this.state.error.nombre  ? "input is-danger" : "input"} type="text" placeholder="Nombre"
                                        autoFocus={true}
                                        name="nombre"
                                        value={Segmento.nombre}
                                        onChange={({target}) => {
                                            Segmento.nombre = target.value
                                            this.setState({
                                                Segmento : Segmento
                                            })
                                        }}
                                    />
                                </div>
                                {this.state.error.nombre && <p className="help is-danger">Este campo no debe estar vacío</p>}
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">Nomenclatura</label>
                                <div className="control">
                                    <input className={this.state.error.nomenclatura  ? "input is-danger" : "input"} type="text" placeholder="Nomenclatura"
                                        autoFocus={true}
                                        name="nombre"
                                        value={Segmento.nomenclatura}
                                        onChange={({target}) => {
                                            Segmento.nomenclatura = target.value
                                            this.setState({
                                                Segmento : Segmento
                                            })
                                        }}
                                    />
                                </div>
                                {this.state.error.nomenclatura && <p className="help is-danger">Este campo no debe estar vacío</p>}
                            </div>
                        </div>
                        
                    </section>

                    <footer className="modal-card-foot">
                    {/* Botón para guardars*/}
                        <Button
                            onClick={this.validateForm}
                            text="Guardar"
                            icon="fa-check"
                            disabled = {
                                this.state.Segmento.nombre == ""
                            }
                        />
                    
                    {/* Botón para cancelar */}
                        <Button
                            onClick={()=> {
                                this.props.cancel()
                            }}
                            text="Cancelar"
                            type="is-danger"
                            icon="fa-ban"
                        />
                    </footer>

                </div>

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

        )
    }
}

export default SegmentoCreate;