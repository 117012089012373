import React, { Component } from 'react'

// Components
import { Icon } from '../../util/Icon/Icon'
import { InputSearch } from '../../util/Input/InputSearch'
import { DatePicker } from '../../util/DatePicker/DatePicker'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'

// Utilities
import moment from 'moment'

export class FormConvocatoria extends Component {

  state = {
    title: '',
    isEditing: false,
    data: {}
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      title: this.props.title
    })
  }

  /**
   * Valida las fechas del form.
   * 
   * @param {String} name
   * @param {String} date
   * 
   * @returns Boolean
   */
  validInputDate = ({ target }) => {
    const TODAY = moment(new Date()).format('YYYY-MM-DD')
    const DATE = moment(target.value).format('YYYY-MM-DD')
    let data = {
      target: {
        name: target.name,
        value: TODAY
      }
    }

    if (target.name === 'fecha_inicio') {
      if (DATE < TODAY) {
        this.handleOnChangeValue(data)
      }
    } else {
      data = {
        target: {
          name: target.name,
          value: target.value,
        }
      }
      this.handleOnChangeValue(data)
    }
  }

  /**
   * Valida las fechas del formulario segun sea para cada caso.
   * 
   * @param {String} name
   * @param {String} value
   * 
   * @returns Boolean
   */
  isValidDate({ name, value }) {
    let isValid = true
    switch (name) {
      case 'fecha_publicacion':
        break
      case 'fecha_inicio':
        break
      case 'fecha_final':
        break
      case 'fecha_inicio_alta':
        break
      case 'fecha_fin_alta':
        break
      case 'fecha_inicio_evaluacion':
        break
      case 'fecha_fin_evaluacion':
        break
    }
    return isValid
  }

  getReasignatedDates = ({ name, value }, prevData) => {
    let convocatoria = {
      ...prevData,
      [name]: value
    }

    switch (name) {
      case 'fecha_inicio':
        delete convocatoria.fecha_final
        // delete convocatoria.fecha_inicio_alta
        // delete convocatoria.fecha_fin_alta
        // delete convocatoria.fecha_inicio_evaluacion
        // delete convocatoria.fecha_fin_evaluacion
        break
      case 'fecha_final':
        // delete convocatoria.fecha_fin_alta
        // delete convocatoria.fecha_inicio_evaluacion
        // delete convocatoria.fecha_fin_evaluacion
        if (moment(value).diff(convocatoria.fecha_inicio, 'days') < 1)
          delete convocatoria.fecha_final
        break
      case 'fecha_inicio_alta':
        delete convocatoria.fecha_fin_alta
        // if (moment(value).format('YYYY-MM-DD') > convocatoria.fecha_final)
        //   delete convocatoria.fecha_inicio_alta
        break
      case 'fecha_fin_alta':
        if (moment(value).diff(convocatoria.fecha_inicio_alta, 'days') < 1)
          delete convocatoria.fecha_fin_alta
        break
      case 'fecha_inicio_evaluacion':
        delete convocatoria.fecha_fin_evaluacion
        break
      case 'fecha_fin_evaluacion':
        if (moment(value).diff(convocatoria.fecha_inicio_evaluacion, 'days') < 1)
          delete convocatoria.fecha_fin_evaluacion
        break
    }

    return convocatoria
  }

  /**
   * Maneja los cambios de los inputs del form.
   * 
   * @param {Object} target
   */
  handleOnChangeValue = ({ target }) => {
    const { data } = this.state

    if (this.isValidDate(target)) {
      this.setState({
        data: this.getReasignatedDates(target, data)
      })

      this.props.changeData(this.getReasignatedDates(target, data))
    }
  }

  render() {
    const TODAY = moment(new Date()).format('YYYY-MM-DD')
    const { title, isEditing, data } = this.state

    return (
      <form>
        <div style={{ margin: '0 10px' }}>
          <div className="column is-flex" style={{ alignItems: 'baseline' }}>
            <Icon icon="fa-bullhorn" color="has-text-info" size="fa-2x" />
            <h4 className="title is-4" style={{ marginLeft: 15 }}>{title}</h4>
          </div>
          {!isEditing &&
            <span>Ingresa fecha inicio y fin de cada periodo que incluye la convocatoria:</span>
          }
        </div>
        <Wrapper label="Nombre de la convocatoria">
          <InputSearch
            name="nombre"
            type="text"
            placeholder="Mi convocatoria"
            changeInput={this.handleOnChangeValue}
            value={data.nombre || ''}
            maxLength={30}
          />
        </Wrapper>
        <span
          className="is-size-6 has-text-weight-bold"
          style={{ marginLeft: 12 }}
        >
          Periodo de convocatoria
        </span>
        <div className="columns">
          <div className="column is-half">
            <Wrapper label="Fecha publicación">
              <DatePicker
                name="fecha_publicacion"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_publicacion || ''}
                initial={TODAY}
                validation={this.validInputDate}
              />
            </Wrapper>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <Wrapper label="Fecha inicio">
              <DatePicker
                name="fecha_inicio"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_inicio || ''}
                initial={TODAY}
                validation={this.validInputDate}
              />
            </Wrapper>
          </div>
          <div className="column is-half">
            <Wrapper label="Fecha fin">
              <DatePicker
                name="fecha_final"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_final || ''}
                initial={
                  moment(data.fecha_inicio).add('days', 1).format('YYYY-MM-DD') ||
                  moment(TODAY).add('days', 1).format('YYYY-MM-DD')
                }
                validation={this.validInputDate}
                disabled={data.fecha_inicio ? false : true}
              />
            </Wrapper>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginLeft: 15 }}
            >
              Solicitar propuestas
            </span>
            <Wrapper label="Fecha inicio">
              <DatePicker
                name="fecha_inicio_alta"
                placeholder="Mi convocatoria"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_inicio_alta || ''}
                validation={this.validInputDate}
                disabled={data.fecha_final ? false : true}
              // initial={data.fecha_inicio ? data.fecha_inicio : TODAY}
              // final={data.fecha_final ? data.fecha_final : ''}
              />
            </Wrapper>
            <Wrapper label="Fecha fin">
              <DatePicker
                name="fecha_fin_alta"
                placeholder="Mi convocatoria"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_fin_alta || ''}
                initial={
                  moment(data.fecha_inicio_alta).add('days', 1).format('YYYY-MM-DD') ||
                  moment(TODAY).add('days', 1).format('YYYY-MM-DD')
                }
                validation={this.validInputDate}
                disabled={data.fecha_inicio_alta ? false : true}
              // final={data.fecha_final ? data.fecha_final : ''}
              />
            </Wrapper>
          </div>
          <div className="column is-half">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginLeft: 15 }}
            >
              Solicitar autoevaluaciones
          </span>
            <Wrapper label="Fecha inicio">
              <DatePicker
                name="fecha_inicio_evaluacion"
                placeholder="Mi autoevaluacion"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_inicio_evaluacion || ''}
                validation={this.validInputDate}
                disabled={data.fecha_final ? false : true}
              // initial={data.fecha_inicio ? data.fecha_inicio : TODAY}
              // final={data.fecha_final ? data.fecha_final : ''}
              />
            </Wrapper>
            <Wrapper label="Fecha fin">
              <DatePicker
                name="fecha_fin_evaluacion"
                placeholder="Mi autoevaluacion"
                changeInput={this.handleOnChangeValue}
                value={data.fecha_fin_evaluacion || ''}
                initial={
                  moment(data.fecha_inicio_evaluacion).add('days', 1).format('YYYY-MM-DD') ||
                  moment(TODAY).add('days', 1).format('YYYY-MM-DD')
                }
                // final={data.fecha_final ? data.fecha_final : ''}
                validation={this.validInputDate}
                disabled={data.fecha_inicio_evaluacion ? false : true}
              />
            </Wrapper>
          </div>
        </div>
      </form>
    )
  }
}