import React, { Component } from 'react'

// Styles
import './AutoEvaluacion.css'

// Utilities
import IndicadorAPI from '../../services/IndicadoresSvc'
import PropuestasAPI from '../../services/Propuestas'
import { toast } from 'react-toastify'
import Loader from '../util/loading/Loading';

// Components
import Select from '../util/select/Selct'
import DragAndDrop from '../util/DragAndDrop/DragAndDrop'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { ListEvidences } from './ListEvidences'
import Confirmar from '../util/propuestas/Confirmar'
import { Modal } from '../util/modal/ModalLayout'
import { Button } from '../util/Button/Button'


const LEVEL = [
  {
    id: 1,
    value: 'sobre_meta',
    text: 'SOBRE META'
  },
  {
    id: 2,
    value: 'meta',
    text: 'META'
  },
  {
    id: 3,
    value: 'bajo_meta',
    text: 'BAJO META'
  },
  {
    id: 4,
    value: 'inaceptable',
    text: 'INACEPTABLE'
  }
]

const TIPO_EVIDENCIA = [
  {
    id: 1,
    value: false,
    text: 'Archivo'
  },
  {
    id: 2,
    value: true,
    text: 'Texto'
  }
]

/**Formulario para la autoevaluación */
class FormAutoevaluacion extends Component {

  state = {
    isTurboEvaluating: false,
    nivel: null,
    comentario_autoevaluacion: '',
    isLoadingInfo: true,
    proposalSelected: {},
    selectedIndicator: {},
    userName: 'sin nombre',
    idEvidenceToDelete: null,
    isConfirmModalVisible: false,
    tipoEvidencia : null,
    descripcion_evidencia : null
  }

  // Toast constants
  toastSuccessId = null;
  toastErrorId = null;

  /**
   * Carga el archivo y lo sube a BD.
   * 
   * @param {Blob} file
  */
  handleUploadFile = file => {
    this.setState({ isLoadingInfo: true });

    IndicadorAPI.uploadFile( this.state.selectedIndicator.id, file )
      .then(() => {
        this.updateProposal();
        if (!toast.isActive(this.toastSuccessId))
          this.toastSuccessId = toast.success('Evidencia cargada');
      }).catch(() => {
        this.setState({ isLoadingInfo: false });
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error('El archivo no se cargo');
      });
  }

  handleUploadEvidenceText = () => {
    
    this.setState({ isLoadingInfo: true });
    const { descripcion_evidencia } = this.state;

    let description = descripcion_evidencia
    let isText = true

    if(description && description !== ''){
      IndicadorAPI.uploadFile( this.state.selectedIndicator.id, null, description, isText )
      .then(() => {
        this.updateProposal();
        if (!toast.isActive(this.toastSuccessId))
          this.toastSuccessId = toast.success('Evidencia cargada');
      }).catch(() => {
        this.setState({ isLoadingInfo: false });
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error('La evidencia no se cargo');
      });
    }
    else{
      this.setState({ isLoadingInfo: false });
      this.toastErrorId = toast.error('La evidencia de texto no puede ir vacía');
    }

    
  }

  /** Abre el modal de confirmación para eliminar la evidencia. */
  showConfirmDeleteEvidence = (itemId) => {
    this.setState({
      idEvidenceToDelete: itemId,
      isConfirmModalVisible: true
    });
  }

  handleCloseConfirmDeleteEvidence = () => {
    this.setState({
      idEvidenceToDelete: null,
      isConfirmModalVisible: false
    });
  }

  /** Manda a eliminar la evidencia seleccionada. */
  handleDeleteEvidence = () => {
    this.setState({ isLoadingInfo: true });
    const { idEvidenceToDelete } = this.state;

    PropuestasAPI.deleteEvidence(idEvidenceToDelete)
      .then(() => {
        this.updateProposal();
        this.setState({
          idEvidenceToDelete: null,
          isConfirmModalVisible: false,
        });
        if (!toast.isActive(this.toastSuccessId))
          this.toastSuccessId = toast.success('Evidencia eliminada.');
      })
      .catch(() => {
        this.setState({
          isLoadingInfo: false,
          idEvidenceToDelete: null,
          isConfirmModalVisible: false,
        });
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error('No se pudo eliminar la evidencia.');
      })
  }

  /**
   * Maneja las descargas de los archivos de evidencias.
   * 
   * @param {String} path
   */
  handleDownloadFile = (path, name = '') => {
    this.setState({ isFileLoading: true });
    PropuestasAPI.evidenceDownload(path)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        toast.success('Archivo descargado!');
        this.setState({ isFileLoading: false });
      })
      .catch(err => {
        if (err.request.status === 404) {
          toast.error('El archivo no existe')
        } else {
          toast.error('El archivo no se pudo descargar')
        }
        this.setState({ isFileLoading: false });
      })
  }

  /**
   * Obtiene la info de la propuesta actualizada para agregar al state.
   */
  updateProposal = () => {
    PropuestasAPI.get( this.state.proposalSelected.id )
      .then( res => {
        const proposal = res.data;
        const indicator = proposal.indicadores.find( ind => ind.id === this.state.selectedIndicator.id ); 
        const listEvidences = indicator.evidencia;

        if ( this.state.nivel !== null ) indicator.cumplimiento = this.state.nivel;

        this.setState({
          listEvidences,
          isLoadingInfo: false,
          proposalSelected: proposal,
          selectedIndicator: indicator,
        }, () => this.props.onInfoUpdated( indicator ) );
      })
      .catch( () => {
        this.setState({ isLoadingInfo: false });
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error('La información de la propuesta no se actualizó');
      });
  }

  /**
   * Agregar el nivel de cumplimiento a la propuesta y guarda la información.
   */
  handleSaveIndicator = () => {
    let proposal = this.state.proposalSelected;
    let indicatorUpdated = proposal.indicadores.find( ind => ind.id === this.state.selectedIndicator.id );
    indicatorUpdated.cumplimiento = this.state.nivel;
    indicatorUpdated.comentario_autoevaluacion = this.state.comentario_autoevaluacion
    this.props.onInfoUpdated( indicatorUpdated );
    this.props.onClose();
  }

  componentDidMount() {
    const { indicator, proposalInfo } = this.props;
    const selectedIndicator = proposalInfo.indicadores.find( ind => ind.id === indicator.id ) || {};
    const comentario_autoevaluacion = selectedIndicator.comentario_autoevaluacion;
    const nivel = this.props.isTurbo ? 'sobre_meta' : selectedIndicator.cumplimiento !== 'sin_evaluacion'
      ? selectedIndicator.cumplimiento
      : null;

    this.setState({
      nivel,
      comentario_autoevaluacion,
      isTurboEvaluating: this.props.isTurbo, 
      isLoadingInfo: false,
      selectedIndicator: { ...selectedIndicator },
      userName: proposalInfo.usuario.usuario.nombre,
      proposalSelected: { ...this.props.proposalInfo },
    });
  }

  render() {
    const {
      isLoadingInfo, isConfirmModalVisible,
      selectedIndicator, userName, nivel, comentario_autoevaluacion, tipoEvidencia, descripcion_evidencia, isTurboEvaluating
    } = this.state;

    return (
      <Modal
        isVisible={ true }
        closable={ false }
        cancelText="Cerrar"
        onClose={ this.props.onClose }
        title="Evaluación de objetivos"
        confirmText="Guardar evidencias"
        onConfirm={this.handleSaveIndicator }
        onConfirmDisable={ nivel === null || selectedIndicator.evidencia.length === 0 }
      >
        <div className="FormAttachFiles">
          <div className="HeaderContentEvalueate">
            <h2 className="HeaderContentEvalueateUser">
              {
                `Indicador ${selectedIndicator.codigo}
                de ${ userName }`
              }
            </h2>
            <p>Objetivo</p>
            <p className="HeaderContentEvalueateIndicator">
              {`${selectedIndicator.objetivo}`}
            </p>
            {
              selectedIndicator.isEvaluated &&
              <span className="HeaderMsgEvaluatedIndicator">Indicador Evaluado</span>
            }
          </div>
          {
            !isTurboEvaluating &&
            <Wrapper label="Nivel de cumplimiento">
              <Select
                items={LEVEL}
                optionProps={{ value: 'value', text: 'text' }}
                value={ nivel }
                change={ e => this.setState({ nivel: e.target.value }) }
              />
            </Wrapper>
          }

          {
            !isTurboEvaluating &&
            <Wrapper label="Comentarios de autoevaluación">
              <textarea
                className="TextAreaEvaluate"
                value={comentario_autoevaluacion}
                onChange={ e => this.setState({ comentario_autoevaluacion: e.target.value }) }
                maxLength={250}
                placeholder="Agregue un comentario"
                disabled={selectedIndicator.isEvaluated}
              />
            </Wrapper>
          }
          
          <Wrapper label="Tipo de evidencia">
            <Select
              items={TIPO_EVIDENCIA}
              value={tipoEvidencia}
              optionProps={{ value: 'value', text: 'text' }}
              change={ e => this.setState({ tipoEvidencia: e.target.value }) }
            />
          </Wrapper>
          <Wrapper label="Evidencia">
            {tipoEvidencia == 'true' &&

              <div className="column">
                <textarea
                  className="TextAreaEvaluate"
                  value={descripcion_evidencia}
                  onChange={ e => this.setState({ descripcion_evidencia: e.target.value }) }
                  maxLength={250}
                  placeholder="Agregue un comentario"
                  disabled={selectedIndicator.isEvaluated}
                />
                <Button
                  text="Guardar evidencia"
                  icon="fa-save"
                  type="is-warning"
                  disabled={selectedIndicator.isEvaluated}
                  onClick={this.handleUploadEvidenceText}
                />
              </div>
              
              
            }
            {tipoEvidencia == 'false' &&
              <div className="WrappetAttachDropZone">
                <DragAndDrop
                  onFilesLoaded={() => console.log()}
                  onUploadFile={file => this.handleUploadFile(file)}
                  name="indicator_evidences"
                  isMultiple={true}
                />
                {
                  isLoadingInfo &&
                  <progress className="progress is-small is-primary" max="100">15%</progress>
                }
                <span className="InfoAttach">Tamaño límite por archivo 2Mb</span>
              </div>
            }

            
          </Wrapper>
          {
            selectedIndicator.comentario_evaluacion !== '' &&
            <div className="column">
              <span className="label">Comentario de evaluación</span>
              <p>{ selectedIndicator.comentario_evaluacion }</p>
            </div>
          }
          <Wrapper label="Evidencias cargadas">
            <ListEvidences
              dataSet={ selectedIndicator.evidencia }
              onDeleteItem={itemId => this.showConfirmDeleteEvidence(itemId)}
              onDownloadItem={(path, name) => this.handleDownloadFile(path, name)}
            />
          </Wrapper>
          {
            isConfirmModalVisible &&
            <Confirmar
              yes={this.handleDeleteEvidence}
              no={this.handleCloseConfirmDeleteEvidence}
            >
              <p className="ConfirmSubtitle">¿Está seguro que desea eliminar esta evidencia?</p>
              <p className="ConfirmDescription">Las evidencias eliminadas no se podrán recuperar.</p>
            </Confirmar>
          }
          <Loader
            isFullscreen={true}
            isLoading={ isLoadingInfo }
            width="100px"
            height="100px"
          />
        </div>
      </Modal>
    )
  }
}

export default FormAutoevaluacion