import lodash from 'lodash';

/**
 * Obtiene la clase de `CSS` de acuerdo al estatus de la propuesta.
 * 
 * @param {String} status 
 * @returns cls
 */
export const getTagClassByStatus = (status) => {
    let cls = "";
    switch (status) {
        case "Revisión":
            cls = "is-info"
            break;
        case "Comentarios":
            cls = "is-warning"
            break;
        case "Validado":
            cls = "is-success"
            break;
        default:
            cls = "is-info"
            break;
    }

    return cls;
}

/**
 * Verifica si tiene permisos conforme a los reles dados.
 * 
 * @param {Array} ARR_ROLES 
 * @param {Array} ARR_PERMISSIONS 
 * @returns hasAccess
 */
export const HasPermission = (ARR_ROLES, ARR_PERMISSIONS) => {
    let hasAccess = false;
    if (localStorage.length > 0) {
        let roles = localStorage.getItem('role').split(',');
        let permissions = localStorage.getItem('permissions').split(',');
        hasAccess = lodash.intersection(ARR_ROLES, roles).length > 0 || lodash.intersection(ARR_PERMISSIONS, permissions).length > 0
    }
    return hasAccess;
}

export const MAX_OBJETIVOS_TURBO = 6;