import React, { Component, Fragment } from 'react';
import Indicador from './indicador';
import PropTypes from 'prop-types';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import {
  sumarPonderacion,
  calcularCompensacion,
  totalCompensacion,
  validarTotalesIndicadores
} from '../../../util/compensacion-util';
import './propuesta.css'
import { toast } from 'react-toastify';
import { Modal } from '../modal/ModalLayout';
import { MessageError } from '../Notifications/MessageError'
import { Button } from '../Button/Button'
import Confirmar from './Confirmar';
import UsuariosAPI from '../../../services/Usuario'

class PropuestaEdit extends Component {
  constructor(props) {
    super(props);

    this.Indicador = {
      propuesta: 0,
      objetivo: "",
      indicador: "",
      comentario: "",
      sobre_meta: "",
      meta: "",
      bajo_meta: "",
      inaceptable: "",
      revision: "",
      ponderacion: 0,
      ponderacionValid: true,
      compensacion: {},
      tipo: "Normal",
      rel_indicador: []
    }

    this.Indicadores = [...this.props.propuesta.indicadores.filter(obj => obj.tipo !== "Turbo")]
    this.indicadoresValidation = this.Indicadores.map(ind => { return { valid: true } })

    this.state = {
      indicadores: [...this.Indicadores],
      indicadoresIniciales: [],
      isSaveButtonEnable: false,
      totalPonderacion: 0,
      isConfirmNotificationVisible: false,
      totalCompensacion: 0,
      maxIndicadores: 6,
      verNotificacion: true,
      verConfirmar: true,
      Confirmado: true,
      agregarDisabled: false,
      verCompensacion: false,
      validForm: false,
      indicadoresValidation: [...this.indicadoresValidation],
      saveLoading: false,
      newIndicador: false,
      showDeleteConfirm: false,
      indicadorToDelete: {},
      listUsers: [],
      isListUsersLoading: true,
      readedPoliticas: false
    }
  }

  componentDidMount() {
    let state = { ...this.state }

    state.indicadores = state.indicadores.map((obj, i) => {
      obj.ponderacionValid = true;

      const sumatoria = sumarPonderacion(state.indicadores);

      if (sumatoria > 100) obj.ponderacionValid = false;
      state.totalPonderacion = sumatoria;
      return { ...obj };
    });

    state.indicadoresIniciales = this.props.propuesta.indicadores.filter(indicador => indicador.tipo === 'Normal')
    state.indicadores = this.calcularCompensaciones(state.indicadores)
    state.totalCompensacion = totalCompensacion(state.indicadores)
    

    if (sumarPonderacion(state.indicadores) === 100){      
      state.isSaveButtonEnable = true
      state.indicadores = validarTotalesIndicadores(state.indicadores, this.props.usuario)
    }
     
    state.totalCompensacion = totalCompensacion(state.indicadores);

    UsuariosAPI.getAll().then(res => {
      this.setState({
        listUsers: res.data,
        isListUsersLoading: false
      })
    }).catch(err => {
      console.log('Error al cargar usuarios: ', err)
      toast.error("Usuarios no cargados")
    })

    this.setState(state)
  }

  /**
   * Agrega una nueva fila al formulario para
   * ingresar un nuevo indicador.
   */
  handlerClickAgregar() {
    let state = { ...this.state };
    let indicador = { ...this.Indicador };
    let indicadorValidation = { valid: false };

    state.indicadores.push(indicador);
    state.newIndicador = true
    state.indicadoresValidation.push(indicadorValidation);

    if (state.indicadores.length === state.maxIndicadores) state.agregarDisabled = true

    state.isSaveButtonEnable = true

    this.setState(state);
  }

  /**
   * Función que maneja el evento Change del campo ponderación en los indicadores
   * @param {*} pond - Valor ingresada
   * @param {*} index - Indice del objeto en el arreglo que actualziará
   */
  handlePondChange(pond, index) {
    pond = Number(pond) || 0; // Si el valor no es numérico, asigna 0
    const state = { ...this.state };

    state.indicadores.map((obj, i) => {
      if (i === index) {
        obj.ponderacion = pond;
        obj.ponderacionValid = true;
        obj['editado'] = true // Bandera para saber que el objeto se actualizó

        const sumatoria = sumarPonderacion(state.indicadores);

        if (sumatoria > 100) obj.ponderacionValid = false;
        state.totalPonderacion = sumatoria;
      }
      return obj;
    });

    state.indicadores = this.calcularCompensaciones(state.indicadores)
    state.totalCompensacion = totalCompensacion(state.indicadores);

    if (sumarPonderacion(state.indicadores) === 100) {
      state.indicadores = validarTotalesIndicadores(state.indicadores, this.props.usuario)
      state.isSaveButtonEnable = true
    }
    state.totalCompensacion = totalCompensacion(state.indicadores)

    this.setState(state)
  }

  /**
   * Calcula la compensacion de los indicadores.
   * 
   * @param {Array} indicadores
   * @returns Array
   */
  calcularCompensaciones = (indicadores) => {
    return indicadores.map(indicador => {
      indicador.compensacion = calcularCompensacion(indicador, this.props.usuario)
      return indicador;
    })
  }

  /**
   * Actualiza los valores introducidos a los campos
   * del formulario.
   * 
   * @param {Event} event 
   * @param {Number} index 
   * @param {Boolean} valid 
   */
  handleCamposChange(event, index, valid) {
    const state = { ...this.state };
    const indicadoresIdList = state.indicadores.map(indicador => indicador.id)
    const indicadoresInicialesIdList = state.indicadoresIniciales.map(indicador =>
      indicador.id
    ).filter(indicador => indicador !== undefined)
    let name
    let value

    if (typeof (event) !== 'number') {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = 'validate_user'
      value = event
    }

    state.indicadores.map((obj, i) => {
      if (i === index) {
        obj['editado'] = true // Bandera para saber que el objeto se actualizó
        obj[name] = value;
      }
      return obj;
    });

    state.validForm = valid;

    this.setState(state);

    this.handleCheckChanges(indicadoresIdList, indicadoresInicialesIdList)
  }

  /**
   * Verifica los cambios realizados en la propuesta.
   * 
   * @param {Array} idActuales
   * @param {Array} idIniciales
   */
  handleCheckChanges = (idActuales = [], idIniciales = []) => {
    const indicadoresActuales = this.state.indicadores
    const indicadoresIniciales = this.state.indicadoresIniciales
    let hasChanges = []

    if (idActuales.length === idIniciales.length) {
      idIniciales.forEach(function (indicador) {
        if (!idActuales.includes(indicador)) {
          hasChanges.push(true)
        } else {
          let inicial = indicadoresIniciales.filter(indicadorInicial => indicadorInicial.id === indicador)[0]
          let actual = indicadoresActuales.filter(indicadorInicial => indicadorInicial.id === indicador)[0]
          const inicialKeys = Object.keys(inicial)

          inicialKeys.map(key => hasChanges.push(inicial[key] !== actual[key]))
        }
      })
    } else {
      hasChanges.push(true)
    }

    this.setState({
      isSaveButtonEnable: hasChanges.filter(change => change === true).length !== 0
    })
  }

  /**
   * Elimina el objetivo de la lista y actualiza
   * la ponderación y compensación total.
   */
  deleteObjetivo = () => {
    let state = { ...this.state }
    let objetivo = this.state.indicadorToDelete
    let indicadores = this.state.indicadores.filter(indicador => indicador.id !== objetivo.id)

    this.removeObjetivoFromList(objetivo.index, objetivo.id)
    state.showDeleteConfirm = false
    this.setState(state)

    this.updatePonderationAndCompensation(indicadores)
  }

  /**
   * Valida y remueve el indicador seleccionado.
   * 
   * @param {Number} index 
   * @param {Number} id 
   */
  removeObjetivoFromList(index, id) {
    let state = { ...this.state }

    this.props.eliminar(id)
    state.indicadores.splice(index, 1)
    state.indicadoresValidation.splice(index, 1)

    if ((state.indicadores.length + 1) >= state.maxIndicadores) {
      state.agregarDisabled = false
    }

    this.setState(state);
  }

  /**
   * Actualiza la ponderación y compensación total.
   */
  updatePonderationAndCompensation = (indicators) => {
    let ponderacion = sumarPonderacion(indicators)
    let compensacion = totalCompensacion(indicators)

    this.setState({
      totalPonderacion: ponderacion,
      totalCompensacion: compensacion
    })
  }

  /**
   * Remueve el indicador seleccionado.
   * 
   * @param {Number} index 
   * @param {Number} id 
   */
  handleRemoveIndicador(index, id) {
    if (id > 0) {
      let relaciones = this.state.indicadores.filter(indicador =>
        indicador.id === id
      )[0].rel_indicador

      this.setState({
        showDeleteConfirm: true,
        indicadorToDelete: {
          index,
          id,
          relaciones
        }
      })
    } else {
      this.removeObjetivoFromList(index, id)
    }
  }

  /**
   * Guarda los cambios realizados a los indicadores.
   */
  handleSave = (isDraft) => {
    let dataIndicators = this.state.indicadores.map(indicador => {
      let copy = {
        ...indicador
      }
      let user = this.state.listUsers.filter(user => user.id === Number(indicador.validate_user))[0]
      copy.validate_user = user ? user.id : null
      return copy
    })
    this.setState(
      { saveLoading: true },
      () => this.props.save(dataIndicators, isDraft)
    )
  }

  /**
   * Valida los indicadores.
   */
  validateIndicators = (isDraft = true) => {
    let state = { ...this.state }
    let indicadorZeroPond = state.indicadores.find(indicador => indicador.ponderacion === 0)

    if (indicadorZeroPond === undefined) {
      this.handleSave(isDraft)
      this.setState({ saveLoading: false })
    } else toast.error("No puede haber objetivos con ponderación en cero. Favor de verificar.")
  }

  /**
   * Verifica los valores de los inputs del formulario.
   * 
   * @returns Boolean
   */
  isFormValid = () => {
    return this.state.indicadoresValidation.find(obj => obj.valid === false) ? false : true
  }

  render() {
    const indicadores = this.state.indicadores.sort(function (a, b) {
      return a.id - b.id
    })

    return (
      <Fragment>
        <div className={"modal is-active"}>
          <div className="modal-background" />
          <div className="modal-card" style={{ width: "95%" }}>

            <header className="modal-card-head">
              <p className="modal-card-title">
                Editar propuesta
              </p>
              <div style={{ position: "relative", marginRight: "15px" }}>
                <div>
                  <div className="field is-grouped">
                    <p className="control">                    
                      <input type="checkbox" id="confirmation" name="confirmation" checked={this.state.readedPoliticas} onChange={
                        () => {
                          let readedPoliticas = !this.state.readedPoliticas
                          this.setState({
                            readedPoliticas: readedPoliticas
                          })
                        }
                      } /> He leído las políticas de propuesta  
                    </p>

                    <p className="control">
                      <button
                        className="button is-success "
                        onClick={this.handlerClickAgregar.bind(this)}
                        disabled={this.state.agregarDisabled || !this.state.readedPoliticas || this.state.totalPonderacion === 100}
                        style={{ marginRight: "3px" }}>
                        <span className="icon is-small">
                          <i className="fa fa-plus-circle"></i>
                        </span>
                        <span>Agregar objetivo</span>
                      </button>
                    </p>

                    <p className="control">

                      {
                        this.props.children // Contiene el componente del botón para ver compensación
                      }
                    </p>
                  </div>
                </div>
              </div>
              <button onClick={() => this.props.cancel()} className="delete" title="Cerrar" aria-label="close"></button>
            </header>

            <section className="modal-card-body">
              {
                
                this.props.propuesta.borrador &&
                <span
                  className={`tags are-medium`}
                  style={{
                    background: 'rgb(255, 249, 229)',
                    color: 'black',
                    border: '1px solid #FFEDA8'
                  }}
                  title={`Propuesta Borrador`}
                >
                  <span
                    className={`icon is-small`}
                    style={{ color: 'black !important' }}
                  >
                    <i className={`fa fa-info-circle`} />
                  </span>
                  <span style={{ marginLeft: '3px' }}>Esta propuesta está guardada como <font color="#FFD128"><b>BORRADOR</b></font>, por lo que, no se le ha notificado a tu jefe directo de la creación de esta misma. 
                  Para que los usuarios correspondientes puedan recibir notificación y visualización de tu propuesta, 
                      da clic en el botón <font color="#5AF046"><b>Guardar y notificar</b></font>, una vez realizada esta acción ya no será posible realizar alguna modificación. 
                  </span>
                </span>
              }              
              <br />

              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th style={BSCColumnStyle.objetivo} width="15%">Objetivo</th>
                    <th style={BSCColumnStyle.indicador} width="12%">Indicador</th>
                    <th style={BSCColumnStyle.comentario} width="18%">Comentario</th>
                    <th style={BSCColumnStyle.sm}><abbr title="Sobre meta">SM</abbr></th>
                    <th style={BSCColumnStyle.m}><abbr title="Meta">M</abbr></th>
                    <th style={BSCColumnStyle.bm}><abbr title="Bajo meta">BM</abbr></th>
                    <th style={BSCColumnStyle.i}><abbr title="Inaceptable">I</abbr></th>
                    <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar el objetivo">Usuario que valida</abbr></th>
                    <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación">% Pond.</abbr></th>
                    {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                    <th style={BSCColumnStyle.acciones}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    indicadores.map((obj, index) => {
                      return <Indicador key={index}
                        validate={!this.state.newIndicador}
                        propuesta={this.props.propuesta}
                        maxRows={3}
                        disable={!this.state.Confirmado}
                        data={obj}
                        showCompensacion={this.props.showCompensacion}
                        userChange={(user) => this.handleCamposChange(user, index)}
                        camposChange={(e) => this.handleCamposChange(e, index)}
                        pondChange={(pond) => this.handlePondChange(pond, index)}
                        remove={(id) => this.handleRemoveIndicador(index, id)}
                        listUsers={this.state.listUsers.map(user => {
                          let copy = { ...user }
                          copy.nombre = user.usuario.nombre

                          return copy
                        })}
                        onValidation={(isValid) => {
                          let state = { ...this.state };

                          state.indicadoresValidation.map((obj, ivindex) => {
                            if (ivindex === index) {
                              obj.valid = isValid;
                            }

                            return obj;
                          });

                          this.setState(state);
                        }}
                      />
                    })
                  }
                  <tr>
                    <td colSpan="9" style={{ textAlign: "right" }}>Total:</td>
                    <td style={{
                      textAlign: "left",
                      color: this.state.totalPonderacion !== 100 ? "red" : "black"
                    }}>
                      {this.state.totalPonderacion}%
                    </td>
                    {
                      this.props.showCompensacion &&
                      <td>{this.state.totalCompensacion}</td>
                    }
                  </tr>
                </tbody>
              </table>

            </section>

            <footer className="modal-card-foot">
              <Button
                className={this.state.saveLoading ? "is-loading" : ""}
                disabled={
                  !this.isFormValid() || this.state.totalPonderacion !== 100 || !this.state.readedPoliticas ||
                  this.state.saveLoading || !this.state.isSaveButtonEnable
                }
                onClick={() => {
                  this.setState({
                    saveLoading: true,
                    isConfirmNotificationVisible: true
                  })
                }}
                text="Guardar y notificar"
                icon="fa-check-circle"
              />
              {
                (this.props.propuesta.usuario.id == this.props.usuario.id) &&
                this.props.propuesta.borrador &&
                <Button
                  className={this.state.saveLoading ? "is-loading" : ""}
                  disabled={
                    !this.isFormValid() || this.state.totalPonderacion === 0 || !this.state.readedPoliticas || this.state.saveLoading 
                  }
                  onClick={() => {
                    this.setState({
                      saveLoading: true
                    }, () => {
                      this.validateIndicators(true)
                    })
                  }}
                  text="Guardar borrador"
                  icon="fa-save"
                  type="is-warning"
                />
              }
              <Button
                onClick={this.props.cancel}
                type="is-danger"
                text="Cancelar"
                icon="fa-close"
              />
            </footer>

          </div>
        </div>
        {
          this.state.showDeleteConfirm &&
          <Modal
            isVisible={this.state.showDeleteConfirm}
            onClose={() => this.setState({ showDeleteConfirm: false })}
            onConfirm={this.deleteObjetivo}
            cancelText="No, cancelar"
            confirmText="Si, eliminar"
            title="Advertencia"
            zIndex={41}
          >
            <span>¿Seguro desea eliminar este objetivo?</span>
            <br />
            {
              this.state.indicadorToDelete.relaciones !== undefined &&
              this.state.indicadorToDelete.relaciones.length !== 0 &&
              <MessageError msg="Este indicador está relacionado con otros!" />
            }
          </Modal>
        }
        {
          this.state.isConfirmNotificationVisible &&
          <Confirmar
            yes={() =>
              this.setState({
                isConfirmNotificationVisible: false
              }, () => this.validateIndicators(false)
              )}
            no={
              () => this.setState({
                isConfirmNotificationVisible: false,
                saveLoading: false
              })
            }
          >
            <h4>¿Desea guardar su propuesta y notificar a su jefe directo?</h4>
          </Confirmar>
        }
      </Fragment>
    )
  }

}

PropuestaEdit.defaultProps = {
  show: false,
  showCompensacion: false
}

PropuestaEdit.propTypes = {
  show: PropTypes.bool,
  showCompensacion: PropTypes.bool,
  cancel: PropTypes.func,
  save: PropTypes.func,
}

export default PropuestaEdit;