
//-react
import React, { Fragment } from "react";
import { toast } from 'react-toastify';

// - componentes
import AreasTable from "./AreasTable";
import AreaCreate from "./AreaCreate";

import { Button } from "../util/Button/Button";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { Modal } from '../util/modal/ModalLayout';
import { Card } from '../util/Card/Card';
import MultiSelect from '../util/MultiSelect/MultiSelect';
import { InputSearch } from '../util/Input/InputSearch'
import { Table } from '../util/Table/Table'

// - Services
import AreaAPI from '../../services/Area'
import GerenciaAPI from '../../services/Gerencia';
import { set } from "lodash";


export default class AdminAreas extends React.Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            areas: [],
            areasFiltered: [],
            areaSelected: {},
            gerencias: [],
            searchValue: "",
            currentPageItems: [],
            currentPage: 1,
            pageSize: 10,
            isModalCreateShow: false,
            isModalEditShow: false
            
        }
    }

    componentDidMount() {
        this.handleLoadInitialData()
    }

    /**Realiza la carga inicial de los datos. */
    handleLoadInitialData = () => {

        Promise.all([
            GerenciaAPI.getAll()
            .then( response => {
              this.setState({
                gerencias: response.data
              });
            })
            .catch( () => {
              toast.error('Ocurrió un error al cargar las gerencias');
            }),     
            AreaAPI.getAll()
            .then(response => {
                this.setState({
                    areas: response.data,
                    areasFiltered : response.data
                })
            }).catch(() => {                 
                this.setState({ isLoading: false });
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Areas no cargadas') }),
        ]).finally(() =>{
            
            this.setState({ isLoading: false });
            toast.success('Carga de información completada')
        });
    }

    handleChangeSearch = ({ target }) => {
        let areasFiltered = this.state.areas.filter(item =>
            item.nombre.toLowerCase().match(target.value.toLocaleLowerCase())
        )

        this.setState({
            areasFiltered: areasFiltered,
            currentPage: 1,
            searchValue: target.value
        },this.setPage())
    }

    handleChangeGerencia = (gerenciaList = []) => {

        let areasFiltered = this.state.areas.filter(item =>{
            let exist = gerenciaList.find(ger => ger.id == item.gerencia)
            return exist != null ? item : null
        })

        if(gerenciaList.length == 0){
            areasFiltered = this.state.areas
        }

        this.setState({
            areasFiltered: areasFiltered,
            currentPage: 1,
            searchValue: this.state.searchValue
        },this.setPage())
    }

    save = (area) => {
        if(area){

            AreaAPI.create(area)
            .then( response => {

                let newArea = response.data

                let areasFiltered = this.state.areasFiltered

                //gerencias.push(newGerencia)
                areasFiltered.push(newArea)

                this.setState({                    
                    areasFiltered: areasFiltered,
                    isModalCreateShow : false,
                    isLoading: false
                })

                toast.success('Área guardada correctamente')
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al crear el área');
            })
        }
        else{
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al crear el área');
        }

    }

    edit = (area) => {
        if(area){

            AreaAPI.edit(area, area.id)
            .then( response => {

                let areasFiltered = this.state.areasFiltered
                let index = areasFiltered.indexOf(area)

                areasFiltered[index] = response.data

                this.setState({
                    isModalEditShow : false,
                    isLoading: false 
                }, this.setPage)

                toast.success('Área guardada correctamente')
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al crear el área');
            })
        }
        else{
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al crear el área');
        }

    }

    /**
     * Muestra/Oculta loader.
     * 
     * @param {Boolean} isLoading
     */
    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    /**
    * Se selecciona la página y se actualizan los elementos de la lista/tabla
    */ 
    setPage = () => {
        let areas = this.state.areasFiltered;
        let page = this.state.currentPage;
        let pageSize = this.state.pageSize
 
        let pager = this.getPager(areas.length, page, pageSize);
        let currentPageItems = areas.slice(pager.startIndex, pager.endIndex + 1);
 
        this.setState({ 
            currentPageItems: currentPageItems
        });
 
    }
 
    /**
     * Se obtiene el paginador, es decir, cuantas páginas habrán
     * Se asigna la primera página como la actual
     * Se obtienen los elementos que serán mostrados en la primera página
     */
    getPager = (totalItems, currentPage, pageSize) =>  {
        currentPage = currentPage || 1;
 
        pageSize = pageSize || 10;
 
        var totalPages = Math.ceil(totalItems / pageSize);
 
        var startPage, endPage;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
 
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
 
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
 
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    
    render() {

        return (
            <div>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title is-size-4">Áreas</p>
                        <span className="card-header-icon" aria-label="more options">
                            <Button
                                icon="fa-plus-circle"
                                onClick={() => {    
                                    this.setState({
                                        isModalCreateShow: true
                                    })                              
                                }}
                                text="Nueva Área"
                            />
                        </span>
                    </header>
                    <div className="card-content">
                        <div className="content">
                            <div>
                                <h5 className="title is-5 has-text-grey">Filtrar por:</h5>
                            </div>
                            <div className="columns">
                                <Wrapper label="Nombre del Área" extraClasses="is-three-quarters">
                                    <InputSearch
                                        name="area_search"
                                        placeholder="Buscar Área"
                                        changeInput={this.handleChangeSearch}
                                        value={this.state.searchValue}
                                    />
                                </Wrapper>
                                <MultiSelect
                                    label="Gerencia"
                                    name="gerencia"
                                    disabled={this.state.gerencias.length === 0}
                                    items={this.state.gerencias.map(gerencia => {
                                        let newGerencia = { ...gerencia }
                                        newGerencia.value = gerencia.nombre

                                        return newGerencia
                                    })}
                                    onChangeValue={gerenciaList => this.handleChangeGerencia(gerenciaList)}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <Card extraClasses="mt-10" >
                {
                    <Fragment>
                        <AreasTable
                            areas= {this.state.currentPageItems}
                            editStatus = {this.edit}
                            edit= {(area) => {
                                this.setState({
                                    areaSelected : area,
                                    isModalEditShow : true
                                })
                            }}
                        />                
                        <br/>
                        <Pagination
                            items={this.state.areasFiltered}
                            pageSize={this.state.pageSize}
                            initialPage= {this.state.currentPage}
                            onChangePage={(items, currentPage) => {
                                this.setState({ 
                                    currentPageItems: items,
                                    currentPage: currentPage 
                                })}
                            }
                        />
                    </Fragment>
                }
                </Card>

                {
                    this.state.isModalCreateShow &&
                    <AreaCreate
                        area = {{
                            id :0,
                            nombre : null,
                            gerencia : null
                        }}
                        gerencias = {this.state.gerencias}
                        save = {this.save}
                        cancel = {() => {
                            this.setState({
                                isModalCreateShow : false
                            })
                        }}
                    />
                }
                {
                    this.state.isModalEditShow &&
                    <AreaCreate
                        area = {this.state.areaSelected}
                        gerencias = {this.state.gerencias}
                        save = {this.edit}
                        cancel = {() => {
                            this.setState({
                                isModalEditShow : false
                            })
                        }}
                    />
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

          );
    }

}
