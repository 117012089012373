import React, { Fragment } from 'react'

// Styles
import './AutoEvaluacion.css'

// Components
import { Icon } from '../util/Icon/Icon'


const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return(
    text.replace(urlRegex, function(url) {
      return "<a href="+url+"> "+url+" </a>";
    })
  )
  
  
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

/**
 * Item de la lista de archivos de evidencia.
 * 
 * @param {Object} item
 */
export const ListEvidenceItem = ({ item, deleteEvidence, onDownloadFile }) => {
  

  if(item.isText){
    return (
      <li className="ListEvidencesItem">
        <Icon
          icon="fa-font"
        />
          <label dangerouslySetInnerHTML={{__html: urlify(item.description)}}/>
        <Icon
          icon="fa-times-circle"
          title="Eliminar evidencia"
          color="has-text-grey-light"
          click={() => deleteEvidence(item.id)}
        />
      </li>
    )
  }
  const fileName = item.file.split('/')
  return (
    <li className="ListEvidencesItem">
      <Icon
        icon="fa-paperclip"
      />
      <span
        className="ListEvidencesItemLabel"
        title="Descargar evidencia"
        onClick={() => onDownloadFile(item.id, fileName[fileName.length - 1])}
      >
        {fileName[fileName.length - 1]}
      </span>
      <Icon
        icon="fa-times-circle"
        title="Eliminar evidencia"
        color="has-text-grey-light"
        click={() => deleteEvidence(item.id)}
      />
    </li>
  )
}