import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../util/Button/Button';
import { Modal } from '../util/modal/ModalLayout';
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import Select from '../util/select/Selct'
import Loading from '../util/loading/Loading'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de montos de compensacion de usuario. */
export class MontosDescuento extends Component {
    state = {
        sobrante : this.props.usuario.monto_total,
        total_descuento : 0
    }

    getPeriodo = () => {
        let year = new Date(Date.now()).getFullYear()
        let mount = new Date(Date.now()).getMonth()
        let semestre = 1

        if(mount > 6)
            semestre = 2
        
        return year.toString() + '-' + semestre.toString()

    }

    handleChangeInput = (item) => {

        var descuento = 0

        if(item.target.value == '')
            descuento = this.state.total_descuento
        else
            descuento = parseFloat(item.target.value ? item.target.value : 0)

        if(descuento <= 0){            
            toast.error('El monto solicitado debe ser mayor a 0');
            this.setState({
                sobrante: this.props.usuario.monto_total,
                total_descuento: 0
            })
        }
        else if(this.props.usuario.monto_total < descuento){
            toast.error('Monto insuficiente');
            this.setState({
                sobrante: this.props.usuario.monto_total,
                total_descuento: 0
            })

        }else{
            this.setState({
                sobrante: this.props.usuario.monto_total - descuento,
                total_descuento: descuento
            })
        }

    }

    

    render() {
        const {sobrante} = this.state
        let usuario = this.props.usuario
        
        return (
            <div className="modal is-active" >
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "50%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Edición de monto</p>
                        
                        <button onClick={this.props.cerrar} className="delete" title="Cerrar" aria-label="close"></button>
                    </header>

                    <section className="modal-card-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Número/Nombre de empleado">
                                            <p>{usuario.usuario.id_empleado + " - " +usuario.usuario.nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-4" style={{backgroundColor: "#E7E7E7", borderRadius: '5px'}}>
                                        <Wrapper label="Fecha de consulta">
                                            <p>{new Date(Date.now()).toLocaleDateString()}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-2" style={{backgroundColor: "#E7E7E7", borderRadius: '5px'}}>
                                        <Wrapper label="Periodo">
                                            <p>{this.getPeriodo()}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Área / Departamento">
                                            <p>{usuario.area_nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-6">
                                        <Wrapper label="Puesto">
                                            <p>{usuario.puesto}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Razón Social">
                                            <p>{usuario.razon_social_nombre}</p>
                                        </Wrapper>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-head">
                            <p>&nbsp;&nbsp;Ajuste: La cantidad ingresada, se descontará del monto total disponible.</p>
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <div className="columns">
                                    <div className="column is-6" style={{textAlign:'center'}}>
                                        <label className="label">Monto total disponible</label>
                                        <div className="control">
                                            <div className="is-fullwidth">
                                                <div className="field is-horizontal">
                                                <div className="field-body">
                                                    <input className="input" type="text" placeholder="Monto de ajuste"
                                                        style={{textAlign:'center', backgroundColor:'#D9EAD3', color: 'black'}}
                                                        value={formatter.format(sobrante)}
                                                        disabled
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-6" style={{textAlign:'center'}}>
                                        <label className="label">Ingrese monto de ajuste</label>
                                        <div className="control">
                                        <div className="is-fullwidth">
                                            <div className="field is-horizontal">
                                            <div className="field-body">
                                                <input className="input" type="number" step="0.01" placeholder="Monto de ajuste"
                                                    style={{textAlign:'center'}}
                                                    max={usuario.monto_total}
                                                    min={0}
                                                    value={this.state.descuento}
                                                    onChange={e =>{this.handleChangeInput(e)}}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </section>

                    <footer className="modal-card-foot">
                    {/* Botón para guardar y notificar a Jefe directo */}
                        <Button
                            onClick={() => {
                                this.setState({
                                    isModalConfirmacion: true
                                })
                            }}
                            text="Guardar y Notificar"
                            icon="fa-check"
                        />
                    
                    {/* Botón para cancelar la edición de la propuesta */}
                        <Button
                            onClick={()=> {this.props.cerrar()}}
                            text="Cancelar"
                            type="is-danger"
                            icon="fa-ban"
                        />
                    </footer>

                </div>

                {
                    this.state.isModalConfirmacion &&
                    <Modal
                        title="Confirmar "
                        onClose={() => {
                            this.setState({
                                isModalConfirmacion: false
                            })
                        }}
                        cancelText="Cancelar"
                        confirmText="Aceptar"
                        onConfirm={() => {
                            if(this.state.total_descuento <= 0){            
                                toast.error('El monto solicitado debe ser mayor a 0');
                                this.setState({
                                    sobrante: this.props.usuario.monto_total,
                                    total_descuento: 0
                                })
                            }else{
                                this.props.save(usuario, this.state.total_descuento)
                            }
                        }}
                        isVisible={this.state.isModalConfirmacion}
                    >                        
                        <p>¿Está seguro que desea modificar el monto? </p>
                        
                    </Modal>
                }

            </div>

        )
    }
}

export default MontosDescuento;