import { apiUrlIndicadores }  from "../constants";
import { stringify } from "querystring";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {'Authorization': 'Bearer '+ localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ConsultaEvaluacionesAPI {
    static consultar(filters) {
        return middleware.hasAuth(() => request.get(`/consultas/evaluaciones/?${stringify(filters)}`));
    }

    static getNivelCumplimiento() {
        return middleware.hasAuth(() => request.get(`/consultas/evaluaciones/nivel_cumplimiento/`));
    }

    static downloadExcel(filters) {
        const config = {
            responseType: 'blob',
        };
        return middleware.hasAuth(() => request.get(`/consultas/evaluaciones/download/?${stringify(filters)}`, config));
    }

    static downloadEstatusExcel(filters) {
        const config = {
            responseType: 'blob',
        };
        return middleware.hasAuth(() => request.get(`/consultas/estatus_evaluaciones/download/?${stringify(filters)}`, config));
    }

    static downloadEstatusIndicadoresExcel(filters) {
        const config = {
            responseType: 'blob',
        };
        return middleware.hasAuth(() => request.get(`/consultas/estatus_indicadores/download/?${stringify(filters)}`, config));
    }

    static downloadReporteDeEnvioExcel() {
        const config = {
            responseType: 'blob',
        };
        return middleware.hasAuth(() => request.get(`/evaluacion/file/envioEurosoft/`, config));
    }

    static consultar_estatus_indicadores(filters) {
        return middleware.hasAuth(() => request.get('/consultas/estatus_indicadores/',{"params": filters}));
    } 
}