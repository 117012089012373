import Excel from 'exceljs';
import { saveAs } from 'file-saver';

// Tipos de archivos
const TYPE = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel'
}

// Colores necesarios para excel de propuestas.
const COLORS = {
  darkGreen: 'FF339966',
  lightGreen: 'FF91B800',
  lightBlue: 'FF00B0F0',
  green: 'FF23D160',
  blue: 'FF002FF2',
  red: 'FFFF0000',
  yellow: 'FFFFFF00',
  orange: 'FFFFC000',
  black: 'FF000000',
  white: 'FFFFFFFF'
}

// Tamaños basicos para las fuentes del Excel de propuestas.
const FONT_SIZE = {
  extra_small: 10,
  small: 12,
  meddium: 14,
  large: 16,
  extra_large: 18,
}

// Colores segun estatus de una propuesta.
const STATUS = {
  validado: COLORS.green,
  revision: COLORS.lightBlue,
  comentario: COLORS.yellow,
}

// Ancho de cada una de las columnas de una propuesta.
const COLUMNS_WIDTHS = {
  objetivo: 25,
  indicador: 25,
  comentario: 25,
  sobre_meta: 17,
  meta: 17,
  bajo_meta: 17,
  inaceptable: 17,
  'ponderación': 17,
  'usuario_que_valida': 25,
  'compensación': 20
}

// Colores de las columnas segun su nombre
const COLUMNS_COLORS = {
  objetivo: COLORS.green,
  indicador: COLORS.green,
  comentario: COLORS.green,
  sobre_meta: COLORS.blue,
  meta: COLORS.darkGreen,
  bajo_meta: COLORS.yellow,
  inaceptable: COLORS.red,
  'ponderación': COLORS.orange,
  'usuario_que_valida': COLORS.green,
  'compensación': COLORS.yellow
}

/**
 * Formato de fuente para una celda.
 * 
 * @param {Boolean} isBold
 * @param {String} colorString
 * @param {Number} fontSize
 * @returns CellFontFormatt
 */
const cellFormatFont = (isBold = false, colorString = COLORS.black, fontSize = FONT_SIZE.small, isItalic = false) => {
  return {
    bold: isBold,
    color: { argb: colorString },
    size: fontSize,
    italic: isItalic
  }
}

/**
 * Alineamiento de contenido de una celda.
 * 
 * @returns CellAlignment
 */
const cellAlignment = () => {
  return {
    horizontal: 'center',
    vertical: 'middle'
  }
}

/**
 * Color de relleno de una celda.
 * 
 * @param {String} color 
 * @returns CellFill
 */
const cellFillColor = (color = COLORS.white) => {
  return {
    type: 'gradient',
    gradient: 'path',
    center: { left: 0.5, top: 0.5 },
    stops: [
      { position: 0, color: { argb: color } },
      { position: 1, color: { argb: color } }
    ]
  }
}

/**
 * Label para header de usuario de Excel de propuestas.
 * 
 * @param {*} sheet 
 * @param {*} nameLabel 
 * @param {*} status 
 * @returns SheetLabelHeader
 */
const setLabelHeader = (sheet, nameLabel = 'No name', status) => {
  let sheetLabelHeader = sheet
  let statusColor = ''

  switch (status.toLowerCase()) {
    case 'validado':
      statusColor = STATUS.validado
      break
    case 'revisión':
      statusColor = STATUS.revision
      break
    case 'comentarios':
      statusColor = STATUS.comentario
      break
    default:
      statusColor = COLORS.black
      break
  }

  sheetLabelHeader.getRow(2).height = 25
  sheetLabelHeader.mergeCells('B2', 'D2')

  let cellLabelHeader = sheetLabelHeader.getCell('B2')
  cellLabelHeader.value = nameLabel
  cellLabelHeader.font = cellFormatFont(true, COLORS.black, FONT_SIZE.large)
  cellLabelHeader.alignment = cellAlignment()

  let cellStatusHeader = sheetLabelHeader.getCell('E2')
  cellStatusHeader.value = status
  cellStatusHeader.alignment = cellAlignment()
  cellStatusHeader.font = cellFormatFont(false, COLORS.white, FONT_SIZE.small)
  cellStatusHeader.fill = cellFillColor(statusColor)

  return sheetLabelHeader
}

/**
 * Asigna el label para los indicadores turbo.
 * 
 * @param {Sheet} sheet
 * @param {String} labelPonderacion
 * @param {Number} rowCount
 */
const setLabelTurbos = (sheet, labelPonderacion = '0', rowCount) => {
  let sheetLabelTurbos = sheet
  sheetLabelTurbos.getRow(rowCount)
  sheetLabelTurbos.mergeCells(`A${rowCount}`, `B${rowCount}`)

  let cellLabel = sheetLabelTurbos.getCell(`A${rowCount}`)
  cellLabel.value = `Indicadores turbo - ${labelPonderacion}% adicional del resultado BSC`
  cellLabel.font = cellFormatFont(false, COLORS.red, FONT_SIZE.small, true)

  return sheetLabelTurbos
}

/**
 * Se agregan las columnas con su configuración.
 * 
 * @param {Array} columns
 * @returns SheetWithColumns
 */
const setColumns = (columns = []) => {
  let columnsNames = []
  columnsNames = columns.map((column) => {
    let columnName = column.toLowerCase().replace(/ /g, '_')
    let config = {
      key: columnName,
      width: COLUMNS_WIDTHS[columnName]
    }
    return config
  })

  return columnsNames
}

/**
 * Agrega los titulos de las tablas y sus estilos.
 * 
 * @param {SheetExcel} sheet 
 * @param {Number} rowNumber 
 * @param {Array} columns 
 */
const setColumnsTitles = (sheet, rowNumber, columns) => {
  let row = sheet.getRow(rowNumber)
  row.values = columns
  columns.map((column, index) => {
    let columnName = column.toLowerCase().replace(/ /g, '_')
    row.getCell(++index).fill = cellFillColor(COLUMNS_COLORS[columnName])
  })
  row.font = cellFormatFont(false, COLORS.black, FONT_SIZE.small)
  row.alignment = cellAlignment()
}

/**
 * Genera las filas a escribir en el Excel.
 * 
 * @param {SheetExcel} sheet 
 * @param {Number} rowNumber 
 * @param {Array} columns 
 * @param {Array} infoRows 
 */
const generateRow = (sheet, rowNumber, columns, infoRows, isNormal = true) => {
  if (infoRows.lenght !== 0) {
    // Se asignan los titulos de las columnas
    setColumnsTitles(sheet, rowNumber, columns)
    // Se define configuracion de cada columna
    if (isNormal) {
      sheet.columns = setColumns(columns)
      let sheetLabelTurbos = sheet
      sheetLabelTurbos.getRow(sheet.rowCount)
      sheetLabelTurbos.mergeCells('A3', 'B3')

      let cellLabel = sheetLabelTurbos.getCell('A3')
      cellLabel.value = `Indicadores normales BSC`
      cellLabel.font = cellFormatFont(false, COLORS.black, FONT_SIZE.small, true)
    }

    // Se agregan las filas con la información
    infoRows.map(row => {
      sheet.addRow(row)
      let rowConfig = sheet.getRow(sheet.rowCount)
      rowConfig.height = 60
      rowConfig.alignment = {
        vertical: 'top',
        horizontal: 'left',
        wrapText: true
      }
    })
  }
}

/**
 * Genera una hoja de excel con el formato para Propuestas.
 * 
 * @param {WorkbookExcel} workbook 
 * @param {Object} columnsNames 
 * @param {Object} infoRows 
 * @param {Array} proposalInfo 
 */
const generateSheetExcel = (
  workbook,
  columnsNames = {},
  infoRows = {},
  proposalInfo = {}
) => {
  let rowNumber = 4
  // Creación de la hoja en la que se va a trabajar
  let sheet = workbook.addWorksheet(proposalInfo.nombre_usuario, {
    pageSetup: {
      paperSise: 9,
      orientation: 'landscape',
      printArea: 'A1:H20'
    }
  })

  // Label para titulo `Propuesta - Nombre de usuario - Estatus de propuesta`
  setLabelHeader(
    sheet,
    `${proposalInfo.codigo} - ${proposalInfo.nombre_usuario}`,
    proposalInfo.estatus
  )

  if (infoRows.normales.length !== 0) {
    generateRow(sheet, rowNumber, columnsNames.normales, infoRows.normales)
  }

  // Salto de fila
  sheet.addRow(sheet.rowCount + 1).addPageBreak()

  if (infoRows.turbos.toString() !== '') {
    setLabelTurbos(sheet, proposalInfo.ponderacion_total, sheet.rowCount + 2)
    rowNumber = sheet.rowCount + 1
    generateRow(sheet, rowNumber, columnsNames.turbos, infoRows.turbos, false)
  }
}

/**
 * Genera excel personalizado para formato de propuestas de usuario
 * 
 * @param {Strin} excelName
 * @param {Array} infoRows
 * @param {Array} columnsNames
 */
export const generateExcel = (excelName, infoRows, columnsNames) => {
  let libro = new Excel.Workbook()

  try {
    // Generación de las hojas de excel
    infoRows.map((user, index) =>
      generateSheetExcel(
        libro,
        columnsNames,
        user.proposals,
        {
          ...user.info,
          nombre_usuario: `${++index}-${user.info.nombre_usuario}`
        }
      )
    )

    // Escritura y generacion del EXCEL
    libro.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], { type: TYPE.xlsx })
      saveAs(blob, `${excelName} - ${new Date().toLocaleDateString('es')}`)
    })
  } catch (error) {
    console.log('Error al generar Excel personalizado: ', error)
  }
}

/**
 * Genera un Excel y lo descarga al navegador.
 * 
 * @param {Object} excelProps 
 * @param {Array} infoRows 
 * @param {Array} columnsNames 
 */
export const generateBasicExcel = (excelProps, infoRows, columnsNames) => {
  let libro = new Excel.Workbook()

  try {
    let sheet = libro.addWorksheet(excelProps.sheetName)

    sheet.columns = columnsNames.map(column => {
      return { header: column.toUpperCase().replace(/_/g, ' '), key: column, width: 20 }
    })

    infoRows.map(row => {
      let info = Object.values(row)
      sheet.addRow(info)
    })

    libro.xlsx.writeBuffer().then(function (data) {
      let blob = new Blob([data], { type: TYPE.xlsx })
      saveAs(blob, `${excelProps.excelName} - ${new Date().toLocaleDateString()}`)
    })
  } catch (err) {
    console.log('Error al generar Excel: ', err)
  }
}
