import React from 'react'
import { Icon } from '../Icon/Icon'

/**
 * Componente para seleccionar fechas.
 * 
 * @param {Object} DatePicker
 * 
 * @returns Component
 */
export const DatePicker = ({
  name,
  placeholder,
  value,
  disabled,
  changeInput,
  initial,
  final,
  validation
}) => {
  return (
    <p className="control has-icons-left">
      <input
        className="input"
        autoComplete="off"
        name={name}
        type="date"
        placeholder={placeholder}
        value={value}
        readOnly={disabled}
        disabled={disabled}
        onChange={changeInput}
        min={initial ? initial.toString() : ''}
        max={final ? final.toString() : undefined}
        onBlur={e => validation(e)}
      />
      <Icon icon="fa-calendar" size="is-small is-left" />
    </p>
  )
}