
//-react
import React, { Fragment } from "react";
import { toast } from 'react-toastify';

// - componentes
import { Button } from "../util/Button/Button";
import HasAccess from '../util/hasAccess/HasAccess';
import Loading from '../util/loading/Loading'
import { Modal } from '../util/modal/ModalLayout';
import { Card } from '../util/Card/Card';
import DragAndDrop from '../util/DragAndDrop/DragAndDrop'
import FileLoader from '../util/FileWrapper/FileLoader'
import {apiUrlBsc} from '../../constants'
import axios from 'axios'

import ArchivosEstaticosApi from '../../services/ArchivosEstaticos'

export default class ArchivosEstaticos extends React.Component {
    constructor(props) {
        super(props);
    
    
        this.state = {

          banner: null,   
          bannerId: 0,       
          currentBanner: null,
          fileBanner: null,
          politicas: null,      
          politicaId: 0,    
          currentpoliticas: null,
          filePoliticas: null,
          listFiles:[],
          isModalNuevaSolicitudVisible: false,
          isLoadingInfo: false,
          urlPresentacionNew: null,
          urlPresentacion: {
              url: null
          },
          
        }
    }

    componentDidMount() {
        this.showLoading(true)

        Promise.all([  
            ArchivosEstaticosApi.getAll()
            .then( response => {
                let archivos = response.data
                this.showLoading(false)

                archivos.map(archivo =>{
                    if(archivo.name ==="Banner"){
                        this.setState({
                            banner: `${apiUrlBsc}file/statics/${archivo.id}`,
                            bannerId: archivo.id,                 
                            currentBanner: `${apiUrlBsc}file/statics/${archivo.id}`
                        })
                        
                        this.handleCargarBanner()
                    }

                    if(archivo.name ==="Politicas"){
                        this.setState({
                            politicas: `${apiUrlBsc}file/statics/${archivo.id}`,      
                            politicaId: archivo.id,           
                            currentpoliticas: `${apiUrlBsc}file/statics/${archivo.id}`
                        })

                        this.handleCargarPoliticas()
                    }
                })

                this.setState({
                    listFiles : response.data
                })
                
                this.showLoading(false)
                
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            }),
            ArchivosEstaticosApi.getAllUrlStatic()
            .then( response => {
                let urls = response.data

                urls.forEach(url =>{
                    if(url.name === "Enlace presentación"){
                        this.setState({
                            urlPresentacion : url,
                            urlPresentacionNew : url.url
                        })                        
                    }
                })
                
                this.showLoading(false)
                
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            }),
            
        ]).finally(() => toast.success('Carga de información completada'));
      
    }

    /**
     * Carga imagen preview de Banner
     */
    handleCargarBanner = () => {
        const request = axios.create({
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type':'application/json',
            }
          })
      
          this.setState({
              isLoading: true,
              fileRgxType: /^(image\/).+/
          })
      
          request.get(this.state.banner, {responseType: 'arraybuffer'})
            .then(response => {
                  if (this.state.fileRgxType.test(response.headers['content-type'])) {
                      let image = `data: ${response.headers};base64,${Buffer.from(response.data, 'binary').toString('base64')}`
                      this.setState({
                          banner: image,
                          currentBanner: image
                      })
                  } else {
                      this.setState({hasErrors: true})
                  }
            })
            .catch(() => {
              this.setState({
                  banner: this.state.banner,                
                  currentBanner: this.state.banner
              })
            })
            .finally(() => this.setState({isLoading: false}))
    }

    /**
     * Carga archivo descargado de politicas en url
     */
    handleCargarPoliticas = () => {
        const request = axios.create({
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type':'application/json',
            }
        })
      
        this.setState({
            isLoading: true
        })
      
        request.get(this.state.politicas, {responseType: 'arraybuffer'})
            .then(response => {
                
                let contentType = response.headers["content-type"]
                let file = new Blob([response.data], {type: contentType});
                let filePoliticas = URL.createObjectURL(file)

                this.setState({
                    politicas: filePoliticas,
                    currentPoliticas: filePoliticas
                })
                  
            })
            .catch(() => {
                this.setState({
                    politicas: this.state.politicas,                
                    currentPoliticas: this.state.politicas
                })
            })
            .finally(() => this.setState({isLoading: false}))
    }

    /**
     * Carga el archivo seleccionado en preview
     * @param {Archivo seleccionado} file 
     */
    handlePreviewBanner = (file) => {        
        let image = URL.createObjectURL(file)

        this.setState({
            banner: image,
            fileBanner: file
        })

    }

    /**
     * Carga archivo seleccionado de politicas en preview
     * @param {Archivo seleccionado} file 
     */
    handlePreviewPoliticas = (file) => {        
        let filePoliticas = URL.createObjectURL(file)

        this.setState({
            politicas: filePoliticas,
            filePoliticas: file
        })

    }

    /**
     * Guarda o actualiza el archivo del Banner
     */
    handleGuardarBanner= () => {
        
        this.showLoading(true)

        if(this.state.bannerId > 0){
            ArchivosEstaticosApi.updateStatic(this.state.bannerId, this.state.fileBanner)
            .then( response => {

                this.setState({
                    fileBanner: null
                })
                
                this.handleCargarBanner()
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            })

        }
        else{
            ArchivosEstaticosApi.newStatic(this.state.fileBanner, "Banner página inicio", "Banner")
            .then( response => {
                let archivo = response.data
                this.setState({
                    banner: `${apiUrlBsc}file/statics/${archivo.id}`,
                    bannerId: archivo.id,                 
                    currentBanner: `${apiUrlBsc}file/statics/${archivo.id}`,
                    fileBanner: null
                })
                
                this.handleCargarBanner()
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            })
        }
    }

    /**
     * Guarda o actualiza archivo de Politicas
     */
    handleGuardarPoliticas= () => {
        
        this.showLoading(true)

        if(this.state.politicaId > 0){
            ArchivosEstaticosApi.updateStatic(this.state.politicaId, this.state.filePoliticas)
            .then( response => {

                this.setState({
                    filePoliticas: null
                })
                
                this.handleCargarPoliticas()
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            })

        }
        else{
            ArchivosEstaticosApi.newStatic(this.state.filePoliticas, "Politicas", "Politicas")
            .then( response => {
                let archivo = response.data
                this.setState({
                    politicas: `${apiUrlBsc}file/statics/${archivo.id}`,
                    politicaId: archivo.id,                 
                    currentPoliticas: `${apiUrlBsc}file/statics/${archivo.id}`,
                    filePoliticas: null
                })
                
                this.handleCargarPoliticas()
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar información');
            })
        }
    }

    /**
     * Remueve las politicas de la pagina de inicio
     */
    handleRemovePoliticas = () => {
        this.showLoading(true)

        ArchivosEstaticosApi.removeStatic(this.state.politicaId)
        .then( response => {

            this.setState({
                politicas: null,
                politicaId: 0,                 
                currentPoliticas: null,
                filePoliticas: null
            })
            
            this.handleCargarPoliticas()
            this.showLoading(false)
        }).catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al eliminar políticas');
        })

    }

    /**
     * Remueve el banner de la pagina de inicio
     */
    handleRemoveBanner = () => {
        this.showLoading(true)

        ArchivosEstaticosApi.removeStatic(this.state.bannerId)
        .then( response => {

            this.setState({
                banner: null,
                bannerId: 0,                 
                currentBanner: null,
                fileBanner: null
            })
            
            this.showLoading(false)
        }).catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al eliminar políticas');
        })

    }

    handleChangeInputUrl = (item) => {

        let{urlPresentacion, urlPresentacionNew} = this.state

        urlPresentacion.url = item.target.value
        urlPresentacion.name = urlPresentacion.name ? urlPresentacion.name : "Enlace presentación"
        urlPresentacion.description = urlPresentacion.description ? urlPresentacion.description : "Enlace presentación"

        urlPresentacionNew = item.target.value

        this.setState({
            urlPresentacion: urlPresentacion,
            urlPresentacionNew: urlPresentacionNew
        })
    }

    handleGuardarPresentacion = () => {
        let{urlPresentacion} = this.state
        this.showLoading(true)

        if(urlPresentacion.id){
            ArchivosEstaticosApi.updateUrlStatic(urlPresentacion.id, urlPresentacion.name, urlPresentacion.url, urlPresentacion.description)
            .then( response => {

                let newPresentacion = response.data

                this.setState({
                    urlPresentacion: newPresentacion
                })
                
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al agregar enlace de presentación');
            })
        }
        else{
            ArchivosEstaticosApi.newUrlStatic(urlPresentacion.name, urlPresentacion.url, urlPresentacion.description)
            .then( response => {

                let newPresentacion = response.data

                this.setState({
                    urlPresentacion: newPresentacion
                })
                
                this.showLoading(false)
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al agregar enlace de presentación');
            })
        }

    }

    handleRemovePresentacion = () => {
        let{urlPresentacion} = this.state
        this.showLoading(true)

        ArchivosEstaticosApi.deleteUrlStatic(urlPresentacion.id)
        .then( response => {

            this.setState({
                urlPresentacion: {
                    url: null
                },
                urlPresentacionNew: ""
            })
            
            this.showLoading(false)
        }).catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al eliminar enlace de presentación');
        })

    }

    /**
     * Muestra/Oculta loader.
     * 
     * @param {Boolean} isLoading
     */
    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    
    render() {

        return (
            <div>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title is-size-4">Archivos del sitio</p>
                    </header>
                </div>
                <br />
                <div className="card">
                    <div className="card-content">
                        <div className="content">
                            
                            <div className="columns">
                                <div className="column is-6">                                  
                                    <div>
                                        <h5 className="title is-5 has-text-grey">Banner</h5>
                                    </div>
                                    <div className="WrappetAttachDropZone">
                                        <DragAndDrop
                                            accepts={"image/png, image/gif, image/jpeg"}
                                            onFilesLoaded={() => console.log()}
                                            onUploadFile={file => this.handlePreviewBanner(file)}
                                            name="imagen_banner"
                                            isMultiple={false}
                                        />
                                        {
                                            this.state.isLoadingInfo &&
                                            <progress className="progress is-small is-primary" max="100">15%</progress>
                                        }
                                        <span className="InfoAttach">Tamaño límite por archivo 2Mb</span>
                                    </div>
                                    {
                                        this.state.fileBanner && 
                                        <div style={{float: "right"}}>
                                            <Button
                                                text="Guardar banner"
                                                onClick={this.handleGuardarBanner}
                                                className="is-small"
                                                icon="fa-check-circle"
                                                type="is-success"
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.bannerId > 0 &&
                                        <div style={{float: "right"}}>
                                            <Button
                                                text="Eliminar Banner"
                                                onClick={this.handleRemoveBanner}
                                                className="is-small"
                                                icon="fa-times-circle"
                                                type="is-danger"
                                            />
                                        </div>
                                    }
                                    
                                    <div>
                                        <h5 className="title is-5 has-text-grey"></h5>
                                    </div>
                                    <div>
                                        <h5 className="title is-5 has-text-grey">Políticas</h5>
                                    </div>
                                    <div className="WrappetAttachDropZone">
                                        <DragAndDrop
                                            onFilesLoaded={() => console.log()}
                                            onUploadFile={file => this.handlePreviewPoliticas(file)}
                                            name="file_politicas"
                                            isMultiple={false}
                                        />
                                        <span className="InfoAttach">Tamaño límite por archivo 2Mb</span>
                                    </div>
                                    {
                                        this.state.filePoliticas &&
                                        <div style={{float: "right"}}>
                                            <Button
                                                text="Guardar politicas"
                                                onClick={this.handleGuardarPoliticas}
                                                className="is-small"
                                                icon="fa-check-circle"
                                                type="is-success"
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.politicaId > 0 &&
                                        <div style={{float: "right"}}>
                                            <Button
                                                text="Eliminar politicas"
                                                onClick={this.handleRemovePoliticas}
                                                className="is-small"
                                                icon="fa-times-circle"
                                                type="is-danger"
                                            />
                                        </div>
                                    }

                                    <div>
                                        <h5 className="title is-5 has-text-grey"></h5>
                                    </div>
                                    <div>
                                        <h5 className="title is-5 has-text-grey">Presentación de  la estrategia</h5>
                                    </div>
                                    <div className="control">
                                        <input className="input" type="text" placeholder="Enlace de presentación"
                                            name="presentacion"
                                            value={this.state.urlPresentacionNew}
                                            onChange={e =>{
                                                this.handleChangeInputUrl(e)
                                            }}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        {
                                            (this.state.urlPresentacionNew || this.state.urlPresentacion.id > 0 ) &&
                                            <a className="button" href={this.state.urlPresentacion.url} target="blank" rel="noopener" >
                                                <span className="icon">
                                                    <i className="fa fa-eye"></i>
                                                </span>
                                                <span>Ver presentación</span>
                                            </a>
                                        }                                        
                                        {
                                            this.state.urlPresentacionNew &&
                                            <div style={{float: "right"}}>
                                                <Button
                                                    text="Guardar enlace"
                                                    onClick={this.handleGuardarPresentacion}
                                                    className="is-small"
                                                    icon="fa-check-circle"
                                                    type="is-success"
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.urlPresentacion.id > 0 &&
                                            <div style={{float: "right"}}>
                                                <Button
                                                    text="Eliminar enlace"
                                                    onClick={this.handleRemovePresentacion}
                                                    className="is-small"
                                                    icon="fa-times-circle"
                                                    type="is-danger"
                                                />
                                            </div>
                                        }
                                    </div>
                                   
                                    
                                </div>
                                <div className="column is-6">
                                    <div>
                                        <h5 className="title is-5 has-text-grey">Vista previa:
                                        {
                                            (this.state.fileBanner || this.state.filePoliticas ) &&
                                            <Button
                                                text="Quitar"
                                                onClick={() => {
                                                    this.setState({
                                                        banner: this.state.currentBanner,
                                                        fileBanner: null,
                                                        politicas: this.currentpoliticas,
                                                        filePoliticas: null
                                                    })
                                                }}
                                                className="is-small"
                                                icon="fa-times-circle"
                                                type="is-warning"
                                            />

                                        }
                                        
                                        </h5>
                                        
                                    </div>
                                    {
                                        this.state.banner &&
                                        <Fragment>
                                            <img src={this.state.banner} alt="Fechas del semestre" style={{width: this.props.width || '100%'}}/>
                                        </Fragment>
                                    }
                                    {
                                        this.state.politicas &&
                                        <div style={{textAlign: "center"}}>
                                            <h5 className="title is-5 has-text-grey"><a href={this.state.politicas} target="_blank">Políticas</a></h5>
                                        </div>
                                    }
                                    
                                    
                                </div>
                            </div>
                            <div className="columns">

                            </div>

                        </div>
                    </div>
                </div>

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

          );
    }

}
