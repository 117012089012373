import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { MessageError } from '../../util/Notifications/MessageError'
import { Card } from '../../util/Card/Card'
import { InputSearch } from '../../util/Input/InputSearch'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading'
import NoItems from "../../util/noitems/NoItems";
import Confirmar from '../../util/propuestas/Confirmar';

import GrupoIndicadores from '../Indicadores/Grupo_indicadores'
import TablaGrupos from '../Indicadores/Indicadores_tabla'
import AsignacionIndicadores from '../Indicadores/Indicadores_asignacion'
import GrupoIndicadoresAPI from '../../../services/indicadores_variables/GrupoIndicadores'
import UsuariosAPI from '../../../services/Usuario'

class Indicadores extends Component {    

    toastSuccessId = null
    toastErrorId = null
    toastWarnId = null
    toastInfoId = null

    constructor(props) {
        super(props);

        this.grupo_indicadores_selected = {
            nombre : '',
            indicadores : []
        }
        
        this.state = {
            name_filter : '',
            grupo_indicadores : [],
            grupo_indicadores_filtered : [],
            grupo_indicadores_edit: {},
            grupo_indicadores_delete: {},
            listUsers : [],
            listUsersByAsign: [],
            filtros : {},
            showCapturaIndicadores: false,
            showEditIndicadores : false,
            showEliminarIndicadores : false,
            showAsignacionUsuarios : false,
            isLoading : true
        }
    }

    handleLoadData = () => {
        this.showLoading(true)
        const state = {...this.state}
   
        Promise.all([
            UsuariosAPI.getEvaluadoresKPIs().then(res => {
                this.setState({
                  listUsers: res.data,
                  agregarDisabled: false,
                })
              }).catch(err => {
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
              }),
            GrupoIndicadoresAPI.get_group_list_by_me()
            .then(response => {
                let grupo_indicadores = response.data
                this.setState({
                    grupo_indicadores : grupo_indicadores,
                    grupo_indicadores_filtered : grupo_indicadores
                })
            }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') }),
            GrupoIndicadoresAPI.get_usuarios_por_asignar().then(res => {
                this.setState({
                  listUsersByAsign: res.data,
                  agregarDisabled: false,
                })
              }).catch(err => {                
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
              }),
        ]).then( res => {
            this.setState({ isLoading: false });
        })
      }

    componentWillMount() {
        this.handleLoadData()
    }

    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    save = (data, isDraft) => {
        this.showLoading(true);

        let grupo_indicadores_to_save = data

        grupo_indicadores_to_save.borrador = isDraft

        if(grupo_indicadores_to_save.id && grupo_indicadores_to_save.id != 0){

            GrupoIndicadoresAPI.update(grupo_indicadores_to_save, grupo_indicadores_to_save.id)
            .then(response => {
                let state = { ...this.state };
                state.showEditIndicadores = false
                state.isLoading = false

                this.grupo_indicadores_selected = {
                    nombre : '',
                    indicadores : []
                }

                this.setState(state, () => {
                    if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)

                    GrupoIndicadoresAPI.get_group_list_by_me()
                    .then(response => {
                        let grupo_indicadores = response.data
                        this.setState({
                            grupo_indicadores : grupo_indicadores,
                            grupo_indicadores_filtered : grupo_indicadores
                        })
                    }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })
                    });

            })
            .catch(err => {
                this.showLoading(false);
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
            })

        }
        else{
            GrupoIndicadoresAPI.create(grupo_indicadores_to_save)
            .then(response => {
                let state = { ...this.state };
                state.showCapturaIndicadores = false
                state.isLoading = false
                this.setState(state, () => {
                    if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)

                    this.grupo_indicadores_selected = {
                        nombre : '',
                        indicadores : []
                    }

                    GrupoIndicadoresAPI.get_group_list_by_me()
                    .then(response => {
                        let grupo_indicadores = response.data
                        this.setState({
                            grupo_indicadores : grupo_indicadores,
                            grupo_indicadores_filtered : grupo_indicadores
                        })
                    }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })
                    });

            })
            .catch(err => {
                this.showLoading(false);
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
            })
        }

        
    };

    delete = () => {
        this.showLoading(true);
        let state = {...this.state}

        let grupo_indicadores_delete = state.grupo_indicadores_delete

        GrupoIndicadoresAPI.delete(grupo_indicadores_delete.id)
        .then(response => {
            let state = { ...this.state };
            state.showEliminarIndicadores = false
            state.isLoading = false
            state.grupo_indicadores_delete = {}

            this.setState(state, () => {
                if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)

                GrupoIndicadoresAPI.get_group_list_by_me()
                .then(response => {
                    let grupo_indicadores = response.data
                    this.setState({
                        grupo_indicadores : grupo_indicadores,
                        grupo_indicadores_filtered : grupo_indicadores
                    })
                }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') })
                });

        })
        .catch(err => {
            this.showLoading(false);
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
        })

        

        
    };
    
    asignar = (id_grupo, list_usuarios, list_usuarios_no_asignados) => {
        
        this.showLoading(true);

        GrupoIndicadoresAPI.asignacion(id_grupo, list_usuarios)
        .then(response => {            
            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
            this.setState({
                showAsignacionUsuarios:false,
                listUsersByAsign: list_usuarios_no_asignados,
                isLoading : false
            })
        })
        .catch(err => { 
            if (!toast.isActive(this.toastErrorId)) 
                this.toastErrorId = toast.error('Error al actualizar la asignacion de usuarios')
            this.showLoading(false); 
        })
        
    }

    handleChanceSearchNameGroup = (event) => {

        const state = {...this.state}

        let grupo_indicadores_filtered = []
        
        state.grupo_indicadores.map(grupo => {
            if(grupo.nombre.toLowerCase().includes(event.target.value.toLowerCase())){
                grupo_indicadores_filtered.push(grupo) 
            }
        })
        
        this.setState({
            name_filter : event.target.value,
            grupo_indicadores_filtered : grupo_indicadores_filtered
        })

    }

    render(){
        const isAdmin = localStorage.role.split(',').includes('Administrador KPIs')
        return (
            <Fragment>
                <Card title="Indicadores Variables" >
                    <div>
                        <div className="columns">
                            <Wrapper label="Nombre de indicador" extraClasses="is-tow-quarters">
                                <InputSearch
                                    name="grupo_search"
                                    placeholder="Buscar indicador"
                                    changeInput={this.handleChanceSearchNameGroup}
                                    value={this.state.name_filter}
                                />
                            </Wrapper>
                            <div className='column is-4'></div>
                            <div className="column is-flex" style={{ alignItems: 'flex-end' }} >
                                <Button
                                    icon="fa-plus"
                                    type="is-info"
                                    onClick={() => {this.setState({showCapturaIndicadores:true})}}
                                    title="Nuevo indicador"
                                    text="Nuevo indicador"
                                />
                                <Button
                                    icon="fa-user"
                                    type="is-success"
                                    onClick={() => {this.setState({showAsignacionUsuarios:true})}}
                                    title="Asignar indicadores"
                                    text="Asignar indicadores"
                                />
                            </div>
                        </div>
                    </div>

                </Card>
                <Card >
                {
                    this.state.isLoading ?
                    <Loading
                      isFullscreen={true}
                      isLoading={this.state.isLoading}
                      width="100px"
                      height="100px"
                    />
                    :
                    this.state.grupo_indicadores_filtered.length > 0 ?
                    this.state.grupo_indicadores_filtered.map(obj => {
                        return(
                            <div className="card" key={obj.id}>
                                <div className="card-content CardProposal">
                                    <div className="mb-10">
                                        <div className="Row">
                                            <span className="title is-5" style={{ marginBottom: "0" }}>{obj.nombre}</span>
                                            
                                            {obj.borrador ?
                                                <span
                                                className={`tag is-light ml-10`}
                                                style={{
                                                    background: 'rgb(255, 152, 152)',
                                                    color: 'white',
                                                    border: '1px solid #ff5757'
                                                }}
                                                >
                                                <span
                                                    className={`icon is-small`}
                                                    style={{ color: 'white !important' }}
                                                >
                                                    <i className={`fa fa-info-circle`} />
                                                </span>
                                                <span style={{ marginLeft: '3px' }}>Borrador</span>
                                                </span>:
                                                <span className={`tag ml-10`}>
                                                    Indicadores en curso
                                                </span>
                                            }
                                        
                                        </div>
            
                                        <div className="is-pulled-right is-large Row">  

                                        {
                                            obj.borrador &&
                                            // El usuario puede editar siempre y cuando
                                            // exista una convocatoria (openConvocatoria) en curso
                                            // y la propuesta no esté autorizada aún   
                                            <Button
                                                text="Editar"
                                                onClick={() => this.setState({ showEditIndicadores: true, grupo_indicadores_edit: obj })}
                                                className="is-small"
                                                icon="fa-edit"
                                                type="is-warning"
                                            />
                                            
                                        }
                                        {
                                            obj.borrador &&
                                            // El usuario puede editar siempre y cuando
                                            // exista una convocatoria (openConvocatoria) en curso
                                            // y la propuesta no esté autorizada aún   
                                            <Button
                                                text="Eliminar"
                                                onClick={() => this.setState({ showEliminarIndicadores: true, grupo_indicadores_delete: obj })}
                                                className="is-small"
                                                icon="fa-solid fa-trash"
                                                type="is-danger"
                                            />
                                            
                                        }
            
                                        
                                        </div>
                                    </div>
            
                                    <TablaGrupos
                                        grupo_indicador={obj}
                                        usuarios = {this.state.listUsers}
                                        isAdmin={isAdmin}
                                    />
        
                                </div> {/* div card-content */}
                            </div>
                        )
                    
                    }): (
                        !this.state.isLoading &&
                        <Fragment>
                          <div style={{ textAlign: "center" }}>
                            <span className="tag is-warning is-large mt-10">
                              <NoItems itemName={" resultados"} />
                            </span>
                          </div>
                        </Fragment>
                      )
                }
                </Card>

                {
                    this.state.showEliminarIndicadores &&
                    <Confirmar
                        yes={this.delete}
                        no={() => this.setState({   
                            showEliminarIndicadores: false 
                        })}>
                        
                        <h4>¿Desea eliminar el indicador?</h4>
                        <p>Los indicadores borraran, perdera sus cambios realizados</p>
                    </Confirmar>
                    }

                {/** Elaborar grupo de indicadores */}
                {
                    this.state.showCapturaIndicadores &&
                        <GrupoIndicadores show={this.state.showCapturaIndicadores}
                            cancel={() => this.setState({ showCapturaIndicadores: false }, () => {
                                this.grupo_indicadores_selected = {
                                    nombre : '',
                                    indicadores : []
                                }
                            })}
                            save={(indicators, isDraft) => this.save(indicators, isDraft)}
                            grupo_indicadores={this.grupo_indicadores_selected}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsers}>
                            
                        </GrupoIndicadores>
                }

                {
                    this.state.showEditIndicadores &&
                        <GrupoIndicadores show={this.state.showEditIndicadores}
                            cancel={() => this.setState({ showEditIndicadores: false }, () => {
                                this.grupo_indicadores_edit = {}
                            })}
                            save={(indicators, isDraft) => this.save(indicators, isDraft)}
                            grupo_indicadores={this.state.grupo_indicadores_edit}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsers}>
                            
                        </GrupoIndicadores>
                }

                {
                    this.state.showAsignacionUsuarios &&
                        <AsignacionIndicadores show={this.state.showAsignacionUsuarios}
                            cancel={() => this.setState({ showAsignacionUsuarios: false })}
                            save={(id_grupo, list_usuarios, list_usuarios_no_asignados) => this.asignar(id_grupo, list_usuarios, list_usuarios_no_asignados)}
                            grupos_indicadores={this.state.grupo_indicadores}
                            usuario={this.state.usuario}
                            listUsers = {this.state.listUsersByAsign}>
                            
                        </AsignacionIndicadores>
                }
                
            </Fragment>
        )

    }

}

export default Indicadores;