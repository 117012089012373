import React, { Fragment } from 'react'

/**Componente wrapper para los filtros de un módulo. */
export const FiltersWrapper = ({ children }) => {
  return (
    <Fragment>
      <div style={{ padding: "0 0 15px 0" }}>
        <h5 className="title is-5 has-text-grey">Filtrar por:</h5>
      </div>
      <div className="columns">
        {children}
      </div>
    </Fragment>
  )
}