import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Propuesta from './Propuesta';
import './modoValidacion.css';

class ModoValidacion extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    render() {
        return(
            <div className={this.props.show ? "modal is-active" : "modal"}>
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "95%", minHeight: "60%" }}>
                    
                    <header className="modal-card-head">
                        <p className="modal-card-title">Validar objetivos</p>
                        
                        <button onClick={() => this.props.cancel()} className="delete" aria-label="close"></button>

                    </header>

                    <section className="modal-card-body">
                        <Propuesta usuario={this.props.propuesta.usuario} 
                            indicadores={this.props.propuesta.indicadores}
                            saveComentario={this.props.saveComentarios} 
                            validarIndicador={this.props.validarIndicador} 
                            getPrevious={this.props.getPrevious}
                            comentarios={this.props.comentarios}
                        />             
                    </section>
                </div>
            </div>
        )
    }
}

ModoValidacion.defaultProps = {
    show: false,
    propuesta: {}
}

ModoValidacion.propTypes = {
    show: PropTypes.bool,
    propuesta: PropTypes.object,
    cancel: PropTypes.func,
    showCompensacion: PropTypes.func
}

export default ModoValidacion;