import React, { Fragment } from 'react';
import './MenuInterior.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-colores-negro.png';
import HasAccess from '../util/hasAccess/HasAccess';
/**
 * Elementos del menú de la barra lateral izquierda 
 */
export default class MenuVariable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        }
    }
    // Preparando para un menú más grande
    // También se podría utilizar la navbar para eso, se verá cuando el proyecto crezca.

    loadBSC(){
        localStorage.setItem("perfil_selected", "bsc");
        if(window.caches != undefined){
            window.caches.keys().then(function(names) {
                for (let name of names)
                window.caches.delete(name);
                });
        }
        
        window.location.href = window.location.origin;
    }

    render() {

        let canSee = true; //! Condición para saber qué pueden
        let roles = localStorage.getItem('role').split(',');        
        return (
            
            <div className="has-text-left sidemenu padding-top">
                <figure className="image container-image">
                    <img className="image-logo" src={logo} width="213" height="51"/>
                </figure>
                <br />

                <ul className="menu-list">
                    <Link to={'/'}><li>Inicio</li></Link>
                </ul>
                {
                    <Fragment>
                        <HasAccess roles={['Administrador KPIs', 'Consulta','FSAP', 'Evaluador de Indicadores Variables']}>
                        <p className="menu-label">
                            Indicadores
                        </p>
                        </HasAccess>

                        <ul className="menu-list">
                            <HasAccess roles={['Administrador KPIs', 'FSAP']}>
                                <Link to={'/variable/administracion/indicadores'}><li>Administración</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Evaluador de Indicadores Variables']}>
                                <Link to={'/variable/indicadores'}><li>Captura</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Evaluador de Indicadores Variables', 'Administrador KPIs', 'FSAP']}>
                                <Link to={'/variable/evaluaciones'}><li>Evaluación</li></Link>
                            </HasAccess>
                        </ul>

                        <HasAccess roles={['Administrador KPIs', 'Consulta', 'FSAP','Evaluador de Indicadores Variables']}>
                        <p className="menu-label">
                            Reportes
                        </p>
                        </HasAccess>

                        <ul className="menu-list">
                            <HasAccess roles={['Administrador KPIs', 'Consulta', 'FSAP', 'Evaluador de Indicadores Variables', 'Autorizador']}>
                                <Link to={'/variable/historial_evaluaciones'}><li>Historial de evaluaciones</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Administrador KPIs', 'FSAP', 'Autorizador']}>
                                <Link to={'/variable/reporte_estatus'}><li>Estatus de evaluación</li></Link>
                            </HasAccess>
                        </ul>

                        
                        <HasAccess roles={['Administrador KPIs', 'FSAP']}>
                        <p className="menu-label">
                            Administración
                        </p>
                        </HasAccess>

                        <ul className="menu-list">
                            
                            <HasAccess roles={['Administrador KPIs']}>
                                <Link to={'/variable/fsap-ubicacion-base'}><li>FSAP - Ubicación base</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Administrador KPIs', 'FSAP']}>
                                <Link to={'/variable/configuracion'}><li>Períodos y notificaciones</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Administrador KPIs', 'FSAP']}>
                                <Link to={'/variable/administracion'}><li>Envío de pagos variables</li></Link>
                            </HasAccess>
                            <HasAccess roles={['Administrador KPIs']}>
                                <Link to={'/variable/usuarios'}><li>Usuarios</li></Link>
                            </HasAccess>
                        </ul>
                    </Fragment>
                }
                <ul className="menu-list">
                    <HasAccess roles={['Jefe Directo', 'Usuario', 'Administrador']}>
                        <li><Link to={'/'} onClick={this.loadBSC}><i className="fa fa-exchange"></i>BSC</Link></li>
                    </HasAccess>                    
                    <li><Link to={'/logout'}><i className="fa fa-sign-out"></i>Cerrar Sesión</Link></li>
                </ul>
            </div>
        )
    }
}