import React, { Component, Fragment } from 'react'
import { Card } from '../../util/Card/Card'
import { Button } from '../../util/Button/Button'
import { toast } from 'react-toastify';
import { Modal } from '../../util/modal/ModalLayout'
import Dropdown from '../../util/select/Dropdown'
import ReactPaginate from 'react-paginate';
import { Icon } from '../../util/Icon/Icon'
import { Table } from '../../util/Table/Table'

import FullCalendar from '@fullcalendar/react'
import multiMonthPlugin from '@fullcalendar/multimonth'

import ConfiguracionNotificacionesAPI from '../../../services/indicadores_variables/Configuracion'
import UsuarioAPI from '../../../services/Usuario';


class Configuracion extends Component {    
    toastSuccessId = null
    toastErrorId = null
    toastWarnId = null
    toastInfoId = null

    constructor(props) {
        super(props);

        this.grupo_indicadores_selected = {
            nombre : '',
            indicadores : []
        }
        
        this.state = {
            configuracion : {
                dia_inicio_evaluacion: 0,
                dia_fin_evaluacion: 0,
                fecha_especial_inicio: null,
                fecha_especial_fin: null,
                nombre_contacto: "",
                email_contacto: ""
            },
            fecha_individual_inicio: null,
            fecha_individual_fin: null,
            showIndividualPeriodoModal: false,
            listUserEvaluadores: [],
            usuarioIndividualSelected: {
                id: 0,
                id_empleado: 0,
                nombre: ''
            },
            listPeriodosIndividuales: [],
            pagination: null,
            page: 1,
            is_new_configuration : true,
            events : [],
            isLoading : true
        }
    }

    handleLoadData = () => {
        const state = {...this.state}
   
        Promise.all([
            ConfiguracionNotificacionesAPI.get_configuration().then(res => {
                let is_new_configuration = true
                if(!res.data.id){
                    toast.info("No hay configuración previamente guardada")
                }
                else{is_new_configuration = false}
                this.setState({
                    configuracion: res.data,
                    is_new_configuration : is_new_configuration
                },() => {this.handleCreateListEvents()})
            }).catch(err => {                
                console.log('Error al cargar configuración: ', err)
                toast.error("configuración no cargados")
            }),
            UsuarioAPI.getJefesDirectosKPIs().then((result) =>{
                let listUserEvaluadores = result.data
                this.setState({
                    listUserEvaluadores : listUserEvaluadores
                })
            }).catch(err => {                
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
            }),
            ConfiguracionNotificacionesAPI.get_periodo_individual().then((result) =>{
                let listPeriodosIndividuales = result.data.results
                let pagination = result.data.pagination
                this.setState({
                    listPeriodosIndividuales : listPeriodosIndividuales,
                    pagination: pagination
                })
            }),
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    handleCreateListEvents = () => {
        let config = this.state.configuracion
        let events = []
        const today = new Date();

        let curren_month = today.getMonth() + 1;
        let curren_year = today.getFullYear()

        for (var month = curren_month; month < curren_month + 3; month++) {

            let dia_inicio_in = config.dia_inicio_evaluacion
            let dia_fin_in = config.dia_fin_evaluacion + 1
            let month_in = month

            if(config.fecha_especial_inicio){
                let  fecha_inicio = new Date(config.fecha_especial_inicio);
                if(fecha_inicio.getMonth() + 1 == month) {
                    dia_inicio_in = fecha_inicio.getDate() + 1
                    month_in = fecha_inicio.getMonth() + 1
                }                
            } 
            
            if(config.fecha_especial_fin){
                let  fecha_fin = new Date(config.fecha_especial_fin);
                if(fecha_fin.getMonth() + 1 == month) {
                    dia_fin_in = fecha_fin.getDate() + 2
                    month_in = fecha_fin.getMonth() + 1
                }                
            }
            
            let add_zero_month = '0'
            if(month >= 10)
                add_zero_month = ''

            let add_zero_day_inicio = '0'
            if(dia_inicio_in >= 10){add_zero_day_inicio = ''}
                
            let add_zero_day_fin = '0'
            if(dia_fin_in >= 10){add_zero_day_fin = ''}

            let start = curren_year+'-'+add_zero_month+month_in+'-'+add_zero_day_inicio+dia_inicio_in
            let end = curren_year+'-'+add_zero_month+month_in+'-'+add_zero_day_fin+dia_fin_in

            let event_to_create = {
                start: start,
                end: end,
                overlap: false,
                display: 'background'
            }

            events.push(event_to_create)
            
        }        

        this.setState({
            events : events
        })
    }

    componentWillMount() {
        this.handleLoadData()
    }

    handleChangeInicio= (event) => {

        let state = {...this.state}
        let dia_inicio = event.target.value
        let config = state.configuracion
        config.dia_inicio_evaluacion = parseInt(dia_inicio)

        this.setState({
            configuracion : config
        }, () => {this.handleCreateListEvents()})

    }

    handleChangeFin= (event) => {

        let state = {...this.state}
        let dia_fin = event.target.value

        let config = state.configuracion
        config.dia_fin_evaluacion = parseInt(dia_fin)

        this.setState({
            configuracion : config
        }, () => {this.handleCreateListEvents()})

    }

    handleChangeInicioCustom = (event) => {
        let state = {...this.state}
        let config = state.configuracion
        let fecha_inicio = null
        let fecha_fin = null
        let dia_inicio = null
        let dia_fin = null

        let fecha_inicio_selected = event.target.value
        let fecha_fin_selected = config.fecha_especial_fin

        fecha_inicio = new Date(event.target.value)
        fecha_fin = fecha_fin_selected == null ? null : new Date(config.fecha_especial_fin)

        if(fecha_fin != null && fecha_fin.getMonth() == fecha_inicio.getMonth()){
            dia_fin = fecha_fin.getDate() + 1
        }
        else{
            dia_fin = config.dia_fin_evaluacion 
        }
                      
        dia_inicio = fecha_inicio.getDate() + 1 > 30 ? 1 : fecha_inicio.getDate()

        if(dia_fin != null && dia_inicio > dia_fin){
            fecha_inicio_selected = state.configuracion.fecha_especial_inicio
            toast.error('Error, dia inicio no puede ser mayor al dia fin del periodo')
        }
        else{
            
            config.fecha_especial_inicio = fecha_inicio_selected
            config.fecha_especial_fin = fecha_fin_selected
            
            this.setState({
                configuracion : config
            }, () => {this.handleCreateListEvents()})
        }
    }

    handleChangeFinCustom = (event) => {
        let state = {...this.state}
        let config = state.configuracion
        let fecha_inicio = null
        let fecha_fin = null
        let dia_inicio = null
        let dia_fin = null

        let fecha_inicio_selected = config.fecha_especial_inicio
        let fecha_fin_selected = event.target.value

        
        fecha_fin = new Date(fecha_fin_selected)
        fecha_inicio = new Date(fecha_inicio_selected)

        if(fecha_fin.getMonth() == fecha_inicio.getMonth()){
            dia_inicio = fecha_inicio.getDate() + 1
        }
        else{
            dia_inicio = config.dia_inicio_evaluacion 
        }
                      
        dia_fin = fecha_fin.getDate() + 1

        if(dia_inicio > dia_fin){
            fecha_fin_selected = state.configuracion.fecha_especial_inicio
            toast.error('Error, dia fin no puede ser menor al dia inicio del periodo')
        }
        else{
            
            config.fecha_especial_inicio = fecha_inicio_selected
            config.fecha_especial_fin = fecha_fin_selected
            
            this.setState({
                configuracion : config
            }, () => {this.handleCreateListEvents()})
        }
    }

    handleChangeNombre = (event) => {
        let state = {...this.state}
        let nombre = event.target.value
        let config = state.configuracion

        config.nombre_contacto = nombre

        this.setState({configuracion : config})
    }

    handleChangeEmail = (event) => {
        let state = {...this.state}
        let email = event.target.value
        let config = state.configuracion

        config.email_contacto = email

        this.setState({configuracion : config})
    }

    handleSaveConfiguration = () => {        
        let state = {...this.state}

        let dia_fin = state.configuracion.dia_fin_evaluacion        
        let dia_inicio = state.configuracion.dia_inicio_evaluacion

        let valid = true
        

        if(parseInt(dia_inicio) > dia_fin){
            valid = false
            toast.error('Error, dia inicio no puede ser mayor al dia fin del periodo')
        }
        
        if(parseInt(dia_fin) > 30 || parseInt(dia_fin) < 1 || parseInt(dia_inicio) < 1 || parseInt(dia_inicio) > 30){
            valid = false
            toast.error('Error, el rango del periodo debe estar dentro del mes')
        }
        if(state.configuracion.fecha_especial_inicio =='')
            state.configuracion.fecha_especial_inicio = undefined
        if(state.configuracion.fecha_especial_fin =='')
            state.configuracion.fecha_especial_fin = undefined

        if(state.is_new_configuration && valid){
            ConfiguracionNotificacionesAPI.create(state.configuracion).then(res => {
                toast.success("Configuración actualizada correctamente")
                this.handleLoadData()
            }).catch(err => {
                if(err.response.data.message.nombre_contacto){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.nombre_contacto[0])
                }
                if(err.response.data.message.email_contacto){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.email_contacto[0])
                }
                if(err.response.data.message.dia_inicio_evaluacion){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.dia_inicio_evaluacion[0])
                }
                if(err.response.data.message.dia_fin_evaluacion){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.dia_fin_evaluacion[0])
                }
            })
        }
        else if(valid){
            ConfiguracionNotificacionesAPI.update(state.configuracion).then(res => {
                toast.success("Configuración actualizada correctamente")
                this.handleLoadData()
            }).catch(err => {
                if(err.response.data.message.nombre_contacto){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.nombre_contacto[0])
                }
                if(err.response.data.message.email_contacto){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.email_contacto[0])
                }
                if(err.response.data.message.dia_inicio_evaluacion){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.dia_inicio_evaluacion[0])
                }
                if(err.response.data.message.dia_fin_evaluacion){
                    toast.error("Error al actualizar configuración:" + err.response.data.message.dia_fin_evaluacion[0])
                }
            })
        }
    }

    handleIndividualPeriodoModal = () => {
        let state = {...this.state}
        this.setState({showIndividualPeriodoModal : true})
    }

    handleSavePeriodoIndividual = () => {
        let state = {...this.state}

        if(state.usuarioIndividualSelected.id == 0){
            toast.error("Error al agregar periodo individual, debe seleccionar un usuario.")
            return null
        }
        if(state.fecha_individual_inicio == null || state.fecha_individual_inicio == ''){
            toast.error("Error al agregar periodo individual, debe seleccionar una fecha inicio.")
            return null
        }
        if(state.fecha_individual_fin == null || state.fecha_individual_fin == ''){
            toast.error("Error al agregar periodo individual, debe seleccionar una fecha fin.")
            return null
        }
        if (state.fecha_individual_inicio > state.fecha_individual_fin) {
            toast.error("Error al agregar periodo individual, La fecha de inicio no puede ser mayor que la fecha de fin.")
            return null;
        }
    

        let data = {
            'usuario' : state.usuarioIndividualSelected.id,
            'fecha_especial_inicio' : state.fecha_individual_inicio,
            'fecha_especial_fin' : state.fecha_individual_fin
        }

        ConfiguracionNotificacionesAPI.create_periodo_individual(data).then(res => {
            toast.success("Fecha de evaluación individual creada correctamente")
            this.handleLoadData()
        }).catch(err => {
            toast.error("Error al crear evaluación individual. " + err.response.data.message)
        })
    }

    handleCargaPeriodosIndividuales = (page) => {
        Promise.all([
            ConfiguracionNotificacionesAPI.get_periodo_individual(page).then((result) =>{
                let listPeriodosIndividuales = result.data.results
                let pagination = result.data.pagination
                this.setState({
                    listPeriodosIndividuales : listPeriodosIndividuales,
                    pagination: pagination
                })
            }),
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    handleDeletePeriodosIndividuales = (id) => {
        Promise.all([
            ConfiguracionNotificacionesAPI.delete_periodo_individual(id).then(res => {
                toast.success("Fecha de evaluación individual eliminada correctamente")
                this.handleLoadData()
            }).catch(err => {
                toast.error("Error al eliminar evaluación individual")
            })
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    render(){
       
        const isAdmin = localStorage.role.split(',').includes('Administrador KPIs')
        const COLUMNS = [
            {
              id: 1,
              title: 'Empleado',
              value: 'nombre',
            },
            {
              id: 2,
              title: 'Fecha Inicio',
              value: 'fecha_especial_inicio',
            },
            {
                id: 3,
                title: 'Fecha Fin',
                value: 'fecha_especial_fin',
              },
            {
              id: 4,
              title: 'Acciones',
              render: ({ item }) => {
                return (
                <Fragment>
                  <Icon
                    title="Eliminar periodo individual"
                    icon="fa-trash"
                    color="has-text-info"
                    click={() => {
                        this.handleDeletePeriodosIndividuales(item.id)
                    }}
                  />                  
                </Fragment>
                )
              }
            }
          ]

        return (
            <Fragment>
                <Card title="Configuración de períodos y notificaciones" extraActions={
                    <div>
                        <Button
                        icon="fa-user"
                        type="is-default"
                        onClick={this.handleIndividualPeriodoModal}
                        title="Período individual"
                        text="Período individual"
                        />
                        <Button
                        icon="fa-save"
                        type="is-success"
                        onClick={this.handleSaveConfiguration}
                        title="Guardar configuración"
                        text="Guardar configuración"
                        disabled={!isAdmin}
                        />
                    </div>
                }>
                    <div>
                        <div className="columns">
                                <div className="column is-6">
                                    <div className="field">
                                        <label className="label">Nombre de contacto:</label>
                                        <div className="control">
                                            <input className={"input"} type="text" placeholder="Nombre de contacto"
                                                name="nombre_contacto"
                                                disabled={!isAdmin}
                                                value={this.state.configuracion.nombre_contacto}
                                                onChange = {this.handleChangeNombre}
                                                
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-6">
                                    <div className="field">
                                        <label className="label">Email de contacto:</label>
                                        <div className="control">
                                            <input className={"input"} type="email" placeholder="Email del contacto"
                                                name="email_contacto"
                                                disabled={!isAdmin}
                                                value={this.state.configuracion.email_contacto}
                                                onChange = {this.handleChangeEmail}
                                            />
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Día inicio período:</label>
                                    <div className="control">
                                        <input className={"input"} type="number" placeholder="Día del mes que inicia el período de evaluación"
                                            name="dia_inicio_periodo"
                                            min={1}
                                            max={30}
                                            disabled={!isAdmin}
                                            value={this.state.configuracion.dia_inicio_evaluacion}
                                            onChange = {this.handleChangeInicio}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Día fin período:</label>
                                    <div className="control">
                                        <input className={"input"} type="number" placeholder="Día del mes que finaliza el período de evaluación"
                                            name="dia_inicio_periodo"
                                            min={1}
                                            max={30}
                                            disabled={!isAdmin}
                                            value={this.state.configuracion.dia_fin_evaluacion}
                                            onChange = {this.handleChangeFin}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Fecha inicio especial (Este cambio será efectuado para el mes seleccionado)</label>
                                    <div className="control">
                                        <input className={"input"} type="date"
                                            name="dia_especial_inicio_periodo"
                                            disabled={!isAdmin}
                                            value={this.state.configuracion.fecha_especial_inicio ? this.state.configuracion.fecha_especial_inicio : ''}
                                            onChange = {this.handleChangeInicioCustom}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Fecha fin especial (Este cambio será efectuado para el mes seleccionado) </label>
                                    <div className="control">
                                        <input className={"input"} type="date"
                                            name="dia_especial_fin_periodo"
                                            disabled={!isAdmin}            
                                            value={this.state.configuracion.fecha_especial_fin ? this.state.configuracion.fecha_especial_fin : ''}                                            
                                            onChange = {this.handleChangeFinCustom}
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="columns">
                            <div className="column is-12">
                                <FullCalendar
                                    plugins= {[multiMonthPlugin]}
                                    initialView= 'multiMonthFourMonth'
                                    locale= 'es'
                                    views= {{
                                        multiMonthFourMonth: {
                                            type: 'multiMonth',
                                            duration: { months: 3 },

                                        }
                                    }}
                                    headerToolbar={{
                                        start: 'title', // will normally be on the left. if RTL, will be on the right
                                        center: '',
                                        end: '', // will normally be on the right. if RTL, will be on the left
                                    }}
                                    events= {this.state.events}
                                    showNonCurrentDates = {false}
                                />
                            </div>
                        
                        </div>
                    </div>
                    {
                        this.state.showIndividualPeriodoModal &&
                        <Modal
                            title="Período individual"
                            isVisible={this.state.showIndividualPeriodoModal}
                            cancelText="Cerrar"
                            onClose={() => this.setState({ showIndividualPeriodoModal: false })}
                            isLarge={true}
                        >
                            <Card>
                                <div className="columns">
                                    <div className="column is-4">
                                        <label className="label">Usuario:</label>
                                        <Dropdown
                                            name="Usuario"
                                            data={this.state.listUserEvaluadores.map(user => {
                                                let copy = {
                                                    id: user.id,
                                                    value: user.nombre
                                                }
                                                return copy
                                            }).sort((a, b) => {
                                                return a.value.localeCompare(b.value)
                                            })}
                                            value={this.state.usuarioIndividualSelected ?this.state.usuarioIndividualSelected.nombre : ''}
                                            onChange={(user) => {
                                                let usuario = this.state.listUserEvaluadores.find((usuario) =>{
                                                    if(usuario.id == user)
                                                        return usuario
                                                })
                                                this.setState({
                                                    usuarioIndividualSelected: usuario
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="column is-4">
                                        <div className="field">
                                            <label className="label">Fecha evaluación inicio:</label>
                                            <div className="control">
                                                <input className={"input"} type="date"
                                                    name="dia_especial_individual_inicio"
                                                    disabled={ false }                   
                                                    min={new Date().toJSON().slice(0, 10)}
                                                    value={this.state.fecha_individual_inicio ? this.state.fecha_individual_inicio : ''}
                                                    onChange = {(event) => {
                                                        this.setState({fecha_individual_inicio:event.target.value})
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-4">
                                        <div className="field">
                                            <label className="label">Fecha evaluación fin:</label>
                                            <div className="control">
                                                <input className={"input"} type="date"
                                                    name="dia_especial_individual_fin"
                                                    disabled={ false }                   
                                                    min={new Date().toJSON().slice(0, 10)}
                                                    value={this.state.fecha_individual_fin ? this.state.fecha_individual_fin : ''}
                                                    onChange = {(event) => {
                                                        this.setState({fecha_individual_fin:event.target.value})
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    icon="fa-save"
                                    type="is-success"
                                    onClick={this.handleSavePeriodoIndividual}
                                    title="Guardar fecha"
                                    text="Guardar fecha"
                                />
                                
                            </Card>
                            
                            <br />
                            {
                                this.state.pagination 
                                    ? <ReactPaginate 
                                        className="react-paginate"
                                        breakLabel="..."
                                        onPageChange={(page) => this.handleCargaPeriodosIndividuales(page.selected + 1)}
                                        pageCount={this.state.pagination.pages}
                                        previousLabel="Anterior"
                                        nextLabel="Siguiente"
                                    />
                                    : ''
                            }

                            <Card>
                                <Table
                                    columns={COLUMNS}
                                    dataSet={this.state.listPeriodosIndividuales}
                                    noDataText="No se encontraron planes estratégicos"
                                />                                
                            </Card>
                            
                            <br />
                            
                        </Modal>
                    }

                </Card>
            </Fragment>
        )

    }

}

export default Configuracion;

