import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { IndicadorTabla } from '../../util/Indicadores/IndicadorTabla'
import { MessageError } from '../../util/Notifications/MessageError'
import { Modal } from '../../util/modal/ModalLayout'

import './BuscarIndicadores.css'

/**
 * Layout de componente para buscar y mostrar
 * indicadores relacionados.
 */
class BuscarIndicadores extends Component {
    handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            if (this.props.codigo.length > 0) {
                this.props.getIndicador()
            }
        }
    }

    footerModalBuscarIndicadores = (
        selectedIndicator, searchedIndicator,
        searchedIndicatorIsEmpty, isProposalValid,
        isAProposalFromAreaBoss, isPrevRelated
    ) => {
        return <Fragment>
            <Button
                text="Guardar relación"
                icon="fa-save"
                onClick={this.props.confirmar}
                type="is-success"
                disabled={
                    (searchedIndicatorIsEmpty || !Object.keys(selectedIndicator).length) ||
                    this.props.error ||
                    (
                        !isAProposalFromAreaBoss // Si la propuesta no pertenece a un jefe de área y no tiene estatus Validada
                        && !isProposalValid
                    ) ||
                    isPrevRelated
                }
            />
            <Button
                text="Cancelar"
                icon="fa-close"
                type="secondary"
                onClick={this.props.close}
            />
        </Fragment>
    }

    render() {
        const selectedIndicator = [this.props.from_indicador]
        const indicatorToRelate = [this.props.to_indicador]
        const isIndicatorToRelateEmpty = Object.keys(indicatorToRelate[0]).length === 0;
        const isProposalValid = isIndicatorToRelateEmpty ? false : indicatorToRelate[0].propuesta.estatus === "Validado"
        const isAreaBossProposal = isIndicatorToRelateEmpty ? false : indicatorToRelate[0].propuesta.usuario.grupo === 1;
        const isPrevRelated = indicatorToRelate[0].rel_indicador ?
            indicatorToRelate[0].rel_indicador.map(indicador => indicador.codigo).includes(selectedIndicator[0].codigo)
            : false
        // const listUsers = indicatorToRelate[0].rel_indicador ?
        //     [...new Set(indicatorToRelate[0].rel_indicador.map(indicator => {
        //         let indicatorCopy = {

        //         }
        //         indicator.usuario_nombre
        //     }))]
        //     : []
        return (
            <Fragment>
                <Modal
                    title="Buscar indicador"
                    isLarge={true}
                    isVisible={true}
                    Footer={() => this.footerModalBuscarIndicadores(
                        selectedIndicator, indicatorToRelate,
                        isIndicatorToRelateEmpty, isProposalValid,
                        isAreaBossProposal, isPrevRelated
                    )}
                >
                    <div className="mb-10">
                        <div className="field has-addons">
                            <div className={`control is-expanded is-medium ${this.props.isLoading ? "is-loading" : ""}`}>
                                <input type="text"
                                    className="input is-medium"
                                    placeholder="Código del indicador a relacionar"
                                    autoFocus={true}
                                    name="codigo"
                                    value={this.props.codigo}
                                    onChange={this.props.change}
                                    onKeyUp={this.handleKeyPress}
                                />
                            </div>
                            <div className="control">
                                <Button
                                    onClick={this.props.getIndicador}
                                    className={`is-medium ${this.props.isLoading ? "is-loading" : ""}`}
                                    title="Buscar indicador"
                                    icon="fa-search"
                                    type="secondary"
                                    disabled={this.props.codigo === ''}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <strong>Indicador seleccionado:</strong>
                        <IndicadorTabla
                            indicadores={selectedIndicator}
                            usuarios={this.props.usuarios}
                        />
                    </div>

                    {
                        !isIndicatorToRelateEmpty &&
                        <div className="mt-10">
                            <strong>Indicador a relacionar:</strong>
                            <p>Indicador de {indicatorToRelate[0].propuesta.usuario.usuario.nombre}</p>
                            <IndicadorTabla
                                indicadores={indicatorToRelate}
                                isNormal={false}
                                usuarios={this.props.usuarios}
                            />

                            {
                                isPrevRelated &&
                                <MessageError msg="Los indicadores ya están relacionados" />
                            }
                            {
                                this.props.error &&
                                <MessageError msg="No puedes relacionar objetivos que pertenezcan a su misma propuesta" />
                            }
                            {
                                !isProposalValid &&
                                <MessageError msg="No puedes relacionar este indicador porque la propuesta a la que pertenece no ha sido validada" />
                            }

                            <strong>{`Indicadores relacionados a indicador ${indicatorToRelate[0].codigo} de ${indicatorToRelate[0].propuesta.usuario.usuario.nombre}:`}</strong>
                            {
                                indicatorToRelate[0].rel_indicador.length > 0 &&
                                indicatorToRelate[0].rel_indicador.map(indicator => {
                                    return <Fragment key={indicator.id}>
                                        <p>{` - ${indicator.usuario_nombre}`}</p>
                                        <IndicadorTabla
                                            indicadores={[indicator]}
                                            isNormal={false}
                                        // usuarios={this.props.usuarios}
                                        />
                                    </Fragment>
                                })
                            }
                            {
                                indicatorToRelate[0].rel_indicador.length === 0 &&
                                <MessageError msg="Este indicador no está relacionado a otros" />
                            }
                        </div>
                    }
                </Modal>
            </Fragment>
        )
    }
}

export default BuscarIndicadores;