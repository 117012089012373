import React from "react";

export function HistorialEvaluacionesDetailsTotal({ details }) {
    return (
        <div className="columns">
            <div className="column is-three-quarters"></div>
            <div className="column is-one-quarter">
                <table class="table" style={{width: "100%"}}>
                    <tr>
                        <td style={{color: "#ff0000"}}>Sueldo variable</td>
                        <td className="has-text-right">{ details.sueldo_variable.toFixed(2) }</td>
                    </tr>

                    <tr>
                        <td style={{color: "#ff0000"}}>% cumplimiento</td>
                        <td className="has-text-right">{ details.cumplimiento.toFixed(2) }</td>
                    </tr>

                    <tr>
                        <td style={{color: "#ff0000"}}>Monto obtenido</td>
                        <td className="has-text-right">{ details.monto_obtenido.toFixed(2) }</td>
                    </tr>
                </table>
            </div>
        </div>
    )
}