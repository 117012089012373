import { apiUrlIndicadores } from "../../constants";
import axios from 'axios';
import middleware from '../../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    }
})

export default class ConfiguracionNotificacionesAPI {

    static create(data) {
        return middleware.hasAuth(() => request.post('configuracion/create', data));
    }

    static update(data) {
        return middleware.hasAuth(() => request.put(`configuracion/update`, data));
    }

    static get_configuration() {
        return middleware.hasAuth(() => request.get(`configuracion/`));
    }

    static create_periodo_individual(data) {
        return middleware.hasAuth(() => request.post('configuracion/periodo_individual/', data));
    }

    static get_periodo_individual(page = 1) {
        return middleware.hasAuth(() => request.get(`configuracion/periodo_individual/?page=`+page));
    }

    static delete_periodo_individual(id) {
        return middleware.hasAuth(() => request.delete(`configuracion/periodo_individual/`+id));
    }
}