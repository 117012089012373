import React, { Component, Fragment } from 'react';
import { Icon } from '../util/Icon/Icon'
import HasAccess from '../util/hasAccess/HasAccess';
import { Modal } from '../util/modal/ModalLayout';


/**Tabla de solicitudes de compensacion realizadas. */
export class SegmentosTable extends Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            isModalAutorizar: false,
            isModalRechazar : false,
            solicitudToComplete: {}
        }
    }

    render() {

        return (
            <div>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Nomenclatura</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.Segmentos.length === 0 ?
                            <tr>
                                <td
                                colSpan={7}
                                style={{
                                    textAlign: "center",
                                    height: "40px",
                                    fontSize: "1.3em",
                                    color: "#4c4c4c"
                                }}
                                >
                                Sin Segmentos
                                </td>
                            </tr> :
                            this.props.Segmentos.map((Segmento, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{Segmento.nombre}</td>
                                        <td>{Segmento.nomenclatura}</td>
                                        <td>{Segmento.activo? "Activo" : "Inactivo"}</td>
                                        <td>{
                                            <Fragment>
                                                <Icon
                                                title="Ver detalle"
                                                icon="fa-eye"
                                                color="has-text-info"
                                                click={() => {
                                                    this.props.edit(Segmento)
                                                }}
                                                />
                                                {
                                                    Segmento.activo?
                                                    <Icon
                                                        title="Desactivar"
                                                        icon={"fa-times-circle"}
                                                        color="has-text-danger"
                                                        click={() => {
                                                            Segmento.activo = Segmento.nomenclatura != '' ? false : Segmento.activo
                                                            this.props.editStatus(Segmento)
                                                        }}
                                                    />
                                                    :
                                                    <Icon
                                                        title="Activar"
                                                        icon={"fa-check-circle"}
                                                        color="has-text-success"
                                                        click={() => {  
                                                            Segmento.activo = Segmento.nomenclatura != '' ? true : Segmento.activo
                                                            this.props.editStatus(Segmento)
                                                        }}
                                                    />

                                                }
                                                
                                            </Fragment>
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default SegmentosTable;