import React, { Component } from 'react'

const Select = (props) => {
    let groups = props.optionProps["group"] && props.items.reduce((r, a) => {
        r[a[props.optionProps["group"]]] = r[a[props.optionProps["group"]]] || [];
        r[a[props.optionProps["group"]]].push(a);
        return r;
    }, Object.create(null));
    return (
        (groups === undefined) ?
            (
                <div className="column">
                    <div className="field">
                        <label className="label">{props.label}</label>
                        <div className="control">
                            <div className={`select is-fullwidth ${props.error ? 'is-danger' : ''}`}>
                                <select name={props.name} onChange={props.change} onBlur={props.blur} value={props.value} disabled={props.disabled}>
                                    <option value="">Seleccionar...</option>
                                    {
                                        props.items.length > 0 &&
                                        props.items.map((item, index) => {
                                            return (
                                                <option key={index} value={item[props.optionProps["value"]]}>
                                                    {item[props.optionProps["text"]]}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {props.error && <p className="help is-danger">{props.errorMessage}</p>}
                    </div>
                </div>
            ) :
            (
                <div className="column">
                    <div className="field">
                        <label className="label">{props.label}</label>
                        <div className="control">
                            <div className={`select is-fullwidth ${props.error ? 'is-danger' : ''}`}>
                                <select name={props.name} onChange={props.change} onBlur={props.blur} value={props.value}>
                                    <option value="">Seleccionar...</option>
                                    {
                                        Object.keys(groups).map((key, index) => {
                                            return <optgroup label={key} key={index}>
                                                {
                                                    groups[key].map((item, index) => {
                                                        return <option key={index} value={item[props.optionProps["value"]]}>
                                                            {item[props.optionProps["text"]]}
                                                        </option>
                                                    })
                                                }
                                            </optgroup>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {props.error && <p className="help is-danger">{props.errorMessage}</p>}
                    </div>
                </div>
            )
    )
}

export default Select;