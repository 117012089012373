import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

import './Usuario.css'
// Services
import AreaAPI from '../../services/Area';
import UsuarioAPI from '../../services/Usuario';
import GroupsAPI from '../../services/Groups';
import GerenciaAPI from '../../services/Gerencia';
import SegmentoAPI from '../../services/Segmento';
import RegionAPI from '../../services/Region';
import UbicacionBaseAPI from '../../services/UbicacionBase';
import RazonSocialAPI from '../../services/RazonSocial';

// Components
import UserList from './UserList';
import UserForm from './UserForm';
import UserUpload from './UserUpload';
import Loading from '../util/loading/Loading'
import Pagination from '../util/pagination/Pagination';
import ResponseList from './ResponseList';
import HasAccess from '../util/hasAccess/HasAccess';
import { Filtros } from '../propuestas/ObjetivoTurbo/FiltrosObjetivosTurbo';
import { Button } from '../util/Button/Button';
import { Card } from '../util/Card/Card';

// Utils
import { generateBasicExcel } from '../../util/ExcelFunctions';
import { Modal } from '../util/modal/ModalLayout';

class Usuario extends Component {
  constructor(props) {
    super(props);

    this.usuarioEmpty = {
      id: 0,
      nombre: '',
      email: '',
      id_empleado: '',
      jefe_directo: '',
      usuarios_asignados: [],
      grupo: '',
      puesto: '',
      monto: '',
      politica: '',
      area: ''
    }

    this.state = {
      usuario: {
        ...this.usuarioEmpty
      },
      usuariosOriginal: [],
      filtroUsuarios: [],
      selectedUsers: [],
      selectedAreas: [],
      usuarios: [],
      usuariosFiltrados: [],
      listaUsuariosFiltrados: [],
      usuariosList: [],
      usuariosFile: [],
      areas: [],
      grupos: [],
      gerencias: [],
      ubicaciones: [],
      razones_sociales: [],
      segmentos: [],
      regiones: [],
      currentPageItems: [],
      formTitle: '',
      IsEditing: false,
      showForm: false,
      showUpload: false,
      showResponseList: false,
      isLoading: false,
      filtros: {
        nombre: '',
        id_empleado: '',
        area: ''
      }
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    this.handleLoadInitialData()
  }

  /**Realiza la carga inicial de los datos. */
  handleLoadInitialData = () => {
    Promise.all([
      GerenciaAPI.getAll()
        .then( response => {
          this.setState({
            gerencias: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las gerencias');
        }),
      SegmentoAPI.getAll()
        .then( response => {
          this.setState({
            segmentos: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar los segmentos');
        }),
      RegionAPI.getAll()
        .then( response => {
          this.setState({
            regiones: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las regiones');
        }),
      UbicacionBaseAPI.getAll()
        .then( response => {
          this.setState({
            ubicaciones: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las ubicaciones');
        }),
      RazonSocialAPI.getAll()
        .then( response => {
          this.setState({
            razones_sociales: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las razones');
        }),
      GroupsAPI.getAll()
        .then( response => {
          this.setState({
            grupos: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar los grupos');
        }),
      AreaAPI.getAll()
        .then( response => {
          this.setState({
            areas: response.data
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar las áreas');
        }),
      UsuarioAPI.getList()
        .then( response => {
          this.setState({
            usuariosList: response.data.sort(function (a, b) {
              return a.id_empleado - b.id_empleado
            })
          });
        })
        .catch( () => {
          toast.error('Ocurrió un error al cargar a los usuarios');
        }),
      UsuarioAPI.getAll()
        .then( response => {
          const { selectedUsers } = this.state;
          const usersList = response.data.sort(function (a, b) {
            return a.usuario.id_empleado - b.usuario.id_empleado
          });
          this.setState({
            usuarios: usersList,
            usuariosOriginal: [ ...usersList ],
            isLoading: false,
            showUpload: false,
          }, () => {
            if (selectedUsers.length !== 0)
              this.handleChangeUser( selectedUsers )
          })
        })
        .catch( () => {
          this.setState({ isLoading: false });
          toast.error('Ocurrió un error al cargar a los usuarios');
        }),
    ]).finally(() => toast.success('Carga de información completada'));
  }

  new = () => {
    this.setState({
      usuario: { ...this.usuarioEmpty },
      formTitle: 'Nuevo Usuario',
      IsEditing: false,
      showForm: true
    })
  }

  edit = (usuarioSelected) => {
    let userFiltered = this.state.usuariosOriginal.filter(usuario => usuario.usuario.id === usuarioSelected.usuario.id)[0]
    let user = {
      nombre: userFiltered.usuario.nombre,
      email: userFiltered.usuario.email,
      id_empleado: userFiltered.usuario.id_empleado,
      ...userFiltered
    }

    this.setState({
      usuario: user,
      formTitle: 'Editar Usuario',
      IsEditing: true,
      showForm: true
    })
  }

  save = ( userInfo ) => {
    let usuario = { ...this.state.usuario, ...userInfo };
    this.setState({ isLoading: true });
    if (usuario.id === 0) {
      UsuarioAPI.save(usuario)
        .then(() => {
          this.setState({
            showForm: false
          }, () => this.reloadUsersData())
          toast.success("El usuario se guardó correctamente")
        })
        .catch(err => {
          this.setState({ isLoading: false });
          toast.error("Ocurrió un error al guardar al usuario")
        })

    } else {
      UsuarioAPI.edit(usuario.id, usuario)
        .then(() => {
          this.setState({
            showForm: false
          }, () => this.reloadUsersData())
          toast.success("El usuario se actualizó correctamente")
        })
        .catch(err => {
          toast.error("Ocurrió un error al actualizar al usuario: " + err.response.data.message)
        })
    }
  }

  updateUsersArray = (users, user) => {
    return users.map(item => {
      if (item.id === user.id) item = user
      return item
    })
  }

  inputChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    let user = { ...this.state.usuario }

    user[name] = value

    if (name === 'jefe_directo') {
      let usuarioFiltrado = this.state.usuariosList.filter(user => user.id === Number(value))[0]
      user.jefe_directo = Number(value)
      user.jefe_directo_nombre = usuarioFiltrado ? usuarioFiltrado.nombre : ''
      user.usuarios_asignados = user.usuarios_asignados.filter(user => user !== Number(value))
    }

    this.setState({ usuario: user })
  }

  inputChangeUsuarios = (usersList) => {
    let { usuario } = this.state

    usuario.usuarios_asignados = usersList.map(user => user.id)

    this.setState({ usuario })
  }

  handleStatusChange = (usuario) => {
    let user = {
      ...usuario.usuario
    }

    user.activo = !user.activo

    UsuarioAPI.editPartial(usuario.usuario.id, user)
      .then(response => {
        let usuarios = [...this.state.usuarios]
        usuario.usuario = response.data

        this.setState({
          usuarios: this.updateUsersArray(usuarios, usuario)
        }, () => toast.success("El usuario se actualizó correctamente"))

      })
      .catch(err => {
        toast.error("Ocurrió un error al actualizar el estatus del usuario: " + err.response.data.message)
      })
  }

  /**
   * Formatea la información data para generar excel.
   * 
   * @param {Array} usersList
   * @returns [dataSet,sheetNames,columnsNames,skipColumns]
   */
  formatUsersData(usersList = []) {
    const { grupos } = this.state
    let dataSet = []
    let excelProps = {
      sheetName: 'Usuarios',
      excelName: 'Usuarios'
    }
    let columnsNames = []

    dataSet = usersList.map(user => {
      // Asginación de gerencia según el área asignada
      let user_gerencia = '';
      if ( user.gerencia_nombre === '' ) {
        let area_gerencia = this.state.areas.find( ar => ar.id === Number( user.area ));
        if ( area_gerencia ) {
          user_gerencia = this.state.gerencias.find( gr => gr.id === Number( area_gerencia.gerencia ) ).nombre;
        }
      } else {
        user_gerencia = user.gerencia_nombre;
      }

      let grupo_assigned = grupos.find(group => group.id === user.grupo)

      let userInfo = {
        numero_empleado: user.usuario.id_empleado,
        nombre: user.usuario.nombre,
        puesto: user.puesto,
        gerencia: user_gerencia,
        area: user.area_nombre,
        region: user.usuario.region_nombre,
        segmento: user.usuario.segmento_nombre,
        razon_social: user.razon_social_nombre,
        ubicacion_base: user.ubicacion_base_nombre,
        monto: user.monto,
        politica: user.politica,
        grupo: grupo_assigned ? grupo_assigned.name : 'Sin grupo',
        email: user.usuario.email,
        jefe_directo: user.jefe_directo_nombre,
        estatus: user.usuario.activo ? 'Activo' : 'Inactivo'
      }
      return userInfo
    })

    columnsNames = (Object.keys(dataSet[0]))

    return [excelProps, dataSet, columnsNames]
  }

  /**
   * Genera y descarga la lista de usuarios desplegada en la
   * tabla en un archivo Excel.
   */
  handleDownloadUsers = () => {
    let usersList = this.state.listaUsuariosFiltrados.length === 0 ?
      this.state.usuarios : this.state.listaUsuariosFiltrados

    try {
      let [excelProps, infoRows, columnsNames] = this.formatUsersData(usersList)
      generateBasicExcel(excelProps, infoRows, columnsNames)
    } catch (err) {
      console.error( 'REPORTE', err );
      toast.warn('No se pudo generar el archivo Excel')
    }

  }

  /**
   * Maneja los cambios al seleccionar un area de la lista desplegable.
   * 
   * @param {Array} areasList
   */
  handleChangeArea = (areasList = []) => {
    let listUsuarios = this.state.usuarios
    let listAreas = areasList
    let usuariosFiltrados = []
    let selectedUsers = []

    if (listAreas.length > 0) {
      for (let i = 0; i < listAreas.length; i++) {
        for (let j = 0; j < listUsuarios.length; j++) {
          if (listAreas[i].id === listUsuarios[j].area) {
            usuariosFiltrados.push(listUsuarios[j])
            selectedUsers.push(listUsuarios[j])
          }
        }
      }
    } else {
      usuariosFiltrados = listUsuarios
      selectedUsers = []
    }

    this.setState({
      usuariosFiltrados,
      selectedUsers,
      selectedAreas: listAreas,
      listaUsuariosFiltrados: usuariosFiltrados,
    })
  }

  /**
   * Maneja los cambios al seleccionar un usuario de la lista deplegable.
   * 
   * @param {Array} usersList
   */
  handleChangeUser = (usersList = []) => {
    let idsUsers = usersList.map(user => user.id)
    let listSelectedUsers = []

    if (usersList.length === 0) {
      listSelectedUsers = this.state.usuariosFiltrados
    } else {
      listSelectedUsers = this.state.usuarios.filter(usuario => idsUsers.includes(usuario.usuario.id))
    }

    this.setState({
      selectedUsers: usersList,
      listaUsuariosFiltrados: listSelectedUsers
    })
  }

  /**Actualiza la información de la tabla de usuarios. */
  reloadUsersData = () => {
    toast.info('Cargando información de los usuarios');
    if ( !this.state.isLoading )
      this.setState({ isLoading: true })
    this.handleLoadInitialData()
  }

  /** Actualiza la información de los usaurios del layout. */
  layoutLoadComplete = () => {
    this.setState({
      showUpload: false
    }, () => this.reloadUsersData())
    toast.success('Los usuarios se actualizaron correctamente.')
  }

  handleUsersFortiaInport = () => {
    this.setState({ pagos_variables_loading: true });
    UsuarioAPI.postSincronizarUsuarios().then(response => {
      toast.success(response.data.message);
    }).catch(err => {
      let message = "Error al sincronizar usuarios";
      if (err && err.response && err.response.data) {
        message = err.response.data.message;
      }
      toast.error(message);
    }).finally(() => {
      this.setState({ pagos_variables_loading: false });
    });
  }

  render() {

    let { usuarios, listaUsuariosFiltrados } = this.state

    let listaFinal = listaUsuariosFiltrados.length === 0 ? usuarios : listaUsuariosFiltrados

    return (
      <div>
        {/** Filtros */}
        <div className="card">
          <header className="card-header">
            <p className="card-header-title is-size-4">
              Usuarios
            </p>
            <div className="card-header-icon" aria-label="more options">
              <div className="buttons are-medium">
                <Button
                  text="Nuevo usuario"
                  icon="fa-plus-circle"
                  onClick={this.new}
                />
                <Button
                  text="Cargar usuarios"
                  icon="fa-upload"
                  type="secondary"
                  onClick={() => this.setState({ showUpload: true })}
                />

                <HasAccess roles={["Administrador"]}>
                  <Button
                    text="Descargar usuarios"
                    icon="fa-file-excel-o"
                    type="secondary"
                    onClick={this.handleDownloadUsers}
                  />
                  <Button
                    icon={this.state.sincronizando ? "fa fa-spinner fa-spin" : "fa-cloud-download"}
                    type="is-info"
                    onClick={this.handleUsersFortiaInport}
                    title="Descargar usuarios FORTIA"
                    text="Descargar usuarios FORTIA"
                    disabled={this.state.sincronizando}
                  />
                </HasAccess>
              </div>
            </div>
          </header>
          <div className="card-content">
            <div className="content">
              <Filtros
                titleFiltros="Filtrar usuarios:"
                areas={this.state.areas}
                usuarios={this.state.usuariosFiltrados.length === 0 ? this.state.usuarios : this.state.usuariosFiltrados}
                onChangeArea={areaList => this.handleChangeArea(areaList)}
                onChangeUser={userList => this.handleChangeUser(userList)}
              />
            </div>
          </div>
          <footer className="card-footer">

          </footer>
        </div>

        {/** Lista de usuarios */}
        <Card extraClasses="mt-10" >
          {
            this.state.usuarios.length > 0 &&
            (
              <Fragment>
                <UserList
                  usuarios={this.state.currentPageItems}
                  changeStatus={usuario => this.handleStatusChange(usuario)}
                  edit={usuario => this.edit(usuario)}
                />
                <Pagination
                  items={listaFinal}
                  pageSize={10}
                  onChangePage={(items) => this.setState({ currentPageItems: items })}
                />
              </Fragment>
            )
          }
        </Card>

        {
          this.state.showForm &&
          <UserForm usuario={this.state.usuario}
            users={ this.state.usuariosList }
            usuariosList={
              this.state.usuariosOriginal.map(usuario => usuario.usuario)
            }
            usuariosMultiSelect={
              !this.state.IsEditing ?
                this.state.usuariosOriginal.map(usuario => usuario.usuario).filter(usuario =>
                  usuario.id !== this.state.usuario.jefe_directo
                )
                : this.state.usuariosList.filter(usuario =>
                  usuario.id !== Number(this.state.usuario.jefe_directo) &&
                  usuario.id !== Number(this.state.usuario.usuario.id)
                )
            }
            grupos={this.state.grupos}
            gerencias={this.state.gerencias}
            ubicaciones={this.state.ubicaciones}
            razones_sociales={this.state.razones_sociales}
            segmentos={this.state.segmentos}
            regiones={this.state.regiones}
            areas={this.state.areas}
            formTitle={this.state.formTitle}
            IsEditing={this.state.IsEditing}
            close={() => this.setState({ showForm: false })}
            change={this.inputChange}
            changeUsuarios={usersList => this.inputChangeUsuarios(usersList)}
            save={this.save}
            loadingData={this.state.isLoading}
          />
        }

        {
          this.state.showUpload &&
          <Modal
            isVisible={this.state.showUpload}
            title="Cargar Usuarios"
            onClose={() => this.setState({ showUpload: false })}
            cancelText="Cerrar"
          >
            <UserUpload loadComplete={this.layoutLoadComplete} />
          </Modal>
        }

        {
          this.state.showResponseList &&
          <ResponseList close={() => this.setState({ showResponseList: false })}
            usuarios={this.state.usuariosFile}
          />
        }

        <Loading
          isFullscreen={true}
          isLoading={this.state.isLoading}
          width="100px"
          height="100px"
        />

      </div>
    )
  }
}

export default Usuario;