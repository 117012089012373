export const BSCColumnStyle = {
    usuario: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    codigo: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    indice: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    objetivo: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    encargado: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    indicador: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    comentario: {
        backgroundColor: "#99cc00",
        color: "black",
        userSelect: "none"
    },
    sm: {
        backgroundColor: "#0000ff",
        color: "white",
        userSelect: "none"
    },
    m: {
        backgroundColor: "#339966",
        color: "black",
        userSelect: "none"
    },
    bm: {
        backgroundColor: "#ffff00",
        color: "black",
        userSelect: "none"
    },
    i: {
        backgroundColor: "#ff0000",
        color: "black",
        userSelect: "none"
    },
    ponderacion: {
        backgroundColor: "#ffc000",
        color: "black",
        userSelect: "none",
        whiteSpace: "nowrap"
    },
    compensacion: {
        backgroundColor: "#ffc000",
        color: "black",
        userSelect: "none"
    },
    acciones: {
        backgroundColor: "#ffc000",
        color: "black",
        userSelect: "none"
    }
}