import React, { Component } from 'react';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import ModalRelacionados from '../../util/propuestas/ModalRelacionados';
import { Button } from '../../util/Button/Button';
import HasAccess from '../../util/hasAccess/HasAccess';

class Propuesta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showColumnas: {
                objetivo: true,
                indicador: true,
                comentario: true,
                sm: true,
                m: true,
                bm: true,
                i: true,
                validate_user: true,
                ponderacion: true,
                turbo: {
                    objetivo: true,
                    indicador: true,
                    comentario: true,
                    sm: true,
                    ponderacion: true
                },
                rel: {
                    usuario: true,
                    objetivo: true,
                    indicador: true,
                    comentario: true,
                    sm: true,
                    m: true,
                    bm: true,
                    i: true,
                    validate_user: true
                }
            },
            disabledRestablecer: true,
            showRelacionados: false,
            indicadoresRelSelected: []
        }

    }

    componentDidMount() {
        this.setState({ indicadores: this.props.indicadores })
    }

    handleHideColumns = (e, columna, type) => {
        e.preventDefault();
        let state = { ...this.state };
        state.disabledRestablecer = false

        switch (type) {
            case "turbo":
                state.showColumnas.turbo[columna] = false;
                break;
            case "rel":
                state.showColumnas.rel[columna] = false;
                break;
            default:
                state.showColumnas[columna] = false;
                break;
        }

        // if(turbo) state.showColumnas.turbo[columna] = false;
        // else state.showColumnas[columna] = false;        

        this.setState(state);
    }

    handleReestablecer = () => {
        let state = { ...this.state };

        for (const key in state.showColumnas) {
            if (typeof state.showColumnas[key] === 'object') {
                for (const key in state.showColumnas.turbo) {
                    state.showColumnas.turbo[key] = true
                }
                for (const key in state.showColumnas.rel) {
                    state.showColumnas.rel[key] = true
                }
            } else {
                state.showColumnas[key] = true;
            }
        }

        state.disabledRestablecer = true

        this.setState(state);
    }

    handleClickColor = (obj) => {
        /**
         * TODO
         * - Buscar el indicador con el objeto recibido
         * - Asignar la propiedad effect = true
         */

        let indicadores = [...this.state.indicadores]

        let indicador = indicadores.find(item => item.id === obj.id);
        indicador.effect = true;

        this.setState({ indicadores: indicadores }, () => setTimeout(() => {
            let inds = [...this.state.indicadores]
            let ind = indicadores.find(item => item.id === obj.id)

            ind.effect = false
            this.setState({ indicadores: inds })
        }, 1000))

        this.props.toTop()
    }

    handleShowRelacionados = indicadores => {
        this.setState({
            showRelacionados: true,
            indicadoresRelSelected: indicadores
        })
    }

    handleEditIndicators = type => {
        this.props.showModalEditIndicators(type)
    }

    render() {
        let indicadores = this.props.indicadores.filter(obj => obj.tipo === "Normal").sort((a, b) => {
            return a.id - b.id
        })
        let indicadoresTurbo = this.props.indicadores.filter(obj => obj.tipo === "Turbo").sort((a, b) => {
            return a.id - b.id
        })
        let indicadoresRel = indicadores.filter(obj => obj.rel_indicador.length > 0 && obj.rel_indicador)
        let sumaTurbos = indicadoresTurbo.length > 0 ? indicadoresTurbo.reduce((acc, obj) => acc + obj.ponderacion, 0) : 0;
        const isAdmin = localStorage.role.split(',').includes('Administrador')
        return (
            <div style={{ marginTop: "15px" }}>
                <span className="title is-5" style={{ marginBottom: "0" }}>{this.props.usuario.usuario.nombre}</span>
                <span style={{ marginLeft: '10px' }}>
                    {
                        this.props.isAdmin ||
                            ((this.props.isOpenConvocatoria && !this.props.propuesta.autorizada) &&
                                ((
                                    this.props.userProposals.includes(this.props.propuesta.id) ||
                                    this.props.subsProposals.includes(this.props.propuesta.id)
                                ))) ?
                            <Button
                                className="is-small"
                                icon="fa-edit"
                                text="Editar objetivos"
                                onClick={() => this.handleEditIndicators('Normal')}
                                type="is-warning"
                            /> : ''
                    }
                </span>
                <div className="is-pulled-right">
                    <Button
                        className="is-small"
                        icon="fa-undo"
                        text="Restablecer columnas"
                        onClick={this.handleReestablecer}
                        disabled={this.state.disabledRestablecer}
                    />
                </div>
                <div>
                    <table
                        className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
                        style={{ fontSize: this.props.fontSize, marginTop: '5px' }}
                    >
                        <thead>
                            <PropuestaHeader
                                showColumnas={this.state.showColumnas}
                                handleHideColumns={this.handleHideColumns}
                                type="normal"
                            />
                        </thead>
                        <tbody>
                            {
                                indicadores.map((obj, index) => {
                                    const usuario_validate = obj.validate_user !== null ?
                                        this.props.usuarios.filter(user => user.id === obj.validate_user)[0]
                                        : undefined
                                    return (
                                        <tr key={index} className={obj.effect ? "highlight-animation" : ""}>
                                            {this.state.showColumnas.objetivo && <td>{obj.objetivo}</td>}
                                            {this.state.showColumnas.indicador && <td>{obj.indicador}</td>}
                                            {this.state.showColumnas.comentario && <td>{obj.comentario}</td>}
                                            {this.state.showColumnas.sm && <td>{obj.sobre_meta}</td>}
                                            {this.state.showColumnas.m && <td>{obj.meta}</td>}
                                            {this.state.showColumnas.bm && <td>{obj.bajo_meta}</td>}
                                            {this.state.showColumnas.i && <td>{obj.inaceptable}</td>}
                                            {this.state.showColumnas.validate_user && <td>{usuario_validate ? usuario_validate.usuario.nombre : 'Sin encargado'}</td>}
                                            {this.state.showColumnas.ponderacion && <td>{obj.ponderacion}</td>}
                                            {
                                                obj.rel_indicador.length > 0 &&
                                                <td style={{ border: "none" }}>
                                                    <button className="button is-info tooltip is-tooltip-left"
                                                        data-tooltip="Ver indicadores relacionados"
                                                        onClick={() => this.handleShowRelacionados(obj.rel_indicador)}>
                                                        <span className="icon">
                                                            <i className="fa fa-compress"></i>
                                                        </span>
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    indicadoresTurbo.length > 0
                        ? (
                            <div className="mt-10">
                                <hr />
                                <div className="has-text-danger" style={{ marginBottom: '5px' }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-line-chart"></i>
                                    </span>
                                    <span className="title is-6 has-text-danger" style={{ marginLeft: "5px" }}>Indicadores turbo - </span>
                                    <span>{sumaTurbos}% adicional del resultado BSC</span>
                                    <span style={{ marginLeft: '10px' }}>
                                        <HasAccess roles={['Administrador']}>
                                            <Button
                                                className="is-small"
                                                icon="fa-edit"
                                                text="Editar objetivos turbo"
                                                onClick={() => this.handleEditIndicators('Turbo')}
                                                type="is-warning"
                                            />
                                        </HasAccess>
                                    </span>
                                </div>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" style={{ fontSize: this.props.fontSize }}>
                                    <thead>
                                        <PropuestaHeader
                                            showColumnas={this.state.showColumnas.turbo}
                                            handleHideColumns={this.handleHideColumns}
                                            type="turbo"
                                        />
                                    </thead>
                                    <tbody>
                                        {
                                            indicadoresTurbo.map((obj, index) => {
                                                return <tr key={index}>
                                                    {this.state.showColumnas.turbo.objetivo && <td>{obj.objetivo}</td>}
                                                    {this.state.showColumnas.turbo.indicador && <td>{obj.indicador}</td>}
                                                    {this.state.showColumnas.turbo.comentario && <td>{obj.comentario}</td>}
                                                    {this.state.showColumnas.turbo.sm && <td>{obj.sobre_meta}</td>}
                                                    {this.state.showColumnas.turbo.ponderacion && <td>{obj.ponderacion}</td>}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ) : ("")
                }
                {/* {
                    indicadoresRel.length > 0 &&
                    <div className="mt-10">
                        <hr />
                        <span className="title is-6">Indicadores relacionados</span>
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" style={{fontSize:this.props.fontSize}}>
                            <thead>
                                <PropuestaHeader 
                                    showColumnas={this.state.showColumnas.rel}
                                    handleHideColumns={this.handleHideColumns}
                                    type="rel"
                                />
                            </thead>
                            <tbody>
                                {
                                    indicadoresRel.map(obj => {
                                        return(
                                            obj.rel_indicador.map((item, index) => {
                                                return (
                                                    <tr key={index} onClick={() => this.handleClickColor(obj)}>
                                                        { this.state.showColumnas.rel.usuario && <td>{item.usuario_nombre}</td>}
                                                        { this.state.showColumnas.rel.objetivo && <td>{item.objetivo}</td>}
                                                        { this.state.showColumnas.rel.indicador && <td>{item.indicador}</td>}
                                                        { this.state.showColumnas.rel.comentario && <td>{item.comentario}</td>}
                                                        { this.state.showColumnas.rel.sm && <td>{item.sobre_meta}</td>}
                                                        { this.state.showColumnas.rel.m && <td>{item.meta}</td>}
                                                        { this.state.showColumnas.rel.bm && <td>{item.bajo_meta}</td>}
                                                        { this.state.showColumnas.rel.i && <td>{item.inaceptable}</td>}
                                                    </tr>
                                                )
                                            })
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                } */}

                {
                    this.state.showRelacionados &&
                    <ModalRelacionados
                        indicadores={this.state.indicadoresRelSelected}
                        close={() => this.setState({ showRelacionados: false })} />
                }

            </div>
        )
    }

    getRandomColor = () => {
        var letters = 'BCDE'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    }
}

const PropuestaHeader = (props) => {
    /**
     * Props
     * showColumnas - {}
     * handleHideColumns - (func)
     * type - string
     * columns - [string]
     */
    return (
        <tr>
            {props.showColumnas.usuario && <th onDoubleClick={(e) => props.handleHideColumns(e, "usuario", props.type)} style={{ ...BSCColumnStyle.usuario, cursor: "pointer" }}>Usuario</th>}
            {props.showColumnas.objetivo && <th onDoubleClick={(e) => props.handleHideColumns(e, "objetivo", props.type)} style={{ ...BSCColumnStyle.objetivo, cursor: "pointer" }}>Objetivo</th>}
            {props.showColumnas.indicador && <th onDoubleClick={(e) => props.handleHideColumns(e, "indicador", props.type)} style={{ ...BSCColumnStyle.indicador, cursor: "pointer" }} >Indicador</th>}
            {props.showColumnas.comentario && <th onDoubleClick={(e) => props.handleHideColumns(e, "comentario", props.type)} style={{ ...BSCColumnStyle.comentario, cursor: "pointer" }} >Comentario</th>}
            {props.showColumnas.sm && <th onDoubleClick={(e) => props.handleHideColumns(e, "sm", props.type)} style={{ ...BSCColumnStyle.sm, cursor: "pointer" }} width="10%"><abbr title="Sobre meta">SM</abbr></th>}
            {props.showColumnas.m && <th onDoubleClick={(e) => props.handleHideColumns(e, "m", props.type)} style={{ ...BSCColumnStyle.m, cursor: "pointer" }} width="10%"><abbr title="Meta">M</abbr></th>}
            {props.showColumnas.bm && <th onDoubleClick={(e) => props.handleHideColumns(e, "bm", props.type)} style={{ ...BSCColumnStyle.bm, cursor: "pointer" }} width="10%"><abbr title="Bajo meta">BM</abbr></th>}
            {props.showColumnas.i && <th onDoubleClick={(e) => props.handleHideColumns(e, "i", props.type)} style={{ ...BSCColumnStyle.i, cursor: "pointer" }} width="10%"><abbr title="Inaceptable">I</abbr></th>}
            {props.showColumnas.validate_user && <th onDoubleClick={(e) => props.handleHideColumns(e, "validate_user", props.type)} style={{ ...BSCColumnStyle.encargado, cursor: "pointer" }} width="10%"><abbr title="Usuario encargado de validar el objetivo">Usuario que valida</abbr></th>}
            {props.showColumnas.ponderacion && <th onDoubleClick={(e) => props.handleHideColumns(e, "ponderacion", props.type)} style={{ ...BSCColumnStyle.ponderacion, cursor: "pointer" }} width="10%"><abbr title="Ponderación">% Pond.</abbr></th>}
        </tr>
    )
}

export default Propuesta;