import React, { Component, Fragment } from 'react'

// Styles
import './AutoEvaluacion.css'

// Utilitites
import { toast } from 'react-toastify'

// Components
import Select from '../util/select/Selct'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { Button } from '../util/Button/Button'
import { ListEvidences } from './ListEvidences'
import PropuestasAPI from '../../services/Propuestas'
import Confirmar from '../util/propuestas/Confirmar'
import { IndicadorTablaModal } from '../util/Indicadores/IndicadorTablaModal'

const LEVEL = [
  {
    id: 1,
    value: 'sobre_meta',
    text: 'SOBRE META'
  },
  {
    id: 2,
    value: 'meta',
    text: 'META'
  },
  {
    id: 3,
    value: 'bajo_meta',
    text: 'BAJO META'
  },
  {
    id: 4,
    value: 'inaceptable',
    text: 'INACEPTABLE'
  }
]

/** Componente para Formulario de evaluación de la autoevaluación. */
class FormEvaluacion extends Component {

  state = {
    indicators: [],
    idEvidenceToDelete: null,
    listEvidences: [],
    selectedIndicator: {},
    user: {},
    comentario_anterior: '',
    checkAutorizado: false,
    checkRechazado: false,
    isConfirmModalVisible: false,
    comentario: '',
    comentario_autoevaluacion : '',
    nivel_cumplimiento: '',
    status: ''
  }

  /**
   * Maneja los cambios en el campo de comentarios.
   * 
   * @param {Event} target
   */
  handleChangeComment = ({ target }) => {
    let indicador = this.state.selectedIndicator
    indicador.comentario_evaluacion = target.value
    this.setState({
      selectedIndicator: indicador,
      comentario: target.value
    }, () =>
      this.props.validateForm({
        indicador: this.state.selectedIndicator.id,
        nivel_cumplimiento: this.state.nivel_cumplimiento,
        status: this.state.status,
        comentario: this.state.comentario,
        comentario_autoevaluacion : this.state.comentario_autoevaluacion
      })
    )
  }

  /**
   * Maneja los cambios en el campo de comentarios de autoevaluacion.
   * 
   * @param {Event} target
   */
   handleChangeCommentAutoevaluation = ({ target }) => {
    let indicador = this.state.selectedIndicator
    indicador.comentario_autoevaluacion = target.value
    this.setState({
      comentario_autoevaluacion: target.value
    }, () =>
      this.props.validateForm({
        indicador: this.state.selectedIndicator.id,
        nivel_cumplimiento: this.state.nivel_cumplimiento,
        status: this.state.status,
        comentario: this.state.comentario,
        comentario_autoevaluacion : this.state.comentario_autoevaluacion
      })
    )
  }

  /**
   * Maneja los cambios al seleccionar una opción de la lista.
   * 
   * @param {Event} target
   */
  handleChangeLevel = ({ target }) => {
    let indicador = this.state.selectedIndicator
    indicador.cumplimiento = target.value
    this.setState({ nivel_cumplimiento: target.value }, () =>
      this.props.validateForm({
        indicador: this.state.selectedIndicator.id,
        nivel_cumplimiento: this.state.nivel_cumplimiento,
        status: this.state.status,
        comentario: this.state.comentario,
        comentario_autoevaluacion : this.state.comentario_autoevaluacion
      })
    )
  }

  /** Abre el modal de confirmación para eliminar la evidencia. */
  showConfirmDeleteEvidence = (itemId) => {
    this.setState({
      idEvidenceToDelete: itemId,
      isConfirmModalVisible: true
    })
  }

  /** Cierra el modal de confirmación para eliminar evidencias. */
  handleCloseConfirmDeleteEvidence = () => {
    this.setState({
      isConfirmModalVisible: false,
      idEvidenceToDelete: null
    })
  }

  /** Manda a eliminar la evidencia seleccionada. */
  handleDeleteEvidence = () => {
    const { idEvidenceToDelete } = this.state
    PropuestasAPI.deleteEvidence(idEvidenceToDelete)
      .then(() => {
        this.popEvidenceFromList(idEvidenceToDelete)
        // this.props.onDeleteEvidences()
        toast.success('Evidencia eliminada.')
      })
      .catch(err => {
        this.handleCloseConfirmDeleteEvidence()
        toast.error('No se pudo eliminar la evidencia.')
      })
  }

  /**
   * Elimina el archivo seleccionado de la lista.
   * 
   * @param {Number} idEvidence
   */
  popEvidenceFromList = (idEvidence) => {
    const evidences = this.state.listEvidences
    let updatedListEvidences = []

    updatedListEvidences = evidences.filter(item => item.id !== idEvidence)

    this.setState({
      listEvidences: updatedListEvidences
    }, () => this.handleCloseConfirmDeleteEvidence())
  }

  /** Filtra los indicadores de las propuestas. */
  handleFilterProposalIndicators = () => {
    const currentIndicators = this.props.currentProposalIndicators
    const currentEvaluation = this.props.currentEvaluation
    let indicators = []
    let selectedIndicator = {}
    let user = {}
    let currentComment = ''
    let currentStatus = ''
    let currentComentarioAutoevaluacion = ''

    selectedIndicator = this.props.currentIndicator
    let currentLevel = selectedIndicator.cumplimiento

    if (currentIndicators && currentIndicators.length !== 0) {
      currentIndicators.forEach(indicator => {
        if (indicator.indicador === this.props.currentIndicator.id) {
          currentComment = indicator.comentario_evaluacion
          currentStatus = indicator.status
          currentLevel = indicator.nivel_cumplimiento
          currentComentarioAutoevaluacion = indicator.comentario_autoevaluacion
        }
      })
      this.setState({comentario_anterior: selectedIndicator.comentario_evaluacion})
    }
    else if(selectedIndicator.comentario_evaluacion && !selectedIndicator.isEvaluated){
      currentStatus = 'Rechazado'      
      currentComment = selectedIndicator.comentario_evaluacion
    }
    else if(selectedIndicator.isEvaluated){

      if(currentEvaluation.find(item =>item.id === selectedIndicator.propuesta).indicadores)
        currentStatus = currentEvaluation.find(item =>item.id === selectedIndicator.propuesta).indicadores.find(item => item.indicador === selectedIndicator.id).status     
      else
        currentStatus = 'Autorizado'   
      currentComment = selectedIndicator.comentario_evaluacion
    }


    this.props.proposals.forEach(function (proposal) {
      proposal.indicadores.forEach(function (indicator) {
        if (indicator.tipo === 'Normal')
          indicators.push(indicator)
      })
    })


    user = this.props.userData

    this.setState({
      comentario: currentComment,
      checkAutorizado: currentStatus === 'Autorizado',
      checkRechazado: currentStatus === 'Rechazado',
      status: currentStatus,
      indicators,
      selectedIndicator,
      user,
      nivel_cumplimiento: currentLevel,
      listEvidences: selectedIndicator.evidencia,
      comentario_autoevaluacion: selectedIndicator.comentario_autoevaluacion
    })
  }

  /**
   * Maneja las descargas de los archivos de evidencias.
   * 
   * @param {String} path
   */
  handleDownloadFile = (id, name) => {
    PropuestasAPI.evidenceDownload(id)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        toast.success('Archivo descargado!')
      }
      )
      .catch(err => {
        if (err.request.status === 404) {
          toast.error('El archivo no existe')
        } else {
          toast.error('El archivo no se pudo descargar')
        }
      })
  }

  /**
   * Maneja los cambios en el estatus de los checkboxs.
   * 
   * @param {Boolean} isAutorization
   */
  handleChangeStatusCheckbox = (isAutorization = true) => {
    let status = ''
    let checkedStatusAutorizado
    let checkedStatusRechazado
    if (isAutorization) {
      checkedStatusAutorizado = !this.state.checkAutorizado
      checkedStatusRechazado = this.state.checkRechazado ? false : false
      status = checkedStatusAutorizado ? 'Autorizado' : ''
    } else {
      checkedStatusAutorizado = this.state.checkAutorizado ? false : false
      checkedStatusRechazado = !this.state.checkRechazado
      status = checkedStatusRechazado ? 'Rechazado' : ''
    }

    this.setState({
      checkAutorizado: checkedStatusAutorizado,
      checkRechazado: checkedStatusRechazado,
      status
    }, () =>
      this.props.validateForm({
        indicador: this.state.selectedIndicator.id,
        nivel_cumplimiento: this.state.nivel_cumplimiento,
        status: this.state.status,
        comentario: this.state.comentario,
        comentario_autoevaluacion : this.state.comentario_autoevaluacion
      }))
  }

  componentDidMount() {
    this.handleFilterProposalIndicators()
  }

  componentDidUpdate(prevProps, prevState) {
    const prevEvidences = prevProps.currentIndicator.evidencia.length
    const actualEvidences = this.props.currentIndicator.evidencia.length
    if (prevEvidences !== actualEvidences) {
      this.handleFilterProposalIndicators()
    }

    if (prevProps.currentIndicator.id !== this.props.currentIndicator.id) {
      this.handleFilterProposalIndicators()
    }
  }

  render() {
    const {
      selectedIndicator, comentario_anterior,
      checkAutorizado, checkRechazado,
      comentario, comentario_autoevaluacion, nivel_cumplimiento,
      listEvidences, isConfirmModalVisible
    } = this.state

    return (
      <Fragment>
        <div className="HeaderContentEvalueate">
          <h2 className="HeaderContentEvalueateUser">
            {
              `Indicador ${selectedIndicator.proposalCode}
              de ${selectedIndicator.userName}`
            }
          </h2>
          
          <p className="HeaderContentEvalueateIndicator">
            <IndicadorTablaModal
              indicadores={[selectedIndicator]}
              isNormal={selectedIndicator.tipo != 'Turbo'}
            />
          </p>
        </div>
        <Wrapper label="Estatus de validación (*)">
          <div className="WrapperButtonsEvaluate">
            <Button
              title="Validar indicador"
              text="Validar"
              icon="fa-check"
              type={`${checkAutorizado ? 'is-success' : 'secondary'}`}
              onClick={() => this.handleChangeStatusCheckbox(true)}
            />
            <Button
              title="Rechazar indicador"
              text="Rechazar"
              icon="fa-close"
              type={`${checkRechazado ? 'is-danger' : 'secondary'}`}
              onClick={() => this.handleChangeStatusCheckbox(false)}
            />
          </div>
        </Wrapper>
        <Wrapper label="Evidencias">
          <ListEvidences
            dataSet={listEvidences}
            onDeleteItem={itemId => this.showConfirmDeleteEvidence(itemId)}
            onDownloadItem={(itemId, name) => this.handleDownloadFile(itemId, name)}
          />
        </Wrapper>

        {
          selectedIndicator.tipo != 'Turbo' &&
          <Wrapper label="Desea cambiar nivel de cumplimiento">
            <Select
              classes="SelectEvaluate"
              items={LEVEL}
              optionProps={{ value: 'value', text: 'text' }}
              value={nivel_cumplimiento}
              change={this.handleChangeLevel}
              disabled={!checkAutorizado && !checkRechazado}
            />
          </Wrapper>
        }

        {
          selectedIndicator.tipo != 'Turbo' &&
          <Wrapper label="Comentarios de autoevaluación">
            <textarea
              className="TextAreaEvaluate"
              value={comentario_autoevaluacion}
              onChange={this.handleChangeCommentAutoevaluation}
              maxLength={250}
              placeholder="Agregue un comentario"
              disabled={!checkAutorizado && !checkRechazado}
            />
          </Wrapper>
        }
        
        {
          comentario_anterior !== '' &&
          <Wrapper label="Comentario de evaluación anterior">
            <p>{comentario_anterior}</p>
          </Wrapper>
        }
        <Wrapper label="Comentario (*)">
          <textarea
            className="TextAreaEvaluate"
            value={comentario}
            onChange={this.handleChangeComment}
            maxLength={300}
            placeholder="Agregue un comentario"
            disabled={!checkAutorizado && !checkRechazado}
          />
        </Wrapper>

        {
          isConfirmModalVisible &&
          <Confirmar
            yes={this.handleDeleteEvidence}
            no={this.handleCloseConfirmDeleteEvidence}
          >
            <p className="ConfirmSubtitle">¿Está seguro de eliminar ésta evidencia?</p>
            <p className="ConfirmDescription">Las evidencias eliminadas no se podrán recuperar.</p>
          </Confirmar>
        }
      </Fragment>
    )
  }
}

export default FormEvaluacion
