import React from 'react'

/**
 * Componente wrapper para Inputs.
 * 
 * @param {Object} props
 * 
 * @returns Wrapper
 */
export const Wrapper = ({ extraClasses = '', label = '', children }) => {
  return (
    <div className={`column ${extraClasses}`}>
      <div className="field">
        <label className="label">{label}</label>
        <div className="control">
          <div className="is-fullwidth">
            <div className="field is-horizontal">
              <div className="field-body">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}