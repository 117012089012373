import { apiUrlIndicadores }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class PagosVariablesAPI {
    static export() {
        return middleware.hasAuth(() => request.post('/pagos_variables/export/'));
    }
}