import React, { Component, Fragment } from 'react'
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import { toast } from 'react-toastify';

import IndicadorForm from '../Indicadores/Indicador_form'
import Confirmar from '../../util/propuestas/Confirmar';
import {sumarPonderacion} from '../../../util/compensacion-util';


class GrupoIndicadores extends Component {
    constructor(props) {
        super(props);

        this.Indicador = {
            objetivo: "",
            indicador: "",
            comentario: "",
            sobre_meta: "",
            meta: "",
            bajo_meta: "",
            inaceptable: "",
            revision: "",
            ponderacion: 0,
            ponderacionValid: true,
        }

        this.state = {
            grupo_indicadores : this.props.grupo_indicadores,
            validForm: false, 
            blockForm : false,
            nombreValid: false,
            listUsers : this.props.listUsers,       
            maxIndicadores: 6,
            agregarDisabled: false,
            totalCompensacion : 0,            
            isConfirmNotificationVisible: false,
            verConfirmarCerrar : false,
            saveLoading: false,
            indicadores : this.props.grupo_indicadores.indicadores,
            indicadoresValidation: []

        }
    }

    /*Agregar nuevo indicador*/
    handlerClickAgregar() {
        let state = { ...this.state };
        let indicador = { ...this.Indicador };
        let indicadorValidation = { valid: false };
    
        state.indicadores.push(indicador);
        state.indicadoresValidation.push(indicadorValidation);
    
        this.setState(state);
    }

    /**Cambiar ponderacion de indicador */
    handlePondChange(pond, index) {
        pond = Number(pond) || 0; // Si el valor no es numérico, asigna 0
        const state = { ...this.state };
    
        state.indicadores.map((obj, i) => {
            if (i === index) {
              obj.ponderacion = pond;
              obj.ponderacionValid = true;
      
              if(obj.ponderacion == 0 || obj.ponderacion > 100){
                obj.ponderacionValid = false;
              }        
            }
            return obj;
        });
      
        const sumatoria = sumarPonderacion(state.indicadores);
        state.totalPonderacion = sumatoria;
    
        this.setState(state)
    }

    /**Asignacion de valores a campos de formulario */
    handleCamposChange(event, index, valid) {
        const state = { ...this.state };
        let name
        let value
    
        if (typeof (event) !== 'number') {
          name = event.target.name;
          value = event.target.value;
        } else {
          name = 'validate_user'
          value = event
        }
    
        state.indicadores.map((obj, i) => {
          if (i === index) {
            obj[name] = value;
          }
          return obj;
        });
    
        state.validForm = valid;
    
        this.setState(state);
    }

    handleChanceNameGroup = (event) => {
        let grupo_indicadores = this.state.grupo_indicadores
        grupo_indicadores.nombre = event.target.value
        this.setState({
            grupo_indicadores: grupo_indicadores
        })

    }

    /**Remueve indicador de el agrupado */
    handleRemoveIndicador(index) {
        let state = { ...this.state }
        state.indicadores.splice(index, 1)
        state.indicadoresValidation.splice(index, 1)
        // Ya que se removió un elemento, actualizamos tambien la ponderación
        state.totalPonderacion = sumarPonderacion(state.indicadores);    
        state.blockForm = false
    
        this.setState(state);
    }

    validateIndicadores = (isDraft = true) => {
        let state = { ...this.state }
        let indicadorZeroPond = state.indicadores.find(indicador => indicador.ponderacion === 0)

        let dataIndicadores = state.indicadores.map(indicador => {
            let copy = {
                ...indicador
            }
            if (indicador.validate_user) {
                if (typeof (indicador.validate_user) === 'number') {
                    copy.validate_user = this.state.listUsers.filter(user => user.id === Number(indicador.validate_user))[0]
                }
                else{
                    copy.validate_user = this.state.listUsers.filter(user => user.id === Number(indicador.validate_user.id))[0]
                }
                
            } else {
                copy.validate_user = ""
            }
            return copy
        })

        let grupo_indicadores = state.grupo_indicadores
        grupo_indicadores.indicadores = dataIndicadores

        if ((state.blockForm || isDraft ) && indicadorZeroPond === undefined) 
        {
            this.props.save(grupo_indicadores, isDraft);
        }
        else if(!state.blockForm && !isDraft){
            toast.error("Uno de los campos es requerido. Favor de verificar.")
        }
        else {
            toast.error("No puede haber objetivos con ponderación en cero. Favor de verificar.")
        }

        setTimeout(() => this.setState({ saveLoading: false }), 3000);

    }

    close = () =>{
        this.setState({
            grupo_indicadores : {
                nombre : '',
                indicadores : []
            }
        },() => {this.props.cancel()})

    }

    componentWillMount() {
        let state = { ...this.state };
        let agregarDisabled = true //por default cuando abrimos es true
        let blockForm = true //por default cuando abrimos es true
        let nombreValid = false

        //Calcular ponderacion
        let totalPonderacion = sumarPonderacion(state.indicadores);

        if(state.grupo_indicadores.nombre.trim().length > 0){
            nombreValid = true
        }
        
        //Si la ponderacion es menor a 100 entonces se debloquea el boton y los campos
        if(totalPonderacion < 100){
            blockForm = false
            agregarDisabled = false
            this.handlerClickAgregar()
        }
        
        this.setState({
            totalPonderacion : totalPonderacion,
            agregarDisabled : agregarDisabled,
            nombreValid : nombreValid,
            blockForm : blockForm
        })
    }


    render(){
        return (
            <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "95%" }}>
      
                <header className="modal-card-head">
                    <p className="modal-card-title">Nuevo indicador</p>
                    <div style={{ position: "relative", marginRight: "15px" }}>
                    <div>
                        <div className="field is-grouped">                          
      
                            <p className="control">
                                <button
                                    className="button is-success "
                                    onClick={this.handlerClickAgregar.bind(this)}
                                    disabled={this.state.agregarDisabled}
                                    style={{ marginRight: "3px" }}>
                                    <span className="icon is-small">
                                    <i className="fa fa-plus-circle"></i>
                                    </span>
                                    <span>Agregar objetivo</span>
            
                                </button>
                            </p>     
                        
                        </div>
                    </div>
                    </div>
                  <button onClick={() => {this.setState({verConfirmarCerrar:true})}} className="delete" title="Cerrar" aria-label="close"></button>
                </header>
      
                <section className="modal-card-body">
                {
                    this.state.guardarBorrador &&
                    <Confirmar
                        yes={() => {
                        this.setState({guardarBorrador: false}, () => {
                            this.validateIndicadores(true)
                        })
                        }}
                        no={() => this.setState({                   
                            saveLoading: false,
                            guardarBorrador: false 
                        })}>
                        
                        <h5 className="title is-5">
                        Tus indicadores se guardarán como <font color="#FFCD00">BORRADOR</font>. Solamente tú, como dueño de los indicadores, podrás visualizarlos.
                        </h5>
                        <br/>
                        <h5 className="title is-5">
                        El guardar como <font color="#FFCD00">BORRADOR</font> permitirá que realices modificaciones antes de ponerlos en curso.
                        </h5>
                    </Confirmar>
                    }
                    {
                        this.state.isConfirmNotificationVisible &&
                        <Confirmar
                            yes={() =>
                            this.setState({
                                isConfirmNotificationVisible: false
                            }, () => this.validateIndicadores(false)
                            )}
                            no={() => this.setState({ isConfirmNotificationVisible: false, saveLoading: false })}
                        >
                            <h4>¿Desea guardar su grupo de indicadores?</h4>
                            <p>Los indicadores se pondran en curso, ya no podrá editar sus indicadores</p>
                        </Confirmar>
                    }
                    {
                        this.state.verConfirmarCerrar &&
                        <Confirmar
                            yes={this.close}
                            no={() => this.setState({ verConfirmarCerrar: false })}>

                            <h5 className="title is-5">
                            ¿Seguro que desea cerrar la ventana?
                            </h5>
                            <p>Los datos capturados se perderán</p>

                        </Confirmar>
                    }

                    <Card >
                        <div>
                            <div className="columns" extraClasses="is-tow-quarters">
                                <div className="field">
                                    <label className="label">Nombre</label>
                                    <div className="control">
                                        <input className={!this.state.nombreValid ? "input is-danger" : "input"} type="text" placeholder="Nombre indicador"
                                            autoFocus={true}
                                            name="nombre"
                                            value={this.state.grupo_indicadores.nombre}
                                            onChange={this.handleChanceNameGroup}
                                            onBlur={() => {
                                                const state = {...this.state}
                                                if(state.grupo_indicadores.nombre.trim().length <= 0){
                                                    this.setState({
                                                        nombreValid : false
                                                    })
                                                }
                                                else{
                                                    this.setState({
                                                        nombreValid : true
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    {!this.state.nombreValid  && <p className="help is-danger">Este campo no debe estar vacío</p>}
                                </div>
                            </div>
                        </div>

                    </Card>                
      
                    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                        <thead>
                        <tr>
                            <th style={BSCColumnStyle.objetivo} width="20%">Objetivo</th>
                            <th style={BSCColumnStyle.indicador} width="5%">Indicador</th>
                            <th style={BSCColumnStyle.comentario} width="20%">Comentario</th>
                            <th style={BSCColumnStyle.sm}><abbr title="Sobre meta">SM</abbr></th>
                            <th style={BSCColumnStyle.m}><abbr title="Meta">M</abbr></th>
                            <th style={BSCColumnStyle.bm}><abbr title="Bajo meta">BM</abbr></th>
                            <th style={BSCColumnStyle.i}><abbr title="Inaceptable">I</abbr></th>
                            <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar el objetivo">Usuario que valida</abbr></th>
                            <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación">% Pond.</abbr></th>
                            <th style={BSCColumnStyle.acciones}>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.indicadores.map((obj, index) => {
                            return <IndicadorForm key={index}
                                disable={this.state.blockForm}
                                data={obj}
                                camposChange={(e) => this.handleCamposChange(e, index)}
                                userChange={(user) => this.handleCamposChange(user, index)}
                                pondChange={(pond) => this.handlePondChange(pond, index)}
                                remove={() => this.handleRemoveIndicador(index)}
                                isNewProposal={true}

                                listUsers={this.state.listUsers.map(user => {
                                    let copy = { ...user }
                                    copy.nombre = user.nombre
            
                                    return copy
                                })}
                                onValidation={(isValid) => {
                                    let state = { ...this.state };                                    
                                    let block = isValid
            
                                    state.indicadoresValidation.map((obj, ivindex) => {
                                        if (ivindex === index) {
                                            obj.valid = isValid;
                                        }
                                        return obj;
                                    });

                                    let indiValidate = null

                                    if(state.indicadoresValidation.length > 0){
                                        for(let validIndicador in state.indicadoresValidation)
                                            indiValidate = state.indicadoresValidation[validIndicador]
                                            if(!indiValidate.valid) block = false
                                    }
                                        
                                    
                                    if(state.totalPonderacion >= 100 && block){
                                        state.blockForm = true
                                        state.agregarDisabled = true
                                    }                                        
                                    else
                                    {
                                        state.blockForm = false                                        
                                        state.agregarDisabled = false
                                    }
            
                                    this.setState(state);
                                }}
                            />
                            })
                        }
                        <tr>
                            <td colSpan="9" style={{ textAlign: "right" }}>Total:</td>
                            <td style={{ textAlign: "left", color: this.state.totalPonderacion > 100 ? "red" : "black" }}>
                            {this.state.totalPonderacion}%
                            </td>
                        </tr>
                        </tbody>
                    </table>
        
                </section>
      
                <footer className="modal-card-foot">
                            
                  {/* Botón para guardar*/}
                  <Button
                    className={`${this.state.saveLoading ? "is-loading" : ""}`}
                    disabled={
                      this.state.saveLoading ||
                      (this.state.totalPonderacion === 100 && this.state.grupo_indicadores.nombre.trim().length > 0 && this.state.blockForm ? false : true)
                    }
                    onClick={() => {
                      this.setState({
                        saveLoading: true,
                        isConfirmNotificationVisible: true
                      })
                    }}
                    text="Guardar"
                    icon="fa-check"
                  />
                  {/* Botón para guardar y poder seguir editando propuesta */}
                  <Button
                    className={`${this.state.saveLoading ? "is-loading" : ""}`}
                    disabled={
                      this.state.saveLoading ||
                      this.state.grupo_indicadores.nombre.trim().length > 0? false : true
                    }
                    onClick={() => {
                      this.setState({
                        saveLoading: true,
                        guardarBorrador:true
                      })
                    }}
                    text="Guardar borrador"
                    icon="fa-save"
                    type="is-warning"
                  />
                  {/* Botón para cancelar la edición de la propuesta */}
                  <Button
                    onClick={() => {this.setState({verConfirmarCerrar:true})}}
                    text="Cancelar"
                    type="is-danger"
                    icon="fa-ban"
                  />
                </footer>
      
              </div>
            </div>
          )
    }
}

GrupoIndicadores.defaultProps = {
    data: {},
    showCompensacion: false
}

export default GrupoIndicadores;