import React, { Component } from 'react'
import { BSCColumnStyle } from '../../../util/bsc-column-style'

export default class ModalRelacionados extends Component {
    render() {
        let props = this.props;
        return (
            <div className={"modal is-active"}>
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "70%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Indicadores relacionados</p>

                        <button onClick={props.close} className="delete" aria-label="close"></button>

                    </header>

                    <section className="modal-card-body">
                        {
                            props.indicadores.length > 0 &&
                            <div className="table__wrapper">
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th style={BSCColumnStyle.objetivo}>Usuario</th>
                                            <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                                            <th style={BSCColumnStyle.indicador}>Indicador</th>
                                            <th style={BSCColumnStyle.comentario}>Comentario</th>
                                            <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                                            <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                                            <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                                            <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                                            {/* <th style={ BSCColumnStyle.ponderacion }><abbr title="Ponderación" width="7%">% Pond.</abbr></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.indicadores.map((obj, index) => {
                                                return <tr key={index}>
                                                    <td>{obj.usuario_nombre}</td>
                                                    <td>{obj.objetivo}</td>
                                                    <td>{obj.indicador}</td>
                                                    <td>{obj.comentario}</td>
                                                    <td>{obj.sobre_meta}</td>
                                                    <td>{obj.meta}</td>
                                                    <td>{obj.bajo_meta}</td>
                                                    <td>{obj.inaceptable}</td>
                                                    {/* <td>{obj.ponderacion}</td> */}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </section>
                </div>
            </div>
        )
    }
}