import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Dropdown from '../select/Dropdown'

/** Compenente para ver indicadores. */
class Indicador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: {
                objetivoValid: false,
                indicadorValid: false,
                comentarioValid: false,
                sobreMetaValid: false,
                metaValid: false,
                bajoMetaValid: false,
                inaceptableValid: false,
                ponderacionValid: false,
                validateUser: false
            },
            value: '',
            rows: this.props.maxRows || 1,
            minRows: 1,
            maxRows: 10,
        }

        this.validate = {
            isEmpty: (target) => {
                return target.value.trim().length <= 0
            },
            isZero: (target) => {
                return Number(target.value) === 0
            }
        }
    }

    componentDidMount() {
        if (this.props.validate) this.validateForm()
        this.setState({ rows: 5 })

    }

    handlePondFocus(e) {
        e.target.select();
    }

    validation = (e) => {
        let target = e.target;
        let validationName = target.attributes.validationname.value;
        let rule = target.attributes.rule.value;
        let state = { ...this.state };

        if (target === 'ponderacion' & Number(e.value) === 0) {
            state.validation[validationName] = false
        }
        if (this.applyRule(rule, target)) {
            state.validation[validationName] = false;
        } else {
            state.validation[validationName] = true;
        }

        this.setState(state, () => this.formValid())
    }

    applyRule = (rule, target) => {
        switch (rule) {
            case "notEmpty":
                return this.validate.isEmpty(target)
            case "notZero":
                return this.validate.isZero(target)
            default:
                break;
        }
    }

    /**
     * Valida si existe una propiedad igual a false en el state
     * para identificar si el formulario es válido
     */
    formValid = () => {
        let validation = { ...this.state.validation };
        let valid = true;

        for (const key in validation) {
            if (validation.hasOwnProperty(key)) {
                valid = validation[key] ? true : false;
                if (!valid) break;
            }
        }

        this.props.onValidation(valid)
    }

    validateForm = () => {
        let validation = { ...this.state.validation }

        Object.keys(validation).map(key => validation[key] = true)

        this.setState({ validation: validation })
    }

    validateUserSelected = value => {
        let validState = this.state.validation

        if (value !== 0)
            validState.validateUser = true
        else
            validState.validateUser = false

        this.setState({
            validation: validState
        }, () => this.formValid())
    }

    handleChangeUserValidate = (user) => {
        this.validateUserSelected(user)
        this.props.userChange(user)
    }

    handleChange = (event) => {
        this.calculateRow(event.target)
        this.props.camposChange(event)
    }

    calculateRow = (target) => {
        const textareaLineHeight = 24;
        const { minRows, maxRows } = this.state;

        const previousRows = target.rows;
        target.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            target.rows = maxRows;
            target.scrollTop = target.scrollHeight;
        }

        this.setState({
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    }

    getValidateUser = (indicador) => {
        const listUser = this.props.listUsers
        let usuarioValidate = ''
        if (
            indicador.validate_user &&
            listUser.length !== 0 &&
            typeof (indicador.validate_user) === 'number'
        ) {
            let coincidence = listUser.filter(user => user.id === indicador.validate_user)[0]
            usuarioValidate = coincidence != undefined ? coincidence.nombre : null
        }

        return usuarioValidate
    }

    render() {
        let indicador = this.props.data;

        return (
            <tr className={"no-border"}>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.objetivoValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="300"
                        rule="notEmpty"
                        validationname="objetivoValid"
                        name="objetivo"
                        value={indicador.objetivo}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.objetivoValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 300 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.indicadorValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="300"
                        rule="notEmpty"
                        validationname="indicadorValid"
                        name="indicador"
                        value={indicador.indicador}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.indicadorValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 300 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.comentarioValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="300"
                        rule="notEmpty"
                        validationname="comentarioValid"
                        name="comentario"
                        value={indicador.comentario}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.comentarioValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 300 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.sobreMetaValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="50"
                        rule="notEmpty"
                        validationname="sobreMetaValid"
                        name="sobre_meta"
                        value={indicador.sobre_meta}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.sobreMetaValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 50 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.metaValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="50"
                        rule="notEmpty"
                        validationname="metaValid"
                        name="meta"
                        value={indicador.meta}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.metaValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 50 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.bajoMetaValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="50"
                        rule="notEmpty"
                        validationname="bajoMetaValid"
                        name="bajo_meta"
                        value={indicador.bajo_meta}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.bajoMetaValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 50 caracteres</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={this.state.validation.inaceptableValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="50"
                        rule="notEmpty"
                        validationname="inaceptableValid"
                        name="inaceptable"
                        value={indicador.inaceptable}
                        onChange={this.handleChange}
                        onBlur={e => this.validation(e)}
                    />
                    {this.state.validation.inaceptableValid || <p className="help is-danger">El campo no puede estar vacío ni debe contener más de 50 caracteres</p>}
                </td>
                <td>
                    <Dropdown
                        name="validate_user"                        
                        isUserValidate ={true}
                        validationClass={this.state.validation.validateUser ? "" : "is-danger"}
                        data={this.props.listUsers.filter(user => {
                            if (this.props.propuesta) {
                                if (user.id !== this.props.propuesta.usuario.id)
                                    return user
                            } else {
                                return user
                            }
                        }).map(user => {
                            let copy = {
                                id: user.id,
                                value: user.nombre
                            }
                            return copy
                        }).sort((a, b) => {
                            return a.value.localeCompare(b.value)
                        })}
                        value={this.getValidateUser(indicador)}
                        selected={indicador.validate_user || 0}
                        onChange={user => this.handleChangeUserValidate(user)}
                    />
                    {this.state.validation.validateUser || <p className="help is-danger">Este campo es obligatorio</p>}
                </td>
                <td>
                    <textarea
                        rows={this.state.rows}
                        disabled={this.props.disable}
                        className={indicador.ponderacionValid ? "textarea ep-textarea" : "textarea ep-textarea is-danger"}
                        maxLength="50"
                        name="ponderacion"
                        validationname="ponderacionValid"
                        rule="notZero"
                        onChange={(e) => { this.props.pondChange(e.target.value) }}
                        onFocus={this.handlePondFocus}
                        value={indicador.ponderacion}
                        onBlur={e => this.validation(e)}
                    />

                    {
                        indicador.ponderacionValid ||
                        <p className="help is-danger">El campo debe contener un valor mayor a cero</p>
                    }

                </td>
                {
                    this.props.showCompensacion &&
                    <td>
                        <div className="tags are-medium">
                            {
                                indicador.compensacion &&
                                Object.keys(indicador.compensacion).map((key, i) => {
                                    return <span className="tag is-info" key={i}>{key + "=" + indicador.compensacion[key]}</span>
                                })
                            }
                        </div>
                    </td>
                }
                <td className="has-text-centered">
                    {
                        <a className="button is-danger"
                            title="Eliminar"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.remove(indicador.id);
                            }
                            }>
                            <span className="icon">
                                <i className="fa fa-trash" />
                            </span>
                        </a>
                    }
                </td>
            </tr>
        )
    }
}

Indicador.defaultProps = {
    data: {},
    showCompensacion: false
}

Indicador.propTypes = {
    data: PropTypes.object,
    showCompensacion: PropTypes.bool,
    camposChange: PropTypes.func,
    pondChange: PropTypes.func,
    remove: PropTypes.func,
}

export default Indicador;