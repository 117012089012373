import React from 'react';
import { HistorialEvaluacionesTableTitle } from './HistorialEvaluacionesTableTitle';
import { formatEvaluacion, getEvaluacionBg, getEvaluacionFg } from './HistorialEvaluacionesUtils';

const tableStyle = {
    tableLayout: "fixed",
}

const tdStyle = {
    wordWrap: "break-word",
}

export function HistorialEvaluacionesTable({ data, loading, setDetails, isMain = false }) {
    if (!data) return '';
    return (
        <>
        {
            data.map(obj => {
                let userDateTables = [];

                Object.keys(obj).map((userKey, userIndex) => {
                    const user = obj[userKey];
                    Object.keys(user).map((dateKey, dateIndex) => {
                        const userDate = user[dateKey];

                        const authId = userDate.evaluaciones[0].usuario_empleado_id;
                        const month = userDate.evaluaciones[0].mes;
                        const year = userDate.evaluaciones[0].anio;

                        userDateTables.push(<>
                            <HistorialEvaluacionesTableTitle
                                authId={authId}
                                user={userKey}
                                month={month}
                                year={year}
                                row={userDate}
                                loading={loading}
                                setDetails={setDetails}
                                isMain={isMain}
                            />
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" style={tableStyle} key={`${userIndex}_${dateIndex}`}>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#99cc00" }} width="5%">#</th>
                                        <th style={{ backgroundColor: "#99cc00" }}>Objetivo</th>
                                        <th style={{ backgroundColor: "#99cc00" }}>Indicador</th>
                                        <th style={{ backgroundColor: "#99cc00" }} width="15%">Comentario</th>
                                        <th style={{ backgroundColor: "#0000ff", color: "white" }} width="10%"><abbr title="Sobre meta">SM</abbr></th>
                                        <th style={{ backgroundColor: "#339966", color: "black" }} width="10%"><abbr title="Meta">M</abbr></th>
                                        <th style={{ backgroundColor: "#ffff00", color: "black" }} width="10%"><abbr title="Bajo meta">BM</abbr></th>
                                        <th style={{ backgroundColor: "#ff0000", color: "black" }} width="10%"><abbr title="Inaceptable">I</abbr></th>
                                        <th style={{ backgroundColor: "#99cc00" }}>Usuario que valida</th>
                                        <th style={{ backgroundColor: "#ffc000" }} width="10%"><abbr title="Ponderación">% Pond.</abbr></th>
                                        { isMain ? '' : <th style={{ backgroundColor: "#ffc000" }} width="10%"><abbr title="Evaluación">Evaluación</abbr></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {userDate.evaluaciones.map((row, idx) => {
                                        const evaluacion = formatEvaluacion(row.nivel_cumplimiento_desc);
                                        return (
                                            <tr key={idx}>
                                                <td style={tdStyle}>{idx + 1}</td>
                                                <td style={tdStyle}>{row.objetivo}</td>
                                                <td style={tdStyle}>{row.indicador}</td>
                                                <td style={tdStyle}>{row.comentario}</td>
                                                <td style={tdStyle}>{row.sobre_meta}</td>
                                                <td style={tdStyle}>{row.meta}</td>
                                                <td style={tdStyle}>{row.bajo_meta}</td>
                                                <td style={tdStyle}>{row.inaceptable}</td>
                                                <td style={tdStyle}>{row.usuario_valida}</td>
                                                <td style={tdStyle} className="has-text-right">{row.ponderacion}</td>
                                                { isMain ? '' : <td style={tdStyle}>{evaluacion}</td> }
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </>)
                    })
                })

                return userDateTables;
            })
        }
        </>
    )
}