import React from 'react'
import PropTypes from 'prop-types'

export const Card = ({
  title,
  children,
  extraActions,
  extraClasses,
  footer
}) => {
  return (
    <div className={`card ${extraClasses}`}>
      {
        title &&
        <div className="card-header">
          <p className="card-header-title is-size-4">{title}</p>
          {
            extraActions &&
            <span className="card-header-icon" aria-label="more options">
              {extraActions}
            </span>
          }
        </div>
      }
      <div className="card-content">
        {children}
      </div>
      {
        footer &&
        <footer className="card-footer">
          {footer}
        </footer>
      }
    </div>
  )
}

Card.protoTypes = {
  title: PropTypes.string.isRequired
}