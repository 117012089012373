import { apiUrlBsc, backUrl } from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlBsc,
    // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json' }
})

var requestDownloadFile = axios.create({
    baseURL: apiUrlBsc,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json'
    },
    responseType: 'blob'
})
export default class PropuestasAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('propuesta/'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/propuesta/${id}/`));
    }

    /**
     * Obtiene las propuestas con los filtros aplicados.
     * 
     * @param {Object} filters 
     */
    static getWithFilters(filters = {}) {
        return middleware.hasAuth(() => request.get(`/propuesta/?${stringify(filters)}`));
    }

    static getUserProposal() {
        return middleware.hasAuth(() => request.get(`propuesta/usuario/`));
    }

    static create(data) {
        return middleware.hasAuth(() => request.post('propuesta/create/', data));
    }

    static edit(id, data) {
        return middleware.hasAuth(() => request.put(`/propuesta/update/${id}/`, data));
    }

    static delete(id) {
        return middleware.hasAuth(() => request.delete(`Test/${id}`));
    }

    static authorize(id) {
        return middleware.hasAuth(() => request.put(`propuesta/autorizar/${id}/`));        
    }

    /**
     * Autorización masiva de las propuestas.
     * 
     * @param {Array} ids
     */
    static masiveAuthorize(ids) {
        return middleware.hasAuth(() => request.put(`propuesta/autorizar/masiva/`, ids));
    }

    /**
     * Autoevaluación de una propuesta.
     * 
     * @param {Object} proposal
     */
    static autoEvaluation(proposal) {
        return middleware.hasAuth(() => request.post(`propuesta/auto_evaluacion/`, proposal))
    }

    /**
     * Descarga los archivos de evidencia.
     * 
     * @param {String} path
     */
    static evidenceDownload(idEvidence) {
        return middleware.hasAuth(() => requestDownloadFile.get(`file/download/${idEvidence}`))
    }

    /**
     * Elimina el archivo de evidencia.
     * 
     * @param {Number} idEvidence
     */
    static deleteEvidence(idEvidence) {
        return middleware.hasAuth(() => request.delete(`file/delete/${idEvidence}`))
    }

    /**
     * Validación de propuestas autoevaluadas.
     * 
     * @param {Object} evaluation 
     */
    static evaluateProposal(evaluation) {
        return middleware.hasAuth(() => request.post(`propuesta/procesar_auto_evaluacion/`, evaluation))
    }

    /**
     * Reporte de compensación.
     * 
     * @param {Object} filters
     */
    static nominalReport(filters = {}) {
        return middleware.hasAuth(() => request.get(`propuesta/reporte_nomina/?${stringify(filters)}`))
    }

    static reporteNominaComplementario(filters = {}) {
        return middleware.hasAuth(() => request.get(`propuesta/reporte_nomina_complementario/?${stringify(filters)}`))
    }

    /**
     * Reporte estatus plan estratégico.
     * 
     * @param {Object} filters
     */
    static statusPlanReport(filters = {}) {
        return middleware.hasAuth(() => request.get(`propuesta/reporte_estrategia/?${stringify(filters)}`))
    }

    static getProposalCompensationPending(){
        return middleware.hasAuth(() => request.get('propuesta/compensacion_pendiente/'));
    }
}