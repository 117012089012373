import React, { Fragment } from 'react';
import './MenuInterior.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images//logo-colores-negro.png';
import HasAccess from '../util/hasAccess/HasAccess';
import Convocatorias from '../propuestas/Convocatorias/Convocatorias'
import { Button } from '../util/Button/Button';
/**
 * Elementos del menú de la barra lateral izquierda 
 */
export default class MenuInterior extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        }
    }
    // Preparando para un menú más grande
    // También se podría utilizar la navbar para eso, se verá cuando el proyecto crezca.

    loadVariable(){
        localStorage.setItem("perfil_selected", "variable");
        if(window.caches != undefined){
            window.caches.keys().then(function(names) {
                for (let name of names)
                window.caches.delete(name);
                });
        }
        window.location.href = window.location.origin;
    }

    render() {

        let canSee = true; //! Condición para saber qué pueden
        let roles = localStorage.getItem('role').split(',');        
        let have_access_montos = localStorage.getItem('have_access_montos');
        return (
            
            <div className="has-text-left sidemenu padding-top">
                <figure className="image container-image">
                    <img className="image-logo" src={logo} width="213" height="51" />
                </figure>
                <br />

                <ul className="menu-list">
                    <Link to={'/'}><li>Inicio</li></Link>
                </ul>
                {
                    (roles.length > 1 ||
                    (roles.length == 1 && !roles.includes("Usuario Nomina"))) &&
                    <Fragment>
                        <p className="menu-label">Propuestas</p>
                        <ul className="menu-list">
                            <HasAccess roles={['Administrador']}>
                                <Link to={'/convocatorias'}><li>Convocatorias</li></Link>
                            </HasAccess>
                            <Link to={'/propuestas/elaborar'}><li>Propuestas</li></Link>
                            <Link to={'/evaluacion_objetivos'}><li>Evaluación de objetivos</li></Link>
                            {/* <HasAccess roles={['Administrador']}>
                                <Link to={'/objetivoTurbo'}><li>Agregar objetivo turbo</li></Link>
                            </HasAccess> */}
                        </ul>

                        <p className="menu-label">Objetivos</p>
                        <ul className="menu-list">
                            <Link to={'/indicadores/relacion'}><li>Relación entre objetivos</li></Link>
                        </ul>
                        
                        {/* <p className="menu-label">Compensación</p>
                        <ul className="menu-list">
                            <Link to={'/solicitudes'}><li>Solicitud de compensación</li></Link>
                        </ul> */}

                        <HasAccess roles={['Administrador']}>
                            <p className="menu-label">Catálogos</p>
                            <ul className="menu-list"> 
                                <Link to={'/segmentos'}><li>Segmentos</li></Link>                               
                                <Link to={'/gerencias'}><li>Gerencias</li></Link>
                                <Link to={'/areas'}><li>Áreas</li></Link>   
                                <Link to={'/usuarios'}><li>Usuarios</li></Link>
                            </ul>
                        </HasAccess>

                        <HasAccess roles={['Administrador']}>
                            <p className="menu-label">Reportes</p>
                            <ul className="menu-list">
                                <Link to={'/reportes/historial-movimientos'}><li>Historial de movimientos</li></Link>
                            </ul>
                            <ul className="menu-list">
                                <Link to={'/reportes/estatus-plan-estrategico'}><li>Estatus de plan estratégico</li></Link>
                            </ul>
                            <ul className="menu-list">
                                <Link to={'/reportes/compensacion-total'}><li>Compensación total</li></Link>
                            </ul>
                        </HasAccess>
                    </Fragment>
                }
                {/*   
                    roles.length == 1 &&
                    roles.includes("Usuario Nomina") &&
                    <Fragment>
                        <p className="menu-label">Compensación</p>
                        <ul className="menu-list">
                            <Link to={'/solicitudes'}><li>Solicitud de compensación</li></Link>
                        </ul>
                        {
                            have_access_montos == 'true' &&
                            <ul className="menu-list">
                                <Link to={'/montos'}><li>Consulta de montos</li></Link>
                            </ul>
                        }
                        
                    </Fragment>
                    
                    */}


                <p className="menu-label">Configuración</p>
                <HasAccess roles={['Administrador']}>
                    <ul className="menu-list">
                        <Link to={'/archivos'}><li>Archivos del sitio</li></Link>
                    </ul>
                </HasAccess>
                <ul className="menu-list">
                    <HasAccess roles={['Administrador KPIs', 'Evaluador de Indicadores Variables', 'Consulta', 'FSAP', 'Autorizador']}>
                        <li><Link to={'/'} onClick={this.loadVariable}><i className="fa fa-exchange"></i>Indicadores Variables</Link></li>
                    </HasAccess>  
                    <li><Link to={'/logout'}><i className="fa fa-sign-out"></i>Cerrar Sesión</Link></li>
                </ul>
            </div>
        )
    }
}