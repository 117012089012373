import React, { Fragment } from 'react'

// Styles
import './DatePicker.css'

// Components
import { DatePicker } from './DatePicker'
import { Wrapper } from '../WrapperInput/WrapperInput'

/**
 * Componente para seleccionar fechas.
 * 
 * @param {Object} DatePickerRange
 * 
 * @returns Component
 */
export const DatePickerRange = ({
  nameStart,
  nameEnd,
  valueStart,
  valueEnd,
  disabledStart,
  disabledEnd,
  changeInput,
  initial,
  final,
  validation
}) => {
  return (
    <div className="DatePickerRangeWrapper">
      <Wrapper label="Fecha Inicio">
        <DatePicker
          name={nameStart}
          value={valueStart}
          disabled={disabledStart}
          validation={e => validation(e)}
          changeInput={value => changeInput(value)}
          initial={initial}
        />
      </Wrapper>
      <Wrapper label="Fecha Fin">
        <DatePicker
          name={nameEnd}
          value={valueEnd}
          disabled={disabledEnd}
          validation={e => validation(e)}
          changeInput={value => changeInput(value)}
          initial={valueStart}
          fianl={final}
        />
      </Wrapper>
    </div>
  )
}