import React, { Component } from 'react'
import ReactDOM from 'react-dom'

class Actions extends Component {
    state = {
        show: false
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    toggle = () => {
        this.setState({ show: !this.state.show })
    }

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false
            });
        }
        
    }

    render() {
        let children = React.Children.toArray(this.props.children).find(item => item !== false)
        return (
            children ?
                (
                    <div>
                        <div className={this.state.show ? "dropdown is-active is-right" : "dropdown is-right"}>
                            <div className="dropdown-trigger">
                                <button className="button is-default is-small" aria-haspopup="true" aria-controls="dropdown-menu" onClick={this.toggle}>
                                    <span className="icon is-small">
                                        <i className="fa fa-cogs" aria-hidden="true"></i>
                                    </span>
                                    <span>{this.props.title}</span>
                                    <span className="icon is-small">
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    {
                                        this.props.children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null

        )
    }
}

export default Actions;