import React, { Component } from 'react'

class ResponseList extends Component {
    render() {
        // console.log(this.props.usuarios)
        return (
            <div>
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card" style={{ width: "60%" }}>
                        <header className="modal-card-head">
                            <p className="modal-card-title">Archivo procesado</p>
                            <button className="delete" aria-label="close" onClick={this.props.close}></button>
                        </header>
                        <section className="modal-card-body">
                            <table className="table is-bordered">
                                <thead>
                                    <tr>
                                        <th>Número de empleado</th>
                                        <th>Nombre</th>
                                        <th>E-mail</th>
                                        <th>Jefe Directo</th>
                                        <th>Puesto</th>
                                        <th>Monto</th>
                                        <th>Política</th>
                                        <th>Grupo</th>
                                        <th>Área</th>
                                        <th>Mensaje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.usuarios.length > 0 &&
                                        this.props.usuarios.map((usuario, index) => {
                                            return (
                                                <tr key={index} className={usuario.error ? "tr-error" : ""}>
                                                    <td>
                                                        {usuario.id_empleado.data}
                                                        {
                                                            usuario.id_empleado.error &&
                                                            <span className="tag is-danger">{usuario.id_empleado.message}</span>
                                                        }
                                                    </td>

                                                    <td>
                                                        {usuario.nombre.data}
                                                        {
                                                            usuario.nombre.error &&
                                                            <span className="tag is-danger">{usuario.nombre.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.email.data}
                                                        {
                                                            usuario.email.error &&
                                                            <span className="tag is-danger">{usuario.email.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.jefe_directo.data}
                                                        {
                                                            usuario.jefe_directo.error &&
                                                            <span className="tag is-danger">{usuario.jefe_directo.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.puesto.data}
                                                        {
                                                            usuario.puesto.error &&
                                                            <span className="tag is-danger">{usuario.puesto.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.monto.data}
                                                        {
                                                            usuario.monto.error &&
                                                            <span className="tag is-danger">{usuario.monto.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.politica.data}
                                                        {
                                                            usuario.politica.error &&
                                                            <span className="tag is-danger">{usuario.politica.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.grupo.data}
                                                        {
                                                            usuario.grupo.error &&
                                                            <span className="tag is-danger">{usuario.grupo.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {usuario.area.data}
                                                        {
                                                            usuario.area.error &&
                                                            <span className="tag is-danger">{usuario.area.message}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            !usuario.error &&
                                                            <span className="tag is-success">{usuario.message}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button" onClick={this.props.close}>Cerrar</button>
                        </footer>
                    </div>
                </div>


            </div>
        )
    }
}

export default ResponseList