import numeral from 'numeral'
import lodash from 'lodash'

/**
 * Suma el valor de ponderación de todos los indicadores
 * @param {Array} indicadores
 */
export const sumarPonderacion = (indicadores) => {
    return indicadores.reduce((acc, obj) => {
        return acc + obj.ponderacion
    }, 0);
}

export const calcularCompensacion = (indicador, usuario, canWatchComp=true) => {
    let salario = parseFloat(usuario.monto);
    let politica = usuario.politica;

    let subTotal = salario.toFixed(2) * politica;
    let total = subTotal.toFixed(2);
    let totalDividido = (total / 1000).toFixed(2);

    // console.log(totalDividido)
    let SM = ((indicador.ponderacion / 100) * totalDividido).toFixed(2);
    let M = (SM * 0.75);
    let BM = (SM * 0.5);

    if ( !canWatchComp ) {
        SM = 0;
        M = 0;
        BM = 0;
    } else {
        SM = parseFloat(numeral(SM).format("0.0"))
        M = parseFloat(numeral(M).format("0.0"))
        BM = parseFloat(numeral(BM).format("0.0"))
    }

    // SM = splitNumberToRound(SM)
    // M = splitNumberToRound(M)
    // BM = splitNumberToRound(BM)

    // SM = parseFloat((SM).toFixed(1))
    // M =  parseFloat((M).toFixed(1))
    // BM = parseFloat((BM).toFixed(1))

    return { SM, M, BM } //"SM=" + SM + ", M=" + M + ", BM=" + BM;
}

//repsalfo del codigo de calcular
// export const calcularCompensacion = (indicador, usuario, canWatchComp=true) => {
//     let salario = parseFloat(usuario.monto);
//     let politica = usuario.politica;

//     let subTotal = salario * politica;
//     let total = redondear(subTotal);
//     let totalDividido = total / 1000;

//     // console.log(totalDividido)
//     let SM = ((indicador.ponderacion / 100) * totalDividido);
//     let M = (SM * 0.75);
//     let BM = (SM * 0.5);

//     if ( !canWatchComp ) {
//         SM = 0;
//         M = 0;
//         BM = 0;
//     } else {
//         SM = parseFloat(numeral(SM).format("0.0"))
//         M = parseFloat(numeral(M).format("0.0"))
//         BM = parseFloat(numeral(BM).format("0.0"))
//     }

//     // SM = splitNumberToRound(SM)
//     // M = splitNumberToRound(M)
//     // BM = splitNumberToRound(BM)

//     // SM = parseFloat((SM).toFixed(1))
//     // M =  parseFloat((M).toFixed(1))
//     // BM = parseFloat((BM).toFixed(1))

//     return { SM, M, BM } //"SM=" + SM + ", M=" + M + ", BM=" + BM;
// }

// const splitNumberToRound = (number) => {
//     // Separa entero de decimales
//     let splittedNumber = (number).toString().split(".")

//     if(splittedNumber.length === 1) return number

//     let decimals = splittedNumber[1].split("")

//     if(decimals.length === 1) return number

//     let x = 0;
//     let finalDecimal = 0;
//     for(let i = 0; i < decimals.length; i++) {
//         // if(decimals.length-i == 2) {
//         //     if(decimals[decimals.length-i] >= 5) { 
//         //         x = parseInt(decimals[1]) === 9 ? (parseInt(decimals[1]) + 1) : (parseInt(decimals[1]))  
//         //     }
//         // }

//         finalDecimal = parseInt(parseFloat("1." + decimals.join("")).toFixed(decimals.length-i).toString().split(".")[1])
//     }

//     x = finalDecimal === 0 ? (parseFloat(splittedNumber[0]) + 1) : parseFloat(splittedNumber[0] + "." + (finalDecimal).toString())

//     // x = parseFloat(splittedNumber[0] + (x).toString())

//     // if(x === 0) x = parseFloat(splittedNumber[0] + (finalDecimal).toString())

//     return x;

// }

export const conditionalRound = (number, decimalCount) => {
    (number).toFixed(1)
}

export const countDecimals = (number) => {
    if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
    return number.toString().split(".")[1].length || 0;
}

export const redondear = (num) => {
    let numConverted = Number(num)
    let numLength = (isNaN(numConverted) ? 0 : numConverted).toString().length;
    let numRedondeado = ROUND(numConverted, -(numLength - 2))

    return numRedondeado;
}

export const ROUND = (number, digits) => {
    // return Math.round(number)
    return Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits);
}

export const totalCompensacion = (indicadores) => {
    let total = indicadores.reduce((acc, obj) => {
        return acc + obj.compensacion.SM;
    }, 0);

    return parseFloat(total.toFixed(1));
}

export const validarTotalesIndicadores = (indicadores, usuario) => {

    let salario = parseFloat(usuario.monto);
    let politica = usuario.politica;

    let subTotal = salario * politica;
    let total = redondear(subTotal);
    let totalDividido = total / 1000;

    /** 
     *  SM = No puede sobrepasar de 100% Ni ser menor
     *  M = Entre .74 y .77
     *  BM = Entre .5 y .51
     */

    let totales = sumarTotalesIndicadores(indicadores, false)

    let PorcentajeSM = (totales.totalSM / totalDividido)
    let PorcentajeM = (totales.totalM / totales.totalSM)
    let PorcentajeBM = (totales.totalBM / totales.totalSM)

    let count = countSamePond(indicadores)
    let greater = greaterPond(count)

    let indicadoresOrdenados = lodash.orderBy(indicadores, ['ponderacion'], ['desc'])

    let indicador = indicadores.find(indicador => indicador.ponderacion === greater)

    let ioIndex = 0;
    while (PorcentajeSM > 1 && indicadoresOrdenados[ioIndex] !== undefined) {
        indicadoresOrdenados[ioIndex].compensacion.SM = subtractDecimal(indicadoresOrdenados[ioIndex].compensacion.SM)
        // indicador.compensacion.SM = subtractDecimal(indicador.compensacion.SM)
        totales = sumarTotalesIndicadores(indicadores, true)
        PorcentajeSM = (parseFloat(totales.totalSM.toFixed(1)) / totalDividido)
        ioIndex++;
    }

    ioIndex = 0;
    while (PorcentajeM > 0.77 && indicadoresOrdenados[ioIndex] !== undefined) {
        indicadoresOrdenados[ioIndex].compensacion.M = subtractDecimal(indicadoresOrdenados[ioIndex].compensacion.M)
        // indicador.compensacion.M = subtractDecimal(indicador.compensacion.M)
        totales = sumarTotalesIndicadores(indicadores, true)
        PorcentajeM = (parseFloat(totales.totalM.toFixed(1)) / parseFloat(totales.totalSM.toFixed(1)))
        ioIndex++;
    }

    ioIndex = 0;
    while (PorcentajeBM > 0.51 && indicadoresOrdenados[ioIndex] !== undefined) {
        indicadoresOrdenados[ioIndex].compensacion.BM = subtractDecimal(indicadoresOrdenados[ioIndex].compensacion.BM)
        // indicador.compensacion.BM = subtractDecimal(indicador.compensacion.BM)
        totales = sumarTotalesIndicadores(indicadores, true)
        PorcentajeBM = (parseFloat(totales.totalBM.toFixed(1)) / parseFloat(totales.totalSM.toFixed(1)))
        ioIndex++;
    }

    totales = sumarTotalesIndicadores(indicadores, true)

    // console.log(totales)
    return indicadores
}

const sumarTotalesIndicadores = (indicadores, fixed) => {
    let totalSM = indicadores.reduce((acc, obj) => {
        return acc + obj.compensacion.SM;
    }, 0);

    let totalM = indicadores.reduce((acc, obj) => {
        return acc + obj.compensacion.M;
    }, 0);

    let totalBM = indicadores.reduce((acc, obj) => {
        return acc + obj.compensacion.BM;
    }, 0);

    // if(fixed) {
    totalSM = parseFloat(numeral(totalSM).format("0.0"))
    totalM = parseFloat(numeral(totalM).format("0.0"))
    totalBM = parseFloat(numeral(totalBM).format("0.0"))
    // }


    return { totalSM, totalM, totalBM, PM: (totalM / totalSM), PBM: (totalBM / totalSM) }
}

// Contar en cuantos indicadores existe la misma ponderacion
const countSamePond = (indicadores) => {
    let counts = {};
    indicadores.forEach(x => counts[x.ponderacion] = (counts[x.ponderacion] || 0) + 1);

    return Object.keys(counts).map(key => key)
}

// Regresa el indicador con mayor ponderacion
const greaterPond = (arr) => {
    return Math.max(...arr)
}

const subtractDecimal = (numero) => {
    return parseFloat(numeral(numero - 0.1).format("0.0"))
}