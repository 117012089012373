import React, { Component } from "react";
import TextField from "../../util/textfield/TextField";
import { toast } from "react-toastify";
// import { isNumber } from "util";

class ProposalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposal: { ...this.props.proposal },
      isEditing: false
    };
  }

//   componentDidUpdate(props, state) {
//     //props son las del padre
//     //state son

//     console.log("actualizar proposal", props);
//     console.log("update state form", state);
//     if (props.cleanForm === true) {
//       console.log("Start clening form");
//       this.setState({ proposal: props.proposal });
//     }
//   }

  handleChange = e => {
    let state = { ...this.state };
    state.proposal[e.target.name] = e.target.value;
    this.setState(state);
  };
  save = () => {
    if (this.props.save) {
        //TODO: Hacer las validaciones necesarias para cada uno de los campos...
      let state = { ...this.state };
      console.log("add new proposal", state.proposal);
      this.props.save(this.state.proposal)
    //   state.proposal = { };
    //   console.log("clean proposal", state);
    //   this.setState(state, () => console.log(this.state));
    }

    //this.props.save(this.state.proposal)
  };

   // Método para validad los campos de formulario previo a su envío al servicio
   validateForm = () => {
    let arrProps= "objetivo,indicador,comentario,sobre_meta,meta,bajo_meta,inaceptable,ponderacion".split(",")
    let arrPropsNames= "objetivo,indicador,comentario,sobre meta,meta,bajo meta,inaceptable,ponderación".split(",")
    for (let i=0; i< arrProps.length; i++)
    {
        let propName =  arrProps[i];
        let propValue = this.state.proposal[propName];
        if (!propValue && String(propValue).length<=0){
            toast.error(`Ingresa el campo ${arrPropsNames[i]}`);
            return false;
        }    

        if ( !isFinite(this.state.proposal.ponderacion)){
            toast.error(`el campo ponderacion debe ser numero`);
            return false;   
        }
    };
    return true;
}

  render() {
    return (
      <div>
        <TextField
          autoFocus={true}
          maxLength="500"
          label="Objetivo"
          name="objetivo"
          value={this.state.proposal.objetivo}
          onChange={e => this.handleChange(e)}
        />

        <TextField
          label="Indicador"
          name="indicador"
          maxLength="100"
          value={this.state.proposal.indicador}
          onChange={e => this.handleChange(e)}
        />

        <TextField
          label="Comentario"
          name="comentario"
          value={this.state.proposal.comentario}
          onChange={e => this.handleChange(e)}
        />

        <TextField
          label="Sobre Meta"
          maxLength="50"
          name="sobre_meta"
          value={this.state.proposal.sobre_meta}
          onChange={e => this.handleChange(e)}
        />
        <TextField
          label="Meta"
          maxLength="50"
          name="meta"
          value={this.state.proposal.meta}
          onChange={e => this.handleChange(e)}
        />
        <TextField
          label="Bajo Meta"
          maxLength="50"
          name="bajo_meta"
          value={this.state.proposal.bajo_meta}
          onChange={e => this.handleChange(e)}
        />
        <TextField
          label="Inaceptable"
          maxLength="50"
          name="inaceptable"
          value={this.state.proposal.inaceptable}
          onChange={e => this.handleChange(e)}
        />
        <TextField
          label="Ponderación"
          type="number"
          maxLength="25"
          name="ponderacion"
          value={this.state.proposal.ponderacion}
          onChange={e => this.handleChange(e)}
        />
        <div className="has-text-right buttons-modal">
          <div className="column">
            <button className="button is-success" 
                onClick={ ()=> {
                    if(this.validateForm()) 
                        this.save()}
                    }>
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  }//render
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
ProposalForm.defaultProps = {
  isEditing: false,
  proposal: {
    id: 0,
    objetivo: "", //objective
    indicador: "", //indicator
    comentario: "", //comment
    sobre_meta: "", //overgoal
    meta: "", //goal
    bajo_meta: "", //undergoal
    inaceptable: "", //innefficient
    ponderacion: "" //ponderatio
  }
};

export default ProposalForm;
