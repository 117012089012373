import React, { Fragment } from 'react'

// Components
import { Table } from '../../util/Table/Table'
import NoItems from '../../util/noitems/NoItems'

export const TablaConvocatorias = ({
  columns = [],
  data
}) => {
  return (
    <Fragment>
      {
        !data ?
          <NoItems itemName="convocatorias" /> :
          <Table
            columns={columns}
            dataSet={data}
            noDataText="convocatorias"
          />
      }
    </Fragment>
  )
}
