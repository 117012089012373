import React from 'react'
import PropTypes from 'prop-types';

const Select = ({
    items = [],
    name = '',
    classes,
    optionProps = {},
    value,
    change,
    disabled = false,
    unselect = true
}) => {
    return (
        <select
            className={`${classes ? classes : ''}`}
            name={name}
            onChange={change}
            value={value}
            disabled={disabled}
        >
            { 
                unselect &&
                <option value="">Seleccionar...</option>
            }
            {
                items.length > 0 &&
                items.map((item, index) => {
                    return (
                        <option key={index} value={item[optionProps["value"]]}>
                            {item[optionProps["text"]]}
                        </option>
                    )
                })
            }
        </select>
    )
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.any,
    items: PropTypes.arrayOf(PropTypes.object),
    optionProps: PropTypes.object,
    isMultiple: PropTypes.bool
}

Select.defaultProps = {
    name: "",
    change: null,
    value: "",
    items: [{}],
    optionProps: {}
}

export default Select;