import React, { Fragment } from 'react'
import MultiSelect from '../../util/MultiSelect/MultiSelect.jsx'

/**
 * @fileoverview FiltrosObjetivosTurbo, muestra los filtros de un objetivo turbo.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
export const Filtros = ({
  areas = [],
  usuarios = [],
  selectedArea = "",
  selectedUser = "",
  titleFiltros = "Filtrar por:",
  onChangeArea,
  onChangeUser
}) => {
  return (
    <Fragment>
      <h5 className="title is-5 has-text-grey">{titleFiltros}</h5>
      <div className="columns">
        <MultiSelect
          label="Área"
          name="area"
          value={selectedArea}
          disabled={areas.length === 0}
          items={areas.map(area => {
            let newArea = { ...area }
            newArea.value = area.nombre

            return newArea
          })}
          onChangeValue={areaList => onChangeArea(areaList)}
        />

        <MultiSelect
          label="Nombre/No. Empleado"
          name="usuario"
          value={selectedUser}
          disabled={usuarios.length === 0}
          items={usuarios.map(usuario => {
            let newUsuario = { ...usuario.usuario }
            newUsuario.value = `${usuario.usuario.id_empleado} - ${usuario.usuario.nombre}`
            newUsuario.id = usuario.usuario.id

            return newUsuario
          })}
          onChangeValue={usersList => onChangeUser(usersList)}
        />
      </div>
    </Fragment>
  )
}