import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {
        'Authorization':`Bearer ${localStorage.getItem('token')}`,
        'Content-Type':'application/json',
    }
})
export default class ComentariosAPI {
    static getAll(idIndicador) {
        return middleware.hasAuth(() => request.get(`comentario/${idIndicador}/`));
    }

    static getLatest(idIndicador) {
        return middleware.hasAuth(() => request.get(`/comentario/latest/${idIndicador}/`));
    }

    static save(data) {
        return middleware.hasAuth(() => request.post('comentario/', data));
    }
}