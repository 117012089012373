import React, { Component } from 'react'

class Confirmar extends Component {
    render() {
        return (

            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content">
                    <div className="box">
                        <h3 className="title is-3 text-center">Confirmar</h3>

                        {this.props.children}

                        <br />
                        <div className="buttons are-medium">
                            <button title={this.props.titleYes != undefined ? this.props.titleYes : ''} className="button is-success" onClick={this.props.yes} disabled={this.props.disYes != undefined ? !this.props.disYes : false} >
                                <span className="icon">
                                    <i className="fa fa-check"></i>
                                </span>
                                <span>Confirmar</span>
                            </button>
                            {
                                this.props.no &&
                                <button className="button is-danger" onClick={this.props.no}>
                                    <span className="icon">
                                        <i className="fa fa-close"></i>
                                    </span>
                                    <span>Cancelar</span>
                                </button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Confirmar;




