import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './MultiSelect.css'

/**
 * @fileoverview MultiSelect, campo Multiselect que despliega una lista con la información dada.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
class MultiSelect extends Component {

  state = {
    name: this.props.name || '',
    label: this.props.label || '',
    selectedItems: [],
    items: this.props.items,
    itemsFiltered: [],
    value: '',
    isListVisible: false,
    allOptions: false,
    disabled: this.props.disabled || false,
    focusedItem: 0,
    itemIndex: 0,
  }

  /**
   * Permite ocultar la lista mediante la tecla `Escape`
   * o seleccionar un elemento de la lista con la tecla `Enter`.
   * 
   * @param {Event} e
   */
  selectItemFromKeyboard = e => {
    if (e.key === 'Escape')
      this.showItemsList(false)

    if (e.key === 'Enter') {
      const list = this.state.itemsFiltered.length !== 0 ?
        this.state.itemsFiltered
        : this.state.items
      const item = list.filter(item =>
        item.isSelected === false
      ).filter((item, index) =>
        index === this.state.itemIndex
      )[0]
      this.setState({
        value: item ? item.value : ''
      })
      this.handleClickItem(item.id, item.value)
    }
  }

  /**
   * Navegación con teclas `Arriba` `Abajo` para la lista.
   * 
   * @param {Event} e
   */
  handleArrowKeysNavigation = e => {
    const { itemIndex, items, itemsFiltered } = this.state

    const list = itemsFiltered.length !== 0
      ? itemsFiltered.filter(item => !item.isSelected)
      : items.filter(item => !item.isSelected)

    if (e.key === 'ArrowUp' && itemIndex > 0) {
      this.setElementIntoView(false, itemIndex)
      this.setState(prevState => ({
        itemIndex: prevState.itemIndex - 1
      }))
    } else if (e.key === 'ArrowDown' && itemIndex < list.length - 1) {
      this.setElementIntoView(true, itemIndex)
      this.setState(prevState => ({
        itemIndex: prevState.itemIndex + 1
      }))
    }
  }

  /**
   * Mantiene al elemento seleccionado en el campo visible de la lista.
   * 
   * @param {Boolean} isDown
   * @param {Number} itemIndex
   */
  setElementIntoView = (isDown = true, itemIndex = 0) => {
    const element = document.getElementById('IdListWrapper')
    const { isListVisible } = this.state

    if (isListVisible && itemIndex === 0)
      element.scrollTop = 0

    if (isListVisible && isDown)
      element.scrollTop += 30
    else if (isListVisible && !isDown)
      element.scrollTop -= 30
  }

  /**
   * Maneja los cambios en el contenido del campo, al introducir texto
   * al campo se le asigna el valor al mismo y al estado del componente,
   * y muestra las coincidencias del texto con la lista de informacion.
   * 
   * @param {Event} e
   */
  handleChangeContent = (e) => {
    let changeString = e.target.value

    if (changeString !== '') {
      this.showItemsList(true)
      this.setCoincidences(changeString)
      this.setState({
        value: changeString,
        itemIndex: 0
      })
    } else if (changeString === '') {
      this.setState({
        value: changeString,
        itemsFiltered: this.state.items,
        itemIndex: 0
      })
    }
  }

  /**
   * Filtra la lista de datos del campo, segun el texto introducido al campo.
   * 
   * @param {String} content
   */
  setCoincidences = (content = '') => {
    let value = content.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    let expression = new RegExp(value, 'g')
    let listValues = this.state.items

    let listItems = listValues.map(item => {
      let copy = item
      let regexString = copy.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      let coincidences = regexString.toLowerCase().match(expression) || 0
      if (coincidences.length > 0) {
        return copy
      }
    })

    if (listItems.length !== 0) {
      this.setState({ itemsFiltered: listItems.filter(item => item !== undefined) })
    } else {
      this.setState({ itemsFiltered: this.state.items })
    }
  }

  /**
   * Asigna o elimina el elemento clickeado a la lista de elementos seleccionados.
   * 
   * @param {Array} itemsArray 
   * @param {Array} selectedItem 
   */
  setClickedItemToSelectedList(itemsArray = [], selectedItem = {}) {
    let listItems = itemsArray
    let currentItem = selectedItem
    let coincidence = listItems.filter(i => i.id === currentItem.id) || []

    if (coincidence.length > 0) {
      for (let i = 0; i < listItems.length; i++) {
        if (itemsArray[i].id === currentItem.id) {
          listItems.splice(i, 1)
        }
      }
    } else {
      listItems.push(currentItem)
    }

    this.setState({
      selectedItems: listItems,
      itemIndex: 0
    })
  }

  /**
   * Maneja el cambio de estatus de los elementos de la lista de datos,
   * para mostrar si estan seleccionados o no.
   */
  handleToggleSelectedStatus = () => {
    let initialItems = this.state.items
    let filteredItems = this.state.itemsFiltered

    let toggledStatusList = this.toggleSelectedStatus(initialItems)
    let toggledStatusFilteredList = this.toggleSelectedStatus(filteredItems)

    this.setState({
      items: toggledStatusList,
      itemsFiltered: toggledStatusFilteredList,
    })

    this.setState({ items: this.state.items })
  }

  /**
   * Cambia el estatus de los elementos de los datos del campo segun la
   * lista de elementos seleccionados.
   * 
   * @param {Array} listItems 
   */
  toggleSelectedStatus(listItems = []) {
    let selectedItemsList = this.state.selectedItems.map(item => item.id)
    let list = []

    list = listItems.map(item => {
      if (selectedItemsList.includes(item.id)) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }

      return item
    })

    return list
  }

  /**
   * Maneja el evento "click" de un elemento de la lista de datos.
   * Ejecuta las funciones setClickedItemToSelectedList, handleToggleSelectedStatus
   * y manda los cambios en los datos al componente padre.
   * 
   * @param {Int} id
   * @param {String} value
   */
  handleClickItem = (id = 0, value = '') => {
    let selectedItems = this.state.selectedItems
    let item = {
      id: id,
      value: value
    }

    this.setClickedItemToSelectedList(selectedItems, item)
    this.handleToggleSelectedStatus()
    this.props.onChangeValue(selectedItems)
  }

  /**
   * Oculta o muestra la lista desplegable del campo.
   * 
   * @param {Boolean} isVisible
   */
  showItemsList = isVisible => {
    if (this.props.blur) {
      this.props.blur()
    }
    this.setState({ isListVisible: isVisible })
  }

  /**
   * Verifica si hay cambios en los datos recibidos del
   * componente padre.
   * 
   * @param {Props} prevProps 
   * @param {State} prevState 
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items.length !== this.props.items.length) {
      const listItems = this.props.items.sort((a, b) => {
        return a.value.localeCompare(b.value)
      }).map(item => {
        let copy = item
        copy.isSelected = false

        return copy
      })
      this.setState({
        items: listItems,
        itemsFiltered: listItems,
        selectedItems: [],
        disabled: this.props.disabled
      })
    }

    if (prevState.items !== this.state.items) {
      this.handleToggleSelectedStatus()
    }

    if (prevProps.selectedItems !== this.props.selectedItems) {
      this.setState({ selectedItems: this.props.selectedItems })
    }
  }

  /**
   * Asigna los valores iniciales a los items de la lista.
   * 
   * @param {Array} items 
   * @returns listIniitalItems
   */
  initialItemValues(items = []) {
    let listInitialItems = items.map(item => {
      let copyItem = { ...item }
      copyItem.isSelected = false

      return copyItem
    })

    return listInitialItems
  }

  /**
   * Limpia los valores seleccionados de la lista y el valor
   * introducido al input.
   */
  handleClearItems = () => {
    let listItems = this.state.selectedItems

    listItems.map(item => this.setClickedItemToSelectedList(listItems, item))

    this.handleToggleSelectedStatus()
    this.setState({
      items: this.initialItemValues(this.state.items),
      selectedItems: [],
      itemsFiltered: [],
      value: '',
    })

    this.props.onChangeValue([])
  }

  render() {

    const {
      selectedItems, label, name, items,
      value, isListVisible, itemsFiltered,
      disabled, focusedItem, itemIndex
    } = this.state

    const displayItems = itemsFiltered.length === 0 ? items : itemsFiltered
    const selected = displayItems.filter(item => item.isSelected === true)
    const notSelected = displayItems.filter(item => item.isSelected === false)

    return (
      <div className="column ContainerMultiSelect">
        <div className="field">
          <label className="label">{label}</label>
          <div className="control">
            <div className="select is-fullwidth">
              <div className="FieldHorizontal field is-horizontal">
                <div
                  className="field-body MultiSelect"
                  tabIndex="-1"
                  onBlur={() => this.showItemsList(false)}
                  onFocus={() => this.showItemsList(true)}
                  onKeyUp={this.selectItemFromKeyboard}
                  onKeyDown={this.handleArrowKeysNavigation}
                >
                  <input
                    onFocus={() => this.showItemsList(true)}
                    className="input MultiInput"
                    autoComplete="off"
                    onChange={(e) => this.handleChangeContent(e)}
                    name={name}
                    onClick={() => this.showItemsList(true)}
                    type="text"
                    placeholder={`${selectedItems.length} seleccionados`}
                    value={value}
                    disabled={disabled}
                  />
                  {/* <span className="IconInputMulti" onClick={() => this.showItemsList(true)}></span> */}
                  {
                    value.length === 0 &&
                      selectedItems.length !== 0 ?
                      <span className="icon IconInputCross" onClick={this.handleClearItems}>
                        <i className="fa fa-times fa-xs"></i>
                      </span> : ''
                  }
                  <div
                    id={`${isListVisible ? 'IdListWrapper' : ''}`}
                    className={`ListWrapper ${isListVisible ? "IsVisible" : ""}`}
                  >
                    <ul className="List">
                      <span className="TagTitleMultiselect">Elementos seleccionados:</span>
                      {
                        selected && selected.length > 0 ?
                          selected.sort((a, b) => {
                            return a.value.localeCompare(b.value)
                          }).map((item, index) => {
                            return (
                              <li
                                key={item.id}
                                className="Item"
                                onClick={() => this.handleClickItem(item.id, item.value)}
                              >
                                <p className="MultiP" title={item.value}>{item.value}</p>
                                {
                                  item.isSelected || index === focusedItem ?
                                    <span className="IconCheck icon is-small has-text-info">
                                      <i className="fa fa-check-circle" />
                                    </span>
                                    : ''
                                }
                              </li>
                            )
                          }) :
                          <span className="SinResultados">0 seleccionados</span>
                      }
                      <span className="Divisor"></span>
                      <span className="TagTitleMultiselect">Elementos para seleccionar:</span>
                      {
                        notSelected && notSelected.length > 0 ?
                          notSelected.sort((a, b) => {
                            return a.value.localeCompare(b.value)
                          }).map((item, index) => {
                            return (
                              <li
                                key={item.id}
                                className={`Item`}
                                onClick={() => this.handleClickItem(item.id, item.value)}
                              >
                                <p className="MultiP" title={item.value}>{item.value}</p>
                              </li>
                            )
                          }) :
                          <span className="SinResultados">Sin resultados</span>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default MultiSelect

MultiSelect.propTypes = {
  value: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired
}
