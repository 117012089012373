import React, { Component, Fragment } from 'react';
import IndicadorTurbo from './indicadorTurbo';
import PropTypes from 'prop-types';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import { Button } from '../Button/Button';
import { MAX_OBJETIVOS_TURBO } from '../../../util/utileria'
import { Modal } from '../../util/modal/ModalLayout'

import { toast } from 'react-toastify';
import decode from 'jwt-decode';

class Turbo extends Component {
    constructor(props) {
        super(props);

        this.Indicador = {
            propuesta: 0,
            objetivo: "",
            indicador: "",
            comentario: "",
            sobre_meta: "",
            revision: "",
            ponderacion: 0,
            ponderacionValid: true,
            compensacion: "",
            compensacionObj: {},
            tipo: "Turbo"
        }

        this.IndicadorTurbo = {
            objetivo: "",
            indicador: "",
            comentario: "",
            sobre_meta: "",
            ponderacion: 0
        }

        this.indicadorValidation = {
            valid: false
        }

        this.state = {
            ...this.getInitialState()
        }
    }

    /**
     * Regresa un objeto con las propiedades del estado inicial.
     * Se llama cuando se requiere asignar una copia en blanco al state
     */
    getInitialState() {
        return {
            indicadores: [{ ...this.Indicador }],
            removedIndicatorsList: [],
            modifiedIndicatorsList: [],
            addedIndicatorsList: [],
            totalPonderacion: 0,
            totalCompensacion: 0,
            maxIndicadores: 3,
            verNotificacion: true,
            agregarDisabled: false,
            verCompensacion: false,
            guardarLoading: false,
            validForm: false,
            indicadoresValidation: [{ ...this.indicadorValidation }]
        }
    }

    /**
     * Obtiene la ponderación total de los indicadores.
     * 
     * @param {State} stateCopy 
     * @returns Number ponderacion
     */
    getPonderacion(stateCopy) {
        let ponderacion = 0;
        if (stateCopy.indicadores.length === 1) {
            ponderacion = 15
        } else if (stateCopy.indicadores.length === 2) {
            ponderacion = 25
        }

        return ponderacion;
    }

    /**
     * Función que maneja el evento Change del campo ponderación en los indicadores
     * @param {*} pond - Valor ingresada
     * @param {*} index - Indice del objeto en el arreglo que actualziará
     */
    handlePondChange(pond, index) {
        pond = Number(pond) || 0; // Si el valor no es numérico, asigna 0
        const state = { ...this.state };

        state.indicadores.map((obj, i) => {
            if (i === index) {
                obj.ponderacion = pond;
                obj.ponderacionValid = true;

            }
            return obj;
        });

        this.setState(state)
    }

    /**
     * Remueve de la lista el indicador y agrega su id
     * a la lista de eliminados.
     * 
     * @param {Number} idIndicator
     * @param {Number} index
     */
    handleRemoveIndicador = (idIndicator = 0, index) => {
        let indicadores = this.state.indicadores
        let removedList = [...this.state.removedIndicatorsList]
        let indicadoresValidation = this.state.indicadoresValidation
        let modifiedIds = this.state.modifiedIndicatorsList
        let listIds = []

        indicadores.splice(index, 1)
        indicadoresValidation.splice(index, 1)
        if (idIndicator > 0) removedList.push(idIndicator)

        if (idIndicator !== undefined) listIds = modifiedIds.filter(indicator => indicator !== idIndicator)

        this.setState({
            indicadores,
            indicadoresValidation,
            removedIndicatorsList: removedList,
            modifiedIndicatorsList: listIds
        });
    }

    /**
     * Maneja los cambios en los inputs de un indicador.
     * 
     * @param {Event} event 
     * @param {Number} index 
     */
    handleCamposChange(event, index) {
        const indicadores = this.state.indicadores;
        const name = event.target.name;
        const value = event.target.value;
        let modifiedIds = this.state.modifiedIndicatorsList
        let listChanged = []
        let ponderacionTotal

        listChanged = indicadores.map((obj, i) => {
            obj.propuesta = this.props.propuesta.id;
            if (i === index) {
                let idIndicator = obj.id
                obj[name] = value;
                if (!modifiedIds.includes(idIndicator) && idIndicator !== undefined) modifiedIds.push(idIndicator)
            }
            return obj;
        });

        ponderacionTotal = indicadores.reduce((acc, obj) => {
            return Number(obj.ponderacion) + acc
        }, 0)

        this.setState({
            indicadores: listChanged,
            modifiedIndicatorsList: modifiedIds,
            totalPonderacion: ponderacionTotal
        });
    }

    /**
     * Agrega una nueva fila para crear un objetivo turbo solo a un usuario.
     */
    handlerClickAgregar() {
        let state = { ...this.state };
        let indicador = { ...this.IndicadorTurbo };
        let indicadorValidation = { ...this.indicadorValidation };

        state.indicadores.push(indicador);
        state.addedIndicatorsList.push(indicador);
        state.indicadoresValidation.push(indicadorValidation);

        this.setState(state);
    }

    /**
     * Cierra el modal.
     */
    handleClose() {
        this.resetComponentState();
        this.props.cancel()
    }

    /**
     * Llama al estado inicial del componente.
     */
    resetComponentState() {
        let initialState = { ...this.getInitialState() }
        this.setState(initialState);
    }

    /**
     * Obtiene los objetos con la informacion de los indicadores,
     * segun la lista de ids que se pasan como parametro.
     * 
     * @param {Array} listIdsModificated
     * @returns Arrays - listIndicatorsModified
     */
    getIndicatorsModified = (listIdsModificated = []) => {
        let indicators = this.state.indicadores
        let listIndicatorsModified = []

        listIndicatorsModified = indicators.filter(indicator => listIdsModificated.includes(indicator.id))

        return listIndicatorsModified
    }

    /**
     * Guarda los cambios, ediciones y nuevos indicadores realizados.
     */
    saveChanges = () => {
        let {
            removedIndicatorsList, //Ids de indicadores eliminados
            addedIndicatorsList, //Deben ser objetos con la informacion a agregar
            modifiedIndicatorsList //Deben ser objetos con la informacion modificada
        } = this.state

        let listModificated = this.getIndicatorsModified(modifiedIndicatorsList)

        if (this.validateAddedIndicators(addedIndicatorsList)) {
            this.props.save(addedIndicatorsList, removedIndicatorsList, listModificated)
        } else {
            toast.error('Los indicadores son incorrectos!')
        }

    }

    /**
     * Valida el formato de un indicador nuevo añadido,
     * retorna si es valido o no.
     * 
     * @param {Array} addedList 
     * @returns Boolean isValid
     */
    validateAddedIndicators(addedList = []) {
        let isValid = true

        addedList.map(added => {
            let keys = Object.keys(added)
            keys.map(key => {
                if (added[key] === '') return isValid = false
                if (added['ponderacion'] === 0) return isValid = false
            })
            if (!isValid) return isValid
        })

        return isValid
    }

    componentDidMount() {
        const listTurboIndicators = this.props.propuesta.indicadores.filter(indicador => indicador.tipo === 'Turbo')

        this.setState({
            indicadores: listTurboIndicators
        })
    }

    /**
     * Retorna la instancia de un Botón.
     * 
     * @returns Component
     */
    AddObjetivoTurbo = (isAdmin = false) => {
        return <Button
            disabled={
                !isAdmin &&
                (this.state.indicadores.length >= MAX_OBJETIVOS_TURBO || !this.props.propuesta.autorizada)
            }
            icon="fa-plus-circle"
            onClick={this.handlerClickAgregar.bind(this)}
            type="is-success"
            title={`Solo se pueden tener ${MAX_OBJETIVOS_TURBO} objetivos turbo`}
            text="Agregar objetivo turbo"
        />
    }

    render() {
        let isFormValid = this.state.indicadoresValidation.length
            ? this.state.indicadoresValidation.find(obj => obj.valid === false) ? false : true
            : false
        let totalPonderacion = 0
        let isAdmin = decode(localStorage.getItem('token')).role.includes('Administrador')
        let ponderaciones = this.state.indicadores.map(indicador => totalPonderacion += Number(indicador.ponderacion)).pop()

        return (
            <Modal
                cancelText="Cancelar"
                confirmText="Guardar y notificar"
                isVisible={this.props.show}
                title="Objetivos Turbo"
                onClose={this.handleClose.bind(this)}
                onConfirm={this.saveChanges}
                onConfirmDisable={
                    this.state.addedIndicatorsList.length === 0 &&
                    this.state.removedIndicatorsList.length === 0 &&
                    this.state.modifiedIndicatorsList.length === 0
                }
                Actions={() => this.AddObjetivoTurbo(isAdmin)}
                isLarge={true}
                zIndex={41}
            >
                {
                    this.state.indicadores.length !== 0 ?
                        <Fragment>
                            <strong style={{ color: "red" }}>Los objetivos turbo son prioridad, para obtener el {ponderaciones || 0}% adicional del resultado del BSC, se debe cumplir con el objetivo.</strong>
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                                        <th style={BSCColumnStyle.indicador}>Indicador</th>
                                        <th style={BSCColumnStyle.comentario}>Comentario</th>
                                        <th style={BSCColumnStyle.sm} width="10%"><abbr title="Sobre meta">SM</abbr></th>
                                        <th style={BSCColumnStyle.ponderacion} width="10%"><abbr title="Ponderación">% Pond.</abbr></th>
                                        {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                                        <th style={BSCColumnStyle.acciones}>Acciones</th>
                                    </tr>
                                </thead>
                                {
                                    this.state.indicadores.map((obj, index) => {
                                        return <IndicadorTurbo
                                            key={index}
                                            data={obj}
                                            showCompensacion={this.props.showCompensacion}
                                            camposChange={(e) => this.handleCamposChange(e, index)}
                                            pondChange={(pond) => this.handlePondChange(pond, index)}
                                            remove={() => this.handleRemoveIndicador(obj.id || -1, index)}
                                            onValidation={(isValid) => {
                                                let state = { ...this.state };

                                                state.indicadoresValidation.map((obj, ivindex) => {
                                                    if (ivindex === index) {
                                                        obj.valid = isValid;
                                                    }

                                                    return obj;
                                                });

                                                this.setState(state);
                                            }}
                                        />
                                    })
                                }
                            </table>
                        </Fragment> :
                        <span>El usuario no cuenta con objetivos turbo!</span>
                }
            </Modal>
        )
    }
}

Turbo.defaultProps = {
    show: false,
    showCompensacion: false
}

Turbo.propTypes = {
    show: PropTypes.bool,
    showCompensacion: PropTypes.bool,
    cancel: PropTypes.func,
    save: PropTypes.func
}

export default Turbo;