import React, { Component } from 'react'

class UserList extends Component {
    render() {
        return(
            <div>
                <table className="table is-hoverable">
                    <thead>
                        <tr>
                            <th>Número de empleado</th>
                            <th>Nombre</th>
                            <th>Jefe Directo</th>
                            <th>Puesto</th>
                            <th>Área</th>
                            <th>Activo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.usuarios.map((usuario, index) => {
                                return(
                                    <tr key={index} className={usuario.effect ? "new-animation" : ""}>
                                        <td>{usuario.usuario.id_empleado}</td>
                                        <td>{usuario.usuario.nombre}</td>
                                        <td>{usuario.jefe_directo_nombre ? usuario.jefe_directo_nombre : ""}</td>
                                        <td>{usuario.puesto}</td>
                                        <td>{usuario.area_nombre}</td>
                                        <td>
                                            <input   
                                                type="checkbox"
                                                name="status"
                                                className="switch"
                                                checked={usuario.usuario.activo}
                                                onChange={() => {}}
                                            />
                                            <label htmlFor="status" onClick={(e) => {
                                                e.stopPropagation(); //Se evita la propagación del click al elemento <tr>
                                                this.props.changeStatus(usuario)}
                                            }/>                                        
                                        </td>
                                        <td>
                                            <button className="button is-warning" 
                                                onClick={() => this.props.edit(usuario)}
                                            >
                                                Editar
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default UserList;