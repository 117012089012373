import { apiUrlBsc, apiUrlIndicadores }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

var requestKpi =axios.create({
        baseURL: apiUrlIndicadores,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })

export default class UbicacionBaseAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('ubicaciones/'));
    }

    static getUbicacionesAsignadas(filters) {
        return middleware.hasAuth(() => requestKpi.get('usuarios_fsap_ubicaciones_bases/', {"params": filters}));
    }

    static postUbicacionesAsignadas(data) {
        return middleware.hasAuth(() => requestKpi.post('usuarios_fsap_ubicaciones_bases/create/', data));
    }
}