import { caffenioAuthURL, caffenioAuthId}  from "../constants";
import axios from 'axios';
import * as querystring from 'querystring';

// Objecto de axios con el único fin de realizar la autentificación en CaffenioAuth
var authRequest = axios.create({
    baseURL: caffenioAuthURL,
    headers: {'Content-Type':'application/x-www-form-urlencoded'}
})


// Llamadas a la API para la autentificación
export default class Auth {
    static login = (username, password) => {
        let data = {
            username: username,
            password: password,
            grant_type: "password",
            client_id: caffenioAuthId
        };
        // let url = 
        // console.log();
        return authRequest.post('/login',querystring.stringify(data));
    }
}

/*
"user" : "aldo.romo",
"password" : "123654",
"client_id" : "CAD47816-B8D3-4E3A-950D-5D36B2A52260",
"grant_type" : "password"
*/