import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import { toast } from 'react-toastify';
import EvaluacionesAPI from '../../../services/indicadores_variables/Evaluaciones';
import { EvaluacionesTable } from '../Evaluaciones/EvaluacionesTable';
import Loading from '../../util/loading/Loading'
import NoItems from '../../util/noitems/NoItems';
import { debounce } from 'lodash';
import { HistorialEvaluacionesDetails } from '../HistorialEvaluaciones/HistorialEvaluacionesDetails';
import ReactPaginate from 'react-paginate';
import { Modal } from '../../util/modal/ModalLayout'
import FormEvaluacion from './FormEvaluacion'


class Evaluaciones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: undefined,
            currenData : undefined,            
            selectedIndicator:undefined,      
            currentUserData:undefined,
            currentIndicadorIndex: 0,
            temporalIndicator:undefined,
            pagination: undefined,
            isModalEvaluationVisible: false,
            isFormEvaluationValid:false,
            setCheckToValidateNextIndicator: false,
            currentUserKey: '',
            currentUserIndex: 0,
            filters: {
                usuario: '',
                page: 1,
            },
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.showLoading(true)
        EvaluacionesAPI.consultar(this.state.filters).then(response => {
            this.setState({
                data: response.data.results,
                currenData : response.data.results,
                pagination: response.data.pagination,
            });
        }).catch(err => {
            toast.error(err.message);
        }).finally(() => {
            this.showLoading(false)
        });
    }

    handleChangeFilter(key, value) {
        const filters = {...this.state.filters};
        filters[key] = value;
        this.setState({filters: filters}, () => {
            this.getData();
        });
    }

    handleDebouncedOnChangeFilter = debounce(this.handleChangeFilter, 1500, {maxWait: 2000});

    showModal = (indicador,UserData, indicadorIndex, userKey, userIndex) => {
        
        this.setState({
            selectedIndicator : indicador,
            currentUserIndex : userIndex,
            currentUserKey : userKey,
            currentIndicadorIndex: indicadorIndex,
            currentUserData: UserData,
            isModalEvaluationVisible:true
        });
    }    

    closeModalEvaluation = () => {
        this.setState({
            isModalEvaluationVisible: false
        });
    }

    handleChangeIndicators = () => {

    }

    saveAvance = async (dataUser, userKey) => {
        this.showLoading(true)
        let state = {...this.state}; // Clonar el estado actual
        let data = state.data; // Obtener la propiedad 'data' del estado
        let usuario_indicadores = dataUser; // Obtener los indicadores de usuario
    
        try {
            for (const element of usuario_indicadores) {
                if (element.nivel_cumplimiento_id != null) {
                    let body = {
                        "usuario": element.usuario_id,
                        "indicador": element.id,
                        "nivel_cumplimiento": element.nivel_cumplimiento_id,
                        "comentario": element.comentario_evaluacion,
                        "evaluado": false
                    };
    
                    try {
                        const response = await EvaluacionesAPI.save_evaluacion(body);
                        let result = response.data;
                        element.id_evaluacion = result.id;
                        element.nivel_cumplimiento_id = result.nivel_cumplimiento;
                        element.comentario_evaluacion = result.comentario;
                        element.evaluado = result.evaluado;
                    } catch (err) {
                        toast.error(err.message);
                    }
                }
            }
    
            data[userKey] = usuario_indicadores; // Actualizar los datos en la ubicación especificada
    
            this.setState({
                data: data // Actualizar el estado con los nuevos datos
            },() => {
                this.showLoading(false)
            
                toast.success("Avance de evaluación guardado correctamente");
            })

            

        } catch (error) {
            // Manejar cualquier error en el nivel superior si es necesario
            console.error(error);
        }
    };

    saveEvaluationComplet = async (dataUser, userKey) => {
        
        this.showLoading(true)
        let state = {...this.state}; // Clonar el estado actual
        let data = state.data; // Obtener la propiedad 'data' del estado
        let usuario_indicadores = dataUser; // Obtener los indicadores de usuario
    
        try {
            for (const element of usuario_indicadores) {
                if (element.nivel_cumplimiento_id != null) {
                    let body = {
                        "usuario": element.usuario_id,
                        "indicador": element.id,
                        "nivel_cumplimiento": element.nivel_cumplimiento_id,
                        "comentario": element.comentario_evaluacion,
                        "evaluado": true
                    };
    
                    try {
                        const response = await EvaluacionesAPI.save_evaluacion(body);
                        let result = response.data;
                        element.id_evaluacion = result.id;
                        element.nivel_cumplimiento_id = result.nivel_cumplimiento;
                        element.comentario_evaluacion = result.comentario;
                        element.evaluado = result.evaluado;
                    } catch (err) {
                        toast.error(err.message);
                        this.showLoading(false)
                    }
                }
            }
    
            data[userKey] = usuario_indicadores; // Actualizar los datos en la ubicación especificada

            this.setState({
                data: data // Actualizar el estado con los nuevos datos
            },() => {
                this.showLoading(false)            
                toast.success("Evaluación completada correctamente");
            })
            
            

        } catch (error) {
            // Manejar cualquier error en el nivel superior si es necesario
            console.error(error);
        }
    };

    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    render() {
        return (
            <Fragment>
                <Card title="Evaluación de indicadores">
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Empleado:</label>
                                <div className="control">
                                    <input className={"input"} type="text" pattern="\d*" placeholder="Nombre de empleado"
                                        name="empleado_name"
                                        maxLength="250"
                                        onChange={(e) => this.handleDebouncedOnChangeFilter("usuario", e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <br />
                {
                    this.state.pagination 
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleChangeFilter('page', page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }
                <Card>
                    { this.state.data && this.state.data.length === 0
                        ? <div style={{ textAlign: "center" }}><span className="tag is-warning is-large mt-10"><NoItems itemName={"evaluaciones"}/></span></div>
                        : <EvaluacionesTable 
                            data={this.state.data} 
                            loading={this.state.isLoading} 
                            showModal={this.showModal}
                            confirmAvanceEvaluacion = {(dataUser, userKey, userIndex) => this.saveAvance(dataUser, userKey, userIndex)}
                            confirmTerminarEvaluacion = {(dataUser, userKey, userIndex) => this.saveEvaluationComplet(dataUser, userKey, userIndex)}
                            />
                    }
                </Card>

                <br />

                {
                    this.state.pagination 
                        ? <ReactPaginate 
                            className="react-paginate"
                            breakLabel="..."
                            onPageChange={(page) => this.handleChangeFilter('page', page.selected + 1)}
                            pageCount={this.state.pagination.pages}
                            previousLabel="Anterior"
                            nextLabel="Siguiente"
                        />
                        : ''
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />

                {
                    this.state.isModalEvaluationVisible &&
                    <FormEvaluacion
                        currentIndicator={this.state.selectedIndicator}
                        currentIndicadorIndex = {this.state.currentIndicadorIndex}
                        currentUserData = {this.state.currentUserData}
                        closeModalEvaluation = {this.closeModalEvaluation}
                        currentUserKey = {this.state.currentUserKey}
                        currentUserIndex = {this.state.currentUserIndex}
                    />       
                }

            </Fragment>
        )
    }
}

export default Evaluaciones;