import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

let request = () => {
    return axios.create({
        baseURL: apiUrlBsc,
        // headers: {'Authorization': localStorage.getItem('token'),'Content-Type':'application/json'}
        headers: {
            'Authorization':`Bearer ${localStorage.getItem('token')}`,
            'Content-Type':'application/json',
        }
    })
}

export default class GroupsAPI {
    static getAll() {
        return middleware.hasAuth(() => request().get('groups/'));
    }
}