import React from 'react';
import Comentario from "../../util/propuestas/comentario";

export const ModalComentarios = (props) => {
    /**
     * props:
     * - toggle()              : [OK] funcion que abre y cerra el modal
     * - show                  : [OK] [true, false]
     * - {indicador}           : [OK] objeto indicador
     * - save()                : [OK] función que manda a guardar el comentario
     * - getPreviousComments() : [OK] hace una llamada para obtener los comentarios anteriores para ese indicador
     */

    
    let allLoaded = props.indicador.all_comentarios && props.indicador.all_comentarios.length >= 0;

    return (
        <div className={"modal is-active"} >
            <div className="modal-background" />
            <div className="modal-card">

                <header className="modal-card-head">
                    <p className="modal-card-title">Motivo de rechazo de objetivo</p>
                    <button onClick={props.toggle} className="delete" aria-label="close"></button>
                </header>

                <section className="modal-card-body">
                    <div className="mb-10">
                        <article className="message is-warning">
                            <div className="message-body">
                                Indica en qué columna se realizará el cambio y se muy especifico en lo solicitado.
                            </div>
                        </article>
                    </div>
                    <div>
                        <label className="label">Comentario:</label>
                        <textarea className={props.comentario.error ? "textarea is-danger" : "textarea"}
                            autoFocus={true}
                            placeholder="Comentario"
                            name="comentario"
                            value={props.comentario.comentario}
                            onChange={props.change}
                        />
                        { props.comentario.error && <p className="help is-danger">El comentario no puede estar vacío</p>}
                    </div>
                    <div className="mv-c-margin-top">
                        <div className="buttons are-small">
                            <button className="button is-success" disabled={props.comentario.error} onClick={props.save}>Guardar</button>
                            {/* <button className="button" onClick={props.toggle}>Cerrar</button> */}
                        </div>
                    </div>
                    <hr />
                    {
                        props.indicador.comentarios.length > 0 ?
                        <div className="mv-c-margin-top">
                            <h5 className="title is-5">Último comentario: </h5>
                            <div>

                                {
                                    props.indicador.comentarios.map((comentario, index) => {
                                        return (
                                            <Comentario comentario={comentario} key={index} />
                                        )
                                    })
                                }

                            </div>
                            {
                                (props.indicador.all_comentarios && props.indicador.all_comentarios.length > 0) &&
                                <div>
                                    <hr />
                                    <h5 className="title is-5">Todos los comentarios: </h5>

                                    {
                                        props.indicador.all_comentarios.map((comentario, index) => {
                                            return (
                                                <div key={index} className="mt-10">
                                                    <Comentario comentario={comentario} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                
                            }
                            <div style={{marginTop: "10px"}}>
                                {
                                    allLoaded &&
                                    <article className="message is-info">
                                        <div className="message-body">
                                            Todos los comentarios fueron cargados
                                        </div>
                                    </article>
                                }
                                <button className="button is-fullwidth" 
                                    disabled={allLoaded} 
                                    onClick={() => props.getPrevious(props.indicador.id)}
                                >
                                    <span className="icon">
                                        <i className="fa fa-refresh"></i>
                                    </span>
                                    <span>Cargar comentarios anteriores</span>
                                </button>
                            </div>
                        </div>
                        : (
                            <article className="message is-info">
                                <div className="message-body">
                                    No hay comentarios
                                </div>
                            </article>
                        )
                    }
                </section>
            </div>
        </div>
    )
}