import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ArchivosEstaticosApi {
    static getAll() {
        return middleware.hasAuth(() => request.get('file/statics'));
    }

    static newStatic(file, description = null, name = false) {
        if (!file || !file.name || !file.size) {
            // Manejar el caso en que el archivo no sea válido
            return Promise.reject(new Error('Archivo no válido'));
        }

        let form_data = new FormData();
        form_data.append('file', file, file.name);  // Añade el nombre del archivo aquí
        form_data.append('description', description);
        form_data.append('filename', name);
        
        return middleware.hasAuth(() => request.post('file/statics/upload/', form_data));
    }

    static updateStatic(id, file) {
        let form_data = new FormData()
        form_data.append('file', file)
        return middleware.hasAuth(() => request.post(`file/statics/change/${id}`, form_data));
    }

    static removeStatic(id = 0) {
        return middleware.hasAuth(() => request.delete(`file/statics/change/${id}`));
    }

    static getUrlStatic(id = 0){
        return middleware.hasAuth(() => request.get(`url_static/${id}`));
    }

    static deleteUrlStatic(id = 0){
        return middleware.hasAuth(() => request.delete(`url_static/${id}`));
    }

    static getAllUrlStatic(){
        return middleware.hasAuth(() => request.get(`url_static`));
    }

    static newUrlStatic(name = null, url = null, description = null){
        let form_data = new FormData()
        form_data.append('url', url)
        form_data.append('description', description)
        form_data.append('name', name)
        return middleware.hasAuth(() => request.post('url_static/create/', form_data));
    }

    static updateUrlStatic(id = 0, name = null, url = null, description = null){
        let form_data = new FormData()
        form_data.append('url', url)
        form_data.append('description', description)
        form_data.append('name', name)
        return middleware.hasAuth(() => request.put(`url_static/${id}`, form_data));
    }
}