import React, { useEffect } from 'react'

// Components
import { Button } from '../Button/Button'

// Styles
import { BSCColumnStyle } from '../../../util/bsc-column-style'
import './IndicadorTabla.css'

/**
 * Componente para rendericar una tabla con la información
 * de los indicadores que se pasen en la propiedad `indicadores`.
 * 
 * @param {Array} indicadores
 * @param {Boolean} isNormal
 * @returns Componente IndicadorTablaModal
 */
export const IndicadorTablaModal = ({
  indicadores, isNormal = true, usuarios,
  evaluacion, showModal, autoEvaluacion,
  estatusEvaluacion, openConvocatoria = true,
  userRol = ''
}) => {

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th style={BSCColumnStyle.objetivo}>Objetivo</th>
          <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
          {
            isNormal &&
            <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
          }
          {
            isNormal &&
            <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
          }
          {
            isNormal &&
            <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
          }
          <th style={BSCColumnStyle.ponderacion}>
            <abbr title="Ponderación" width="7%">% Pond.</abbr>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          indicadores.map((indicador, index )=> {
            return <tr key={indicador.id}>
              <td className="ProposalColAutoEvaluation">{indicador.objetivo}</td>
              <td className="ProposalColAutoEvaluation">{indicador.sobre_meta}</td>
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.meta}</td>
              }
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.bajo_meta}</td>
              }
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.inaceptable}</td>
              }
              <td>{indicador.ponderacion}</td>
            </tr>
          })
        }
      </tbody>
    </table>
  )
}

