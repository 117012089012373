import React, { Fragment } from 'react'
import { Icon } from '../Icon/Icon'

/**
 * Componente Table.
 * 
 * @param {Object} props
 * 
 * @returns Table
 */
export const Table = ({
  columns,
  dataSet,
  noDataText = "Sin elementos",
  loading
}) => {
  return (
    <div className="table-container">
      <table className="table is-striped is-hoverable is-fullwidth">
        <thead>
          {generateHeaders(columns)}
        </thead>
        <tbody>
          {
            dataSet.length === 0 || loading ?
              <tr>
                <td
                  colSpan={columns.length || 4}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    fontSize: "1.3em",
                    color: "#4c4c4c"
                  }}
                >
                  {
                    loading ?
                      <Icon icon="fa-spinner fa-pulse" size="fa-lg" />
                      : noDataText
                  }
                </td>
              </tr> :
              generateRows(dataSet, columns)
          }
        </tbody>
      </table>
    </div>
  )
}

/**
 * Genera los encabezados de una tabla.
 * 
 * @param {Array} columns
 * 
 * @returns TableHeaders
 */
const generateHeaders = (columns = []) => {
  return <tr>
    {
      columns.map(column => {
        return <td
          key={column.id}
          className="is-uppercase has-text-weight-bold is-size-7"
          style={{
            width: column.size || 'auto',
            backgroundColor: column.color || 'white',
            color: column.fontColor || 'black'
          }}
        >
          {column.title}
        </td>
      })
    }
  </tr>
}

/**
 * Genera las filas de una tabla.
 * 
 * @param {Array} data
 * @param {Array} columns
 * 
 * @returns Row
 */
const generateRows = (data = [], columns = []) => {
  return data.map((row, index) => {
    return <tr key={row.id || index}>
      {
        columns.map((item, index) => {
          if (item.render) {
            let Item = item.render
            return <td key={index}>
              <Item item={row} />
            </td>
          } else if (item.renderClass) {
            let RenderClass = item.renderClass
            return <RenderClass item={row} key={index} />
          } else {
            return <td key={index}>
              <span className="has-text-grey">
                {row[item.value]}
              </span>
            </td>
          }
        })
      }
    </tr>
  })
}
