import * as React from 'react'

/**
 * Componente de utilería encargado de desplegar un modal con la única funcionalidad de
 * confirmar si desea o no realizar alguna acción especificada por el título del mismo.
 */

class AcceptModal extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className={this.props.isActive ? "modal is-active " : "modal"}>
                <div className="modal-background" onClick={() => this.props.cancel(false)}/>
                <div className="modal-card">
                    <div className="modal-card-head">
                        {this.props.modalTitle}
                    </div>
                    <div className="modal-card-foot">
                        <button
                            className="is-danger button"
                            onClick={() => this.props.done(true)}
                        >
                            Aceptar
                        </button>
                        <button
                            className="button"
                            onClick={() => this.props.cancel(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AcceptModal