import React, { Component, Fragment } from 'react';
import { Icon } from '../util/Icon/Icon'
import HasAccess from '../util/hasAccess/HasAccess';
import { Modal } from '../util/modal/ModalLayout';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de solicitudes de compensacion realizadas. */
export class SolicitudesRealizadas extends Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            isModalAutorizar: false,
            isModalRechazar : false,
            solicitudToComplete: {}
        }
    }

    render() {

        return (
            <div>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <HasAccess roles={["Usuario Nomina"]}>
                            {
                                this.props.solicitudes.filter(item => item.estatus == 'Pendiente').length > 1 &&
                                <th></th>
                            }
                            </HasAccess>
                            <th>Número de empleado</th>
                            <th>Nombre</th>
                            <th>Puesto</th>
                            <th>Fecha de solicitud</th>
                            <th>Monto solicitado</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.solicitudes.length === 0 ?
                            <tr>
                                <td
                                colSpan={7}
                                style={{
                                    textAlign: "center",
                                    height: "40px",
                                    fontSize: "1.3em",
                                    color: "#4c4c4c"
                                }}
                                >
                                Sin solicitudes pendientes
                                </td>
                            </tr> :
                            this.props.solicitudes.map((solicitud, index) => {
                                return(
                                    <tr key={index}>
                                        <HasAccess roles={["Usuario Nomina"]}>
                                        {
                                            this.props.solicitudes.filter(item => item.estatus == 'Pendiente').length > 1 &&
                                            <th>
                                            {
                                                (solicitud.estatus == "Pendiente") &&                                                
                                                <input
                                                    type='checkbox'
                                                    checked= {solicitud.selected}
                                                    name='seleccionada'
                                                    onChange={() => {
                                                        this.props.seleccionar(solicitud.id,!solicitud.selected)
                                                    }}
                                                />                                                
                                            }
                                            </th> 
                                        }
                                        
                                        </HasAccess>
                                        <td>{solicitud.usuario.usuario.id_empleado}</td>
                                        <td>{solicitud.usuario.usuario.nombre}</td>
                                        <td>{solicitud.usuario.puesto}</td>
                                        <td>{solicitud.fecha_solicitud}</td>
                                        <td>{formatter.format(solicitud.monto_solicitado_total)}</td>
                                        <td>{solicitud.estatus}</td>
                                        <td>{
                                            <Fragment>
                                                <Icon
                                                title="Ver detalle"
                                                icon="fa-eye"
                                                color="has-text-info"
                                                click={() => {
                                                    this.props.detalle(solicitud)
                                                }}
                                                />
                                                <HasAccess roles={["Usuario Nomina"]}>
                                                    {
                                                        solicitud.estatus == "Pendiente" &&

                                                        <Icon
                                                            title="Confirmar solicitud"
                                                            icon={"fa-check-circle"}
                                                            color="has-text-success"
                                                            click={() => {
                                                                if(solicitud.estatus == "Pendiente"){
                                                                    this.setState({
                                                                        solicitudToComplete : solicitud,
                                                                        isModalAutorizar: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        solicitud.estatus == "Pendiente" &&

                                                        <Icon
                                                            title="Rechazar solicitud"
                                                            icon={"fa-times-circle"}
                                                            color="has-text-danger"
                                                            click={() => {
                                                                if(solicitud.estatus == "Pendiente"){
                                                                    this.setState({
                                                                        solicitudToComplete : solicitud,
                                                                        isModalRechazar: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    
                                                </HasAccess>
                                            
                                            </Fragment>
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {
                    this.state.isModalAutorizar &&
                    <Modal
                        title="Confirmar solicitud"
                        cancelText="Cancelar"
                        onClose={() => {
                            this.setState({
                                isModalAutorizar: false
                            })
                        }}
                        confirmText="Aceptar"
                        onConfirm={() =>{
                            let solicitud = this.state.solicitudToComplete

                            if(solicitud.estatus == "Pendiente"){
                                this.props.terminar(solicitud)
                            }

                            this.setState({
                                isModalAutorizar: false
                            })
                        }}
                        isVisible={this.state.isModalAutorizar}
                    >
                        <p>¿Está seguro que desea confirmar la solicitud?</p>                        
                        
                    </Modal>
                }
                {
                    this.state.isModalRechazar &&
                    <Modal
                        title="Rechazar solicitud"
                        cancelText="Cancelar"
                        onClose={() => {
                            this.setState({
                                isModalRechazar: false
                            })
                        }}
                        confirmText="Aceptar"
                        onConfirm={() =>{
                            let solicitud = this.state.solicitudToComplete

                            if(solicitud.estatus == "Pendiente"){
                                this.props.rechazar(solicitud)
                            }

                            this.setState({
                                isModalRechazar: false
                            })
                        }}
                        isVisible={this.state.isModalRechazar}
                    >
                        <p>¿Está seguro que desea rechazar la solicitud?</p>                        
                        
                    </Modal>
                }
            </div>
        )
    }
}

export default SolicitudesRealizadas;