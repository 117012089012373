import React from 'react'

// Styles
import './Usuario.css'

/** Compenente para ver las reglas que tiene que seguir el formato de layout */
export function ExcelFormatRules() {
  return (
    <div>
      <p>El documento debe cumplir con las siguientes reglas:</p>
      <p className="DescriptionRule">* No se permiten campos vacíos</p>
      <p className="DescriptionRule">* Columna <strong>NOMBRE</strong> solo permite letras</p>
      <p className="DescriptionRule">* Columna <strong>NUMERO EMPLEADO</strong> solo permite números</p>
      <p className="DescriptionRule">* Columna <strong>EMAIL</strong> debe de contar con el formato</p>
      <p className="DescriptionExcelEmail">
        nombre.apellido@caffenio.com
      </p>
      <p className="DescriptionRule">* Columnas necesarias</p>
      <p className="DescriptionExcelColumns">
        NUMERO EMPLEADO | NOMBRE | PUESTO | GERENCIA | AREA | REGION | SEGMENTO | RAZON SOCIAL | UBICACION BASE | MONTO | POLITICA | GRUPO | EMAIL | JEFE DIRECTO | ESTATUS

      </p>
    </div>
  )
}