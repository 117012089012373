import React, { Component, Fragment } from 'react'

// Utilities
import { FormIndicadorTurbo } from '../../propuestas/elaborar/IndicadorTurboForm'
import { Modal } from '../../util/modal/ModalLayout'
import { Filtros } from './FiltrosObjetivosTurbo'
import { MAX_OBJETIVOS_TURBO } from '../../../util/utileria'

// Services
import AreasAPI from '../../../services/Area'
import PropuestaAPI from '../../../services/Propuestas'

/**
 * @fileoverview ObjetivoTurbo, despliega formulario con filtros para crear objetivo turbo.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
class ObjetivoTurbo extends Component {

    state = {
        areas: [],
        usuarios: [],
        usuariosFiltrados: [],
        selectedAreas: [],
        selectedUsers: [],
        isAreasLoading: true,
        isUsersLoading: true,
        modalVisible: false,
        indicador: {},
        valid: {
            objetivo: false,
            indicador: false,
            comentario: false,
            sobre_meta: false,
            ponderacion: false
        },
        error: {
            objetivo: false,
            indicador: false,
            comentario: false,
            sobre_meta: false,
            ponderacion: false
        },
    }

    /**
     * Realiza llamado a las APIs Areas y Propuestas para llenar multiselects
     * de Area y Usuario.
     */
    componentDidMount() {
        Promise.all([
            AreasAPI.getAll().then(res => {
                this.setState({
                    areas: res.data,
                    isAreasLoading: false
                })
            }).catch(err => console.log(err)),
            PropuestaAPI.getWithFilters(this.props.filter).then(res => {
                let listUsers = res.data.filter(item => item.estatus === "Validado").map(item => {
                    let numTurbos = item.indicadores.filter(indicador => indicador.tipo === "Turbo")
                    let copy = { ...item.usuario }
                    copy.id = item.id
                    copy.numTurbos = numTurbos.length
                    return copy
                })

                this.setState({
                    usuarios: listUsers.filter(usuario => usuario.numTurbos < MAX_OBJETIVOS_TURBO),
                    usuariosFiltrados: listUsers.filter(usuario => usuario.numTurbos < MAX_OBJETIVOS_TURBO),
                    isUsersLoading: false
                })
            }).catch(err => console.log(err))
        ])
    }

    /**
     * Elimina el objetivo turbo a crear.
     */
    handleRemoveIndicador = () => {
        this.setState({ indicador: {} })
    }

    /**
     * Maneja los cambios en el campo "Ponderación",
     * asigna los cambios al estado.
     * 
     * @param {Number} pond 
     */
    handlePondChange(pond) {
        pond = Number(pond) || 0;
        const { indicador } = this.state;

        indicador.ponderacion = pond;
        indicador.ponderacionValid = true;

        if (indicador.ponderacion > 100) indicador.ponderacionValid = false;

        this.setState({ indicador })
    }

    /**
     * Maneja los cambios al seleccionar un area de la lista desplegable.
     * 
     * @param {Array} areasList
     */
    handleChangeArea = (areasList = []) => {
        let listUsuarios = this.state.usuarios
        let listAreas = areasList
        let usuariosFiltrados = []

        if (listAreas.length > 0) {
            for (let i = 0; i < listAreas.length; i++) {
                for (let j = 0; j < listUsuarios.length; j++) {
                    if (listAreas[i].id === listUsuarios[j].area) {
                        usuariosFiltrados.push(listUsuarios[j])
                    }
                }
            }
        } else {
            usuariosFiltrados = []
        }

        this.setState({
            usuariosFiltrados,
            selectedAreas: listAreas
        })
    }

    /**
     * Maneja los cambios al seleccionar un usuario de la lista deplegable.
     * 
     * @param {Array} usersList
     */
    handleChangeUser = (usersList = []) => {
        this.setState({ selectedUsers: usersList })

        this.props.onChangeInfo({
            indicador: this.state.indicador,
            users: this.state.selectedUsers
        })
    }

    /**
     * Cierra la ventana modal.
     */
    handleCloseModal = () => {
        this.setState({ modalVisible: false })
    }

    /**
     * Cierra la ventana modal-
     */
    handleConfirmModal = () => {
        this.setState({ modalVisible: false })
    }

    /**
     * Maneja los cambios en el objetivo turbo, cambios 
     * de la informacion de los campos y lista de usuarios
     * del objetivo.
     * 
     * @param {Event} e
     */
    onChangeIndicador = (e) => {
        // this.handleInputBlur(e)
        let state = this.state

        let name = e.target.name
        let value = e.target.value
        let valid = { ...this.state.valid }
        let error = { ...this.state.error }

        valid[name] = true
        error[name] = false

        switch (name) {
            case "objetivo":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "indicador":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "comentario":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "sobre_meta":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "ponderacion":
                let ponderacion = value;
                let patron = /^[0-9]+$/
                // debugger
                if (
                    !ponderacion.match(patron) ||
                    ponderacion.length > 3 ||
                    parseInt(ponderacion, 10) <= 0 ||
                    parseInt(ponderacion, 10) > 100
                ) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            default:
                break;
        }

        state.indicador[name] = value
        state.valid = valid
        state.error = error

        this.setState(state)

        this.props.onChangeInfo({
            indicador: this.state.indicador,
            users: this.state.selectedUsers
        })
    }

    /**
     * Verifica los datos introducidos a los campos del formulario
     * de un nuevo objetivo turbo.
     * 
     * @param {Event} e
     */
    handleInputBlur = (e) => {
        let name = e.target.name
        let value = e.target.value
        let valid = { ...this.state.valid }
        let error = { ...this.state.error }

        valid[name] = true
        error[name] = false

        switch (name) {
            case "objetivo":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "indicador":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "comentario":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "sobre_meta":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "ponderacion":
                let ponderacion = value;
                let patron = /^[0-9]+$/

                if (
                    !ponderacion.match(patron) ||
                    ponderacion.length > 3 ||
                    parseInt(ponderacion, 10) <= 0 ||
                    parseInt(ponderacion, 10) > 100
                ) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            default:
                break;
        }

        this.setState({ valid: valid, error: error })
    }

    render() {

        let {
            areas, usuarios, error,
            usuariosFiltrados, selectedAreas
        } = this.state

        return (
            <Fragment>
                <Filtros
                    titleFiltros="Filtrar usuarios:"
                    areas={areas}
                    usuarios={selectedAreas.length === 0 ? usuarios : usuariosFiltrados}
                    onChangeArea={areasList => this.handleChangeArea(areasList)}
                    onChangeUser={usersList => this.handleChangeUser(usersList)}
                />

                <FormIndicadorTurbo
                    indicador={this.props.indicador}
                    change={this.onChangeIndicador}
                    inputBlur={this.handleInputBlur}
                    errors={error}
                />
            </Fragment >
        )
    }
}

export default ObjetivoTurbo