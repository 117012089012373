import React from 'react';
import './Header.css';
import decode from 'jwt-decode';
import { Button } from '../util/Button/Button'
// import logo from '../../assets/images/CAFFENIO.svg';
/**
 * Componente del Header de la aplicación donde podrían haber acciones o información
 */
export default class Header extends React.Component {

    handleCloseMenu = (isVisible) => {
        this.props.showMenu(isVisible)
    }

    render() {
        let token = localStorage.getItem('token');
        
        let perfil_selected = localStorage.getItem('perfil_selected');
        let decodedToken = decode(token)
        return (
            <nav className="navbar is-alpha is-fixed-top is-shadow is-primary" id="top">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <span
                            className="icon"
                            onClick={() => this.handleCloseMenu(!this.props.isMenuVisible)}
                            style={{ cursor: 'pointer', margin: '0 10px' }}
                            title="Mostrar/Ocultar menú"
                        >
                            <i className="fa fa-bars fa-lg"></i>
                        </span>

                        <h4 className="title is-4 has-text-weight-bold">
                            <span style={{ color: "white" }}>
                                {perfil_selected == 'bsc' &&
                                    "Balanced Scorecard"
                                }
                                {perfil_selected == 'variable' &&
                                    "Indicadores Variables"
                                }
                                
                            </span>
                        </h4>

                    </div>
                    {/* <Link className='navbar-item' to={'/'}>
                        <img src=""/>
                    </Link> */}
                    <div id="navbarBurger" className="navbar-burger burger" data-target="navMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                </div>
                <div className="navbar-end">
                    <div className="is-pulled-right" style={{ margin: "14px" }}>
                        <span className="icon">
                            <i className="fa fa-user"></i>
                        </span>
                        {decodedToken.fullname}
                    </div>
                </div>
            </nav>
        )
    }
}