import React from 'react';

import { toast } from 'react-toastify';
// import fechas_estrategias from '../../assets/images/gestion_estrategia_bsc20201.08da2c5a.png';
// import {ProtectedImage} from '../util/FileWrapper/ProtectedImage'
import {apiUrlBsc} from '../../constants'
import FileLoader from '../util/FileWrapper/FileLoader'
import ArchivosEstaticosApi from '../../services/ArchivosEstaticos'
import axios from 'axios'

const FECHAS_ESTRATEGIAS = `${apiUrlBsc}file/statics/5`

/**
 * Componente de la pantalla principal del sistema.
 */
export default class Index extends React.Component {

    constructor(props) {
        super(props);
    
    
        this.state = {

          banner: null,   
          bannerId: 0,       
          currentBanner: null,
          fileBanner: null,
          politicas: null,      
          politicaId: 0,    
          currentpoliticas: null,
          filePoliticas: null
        }
    }

    componentDidMount() {
      
        ArchivosEstaticosApi.getAll()
        .then( response => {
            let archivos = response.data

            archivos.map(archivo =>{
                if(archivo.name ==="Banner"){
                    this.setState({
                        banner: `${apiUrlBsc}file/statics/${archivo.id}`,
                        bannerId: archivo.id,                 
                        currentBanner: `${apiUrlBsc}file/statics/${archivo.id}`
                    })
                }

                if(archivo.name ==="Politicas"){
                    this.setState({
                        politicas: `${apiUrlBsc}file/statics/${archivo.id}`,      
                        politicaId: archivo.id,           
                        currentpoliticas: `${apiUrlBsc}file/statics/${archivo.id}`
                    })

                    this.handleCargarPoliticas()
                }
            })

            this.setState({
                listFiles : response.data
            })
            
            
        })
        .catch( err => {
            toast.error('Ocurrió un error al cargar información');
        })

    }

    /**
     * Carga archivo descargado de politicas en url
     */
     handleCargarPoliticas = () => {
        const request = axios.create({
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type':'application/json',
            }
        })
      
        this.setState({
            isLoading: true
        })
      
        request.get(this.state.politicas, {responseType: 'arraybuffer'})
            .then(response => {
                
                let contentType = response.headers["content-type"]
                let file = new Blob([response.data], {type: contentType});
                let filePoliticas = URL.createObjectURL(file)

                this.setState({
                    politicas: filePoliticas,
                    currentPoliticas: filePoliticas
                })
                  
            })
            .catch(() => {
                this.setState({
                    politicas: this.state.politicas,                
                    currentPoliticas: this.state.politicas
                })
            })
            .finally(() => this.setState({isLoading: false}))
    }

    render(){
        return (
            <div>
                <div className="card">
                    <div className="card-content">                        
                        {
                            this.state.banner &&
                            <div style={{textAlign: "center"}}>                                
                            <FileLoader fileSrc={this.state.banner} fileType={/^(image\/).+/} width='50%'/>
                            </div>
                        }
                        {
                            this.state.politicas &&
                            <div style={{textAlign: "center"}}>
                                <h5 className="title is-5 has-text-grey"><a href={this.state.politicas} target="_blank">Políticas</a></h5>
                            </div>
                        }
                    </div>
                </div> 
            </div>
        )
    }
}