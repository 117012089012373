import React, { Fragment } from 'react'

// Styles
import './Usuario.css'

export const LayoutErrors = ({ errors = [] }) => {
  return <Fragment>
    <p className="LayoutErrorDescr">El archivo contiene los siguientes errores:</p>
    {
      errors.map((item, index) => {
        return <RowError key={index} messages={item.message} fila={item.fila} />
      })
    }
  </Fragment>
}

const RowError = ({ fila, messages = [] }) => {
  return <Fragment>
    <p className="LayoutErrorRowTitle">{`La fila ${fila}`}</p>
    {
      messages.map((msg, index) => {
        return <p
          key={index}
          className="LayoutErrorMsg"
        >{`* ${msg}`}</p>
      })
    }
  </Fragment>
}