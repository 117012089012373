import React, { Fragment } from 'react'

/**
 * @fileoverview IndicadorTurboForm, formulario para agregar un indicador turbo.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
export const FormIndicadorTurbo = ({ indicador = {}, errors = {}, change, inputBlur }) => {
  return (
    <Fragment>
      <h5 className="title is-5 has-text-grey">Objetivo turbo:</h5>

      <div className="field">
        <label className="label">Objetivo</label>
        <div className="control">
          <input className={errors.objetivo ? "input is-danger" : "input"} type="text" placeholder="Objetivo"
            autoFocus={true}
            name="objetivo"
            value={indicador.objetivo}
            onChange={change}
            onBlur={inputBlur}
          />
        </div>
        {errors !== undefined &&
          errors.objetivo ?
          <ErrorMessage /> : ''
        }
      </div>

      <div className="field">
        <label className="label">Indicador</label>
        <div className="control">
          <input className={errors.indicador ? "input is-danger" : "input"} type="text" placeholder="Indicador"
            name="indicador"
            value={indicador.indicador}
            onChange={change}
            onBlur={inputBlur}
          />
        </div>
        {errors !== undefined &&
          errors.indicador ?
          <ErrorMessage /> : ''
        }
      </div>

      <div className="field">
        <label className="label">Comentario</label>
        <div className="control">
          <input className={errors.comentario ? "input is-danger" : "input"} type="text" placeholder="Comentario"
            name="comentario"
            value={indicador.comentario}
            onChange={change}
            onBlur={inputBlur}
          />
        </div>
        {errors !== undefined &&
          errors.comentario ?
          <ErrorMessage /> : ''
        }
      </div>

      <div className="field">
        <label className="label">Sobre Meta</label>
        <div className="control">
          <input className={errors.sobre_meta ? "input is-danger" : "input"} type="text" placeholder="Sobre Meta"
            name="sobre_meta"
            value={indicador.sobre_meta}
            onChange={change}
            onBlur={inputBlur}
          />
        </div>
        {errors !== undefined &&
          errors.sobre_meta ?
          <ErrorMessage /> : ''
        }
      </div>

      <div className="field">
        <label className="label">Ponderación</label>
        <div className="control">
          <input className={errors.ponderacion ? "input is-danger" : "input"}
            type="number" max={100}
            maxLength={3}
            placeholder="Ponderación"
            name="ponderacion"
            value={indicador.ponderacion}
            onChange={change}
            onBlur={inputBlur}
          />
        </div>
        {errors !== undefined &&
          errors.ponderacion ?
          <ErrorMessage msg="El valor debe ser numérico, entero y positivo entre 1 y 100" /> : ''
        }
      </div>
    </Fragment>
  )
}

/**
 * Mensaje de error para campos de formulario
 */
const ErrorMessage = ({ msg = 'Favor de llenar correctamente este campo' }) => {
  return <span style={{ color: 'red', fontSize: '12px', paddingLeft: '10px' }}>
    * {msg}
  </span>
}