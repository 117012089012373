import React, { Component } from 'react'
import { BSCColumnStyle } from '../../../util/bsc-column-style';

const TYPE_NORMAL = "Normal"

class Historial extends Component {

  state = {
    indicator: {},
    history: []
  }

  componentWillMount() {
    let proposal = this.props.proposal
    this.setState({
      indicator: proposal,
      history: proposal.history
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let proposal = this.props.proposal
    if (prevProps.proposal !== this.props.proposal) {
      this.setState({
        indicator: proposal,
        history: proposal.history
      })
    }
  }

  render() {

    let { indicator = {}, history = [] } = this.state
    let normalIndicator = indicator.tipo === TYPE_NORMAL

    return (
      history.length !== 0 ?
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th style={BSCColumnStyle.objetivo}>Objetivo</th>
              <th style={BSCColumnStyle.indicador}>Indicador</th>
              <th style={BSCColumnStyle.comentario}>Comentario</th>
              <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
              {
                normalIndicator &&
                <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
              }
              {
                normalIndicator &&
                <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
              }
              {
                normalIndicator &&
                <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
              }
              <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación" width="7%">% Pond.</abbr></th>
            </tr>
          </thead>
          <tbody>
            {
              history.map((item, index) => {
                return <tr key={index}>
                  <td>{item.objetivo}</td>
                  <td>{item.indicador}</td>
                  <td>{item.comentario}</td>
                  <td>{item.sobre_meta}</td>
                  {
                    normalIndicator &&
                    <td>{item.meta}</td>
                  }
                  {
                    normalIndicator &&
                    <td>{item.bajo_meta}</td>
                  }
                  {
                    normalIndicator &&
                    <td>{item.inaceptable}</td>
                  }
                  <td>{item.ponderacion}</td>
                </tr>
              })
            }
          </tbody>
        </table> :
        <p>No hay historial de este indicador!</p>
    )
  }
}

export default Historial