import React from 'react';
import { Button } from '../../util/Button/Button';
import { formatMonth, formatYear } from './HistorialEvaluacionesUtils';
import { HistorialEvaluacionesDetailsTable } from './HistorialEvaluacionesDetailsTable';
import { HistorialEvaluacionesDetailsTotal } from './HistorialEvaluacionesDetailsTotal';

export function HistorialEvaluacionesDetails({ open, closeModal, details, nivelesCumplimiento }) {
    const usuario = details.evaluaciones[0].usuario;
    const month = formatMonth(details.evaluaciones[0].mes);
    const year = formatYear(details.evaluaciones[0].anio);

    return (
        <div className={`modal ${open ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-card" style={{width: "90%"}}>
                <header className="modal-card-head">
                    <p className="modal-card-title">Variables: {usuario} ({month}-{year})</p>
                <button className="delete" aria-label="close" onClick={() => closeModal()}></button>
                </header>
                <section className="modal-card-body">
                    <HistorialEvaluacionesDetailsTable details={details.evaluaciones} nivelesCumplimiento={nivelesCumplimiento}/>
                    <HistorialEvaluacionesDetailsTotal details={details} />
                </section>
                <footer className="modal-card-foot">
                    <Button
                        icon="fa-times"
                        type="is-danger"
                        title="Cerrar reporte"
                        text="Cerrar reporte"
                        onClick={() => closeModal()}
                    />
                </footer>
            </div>

        </div>
    )
}