import React, { Component } from 'react'

import './Dropdown.css'
import { Icon } from '../Icon/Icon'


/**Comonente DropDown para selección de elementos de una lista. */
class Dropdown extends Component {
  state = {
    data: [],
    listFiltered: [],
    value: '',
    name: '',
    isDisabled: false,
    isListVisible: false,    
    isUserValidate: false,
    itemIndex: 0,
    selected: 0,
  }

  /**
   * Muestra u oculta lista de items.
   * 
   * @param {Boolean} isVisible
   */
  setListVisibility = (isVisible) => {
    this.setState({ isListVisible: isVisible })
  }

  /**
   * Maneja los cambios en el input.
   * 
   * @param {Event} event
   */
  handleChange = event => {
    const value = event.target.value
    if (value !== '') {
      this.setFilteredListItems(value)
      this.setState({
        value: event.target.value,
        itemIndex: 0,
      })
    } else {
      this.setState({
        value: event.target.value,
        listFiltered: this.state.data,
        selected: 0
      }, () => this.props.onChange(0))
    }
  }

  /**
   * Filtra los elementos de la lista segun la condición.
   * 
   * @param {String} queryString
   */
  setFilteredListItems = (queryString = '') => {
    const { data } = this.state

    if (queryString.length !== 0) {
      let listItems = data.map(item => {
        queryString.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let regexString = item.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let coincidences = regexString.toLowerCase().indexOf(queryString.toLocaleLowerCase())
        if (coincidences !== -1)
          return item
      })

      if (listItems.length !== 0) {
        this.setState({
          listFiltered: listItems.filter(item => item !== undefined)
        })
      } else if (listItems.length === 0) {
        this.setState({
          listFiltered: []
        })
      }
    } else if (queryString.length === 0) {
      this.setState({ listFiltered: data })
    }
  }

  /**
   * Permite ocultar la lista o asignar un valor al input.
   * 
   * @param {Event} e
   */
  keyEvent = e => {
    // Al dar Escape la lista se oculta
    if (e.key === 'Escape')
      this.setListVisibility(false)

    // Al dar enter se asigna el elemento resaltado al valor del input
    if (e.key === 'Enter') {
      const list = this.state.listFiltered.length !== 0 ?
        this.state.listFiltered
        : this.state.data
      const item = list.filter((item, index) => index === this.state.itemIndex)[0]
      this.setState({
        value: item ? item.value : '',
        selected: item ? item.id : 0,
      })
      this.props.onChange(item.id)
    }
  }

  /**
   * Navegacion con teclas `Arriba` `Abajo` para la lista
   * 
   * @param {Event} e
   */
  handleArrowKeysNavigation = e => {
    const { itemIndex, data, listFiltered } = this.state
    const list = listFiltered.length !== 0 ? listFiltered : data

    if (e.key === 'ArrowUp' && itemIndex > 0) {
      this.setElementIntoView(false, itemIndex)
      this.setState(prevState => ({
        itemIndex: prevState.itemIndex - 1
      }))
    } else if (e.key === 'ArrowDown' && itemIndex < list.length - 1) {
      this.setElementIntoView(true, itemIndex)
      this.setState(prevState => ({
        itemIndex: prevState.itemIndex + 1
      }))
    }
  }

  /**
   * Mantiene al elemento seleccionado en el campo visible de la lista.
   * 
   * @param {Boolean} isDown
   * @param {Number} itemIndex
   */
  setElementIntoView = (isDown = true, itemIndex = 0) => {
    const element = document.getElementById('IdListDropdownWrapper')
    const { isListVisible } = this.state

    if (isListVisible && itemIndex === 0)
      element.scrollTop = 0

    if (isListVisible && isDown)
      element.scrollTop += 30
    else if (isListVisible && !isDown)
      element.scrollTop -= 30
  }

  /**
   * Maneja la selección de un elemento.
   * 
   * @param {Object} item
   * @param {Number} index
   */
  handleSelectItem = (item = {}, index) => {
    this.setState({
      value: item.value,
      itemIndex: index,
      selected: item.id,
      isListVisible: false
    },() => {
      this.props.onChange(item.id)
    })
    
  }

  componentDidMount() {
    this.setState({
      name: this.props.name || '',
      data: this.props.data || [],
      value: this.props.value || '',
      selected: this.props.selected || 0,
      isDisabled : this.props.disabled || false,        
      isUserValidate : this.props.isUserValidate || false,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        name: this.props.name,
        data: this.props.data,
        value: this.props.value,
        selected: this.props.selected,
        isDisabled : this.props.disabled,        
        isUserValidate : this.props.isUserValidate
      })
    }
  }

  render() {
    const {
      data, isListVisible, value,
      name, isDisabled, isUserValidate, itemIndex,
      listFiltered, selected
    } = this.state
    const list = listFiltered.length !== 0 ? listFiltered : data

    return (
      <div
        className="DropdownContainer"
        tabIndex="-1"
        onBlur={() => {this.setListVisibility(false)}}
        onFocus={() => {if(!isDisabled)this.setListVisibility(true)}}
        onKeyUp={this.keyEvent}
        onKeyDown={this.handleArrowKeysNavigation}
      >
        <input
          className={`input DropdownInput ${this.props.validationClass}`}
          type="text"
          value={value}
          autoComplete="off"
          onClick={() => {if(!isDisabled)this.setListVisibility(true)}}
          onChange={this.handleChange}
          name={name}
          disabled={isDisabled}
          placeholder="Seleccionar..."
          validationname={this.props.validationname}
          rule={this.props.rule}
        />
        <div className={`DropdownListWrapper ${isListVisible ? 'IsListVisible' : ''}`}>
          <ul
            id={`${isListVisible ? 'IdListDropdownWrapper' : ''}`}
            className={`${isUserValidate ? 'DropdownListUserValidate': 'DropdownList'}`}
          >
            {
              list.length !== 0 ?
                list.map((item, index) => {
                  return <p key={item.id} className="control has-icons-left">
                    <li
                      className={
                        `DropdownListItem 
                      ${itemIndex === index ? 'IsSelected' : ''}`
                      }
                      onClick={() => this.handleSelectItem(item, index)}
                    >
                      {item.value}
                    </li>
                    {
                      item.id === selected &&
                      <Icon icon="fa-check-circle" size="is-small is-left DropdownIcon has-text-info" />
                    }
                  </p>
                })
                : <span className="NoItemsFiltered">No se encontraron resultados</span>
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default Dropdown