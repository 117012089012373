import { apiUrlBsc, apiUrlBsc2 } from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    // baseURL: apiUrlBsc2,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    }
})

export default class IndicadoresAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('indicador/'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/indicador/${id}/`));
    }

    static find(codigo) {
        return middleware.hasAuth(() => request.get(`/indicador/find/${codigo}/`));
    }

    static save(data) {
        return middleware.hasAuth(() => request.post('indicador/save/', data));
    }

    static saveMultiple(data) {
        return middleware.hasAuth(() => request.post('indicador/save/multiple/', data));
    }

    static saveTurbo(data) {
        return middleware.hasAuth(() => request.post('indicador/turbo/save/', data));
    }

    static editTurbo(id, data) {
        return middleware.hasAuth(() => request.put(`indicador/turbo/update/${id}/`, data));
    }

    static saveRelation(data) {
        return middleware.hasAuth(() => request.post('indicador/relate/', data))
    }

    static validate(id) {
        return middleware.hasAuth(() => request.put(`indicador/validate/${id}/`));
    }

    static edit(unit, id) {
        return middleware.hasAuth(() => request.put(`/Test`, unit));
    }

    static delete(id) {
        return middleware.hasAuth(() => request.post(`indicador/delete/${id}/`));
    }

    /**
     * Asigna como visto al indicador para no mostrar los cambios hechos.
     * 
     * @param {*} idIndicador 
     */
    static setIndicadorViewed(idIndicador) {
        // return middleware.hasAuth(() => request.post(`indicador/viewed/`, idIndicador))
        return middleware.hasAuth(() => request.post(`indicador/viewed/`, idIndicador))
    }

    /**
     * Carga los archivos de evidencia para la autoevaluación.
     * 
     * @param {Blob} file
     */
    static uploadFile(idIndicador, file, description = null, isText = false) {
        let form_data = new FormData()
        form_data.append('file', file)
        form_data.append('indicador', idIndicador)
        form_data.append('description', description)
        form_data.append('isText', isText)

        return middleware.hasAuth(() => request.post(`file/upload/`, form_data))
    }
}