import React, { Component } from 'react'
import { FormIndicadorTurbo } from './IndicadorTurboForm'

class EditTurbo extends Component {

    state = {
        valid: {
            objetivo: false,
            indicador: false,
            // comentario: false,
            sobre_meta: false,
            ponderacion: false
        },
        error: {
            objetivo: false,
            indicador: false,
            // comentario: false,
            sobre_meta: false,
            ponderacion: false
        },
        formValid: false,
        guardarLoading: false
    }

    componentDidMount() {
        let IsEditing = this.props.IsEditing

        if (IsEditing) {
            let valid = { ...this.state.valid }

            Object.keys(valid).map(key => {
                valid[key] = true
            })

            this.setState({ valid: valid })
        }
    }

    handleInputBlur = (e) => {
        let name = e.target.name
        let value = e.target.value
        let valid = { ...this.state.valid }
        let error = { ...this.state.error }

        valid[name] = true
        error[name] = false

        switch (name) {
            case "objetivo":
            case "indicador":
            // case "comentario":
            case "sm":
                if (value.length === 0) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "ponderacion":
                let ponderacion = parseInt(value) || 0;
                if (ponderacion === 0 || ponderacion > 100) {
                    valid[name] = false
                    error[name] = true
                }
                break;
        }

        this.setState({ valid: valid, error: error })
    }

    validateForm = () => {
        let valid = { ...this.state.valid }
        let error = { ...this.state.error }
        let formValid = this.state.formValid

        formValid = true

        Object.keys(valid).map(key => {
            if (!valid[key]) {
                formValid = false
                error[key] = true
            }
        })

        this.setState({ error: error, formValid: formValid, guardarLoading: true }, () => {
            if (formValid) {
                this.props.save()
            }

            setTimeout(() => this.setState({ guardarLoading: false }), 3000);

        })
    }

    render() {
        let error = { ...this.state.error }
        let instantFormError = (Object.keys(error).filter(key => error[key] === true).length > 0)
        return (
            <div className={"modal is-active"}>
                <div className="modal-background" />
                <div className="modal-card">

                    <header className="modal-card-head">
                        <p className="modal-card-title">Editar indicador turbo</p>

                        <button onClick={this.props.close} className="delete" aria-label="close"></button>

                    </header>

                    <section className="modal-card-body">
                        <FormIndicadorTurbo
                            indicador={this.props.indicador}
                            change={this.props.change}
                            inputBlur={this.handleInputBlur}
                            errors={error}
                        />
                        <div>
                            {/* <div className="field">
                                <label className="label">Objetivo</label>
                                <div className="control">
                                    <input className={error.objetivo ? "input is-danger" : "input"} type="text" placeholder="Objetivo"
                                        autoFocus={true}
                                        name="objetivo"
                                        value={this.props.indicador.objetivo}
                                        onChange={this.props.change}
                                        onBlur={this.handleInputBlur}
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Indicador</label>
                                <div className="control">
                                    <input className={error.indicador ? "input is-danger" : "input"} type="text" placeholder="Indicador"
                                        name="indicador"
                                        value={this.props.indicador.indicador}
                                        onChange={this.props.change}
                                        onBlur={this.handleInputBlur}

                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Comentario</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="Comentario"
                                        name="comentario"
                                        value={this.props.indicador.comentario}
                                        onChange={this.props.change}
                                    // onBlur={this.handleInputBlur}
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Sobre Meta</label>
                                <div className="control">
                                    <input className={error.sobre_meta ? "input is-danger" : "input"} type="text" placeholder="Sobre Meta"
                                        name="sobre_meta"
                                        value={this.props.indicador.sobre_meta}
                                        onChange={this.props.change}
                                        onBlur={this.handleInputBlur}
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Ponderación</label>
                                <div className="control">
                                    <input className={error.ponderacion ? "input is-danger" : "input"} type="number" max={100} maxLength={3} placeholder="Ponderación"
                                        name="ponderacion"
                                        value={this.props.indicador.ponderacion}
                                        onChange={this.props.change}
                                        onBlur={this.handleInputBlur}
                                    />
                                </div> */}
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button
                            className={`button is-success ${this.state.guardarLoading ? "is-loading" : ""}`}
                            disabled={(instantFormError) || this.state.guardarLoading}
                            onClick={this.validateForm}>
                            Guardar
                        </button>
                        <button className="button" onClick={this.props.close}>Cancelar</button>
                    </footer>
                </div>
            </div >
        )
    }
}

export default EditTurbo;