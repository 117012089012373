import React, { Component } from 'react';
import { BSCColumnStyle } from '../../../util/bsc-column-style';

class Propuesta extends Component {
    render() {
        let indicadores = this.props.propuesta.indicadores.filter(indicador => indicador.tipo === "Normal").sort(function (a, b) {
            return a.id - b.id
        })
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th style={BSCColumnStyle.objetivo}>Código</th>
                        <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                        <th style={BSCColumnStyle.indicador}>Indicador</th>
                        <th style={BSCColumnStyle.comentario}>Comentario</th>
                        <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                        <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                        <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                        <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                        <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar objetivo" width="7%">Usuario que valida</abbr></th>
                        <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación" width="7%">% Pond.</abbr></th>
                        {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                        {<th style={BSCColumnStyle.acciones}>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        indicadores.map((obj, index) => {
                            // let hasComentarios = obj.comentarios.length > 0 ? true : false;
                            const usuario_validate = obj.validate_user !== null ?
                                this.props.usuarios.filter(user => user.id === obj.validate_user)[0]
                                : undefined
                            let compensacionObj = {}//this.calcularCompensacion(obj, usuario)
                            return <tr key={index}>
                                <td>{obj.codigo}</td>
                                <td>{obj.objetivo}</td>
                                <td>{obj.indicador}</td>
                                <td>{obj.comentario}</td>
                                <td>{obj.sobre_meta}</td>
                                <td>{obj.meta}</td>
                                <td>{obj.bajo_meta}</td>
                                <td>{obj.inaceptable}</td>
                                <td>{usuario_validate ? usuario_validate.usuario.nombre : 'Sin encargado'}</td>
                                <td>{obj.ponderacion}</td>
                                {
                                    this.props.showCompensacion
                                    && (
                                        <td>
                                            <div className="tags are-medium">
                                                {
                                                    Object.keys(compensacionObj).map((key, i) => {
                                                        return <span className="tag is-info" key={i}>{key + "=" + compensacionObj[key]}</span>
                                                    })
                                                }
                                            </div>
                                        </td>
                                    )
                                }
                                {
                                    <td>
                                        <div className="column">
                                            <button disabled={this.props.propuesta.usuario.grupo !== 1 && !(this.props.propuesta.estatus === "Validado")}
                                                className="button is-info tooltip" data-tooltip="Relacionar" onClick={() => this.props.showBuscador(obj)}>
                                                <span className="icon">
                                                    <i className="fa fa-compress"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="column">
                                            <button
                                                disabled={obj.rel_indicador.length === 0}
                                                className="button is-info tooltip"
                                                data-tooltip="Indicadores relacionados"
                                                onClick={() => this.props.showRelatedIndicators(obj.rel_indicador)}
                                            >
                                                <span className="icon">
                                                    <i className="fa fa-info"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        )
    }
}

export default Propuesta;