import React, { Fragment } from 'react'

// Styles
import './CompensationTables/CompensationTables.css'

// Components
import { IndicatorsCompensationTable } from './CompensationTables/IndicatorsCompesationTable'
import { TurboIndicatorsTable } from './CompensationTables/TurboIndicatorsTable'
import { ExtraInfoCompensationTable } from './CompensationTables/ExtraInfoCompensationTable'

/**
 * Componente para ver reporte de compensación de la propuesta.
 * 
 * @param {Object} proposal
 * @param {Array} normalIndicators
 * @param {Array} turboIndicators
 */
export function ProposalCompReport({
  selectedProposal,
  proposal,
  normalIndicators,
  turboIndicators
}) {
  
  return (
    <Fragment>
      <IndicatorsCompensationTable
        selectedProposal={selectedProposal}
        indicators={normalIndicators.filter(item => item.evaluado)}
      />
      <div className="TurbosInfo">
        {
          turboIndicators.length !== 0
            ? <TurboIndicatorsTable
              indicators={turboIndicators}
            />
            : <span className="NoTurbos">
              La propuesta no cuenta con indicadores turbo
            </span>
        }
        <ExtraInfoCompensationTable
          proposalInfo={proposal}
          turbos={turboIndicators.filter(item => item.evaluado)}
        />
      </div>
    </Fragment>
  )
}