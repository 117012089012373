import React, { Fragment, Component } from 'react'

// Services
import UsersAPI from '../../../services/Usuario'
import GerenciasAPI from '../../../services/Gerencia'
import AreaAPI from '../../../services/Area'
import ConvocatoriaAPI from '../../../services/Convocatorias'
import ConsultaEvaluacionesAPI from '../../../services/ConsultaEvaluaciones';

// Components
import { Card } from '../../util/Card/Card'
import { Table } from '../../util/Table/Table'
import Pagination from '../../util/pagination/Pagination'
import { FiltersWrapper } from '../../util/FiltersWrapper/FiltersWrapper'
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import { toast } from 'react-toastify'
import { Button } from '../../util/Button/Button'
import Select from '../../util/select/Selct'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import Loader from '../../util/loading/Loading'
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

// Utils
import { generateBasicExcel } from '../../../util/ExcelFunctions'
import moment from 'moment'
import { filter } from 'lodash'


/** Componente para ver reporte de plan estratégico. */
class EstatusIndicadoresVariables extends Component {

  state = {
    currentPageItems: [],
    areas: [],
    areasOriginal: [],
    selectedAreas: [],
    currentPeriod: undefined,
    mesSelected: new Date().getMonth() + 1,
    listUsers: [],
    listUsersFiltered: [],
    selectedUsers: [],
    listGerencias: [],
    selectedGerencias: [],
    loadingExcel: false,
    listEstatus: LIST_ESTATUS,
    selectedEstatus: undefined,
    selectedEstatusUsuario:undefined,
    listReport: [],
    listPeriodos: [],
    filteredlistReport: [],
    filters: {},
    loadingTable: true,    
    pagination : null,
    pageSelected : 1
  }

  toastFileDownloadSuccess = null
  toastFileDownloadError = null

  componentDidMount() {

    let {filters, pageSelected} = this.state

    filters.page = pageSelected

    let listGerencias = []
    let areas = []
    let areasOriginal = []

    this.setState({
      loadingTable: true
    })

    Promise.all([
      GerenciasAPI.getAll()
      .then(res => {
        listGerencias = res.data
        return AreaAPI.getAll()
      })
      .then(({data}) => {
        areas = data
        areasOriginal = data
        this.setState({listGerencias, areas, areasOriginal})
      })
      .catch(err => toast.error('Gerencias no cargadas.')),
      UsersAPI.getUsuariosAllIdicadores()
      .then(res =>
        this.setState({
          listUsers: res.data,
          listUsersFiltered: res.data
        })
      )
      .catch(err => toast.error('Usuarios no cargados.')),
      ConsultaEvaluacionesAPI.consultar_estatus_indicadores(filters).then(response => {
        this.setState({
          listReport: response.data.results,
          pagination : response.data.pagination
        })
      }).catch(err => {
        toast.error("No se puedo cargar la información");
      })
    ]).then(() => {
      this.setState({
        loadingTable: false
      })
    })
  }

  /**
   * Carga de los usuarios de la aplicación. 
   * 
   * @param {Object} filters
  */
  loadReport = () => {
    let {filters, pageSelected} = this.state

    filters.page = pageSelected

    this.setState({
      loadingTable: true
    })

    ConsultaEvaluacionesAPI.consultar_estatus_indicadores(filters).then(response => {
      this.setState({
        loadingTable: false,
        listReport: response.data.results,
        pagination : response.data.pagination
      })
    }).catch(err => {
      this.setState({
        loadingTable: false
      })
      toast.error("No se puedo cargar la información");
    })
  }


  /**
   * Manjea los cambios en la lista de areas.
   * 
   * @param {Array} listAreas
   */
  handleChangeArea = (listAreas = []) => {
    let filters = { ...this.state.filters }
    filters.area = listAreas.map(area => area.id)
    let listUsersFiltered = []
    let areasIds = listAreas.map(area => area.id)

    delete filters.usuario

    if (listAreas.length === 0) {
      delete filters.area
    } else {
      filters.area = listAreas.map(area => area.id).join(",")
    }

    Promise.all([
      UsersAPI.getUsuariosAllIdicadores(filters)
      .then(res =>{
        this.setState({
          listUsers: res.data,
          listUsersFiltered: res.data
        })}
      )
      .catch(err => toast.error('Usuarios no cargados.'))
    ]).then(() => {
      this.setState({
        filters,
        pageSelected : 1
      },() => {
        this.loadReport(filters)
      })
    })
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de gerencia.
   * 
   * @param {Array} gerenciaList
   */
  handleChangeGerencia = (gerenciaList = []) => {
    let filters = { ...this.state.filters }
    let gerenciasIds = []
    let listAreas = []

    delete filters.area
    delete filters.usuario

    if (gerenciaList.length === 0) {
      delete filters.gerencia
      listAreas = this.state.areasOriginal
    } else {
      gerenciasIds = gerenciaList.map(gerencia => gerencia.id)
      listAreas = this.state.areasOriginal.filter(area => gerenciasIds.includes(area.gerencia))
      filters.gerencia = gerenciaList.map(gerencia => gerencia.id).join(",")
    }

    Promise.all([
      UsersAPI.getUsuariosAllIdicadores(filters)
      .then(res =>
        this.setState({
          listUsers: res.data,
          listUsersFiltered: res.data,      
          areas: listAreas,
        })
      )
      .catch(err => toast.error('Usuarios no cargados.'))
    ]).then(() => {
      this.setState({
        filters: filters,
        pageSelected : 1 
      },() => {
        this.loadReport(filters)
      })
    })
  }

  handleDebouncedOnChangeFilter = debounce(this.handleChangeAnio, 1500, {maxWait: 2000});

  handleChangeAnio(key, value) {
    const filters = {...this.state.filters};
    filters[key] = value;
    if(value == '')
      delete filters.anio

    this.setState({filters: filters}, () => {
        this.loadReport();
    });
  }

  handleChangeMes(key, target) {
    const filters = {...this.state.filters};
    const mes = Number(target.value) === 0
      ? 0
      : Number(target.value)
    filters[key] = target.value;
    if(mes == 0)      
      delete filters.mes
    this.setState({filters: filters, mesSelected : mes}, () => {
        this.loadReport();
    });
  }

  handleChangeEstatus(key, target) {
    const filters = {...this.state.filters};
    const estatus = target.value
    filters[key] = target.value;
    if(estatus == 0)      
      delete filters.estatus
    this.setState({filters: filters, selectedEstatus : estatus}, () => {
        this.loadReport();
    });
  }

  /**
   * Manjea los cambios en la lista de usuarios.
   * 
   * @param {Array} listUsers
   */
  handleChangeUser = (listUsers = []) => {
    let filters = { ...this.state.filters }

    if (listUsers.length === 0) {
      delete filters.usuario
    } else {
      filters.usuario = listUsers.map(user => user.id).join(",")
    }

    this.setState({
      pageSelected: 1,
      filters,
      loadingTable: true
    }, () => {
      this.loadReport()
    })

  }

  /**Descarga de Excel de plan estratégico. */
  handleDownloadReport = () => {
    this.setState({loading: true});
      ConsultaEvaluacionesAPI.downloadEstatusIndicadoresExcel(this.state.filters).then(response => {
          const now = new Date()
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(response.data);
          a.download = `Estatus de evaluación - ${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
          a.click();
          toast.success("Archivo descargado con éxito.");
      }).catch(err => {
          toast.error("No se pudo descargar el archivo.");
      }).finally(() => {
          this.setState({loading: false});
      });
  }

  /**
   * Obtiene la información de los renglones procesados
   * para dale formato al archivo Excel.
   * 
   * @param {Array} infoRows 
   * 
   * @returns Array
   */
  formattedInfo(infoRows = []) {
    let rows = []
    let columns = []

    rows = infoRows.map(item => {
      return {
        nombre_usuario: item.usuario.nombre,
        estatus_de_plan: item.estatus_plan,
        correo: item.usuario.email,
        area: item.area_nombre,
        gerencia: item.gerencia_nombre,
        puesto: item.puesto,
        monto: item.monto,
        politica: item.politica,
        jefe_directo: item.jefe_directo_nombre,
        grupo: item.grupo_nombre
      }
    })

    columns = Object.keys(rows[0])

    return [rows, columns]
  }

  

  render() {
    const {
      currentPageItems, areas, listGerencias, listEstatus,listEstatusUsuario,
      filteredlistReport, loadingExcel, loadingTable,listReport,
      listPeriodos, listUsersFiltered, selectedMes, currentPeriod, selectedEstatusUsuario
    } = this.state
    const COLUMNS = [
      {
        id: 1,
        title: "Nombre usuario",
        render: row => row.item.nombre_usuario,
        size: "20%"
      },
      {
        id: 2,
        title: "Jefe Directo",
        render: row => row.item.nombre_jefe_directo,
        size: "20%"
      },
      {
        id: 3,
        title: "Área",
        render: row => row.item.area,
        size: "20%"
      },
      {
        id: 4,
        title: "Gerencia",
        render: row => row.item.gerencia,
        size: "20%"
      },
      {
        id: 5,
        title: "Estatus",
        render: row => row.item.estatus,
        size: "20%"
      }
    ]

    return (
      <Fragment>
        <Card
          title="Reporte de estatus de evaluación"
          extraActions={
            <Button
              text="Descargar reporte"
              icon="fa-file-excel-o"
              onClick={this.handleDownloadReport}
              type="secondary"
              title="Descargar Excel de Reporte de estatus de evaluación"
              disabled={loadingExcel}
            />
          }
        >
          <FiltersWrapper>
            <MultiSelect
              label="Gerencia"
              name="gerencia"
              items={listGerencias.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={listGerencias.length === 0}
              onChangeValue={itemsList => this.handleChangeGerencia(itemsList)}
            />
            <MultiSelect
              label="Área"
              name="area"
              items={areas.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={areas.length === 0}
              onChangeValue={itemsList => this.handleChangeArea(itemsList)}
            />
            <MultiSelect
              label="Nombre/No. Empleado"
              name="usuario"
              items={listUsersFiltered.map(item => {
                let newItem = { ...item }
                newItem.value = `${item.id_empleado} - ${item.nombre}`

                return newItem
              })}
              disabled={listUsersFiltered.length === 0}
              onChangeValue={itemsList => this.handleChangeUser(itemsList)}
            />
            <Wrapper label="Año">
              <input className={"input"} type="text" pattern="\d*" placeholder="Año"
                name="anio"
                maxLength="4"
                defaultValue={new Date().getFullYear()}
                disabled={this.state.loading}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g,'');
                  this.handleDebouncedOnChangeFilter("anio", e.target.value);
                }}
              />
            </Wrapper>
            <Wrapper label="Mes">
              <Select
                label="Mes"
                classes="input"
                name="mes"
                value={this.state.mesSelected}
                change={(e) => this.handleChangeMes("mes", e.target)}
                items={LIST_MES}
                optionProps={{ value: "id", text: "value" }}
                unselect={false}
              />
            </Wrapper>
            <Wrapper label="Estatus">
              <Select
                label="Estatus"
                classes="input"
                name="estatus"
                value={this.state.selectedEstatus}
                change={(e) => this.handleChangeEstatus("estatus", e.target)}
                items={LIST_ESTATUS}
                optionProps={{ value: "id", text: "value" }}
              />
            </Wrapper>  
          </FiltersWrapper>
        </Card>
        <br />
        <Card>
          {
            this.state.pagination 
                ? <ReactPaginate 
                    className="react-paginate"
                    breakLabel="..."
                    onPageChange={(page) => {
                      this.setState({pageSelected: page.selected + 1},() => this.loadReport())
                    }}
                    pageCount={this.state.pagination.pages}
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                />
                : ''
          }
          <Table
            columns={COLUMNS}
            dataSet={listReport}
            noDataText="No se encontraron usuarios"
            loading={loadingTable}
          />
        </Card>
        {
          loadingTable &&
          <Loader
            isFullscreen={true}
            isLoading={loadingTable}
            width="100px"
            height="100px"
          />
        }
      </Fragment>
    )
  }
}

export default EstatusIndicadoresVariables

const LIST_ESTATUS = [
  {
    id: 'No tiene indicadores asignados',
    value: 'No tiene indicadores asignados'
  },
  {
    id: 'Evaluado',
    value: 'Evaluado'
  },
  {
    id: 'Pendiente de evaluación',
    value: 'Pendiente de evaluación'
  }
]

const LIST_MES= [
  {
    id: 1,
    value: 'Enero'
  },
  {
    id: 2,
    value: 'Febrero'
  },
  {
    id: 3,
    value: 'Marzo'
  },
  {
    id: 4,
    value: 'Abril'
  },
  {
    id: 5,
    value: 'Mayo'
  },
  {
    id: 6,
    value: 'Junio'
  },
  {
    id: 7,
    value: 'Julio'
  },
  {
    id: 8,
    value: 'Agosto'
  },
  {
    id: 9,
    value: 'Septiembre'
  },
  {
    id: 10,
    value: 'Octubre'
  },
  {
    id: 11,
    value: 'Noviembre'
  },
  {
    id: 12,
    value: 'Diciembre'
  }
]
