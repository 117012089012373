import React, { Fragment, Component } from 'react'

// Services
import UsersAPI from '../../../services/Usuario'
import GerenciasAPI from '../../../services/Gerencia'
import AreaAPI from '../../../services/Area'
import ConvocatoriaAPI from '../../../services/Convocatorias'
import ConsultaEvaluacionesAPI from '../../../services/ConsultaEvaluaciones';
import PagosVariablesAPI from '../../../services/PagosVariables';

// Components
import { Card } from '../../util/Card/Card'
import { Table } from '../../util/Table/Table'
import Pagination from '../../util/pagination/Pagination'
import { FiltersWrapper } from '../../util/FiltersWrapper/FiltersWrapper'
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import { toast } from 'react-toastify'
import { Button } from '../../util/Button/Button'
import Select from '../../util/select/Selct'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import Loader from '../../util/loading/Loading'
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

// Utils
import { generateBasicExcel } from '../../../util/ExcelFunctions'
import moment from 'moment'
import { filter } from 'lodash'


/** Componente para ver reporte de plan estratégico. */
class Administracion extends Component {

    state = {
        pagos_variables_loading: false,
    }

    toastFileDownloadSuccess = null
    toastFileDownloadError = null

    handlePagosVariablesExport = () => {
        this.setState({ pagos_variables_loading: true });
        PagosVariablesAPI.export().then(response => {
            toast.success(response.data.message);
        }).catch(err => {
            let message = "Error al enviar las evaluaciones";
            if (err && err.response && err.response.data) {
                message = err.response.data.message;
            }
            toast.error(message);
        }).finally(() => {
            this.setState({ pagos_variables_loading: false });
        });
    }

    /**Descarga de Excel de plan estratégico. */
    handleDownloadReport = () => {
        this.setState({ pagos_variables_loading: true });
        ConsultaEvaluacionesAPI.downloadReporteDeEnvioExcel().then(response => {
            const now = new Date()
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.data);
            a.download = `Concentrado de pagos - ${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
            a.click();            
            this.setState({ pagos_variables_loading: false });
            toast.success("Archivo descargado con éxito.");
        }).catch(err => {
            toast.error("No se pudo descargar el archivo.");
        })
    }

    render() {

        return (
        <Fragment>
            <Card title="Envío de pagos variables a TAX - Fortia" >
                <Button
                    icon={this.state.pagos_variables_loading ? "fa fa-spinner fa-spin" : "fa-cloud-upload"}
                    type="is-info"
                    onClick={this.handlePagosVariablesExport}
                    title="Enviar datos a pre-nómina"
                    text="Enviar datos a pre-nómina"
                    disabled={this.state.pagos_variables_loading}
                />

                <Button
                    icon={this.state.pagos_variables_loading ? "fa fa-spinner fa-spin" : "fa-cloud-download"}
                    type="is-info"
                    onClick={this.handleDownloadReport}
                    title="Descarga reporte de envio"
                    text="Descarga reporte de envio"
                    disabled={this.state.pagos_variables_loading}
                />
            </Card>
            {
                this.state.pagos_variables_loading &&
                <Loader
                    isFullscreen={true}
                    isLoading={this.state.pagos_variables_loading}
                    width="100px"
                    height="100px"
                />
            }
        </Fragment>
        )
    }
    }

    export default Administracion

    const LIST_ESTATUS = [
    {
        id: false,
        value: 'Pendiente'
    },
    {
        id: true,
        value: 'Enviado'
    }
    ]

    const LIST_MES= [
    {
        id: 1,
        value: 'Enero'
    },
    {
        id: 2,
        value: 'Febrero'
    },
    {
        id: 3,
        value: 'Marzo'
    },
    {
        id: 4,
        value: 'Abril'
    },
    {
        id: 5,
        value: 'Mayo'
    },
    {
        id: 6,
        value: 'Junio'
    },
    {
        id: 7,
        value: 'Julio'
    },
    {
        id: 8,
        value: 'Agosto'
    },
    {
        id: 9,
        value: 'Septiembre'
    },
    {
        id: 10,
        value: 'Octubre'
    },
    {
        id: 11,
        value: 'Noviembre'
    },
    {
        id: 12,
        value: 'Diciembre'
    }
    ]