import React, { Fragment } from 'react'

// Styles
import './CompensationTables.css'

// Components
import { Table } from '../../util/Table/Table'

/**
 * Componente para ver tabla de los indicadores turbo de una propuesta.
 * 
 * @param {Array} indicators
 */
export function TurboIndicatorsTable({ indicators }) {
  const COLUMNS_COLORS = {
    blue: '#0000FF',
    lightGreen: '#99CC00',
    green: '#99CC00',
    darkGreen: '#339966',
    yellow: '#FFFF00',
    red: '#FF0000',
    orange: '#FFC000'
  }

  const COLUMNS = [
    {
      id: 1,
      title: "Objetivo",
      value: "objetivo",
      size: "30%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 2,
      title: "Indicador",
      value: "indicador",
      size: "30%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 3,
      title: "Comentario",
      value: "comentario",
      size: "30%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 4,
      title: "SM",
      value: "sobre_meta",
      size: "10%",
      color: COLUMNS_COLORS.blue,
      fontColor: 'white'
    }
  ]
  return (
    <div className="TurbosWrapper">
      {
        indicators.map((item, index) => {
          if(item.evaluado){
            return (
            <div
              key={index}
              className="TurboIndicatorsCompensationTable"
            >
              <span className="TurboIndicatorsCompensationTitle">
                {`Indicador Turbo: ${item.ponderacion}% adicional del resto`}
              </span>
              <Table
                columns={COLUMNS}
                dataSet={[item]}
                noDataText="Propuesta sin indicadores turbo"
              />
            </div>)
          }
        })
      }
    </div>
  )
}
