import React from 'react'
import moment from 'moment'
import 'moment/locale/es';
moment.locale('es')

const Comentario = (props) => {
    return (
        <div className="card">
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <span className="icon is-large has-text-grey-lighter">
                            <i className="fa fa-user-circle-o fa-2x fa-border"></i>
                        </span>
                        {/* <figure className="image is-48x48">
                            <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                        </figure> */}
                    </div>
                    <div className="media-content">
                        <p className="title is-6">{props.comentario.usuario.usuario.nombre}</p>
                    </div>
                </div>

                <div className="content">
                    <div>
                        <span className="icon">
                            <i className="fa fa-commenting"></i>
                        </span>
                        <span>{props.comentario.comentario}</span>
                    </div>
                    
                    <div>
                        <span className="icon">
                            <i className="fa fa-clock-o"></i>
                        </span>
                        <time dateTime={props.comentario.fecha}>{moment(props.comentario.fecha).format("LLLL")}</time>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comentario;