import React, { Component } from 'react';
import Propuesta from './Propuesta';
import ModoPresentacionAyuda from './ModoPresentacionAyuda';
import './ModoPresentacion.css'


class ModoPresentacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: "initial",
            showAyuda: false,
            proposal: {},
            disabledRestablecerTexto: true,
            isModalEditNormalVisible: false,
            isModalEditTurboVisible: false,
            isCompensacionVisible: false
        }
        this.refSection = React.createRef()
    }

    handleIncreaseFontSize() {
        let state = { ...this.state };

        if (state.fontSize === "initial") {
            state.fontSize = "18px"
        } else {
            let intFontSize = parseInt(state.fontSize, 10) + 2
            state.fontSize = intFontSize.toString() + "px";
        }

        state.disabledRestablecerTexto = false
        this.setState(state);
    }

    handleDecreaseFontSize() {
        let state = { ...this.state };

        if (state.fontSize === "initial") {
            state.fontSize = "14px"
        } else {
            let intFontSize = parseInt(state.fontSize, 10) - 2
            state.fontSize = intFontSize.toString() + "px";
        }

        state.disabledRestablecerTexto = false
        this.setState(state);
    }

    handleRestoreFontSize() {
        let state = { ...this.state };
        state.disabledRestablecerTexto = true
        state.fontSize = "initial";
        this.setState(state);
    }

    handleClickTop = () => {
        this.refSection.current.scrollTo(0, 0);
    }

    componentDidMount() {
        this.setState({
            proposal: this.props.propuesta,
        })
    }

    // componentDidUpdate(prevProps, prevState) {
    //     let prevProposal = prevProps.propuestas.filter(proposal => proposal.id === this.props.propuesta.id)
    //     let proposal = this.props.propuestas.filter(proposal => proposal.id === this.props.propuesta.id)
    //     if (prevProposal.propuesta.indicadores.length !== proposal.indicadores.length) {
    //         this.setState({
    //             proposal: proposal,
    //         })
    //     }
    // }

    handleShowModalEdit = (type) => {
        if (type === 'Normal') {
            this.props.showModalEditNormal()
        } else if (type === 'Turbo') {
            this.props.showModalEditTurbo()
        }
    }

    render() {
        return (
            <div className="modal is-active" style={{ zIndex: 39 }}>
                <div className="modal-background" onClick={this.props.cancel} />
                <div className="modal-card" style={{ width: "95%", height: "100%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">
                            <span>Modo Presentación</span>
                            <button className="button is-small is-info ml-5" onClick={() => this.setState({ showAyuda: true })}>
                                <span className="icon">
                                    <i className="fa fa-question-circle"></i>
                                </span>
                                <span>Ayuda</span>
                            </button>
                        </p>
                        <div style={{ position: "relative", marginRight: "15px" }}>

                            <div>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <button className="button is-warning"
                                            onClick={this.handleIncreaseFontSize.bind(this)}
                                            title="Aumentar el tamaño del texto">
                                            <span className="icon is-small">
                                                <i className="fa fa-font"></i>
                                            </span>
                                            <span>+</span>
                                        </button>
                                    </p>

                                    <p className="control">
                                        <button className="button is-warning"
                                            onClick={this.handleDecreaseFontSize.bind(this)}
                                            title="Disminuir el tamaño del texto">
                                            <span className="icon is-small">
                                                <i className="fa fa-font"></i>
                                            </span>
                                            <span>-</span>
                                        </button>
                                    </p>

                                    <p className="control">
                                        <button className="button is-warning"
                                            disabled={this.state.disabledRestablecerTexto}
                                            onClick={this.handleRestoreFontSize.bind(this)}
                                            title="Restablecer el tamaño del texto">
                                            <span className="icon is-small">
                                                <i className="fa fa-undo"></i>
                                            </span>
                                            <span>Restablecer el tamaño del texto</span>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button onClick={() => this.props.cancel()}
                            className="delete"
                            aria-label="close">
                        </button>

                    </header>

                    <section className="modal-card-body" ref={this.refSection}>
                        <div>
                            <Propuesta
                                usuario={this.props.propuesta.usuario}
                                usuarios={this.props.usuarios}
                                indicadores={this.props.propuesta.indicadores}
                                fontSize={this.state.fontSize}
                                toTop={this.handleClickTop}
                                showModalEditIndicators={(type) => this.handleShowModalEdit(type)}
                                userProposals={this.props.userProposals}
                                subsProposals={this.props.subsProposals}
                                isOpenConvocatoria={this.props.isOpenConvocatoria}
                                isAdmin={this.props.isAdmin}
                                propuesta={this.props.propuesta}
                            />
                        </div>
                    </section>
                </div>

                {
                    this.state.showAyuda &&
                    <ModoPresentacionAyuda close={() => this.setState({ showAyuda: false })} />
                }
            </div>
        )
    }
}

export default ModoPresentacion;
