import React, { Component } from 'react';
import Indicador from './indicador';
import PropTypes from 'prop-types';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import Confirmar from './Confirmar';
import {
  sumarPonderacion,
  calcularCompensacion,
  totalCompensacion,
  validarTotalesIndicadores
} from '../../../util/compensacion-util';
import './propuesta.css'
import { toast } from 'react-toastify';
import Presentacion from '../../presentacion/Presentacion';
import { Button } from '../Button/Button';
import UsuariosAPI from '../../../services/Usuario'
import ArchivosEstaticosApi from '../../../services/ArchivosEstaticos'
import { set } from 'lodash';

class Propuestas extends Component {
  constructor(props) {
    super(props);

    this.Indicador = {
      propuesta: 0,
      objetivo: "",
      indicador: "",
      comentario: "",
      sobre_meta: "",
      meta: "",
      bajo_meta: "",
      inaceptable: "",
      revision: "",
      ponderacion: 0,
      ponderacionValid: false,
      compensacion: {},
      tipo: "Normal"
    }

    this.state = {
      indicadores: [
        { ...this.Indicador }
      ],
      listUsers: [],
      isListUsersLoading: true,
      isConfirmNotificationVisible: false,
      guardarBorrador: false,
      readedPresentacion:false,
      totalPonderacion: 0,
      totalCompensacion: 0,
      maxIndicadores: 6,
      verNotificacion: true,
      verConfirmar: true,
      verConfirmarCerrar: false,
      Confirmado: false,
      agregarDisabled: false,
      verCompensacion: false,
      validForm: false,
      saveLoading: false,
      readedPoliticas : false,
      indicadoresValidation: [{
        valid: false,
      }],
      showPresentacion: false,
      urlPresentacion: {
        url: null
      }
    }
  }

  handlerClickAgregar() {
    let state = { ...this.state };
    let indicador = { ...this.Indicador };
    let indicadorValidation = { valid: false };

    state.indicadores.push(indicador);
    state.indicadoresValidation.push(indicadorValidation);

    if (state.indicadores.length === state.maxIndicadores) {
      state.agregarDisabled = true
    }

    this.setState(state);
  }

  /**
   * Función que maneja el evento Change del campo ponderación en los indicadores
   * @param {*} pond - Valor ingresada
   * @param {*} index - Indice del objeto en el arreglo que actualziará
   */
  handlePondChange(pond, index) {
    pond = Number(pond) || 0; // Si el valor no es numérico, asigna 0
    const state = { ...this.state };

    state.indicadores.map((obj, i) => {
      if (i === index) {
        obj.ponderacion = pond;
        obj.ponderacionValid = true;

        if(obj.ponderacion == 0 || obj.ponderacion > 100){
          obj.ponderacionValid = false;
        }        
      }
      return obj;
    });

    const sumatoria = sumarPonderacion(state.indicadores);
    state.totalPonderacion = sumatoria;

    state.indicadores = this.calcularCompensaciones(state.indicadores)
    state.totalCompensacion = totalCompensacion(state.indicadores);

    if (sumarPonderacion(state.indicadores) === 100)
      state.indicadores = validarTotalesIndicadores(state.indicadores, this.props.usuario)
    state.totalCompensacion = totalCompensacion(state.indicadores);


    this.setState(state)
  }

  calcularCompensaciones = (indicadores) => {
    return indicadores.map(indicador => {
      indicador.compensacion = calcularCompensacion(indicador, this.props.usuario)
      return indicador;
    })
  }

  handleCamposChange(event, index, valid) {
    const state = { ...this.state };
    let name
    let value

    if (typeof (event) !== 'number') {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = 'validate_user'
      value = event
    }

    state.indicadores.map((obj, i) => {
      if (i === index) {
        obj[name] = value;
      }
      return obj;
    });

    state.validForm = valid;

    this.setState(state);
  }

  handleRemoveIndicador(index) {
    let state = { ...this.state }
    state.indicadores.splice(index, 1)
    state.indicadoresValidation.splice(index, 1)
    // Ya que se removió un elemento, actualizamos tambien la ponderación
    state.totalPonderacion = sumarPonderacion(state.indicadores);
    state.totalCompensacion = totalCompensacion(state.indicadores);

    if ((state.indicadores.length + 1) >= state.maxIndicadores) {
      state.agregarDisabled = false
    }

    this.setState(state);
  }

  validateIndicadores = (isDraft = true) => {
    let state = { ...this.state }
    let indicadorZeroPond = state.indicadores.find(indicador => indicador.ponderacion === 0)

    let dataIndicadores = state.indicadores.map(indicador => {
      let copy = {
        ...indicador
      }
      if (indicador.validate_user) {
        copy.validate_user = this.state.listUsers.filter(user => user.id === Number(indicador.validate_user))[0].id
      } else {
        copy.validate_user = ""
      }
      return copy
    })

    if (indicadorZeroPond === undefined) this.props.save(dataIndicadores, isDraft);
    else {
      toast.error("No puede haber objetivos con ponderación en cero. Favor de verificar.")
    }

    setTimeout(() => this.setState({ saveLoading: false }), 3000);

  }

  cerrar = () => {
    this.setState({ verConfirmarCerrar: true })
  }

  componentDidMount() {
    Promise.all([  
      UsuariosAPI.getAll().then(res => {
        this.setState({
          listUsers: res.data,
          isListUsersLoading: false
        })
      }).catch(err => {
        console.log('Error al cargar usuarios: ', err)
        toast.error("Usuarios no cargados")
      }),
      ArchivosEstaticosApi.getAllUrlStatic()
      .then( response => {
          let urls = response.data
  
          urls.forEach(url =>{
              if(url.name === "Enlace presentación"){
                  this.setState({
                      urlPresentacion : url
                  })                        
              }
          })
                    
      })
      .catch( err => {
          this.setState({ isLoading: false });
          toast.error('Ocurrió un error al cargar información');
      })
      
    ]).finally(() => {});
    
  }

  render() {
    let isFormValid = this.state.indicadores.length
      ? this.state.indicadores.find(obj => obj.valid === false) ? false : true
      : false

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card" style={{ width: "95%" }}>

          <header className="modal-card-head">
            <p className="modal-card-title">Elaborar propuesta</p>
            <div style={{ position: "relative", marginRight: "15px" }}>
              <div>
                <div className="field is-grouped">
                  <p className="control">                    
                    <input type="checkbox" id="confirmation" name="confirmation" checked={this.state.readedPoliticas} onChange={
                      () => {
                        let readedPoliticas = !this.state.readedPoliticas
                        this.setState({
                          readedPoliticas: readedPoliticas
                        })
                      }
                    } /> He leído las políticas de propuesta  
                  </p>

                  <p className="control">
                    <button
                      className="button is-success "
                      onClick={this.handlerClickAgregar.bind(this)}
                      disabled={(!this.state.Confirmado || this.state.agregarDisabled || !this.state.readedPoliticas)}
                      style={{ marginRight: "3px" }}>
                      <span className="icon is-small">
                        <i className="fa fa-plus-circle"></i>
                      </span>
                      <span>Agregar objetivo</span>

                    </button>
                  </p>

                  <p className="control">

                    {
                      this.props.children // Contiene el componente del botón para ver compensación
                    }
                  </p>
                </div>
              </div>
            </div>
            <button onClick={this.cerrar} className="delete" title="Cerrar" aria-label="close"></button>
          </header>

          <section className="modal-card-body">
            {
              (this.state.verNotificacion && !this.state.Confirmado) &&
              <div className="notification is-blue-adn">
                <h5 className="title is-5">Antes de elaborar propuestas, asegúrese de haber visto la presentación de la estrategia.</h5>
                <br />
                <div className="buttons are-medium">
                  <p style={{marginRight: 5}}>
                    <a className="button" href={this.state.urlPresentacion.url} target="blank" rel="noopener" onClick={() => {
                      if(this.state.urlPresentacion.url === null){
                        toast.error('No se encuentra una presentación de la estrategia');

                      }
                    }}>
                      <span className="icon">
                        <i className="fa fa-eye"></i>
                      </span>
                      <span>Ver presentación</span>
                    </a>
                  </p>
                  <button className="button" onClick={() => this.setState({ Confirmado: true, verNotificacion: false })}>
                    <span className="icon">
                      <i className="fa fa-check"></i>
                    </span>
                    <span>Confirmar</span>
                  </button>
                </div>
              </div>
            }

            {
              this.state.verConfirmar &&
              <Confirmar
                titleYes={'Debe descargar el archivo de presentación para confirmar'}
                disYes={this.state.readedPresentacion}
                yes={() => this.setState({ Confirmado: true, verConfirmar: false, verNotificacion: false })}
                no={() => this.setState({ verConfirmar: false })}>
                
                <h5 className="title is-5">
                  Antes de elaborar propuestas, asegúrese de haber visto la presentación de la estrategia.
                </h5>
                <p style={{textAlign: 'center'}}>
                  <a className="button is-info" href={this.state.urlPresentacion.url} onClick={() => {
                      this.setState({
                        readedPresentacion:true
                      })
                      if(this.state.urlPresentacion.url === null){
                        toast.error('No se encuentra una presentación de la estrategia');

                      }
                    }} target="blank" rel="noopener" >
                    {/* onClick={() => this.setState({ showPresentacion: true })}> */}
                    <span className="icon">
                      <i className="fa fa-eye"></i>
                    </span>
                    <span>Ver presentación</span>
                  </a>
                </p>
              </Confirmar>
            }

            {
              this.state.guardarBorrador &&
              <Confirmar
                yes={() => {
                  this.setState({guardarBorrador: false}, () => {
                    this.validateIndicadores(true)
                  })
                }}
                no={() => this.setState({                   
                  saveLoading: false,
                  guardarBorrador: false 
                  })}>
                
                <h5 className="title is-5">
                  Tu propuesta se guardará como <font color="#FFCD00">BORRADOR</font>, esto significa que, no se le notificará a tu jefe directo de la creación de esta misma. Solamente tú, como dueño de la propuesta, podrás visualizarla.
                </h5>
                <br/>
                <h5 className="title is-5">
                  El guardar como <font color="#FFCD00">BORRADOR</font> permitirá que realices modificaciones antes de enviarla y notificarle a quien corresponda.
                </h5>
              </Confirmar>
            }

            {
              this.state.showPresentacion &&
              <Presentacion close={() => this.setState({ showPresentacion: false })} />
            }

            {
              this.state.verConfirmarCerrar &&
              <Confirmar
                yes={this.props.cancel}
                no={() => this.setState({ verConfirmarCerrar: false })}>

                <h5 className="title is-5">
                  ¿Seguro que desea cerrar la ventana?
                </h5>
                <p>Los datos capturados se perderán</p>

              </Confirmar>
            }

            {
              this.state.isConfirmNotificationVisible &&
              <Confirmar
                yes={() =>
                  this.setState({
                    isConfirmNotificationVisible: false
                  }, () => this.validateIndicadores(false)
                  )}
                no={() => this.setState({ isConfirmNotificationVisible: false, saveLoading: false })}
              >
                <h4>¿Desea guardar su propuesta y notificar a su jefe directo?</h4>
                <p>Al notificar a su jefe directo, ya no podrá editar su propuesta</p>
              </Confirmar>
            }

            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th style={BSCColumnStyle.objetivo} width="20%">Objetivo</th>
                  <th style={BSCColumnStyle.indicador} width="5%">Indicador</th>
                  <th style={BSCColumnStyle.comentario} width="20%">Comentario</th>
                  <th style={BSCColumnStyle.sm}><abbr title="Sobre meta">SM</abbr></th>
                  <th style={BSCColumnStyle.m}><abbr title="Meta">M</abbr></th>
                  <th style={BSCColumnStyle.bm}><abbr title="Bajo meta">BM</abbr></th>
                  <th style={BSCColumnStyle.i}><abbr title="Inaceptable">I</abbr></th>
                  <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar el objetivo">Usuario que valida</abbr></th>
                  <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación">% Pond.</abbr></th>
                  {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                  <th style={BSCColumnStyle.acciones}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.indicadores.map((obj, index) => {
                    return <Indicador key={index}
                      disable={!this.state.Confirmado || !this.state.readedPoliticas || this.state.totalPonderacion >= 100}
                      data={obj}
                      showCompensacion={this.props.showCompensacion}
                      camposChange={(e) => this.handleCamposChange(e, index)}
                      userChange={(user) => this.handleCamposChange(user, index)}
                      pondChange={(pond) => this.handlePondChange(pond, index)}
                      remove={() => this.handleRemoveIndicador(index)}
                      listUsers={this.state.listUsers.map(user => {
                        let copy = { ...user }
                        copy.nombre = user.usuario.nombre

                        return copy
                      })}
                      isNewProposal={true}
                      onValidation={(isValid) => {
                        let state = { ...this.state };

                        state.indicadoresValidation.map((obj, ivindex) => {
                          if (ivindex === index) {
                            obj.valid = isValid;
                          }

                          return obj;
                        });

                        this.setState(state);
                      }}
                    />
                  })
                }
                <tr>
                  <td colSpan="9" style={{ textAlign: "right" }}>Total:</td>
                  <td style={{ textAlign: "left", color: this.state.totalPonderacion > 100 ? "red" : "black" }}>
                    {this.state.totalPonderacion}%
                  </td>
                  {
                    this.props.showCompensacion &&
                    <td>
                      {
                        this.state.totalCompensacion
                      }
                    </td>
                  }
                </tr>
              </tbody>
            </table>

          </section>

          <footer className="modal-card-foot">
                      
            {/* Botón para guardar y notificar a Jefe directo */}
            <Button
              className={`${this.state.saveLoading ? "is-loading" : ""}`}
              disabled={
                this.state.saveLoading || !isFormValid || !this.state.readedPoliticas ||
                (this.state.totalPonderacion === 100 ? false : true)
              }
              onClick={() => {
                this.setState({
                  saveLoading: true,
                  isConfirmNotificationVisible: true
                })
              }}
              text="Guardar y Notificar"
              icon="fa-check"
            />
            {/* Botón para guardar y poder seguir editando propuesta */}
            <Button
              className={`${this.state.saveLoading ? "is-loading" : ""}`}
              disabled={
                this.state.saveLoading || !isFormValid || !this.state.readedPoliticas ||
                (this.state.totalPonderacion > 0 ? false : true)
              }
              onClick={() => {
                this.setState({
                  saveLoading: true,
                  guardarBorrador:true
                })
              }}
              text="Guardar borrador"
              icon="fa-save"
              type="is-warning"
            />
            {/* Botón para cancelar la edición de la propuesta */}
            <Button
              onClick={this.props.cancel}
              text="Cancelar"
              type="is-danger"
              icon="fa-ban"
            />
          </footer>

        </div>
      </div>
    )
  }

}

Propuestas.defaultProps = {
  show: false,
  showCompensacion: false
}

Propuestas.propTypes = {
  show: PropTypes.bool,
  showCompensacion: PropTypes.bool,
  cancel: PropTypes.func,
  save: PropTypes.func,
}

export default Propuestas;