import React from 'react';
import './app-container.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Menu from '../menu/Menu';
import Header from '../header/Header';
import Index from '../index';
// import SolicitarPropuetasBsc from '../propuestas/solicitar/solicitarPropuestasBsc';
import Convocatorias from '../propuestas/Convocatorias/Convocatorias';
import ElaborarPropuetasBsc from '../propuestas/elaborar/elaborarPropuestasBsc';
import AutoEvaluacion from '../autoevaluaciones/AutoEvaluacion';
import Relacion from '../indicadores/relacion/relacion';
import PrivateRoute from '../util/privaterouter/PrivateRoute';
import SolicitudesCompensacion from '../solicitudes/SolicitudesCompensacion';
import MontosUsuarios from '../montos/montosUsuarios';
import AdminAreas from '../areas/AdminAreas';
// import Usuario from '../usuario/Usuario';
import Usuario from '../usuario/Usuario.jsx';
import ObjetivoTurbo from '../propuestas/ObjetivoTurbo/ObjetivoTurbo';
import HistorialMovimientos from '../Reportes/HistorialMovimientos/HistorialMovimientos';
import EstatusPlanEstrategico from '../Reportes/EstatusPlanEstrategico/EstatusPlanEstrategico';
import CompensacionTotal from '../Reportes/CompensacionTotal/CompensacionTotal';
import ArchivosEstaticos from '../configuracion/ArchivosEstaticos'
import AdminGerencias from '../gerencias/AdminGerencias';
import AdminSegmentos from '../segmentos/AdminSegmentos';

//Indicadores variables
import Configuracion from "../indicadores_variables/Configuracion/configuracion_notificaciones"
import Indicadores from '../indicadores_variables/Indicadores/Indicadores';
import IndicadoresAdministracion from '../indicadores_variables/IndicadoresAdministracion/Indicadores';
import Administracion from '../indicadores_variables/Administracion/Administracion';
import HistorialEvaluaciones from '../indicadores_variables/HistorialEvaluaciones/HistorialEvaluaciones';
import Evaluaciones from '../indicadores_variables/Evaluaciones/Evaluaciones';
import UsuariosFsapUbicacionBase from '../indicadores_variables/UsuariosFSAP/usuarios_fsap_ub'
import EstatusIndicadoresVariables from '../indicadores_variables/EstatusIndicadoresVariables/EstatusIndicadoresVariables'
import UsuariosIndicadores from '../indicadores_variables/Usuarios/Usuarios';


/**
 * Componente encargado de englobar toda la aplicación en el apartado del usuario ya en sesión
 * Esto conlleva el despliegue del Menú, Header y el componente correspondiente a la ruta actual
 */
export default class AppContainer extends React.Component {

    state = {
        isMenuVisible: true
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            if (!(localStorage.getItem("token") && localStorage.getItem("tokenExpiration") > Date.now() / 1000)) {
                localStorage.clear();
                window.location.reload();
            }
        }, 5000);
    }

    showMenu = (isVisible) => {
        this.setState({ isMenuVisible: isVisible })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        let { isMenuVisible } = this.state
        
        let have_access_montos = localStorage.getItem('have_access_montos');
        
        let perfil_selected = localStorage.getItem('perfil_selected');

        return (
            <BrowserRouter>
                <div className="App">
                    <div className="columns">
                        {isMenuVisible &&
                            <Menu />
                        }
                        <div className={`column admin-panel ${isMenuVisible === false ? "Content" : ''}`}>
                            <Header isMenuVisible={isMenuVisible} showMenu={isVisible => this.showMenu(isVisible)} />
                            <div className="padding-top" style={{ marginRight: "10px" }}>
                                
                                {/*BSC*/}
                                {
                                    perfil_selected == 'bsc' &&
                                    <Switch>                                    
                                        {/* 
                                            Aquí se registran todas las rutas disponibles como hijos de este
                                            componente, así como el componente correspondiente a las mismas.
                                        */}

                                        {/* Componente anónimo sin estado para un logout y redirigir al inicio */}
                                        <Route path="/logout" component={() => {
                                            localStorage.clear();
                                            window.location.replace("/")
                                            return <Redirect to={{ pathname: '/', state: null }} />;
                                        }} />

                                        { /** Propuestas */}
                                        {/* <PrivateRoute roles={['Administrador']} path="/solicitarPropuestasBsc" component={SolicitarPropuetasBsc} /> */}
                                        <PrivateRoute roles={['Administrador']} path="/convocatorias" component={Convocatorias} />
                                        <PrivateRoute roles={['Administrador', 'Usuario']} path="/objetivoTurbo" component={ObjetivoTurbo} />
                                        <Route path="/propuestas/elaborar" component={ElaborarPropuetasBsc} />
                                        <Route path="/evaluacion_objetivos" component={AutoEvaluacion} />

                                        { /** Indicadores */}
                                        <Route path="/indicadores/relacion" component={Relacion} />
                                        
                                        <PrivateRoute roles={['Administrador']} path="/usuarios" component={Usuario} />
                                        
                                        { /** Solicitudes compensación */}
                                        {/* <Route path="/solicitudes" component={SolicitudesCompensacion} />
                                        {
                                            have_access_montos == 'true' &&
                                            <PrivateRoute roles={['Usuario Nomina']} path="/montos" component={MontosUsuarios} />  
                                        }   */}

                                        {/* Administracion Catalogos*/}
                                        <PrivateRoute roles={['Administrador']} path="/areas" component={AdminAreas} />      
                                        <PrivateRoute roles={['Administrador']} path="/gerencias" component={AdminGerencias} />  
                                        <PrivateRoute roles={['Administrador']} path="/segmentos" component={AdminSegmentos} />                            
                                        
                                        {/* Reportes */}
                                        <PrivateRoute roles={['Administrador']} path="/reportes/historial-movimientos" component={HistorialMovimientos} />
                                        <PrivateRoute roles={['Administrador']} path="/reportes/estatus-plan-estrategico" component={EstatusPlanEstrategico} />
                                        <PrivateRoute roles={['Administrador']} path="/reportes/compensacion-total" component={CompensacionTotal} />                                    
                                        <PrivateRoute roles={['Administrador']} path="/archivos" component={ArchivosEstaticos} />
                                        {/*
                                        Siempre deben de ir las mas basicas hasta el final por que por cascada...
                                        */}

                                        <Route path="/" component={Index} />

                                        

                                    </Switch>

                                }

                                {/*Indicadores Variables*/}
                                {
                                    perfil_selected == 'variable' &&
                                    <Switch>
                                        <PrivateRoute roles={['Administrador KPIs', 'Consulta', 'FSAP', 'Autorizador','Evaluador de Indicadores Variables']} path="/variable/historial_evaluaciones" component={HistorialEvaluaciones} />  
                                        <PrivateRoute roles={['Administrador KPIs', 'FSAP']} path="/variable/configuracion" component={Configuracion} />  
                                        <PrivateRoute roles={['Evaluador de Indicadores Variables', 'Administrador KPIs', 'FSAP']} path="/variable/evaluaciones" component={Evaluaciones} />                                      
                                        <PrivateRoute roles={['Administrador KPIs', 'FSAP']} path="/variable/administracion/indicadores" component={IndicadoresAdministracion} /> 
                                        <PrivateRoute roles={['Evaluador de Indicadores Variables']} path="/variable/indicadores" component={Indicadores} /> 
                                        <PrivateRoute roles={['Administrador KPIs', 'FSAP']} path="/variable/administracion" component={Administracion} />
                                        <PrivateRoute roles={['Administrador KPIs']} path="/variable/fsap-ubicacion-base" component={UsuariosFsapUbicacionBase} />                                        
                                        <PrivateRoute roles={['Administrador KPIs', 'FSAP', 'Autorizador']} path="/variable/reporte_estatus" component={EstatusIndicadoresVariables} />                                  
                                        <PrivateRoute roles={['Administrador KPIs', 'FSAP']} path="/variable/usuarios" component={UsuariosIndicadores} />      
                                        /variable/usuarios
                                        <Route path="/logout" component={() => {
                                            localStorage.clear();
                                            window.location.replace("/")
                                            return <Redirect to={{ pathname: '/', state: null }} />;
                                        }} />
                                    </Switch>

                                }


                                
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="bottom-right" />
                </div>
                    
            </BrowserRouter>
        )
    }
}