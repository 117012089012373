import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../util/Button/Button';
import { Modal } from '../util/modal/ModalLayout';
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import Select from '../util/select/Selct'
import Loading from '../util/loading/Loading'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de montos de compensacion de usuario. */
export class MontosDetalle extends Component {
    state = {
    }

    
    getPeriodo = () => {
        let year = new Date(Date.now()).getFullYear()
        let mount = new Date(Date.now()).getMonth()
        let semestre = 1

        if(mount > 6)
            semestre = 2
        
        return year.toString() + '-' + semestre.toString()

    }    

    render() {
        let usuario = this.props.usuario
        return (
            <div className="modal is-active" >
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "50%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Detalles de usuario</p>
                        
                        <button onClick={this.props.cerrar} className="delete" title="Cerrar" aria-label="close"></button>
                    </header>

                    <section className="modal-card-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Número/Nombre de empleado">
                                            <p>{usuario.usuario.id_empleado + " - " +usuario.usuario.nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-4" style={{backgroundColor: "#E7E7E7", borderRadius: '5px'}}>
                                        <Wrapper label="Fecha de consulta">
                                            <p>{new Date(Date.now()).toLocaleDateString()}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-2" style={{backgroundColor: "#E7E7E7", borderRadius: '5px'}}>
                                        <Wrapper label="Periodo">
                                            <p>{this.getPeriodo()}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Área / Departamento">
                                            <p>{usuario.area_nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-6">
                                        <Wrapper label="Puesto">
                                            <p>{usuario.puesto}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Razón Social">
                                            <p>{usuario.razon_social_nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-6" style={{backgroundColor: "#D9EAD3", borderRadius: '5px'}}>
                                        <Wrapper label="Monto total disponible">
                                            <p>{formatter.format(usuario.monto_total)}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-head">
                        <p>&nbsp;&nbsp;Detalles de montos por periodos:</p>
                        </div>
                        <div className="card-content">
                            <table className="table is-hoverable is-fullwidth" style={{textAlign:'center'}}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'center'}}>Periodo</th>
                                        <th style={{textAlign:'center'}}>Monto disponible</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        usuario.montos &&
                                        usuario.montos.map((monto, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td style={{textAlign:'center'}}>{monto.convocatoria.year} - {monto.convocatoria.semestre}</td>
                                                    <td style={{textAlign:'center'}}>{formatter.format(monto.compensacion_sobrante)}</td>
                                                </tr>
                                            )
                                        })
                                    }                        
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </section>
                </div>
            </div>

        )
    }
}

export default MontosDetalle;