import React, { Component, Fragment } from 'react'

// Services
import PropuestaAPI from '../../services/Propuestas'
import UsuarioAPI from '../../services/Usuario'
import ConvocatoriaAPI from '../../services/Convocatorias'

// Utilities
import decode from 'jwt-decode'
import './AutoEvaluacion.css'
import moment from 'moment'

// Components
import { Card } from '../util/Card/Card'
import { toast } from 'react-toastify'
import { IndicadorTabla } from '../util/Indicadores/IndicadorTabla'
import { PropuestaWrapper } from '../util/Indicadores/PropuestaWrapper'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { Button } from '../util/Button/Button'
import { Modal } from '../util/modal/ModalLayout'
import { ProposalCompReport } from './ProposalCompReport'
import FormAutoevaluacion from './FormAutoevaluacion'
import FormEvaluacion from './FormEvaluacion'
import Confirmar from '../util/propuestas/Confirmar'
import Loader from '../util/loading/Loading'
import Select from '../util/select/Selct'
import { union } from 'lodash'
import { calcularCompensacion } from "../../util/compensacion-util"

const USER_ROL = {
  area_boss: 1,
  coordinator: 2,
  area_director: 3
}

/**Módulo para realizar la autoevaluación de las propuestas. */
class AutoEvaluacion extends Component {

  state = {
    users: [],
    calcular_compensacion:false,
    proposals: [],
    myProposal: {},
    allProposals: {},
    proposalToEvaluate: {},
    isEvaluating: false,
    isEvaluatedProposal: false,
    checkAutorizado: false,
    checkRechazado: false,
    validateNextIndicator: false,
    status: '',
    currentPeriod: 0,
    openConvocatoria: false,
    convocatory: {},
    selectedPeriod: undefined,
    listPeriods: [],
    porcentajeCompensacion: 100,
    saveBorrador : false,
    porcentajeCompensacionError: {
      isInvalid: false,
      message: ''
    },
    selectedIndicator: {},
    isTurboEvaluating: false,
    isModalAttachFilesVisible: false,
    isModalEvaluationVisible: false,
    isModalReportCompVisible: false,
    isModalMyReportCompVisible: false,
    showConfirmChangeProposal: false,
    showConfirmEvaluateProposal: false,
    showConfirmIndicatorsEvaluated: false,
    showConfirmChangeSelectedIndicator: false,
    isFormEvaluationValid: false,
    listIdIndicators: [],
    evaluation: {},
    moveToLeft: false,
    moveToRight:false,
    currentProposalEvaluation: [],
    currentProposalEvaluationToSave: {},
    isEvaluationCompleted: false,
    proposalEvaluated: null,
    prevAutoEvaluatedIndicators: [],
    loadingData: true,
    myNominalReport: {},
    listNominalReports: [],
    idReportCompSelected: null,
    reportSelected: {},
    proposalSelected: {}
  }

  /**Carga de la lista de usuarios */
  loadUsersList = () => {
    UsuarioAPI.getAll().then(res => {
      this.setState({
        users: res.data
      }, () => this.loadProposals())
    }).catch(err => {
      this.loadProposals()
      toast.error('Los usuarios no se pudieron cargar!')
    })
  }

  /**
   * Filtra la propuesta del usuario 
   * 
   * @param {Array} proposals
  */
  getMyProposal = (proposals = []) => {
    const TOKEN = decode(localStorage.getItem('token'))
    const { prevAutoEvaluatedIndicators } = this.state;
    let myProposal
    if (proposals.length !== 0) {
      let proposal = proposals.filter(proposal =>
        proposal.usuario.usuario.id_auth === Number(TOKEN.uid)
      )

      if (TOKEN.role === 'Administrador') {
        let id = 0
        if (proposal[0]) {
          id = proposal[0].id
        }
        this.loadMyNominalReport(id)
      } else {
        if (proposal[0])
          this.loadMyNominalReport(proposal[0].id)
      }


      myProposal = proposal[0] || []
    } else {
      myProposal = []
    }

    let copyMyProposal = {
      ...myProposal,
      indicadores: myProposal.indicadores.map(ind => {
        let copyInd = { ...ind };
        prevAutoEvaluatedIndicators.forEach(prevInd => {
          if (copyInd.id === prevInd.id) {
            copyInd.cumplimiento = prevInd.nivel;
          }
        });
        return copyInd;
      })
    };

    return copyMyProposal;
  }

  /**
   * Obtiene el reporte de nómina de la propuesta.
   * 
   * @param {Number} idMyProposal
   */
  loadMyNominalReport = (idMyProposal) => {
    let filter = {}
    
    const TOKEN = decode(localStorage.getItem('token'))

    if(idMyProposal != 0)
      filter = {propuesta:idMyProposal}

    if (TOKEN.role === 'Administrador') {
      filter = {}
    }

    
    PropuestaAPI.nominalReport(filter)
      .then(res =>
        this.setState({
          myNominalReport: res.data.filter(item => item.id === idMyProposal)[0] || {},
          listNominalReports: res.data
        })
      )
      .catch(err => toast.error('El reporte de nómina no se puede generar.'))
  }

  /**
   * Filtra las propuestas de los usuarios a mi cargo 
   * 
   * @param {Array} proposals
   * @returns {Array} `FilteredProposals` `indicators`
  */
  filterSubProposals = (proposals = []) => {
    const TOKEN = decode(localStorage.getItem('token'))
    let filteredProposals = this.handleVerifyProposals(
      proposals.filter(item => item.usuario.usuario.id_auth !== Number(TOKEN.uid))
    )
    let indicators = []
    filteredProposals.forEach(function (item) {
      item.indicadores.forEach(function (indicator) {
        if (
          indicator.tipo === 'Normal' &&
          indicator.cumplimiento !== 'sin_evidencia'
        ) {
          indicators.push(indicator.id)
        }
      })
    })

    return [filteredProposals, indicators]
  }

  /**Carga de las propuestas del sistema */
  loadProposals = () => {
    const isAdmin = decode(localStorage.getItem('token')).role === 'Administrador'
    const period = this.state.selectedPeriod
    const currentEvaluation =this.state.currentProposalEvaluation
    let filter = {
      periodo: period
    }
    PropuestaAPI.getWithFilters(filter).then(res => {
      let proposalsList = res.data.map(proposal => {
        return {
          ...proposal,
          indicadores: proposal.indicadores.map(indicator => {
            return {
              ...indicator,
              isEvaluated: indicator.evaluado
            }
          })
        }
      })

      
      res.data.map(proposal => {
        var indicadoresEvaluados = []
        if((proposal.estatus_evaluacion != 'Sin Evaluacion' && proposal.estatus_evaluacion != 'Evaluacion Aceptada' && proposal.estatus_evaluacion != 'Autoevaluacion en proceso')
        || (proposal.estatus_evaluacion != 'Sin Evaluacion' && proposal.estatus_evaluacion != 'Autoevaluacion en proceso' && isAdmin)){
          
          var evaluatedFull = true
          proposal.indicadores.map(indicator => {
            if(indicator.comentario_evaluacion == ''){              
              evaluatedFull = false
            }
            else{
              indicadoresEvaluados.push({
                comentario : indicator.comentario_evaluacion,
                comentario_autoevaluacion : indicator.comentario_autoevaluacion,
                indicador : indicator.id,
                nivel_cumplimiento : indicator.cumplimiento,
                status : indicator.evaluado ? "Autorizado" : indicator.comentario_evaluacion ? "Rechazado" : ''

              })
            }
              
          })

          currentEvaluation.push({id: proposal.id, indicadores : indicadoresEvaluados, isEvaluationCompleted : evaluatedFull})
        }
      })
      
      


      let selected = {}
      const [filteredProposals, indicators] = this.filterSubProposals(proposalsList)
      if (this.state.proposalSelected.id !== undefined) {
        selected = filteredProposals.filter(item => item.id === this.state.proposalSelected.id)[0] || {}
      }

      const sortedProposals = filteredProposals.sort((a, b) => a.id - b.id);
      this.setState({ proposals: sortedProposals });
      this.setState({
        proposalSelected: selected,
        listIdIndicators: indicators,
        myProposal: this.getMyProposal(proposalsList),
        loadingData: false,
        currentProposalEvaluation : currentEvaluation
      })
      this.checkCurrentProposal()
      this.checkPrevAutoEvaluated()
    }).catch(err => {
      this.setState({
        loadingData: false
      })
    })
  }

  /**
   * Muestra el modal para auto-evaluar objetivo 
   * 
   * @param {Number} idIndicator
  */
  showModalAttachFiles = (idIndicator) => {
    const { prevAutoEvaluatedIndicators } = this.state
    const prevAutoEvaluatedIds = prevAutoEvaluatedIndicators.map(item => item.id)
    const infoIndicatorToEvaluate = this.state.myProposal.indicadores.filter(
      item => item.id === idIndicator
    )[0]
    let selectedIndicator = {
      id: idIndicator,
      id_proposal: this.state.myProposal.id,
      evidencia: infoIndicatorToEvaluate.evidencia,
      comentario_evaluacion: infoIndicatorToEvaluate.comentario_evaluacion
    }

    if (prevAutoEvaluatedIds.includes(idIndicator)) {
      selectedIndicator = prevAutoEvaluatedIndicators.filter(item => {
        if (item.id === idIndicator) {
          return {
            ...item,
            ...selectedIndicator
          }
        }
      })[0] || {}
    } else {
      selectedIndicator = {
        ...selectedIndicator,
        nivel: infoIndicatorToEvaluate.cumplimiento,
      }
    }

    this.setState({
      isModalAttachFilesVisible: true,
      selectedIndicator
    })
  }

  /**
   * Muestra el modal para evaluar objetivo 
   * 
   * @param {Number} idIndicator
  */
  showModalEvaluation = (idIndicator) => {
    this.setSelectedIndicator(idIndicator)
  }

  /** Filtra la información del indicador seleccionado. */
  setSelectedIndicator = (idIndicator) => {
    const { currentProposalEvaluation, proposals } = this.state
    let indicadorSelected = {}
    let currentProposalId = null

    // Recorre los indicadores de las propuetas para obtener la 
    // info del indicador seleccionado junto con el id de su propuesta
    proposals.forEach(function (item) {
      item.indicadores.forEach(function (indicator) {
        if (indicator.id === idIndicator) {
          indicadorSelected = {
            idProposalSelectedIndicator: item.id,
            proposalCode: indicator.codigo,
            userName: item.usuario.usuario.nombre,
            ...indicator
          }
          currentProposalId = item.id
        }
      })
    })

    this.setState({
      selectedIndicator: indicadorSelected
    })

    // Verifica que el indicador seleccionado pertenezca a la
    // propuesta evaluada actualmente
    if (currentProposalEvaluation.filter(proposal => (proposal.id === currentProposalId)).length > 0) {
        this.setState({
          isModalEvaluationVisible: true
        })
    } else {
      this.setIndicatorInfo(currentProposalId, currentProposalEvaluation)
    }
  }

  setIndicatorInfo = (currentProposalId, currentProposalEvaluation) => {
    const { proposals } = this.state
    var newEvaluation = currentProposalEvaluation.filter(proposal => (proposal.id === currentProposalId)).length == 0
    if(newEvaluation){
      var currentEvaluation = []
      if(proposals.find(item => item.id == currentProposalId).estatus_evaluacion !== 'Evaluado'){
        let indicadores = []
        proposals.find(item => item.id == currentProposalId).indicadores.forEach(element => {
          indicadores.push({
            indicador: element.id,
            nivel_cumplimiento: element.cumplimiento,
            status: element.evaluado ? 'Autorizado': 'Rechazado',
            comentario: element.comentario_evaluacion,
            comentario_autoevaluacion : element.comentario_autoevaluacion
          })
          
        });
        currentEvaluation = currentProposalEvaluation.concat({id: currentProposalId, indicadores: indicadores})
      }
      else{
        currentEvaluation = currentProposalEvaluation.concat({id: currentProposalId})
      }
      
      this.setState({
        isModalEvaluationVisible: true,
        currentProposalEvaluation: currentEvaluation
      })
    }
  }

  /** Verifica que todos los indicadores de la propuesta hayan sido evaluados. */
  checkCurrentProposal = (proposalId = 0) => {
    const { currentProposalEvaluation, proposals } = this.state
    let currentEvaluation = currentProposalEvaluation.filter(proposal => proposal.id === proposalId)[0]
    let isEvaluationCompleted
    let listIdIndicatorsEvaluated = currentEvaluation.indicadores
      ? currentEvaluation.indicadores.length
      : 0
    let currentProposalIndicators = proposals.filter(proposal =>
      proposal.id === currentEvaluation.id
    )[0].indicadores.length

    isEvaluationCompleted = currentProposalIndicators === listIdIndicatorsEvaluated

    if (isEvaluationCompleted) {
      currentProposalEvaluation.filter(proposal => proposal.id === proposalId)[0].isEvaluationCompleted = isEvaluationCompleted
      this.setState({
        currentProposalEvaluation : currentProposalEvaluation,
        showConfirmIndicatorsEvaluated: true,
        proposalEvaluated: currentEvaluation.id
      })
    }
  }

  /** 
   * Verifica que indicadores de la propuesta fueron 
   * previamente autoevaluados.
   */
  checkPrevAutoEvaluated = () => {
    const { prevAutoEvaluatedIndicators, myProposal } = this.state
    const prevAutoEvaluatedIds = prevAutoEvaluatedIndicators.map(
      item => item.id
    )

    if (myProposal.length !== 0) {
      let myProposalChecked = {
        ...myProposal,
        indicadores: myProposal.indicadores.map(indicator => {
          if (prevAutoEvaluatedIds.includes(indicator.id)) {
            return {
              ...indicator,
              isEvaluated: true
            }
          } else {
            return {
              ...indicator,
              isEvaluated: indicator.cumplimiento !== 'sin_evaluacion'
            }
          }
        })
      }

      this.setState({
        myProposal: myProposalChecked
      })
    }
  }

  /** 
   * Verifica que indicadores de la propuesta fueron 
   * previamente evaluados.
   * 
   * @param {Object} evaluation
   * @returns Array
   */
  checkPreEvaluatedIndicators = (evaluation, currentEvaluation) => {
    let prevEvaluatedIndicators = currentEvaluation.indicadores
    let prevEvaluatedIndicatorsId = prevEvaluatedIndicators.map(item => {
      return item.indicador
    })

    if (prevEvaluatedIndicatorsId.includes(evaluation.indicador)) {
      prevEvaluatedIndicatorsId.forEach(function (item, index) {
        if (item === evaluation.indicador)
          prevEvaluatedIndicators.splice(index, 1, { ...evaluation })
      })
    } else {
      prevEvaluatedIndicators.push({ ...evaluation })
    }

    return prevEvaluatedIndicators
  }

  /**Cierra el modal */
  closeModalAttach = () => {
    this.setState({
      isModalAttachFilesVisible: false,
      selectedIndicator: {}
    }, () => {
      this.loadUsersList()
    })
  }

  /**Cierra el modal */
  closeModalEvaluation = () => {
    this.setState({
      isFormEvaluationValid: false,
      isModalEvaluationVisible: false,
      selectedIndicator: {},
      proposalToEvaluate: {},
      evaluation: {}
    })
  }

  /**Evaluar indicadores de la propuesta.*/
  handleSaveProposalEvaluation = () => {
    let proposal = {};
    const listIndicators = this.state.myProposal.indicadores;

    this.setState({ isEvaluating: true, loadingData: true });

    proposal.saveBorrador = false

    proposal.propuesta = this.state.myProposal.id;
    proposal.detalle = listIndicators.map(ind => {
      return {
        indicador: ind.id,
        nivel_cumplimiento: ind.tipo === 'Turbo' ? 'sobre_meta' : ind.cumplimiento ? ind.cumplimiento : 'sin_evaluacion',
        comentario_autoevaluacion : ind.comentario_autoevaluacion,
        list_id_files: ind.evidencia.length !== 0 ? ind.evidencia.map(evi => evi.id) : [],
      };
    });

    PropuestaAPI.autoEvaluation(proposal).then(res => {
      toast.success('Propuesta autoevaluada con éxito')
      this.setState({
        proposalToEvaluate: {},
        prevAutoEvaluatedIndicators: [],
        isEvaluating: false,
        isEvaluatedProposal: true,
        loadingData: false
      }, () => this.loadProposals())
    }).catch(err => {
      this.setState({ isEvaluating: false, loadingData: false });
      toast.error('No se realizó la autoevaluación de la propuesta')
    })
  }

   /**Evaluar indicadores de la propuesta guardado de borrador.*/
   handleSaveProposalBorrador = () => {
    let proposal = {};
    const listIndicators = this.state.myProposal.indicadores;

    this.setState({ isEvaluating: true, loadingData: true });

    proposal.saveBorrador = true

    proposal.propuesta = this.state.myProposal.id;
    proposal.detalle = listIndicators.map(ind => {
      return {
        indicador: ind.id,
        nivel_cumplimiento: ind.tipo === 'Turbo' ? 'sobre_meta' : ind.cumplimiento ? ind.cumplimiento : 'sin_evaluacion',
        comentario_autoevaluacion : ind.comentario_autoevaluacion,
        list_id_files: ind.evidencia.length !== 0 ? ind.evidencia.map(evi => evi.id) : [],
      };
    });

    PropuestaAPI.autoEvaluation(proposal).then(res => {
      toast.success('Propuesta autoevaluada con éxito')
      this.setState({
        proposalToEvaluate: {},
        prevAutoEvaluatedIndicators: [],
        isEvaluating: false,
        isEvaluatedProposal: false,
        loadingData: false 
      }, () => this.loadProposals())
    }).catch(err => {
      this.setState({ isEvaluating: false, loadingData: false });
      toast.error('No se realizó la autoevaluación de la propuesta')
    })
  }

  /**Guarda la información del indicador para evaluar. */
  handleSaveIndicator = () => {
    const {
      myProposal, selectedIndicator, prevAutoEvaluatedIndicators
    } = this.state
    let prevAutoevaluated = prevAutoEvaluatedIndicators.length !== 0
      ? prevAutoEvaluatedIndicators.map(item => item.id)
      : []
    let myProposalModified = {
      ...myProposal,
      indicadores: myProposal.indicadores.map(item => {
        if (item.id === selectedIndicator.id)
          return {
            ...item,
            isEvaluated: true
          }
        else
          return item
      })
    }

    if (!prevAutoevaluated.includes(selectedIndicator.id)) {
      prevAutoEvaluatedIndicators.push(selectedIndicator)
    } else {
      prevAutoEvaluatedIndicators.forEach(function (item, index) {
        if (item.id === selectedIndicator.id)
          prevAutoEvaluatedIndicators.splice(index, 1, item)
      })
    }

    this.setState({
      isModalAttachFilesVisible: false,
      myProposal: myProposalModified,
      prevAutoEvaluatedIndicators,
      selectedIndicator: {}
    })
  }

  /**
   * Verifica si la propuesta es correcta para enviar
   * a validación.
   * 
   * @return Boolean
  */
  isProposalValid = () => {
    let isValid = true

    const { myProposal } = this.state
    const totalIndicators = myProposal.indicadores;
    const indicators = myProposal.indicadores
      ? myProposal.indicadores.filter(item => {
        if (item.cumplimiento !== 'sin_evaluacion' && item.evidencia.length !== 0)
          return item.id
      })
      : []

    if (indicators.length !== 0 && indicators.length === totalIndicators.length){
      isValid = true 
    }        
    else
      isValid = false

    return isValid
  }

  /**
   * Verifica si la propuesta es correcta para guardar avance
   * a validación.
   * 
   * @return Boolean
  */
   isProposalAvanceValid = () => {
    let isValid = true

    const { myProposal } = this.state
    const totalIndicators = myProposal.indicadores.filter(item =>
      item.tipo === 'Normal'
    );
    const indicators = myProposal.indicadores
      ? myProposal.indicadores.filter(item => {
        if (item.tipo === 'Normal' && (item.cumplimiento !== 'sin_evaluacion' && item.evidencia.length !== 0))
          return item.id
      })
      : []

    if (indicators.length !== 0)
      isValid = true
    else
      isValid = false

    return isValid
  }

  /**Verifica si la evaluación es valida para habilitar o no el botón */
  isEvaluationInvalid = () => {
    const { selectedIndicator } = this.state
    let isInvalid = true
    let checkKeys = []

    checkKeys.push(
      selectedIndicator['id'] !== 0
    )
    checkKeys.push(
      selectedIndicator['id_proposal'] !== 0
    )
    checkKeys.push(
      selectedIndicator['nivel'] !== ''
      && selectedIndicator['nivel'] !== 'sin_evaluacion'
    )
    checkKeys.push(
      selectedIndicator['evidencia'].length !== 0
    )

    isInvalid = checkKeys.filter(item =>
      item === false || item === undefined
    ).length !== 0

    return isInvalid
  }

  /**
   * Maneja los cambios en el nivel de evaluación 
   * 
   * @param {String} value
  */
  handleChangeNivel = value => {
    let detalle = []
    let selected = this.state.selectedIndicator
    let toEvaluate = this.state.proposalToEvaluate
    const idsEvaluated = toEvaluate.detalle ?
      toEvaluate.detalle.map(item => item.indicador)
      : []

    if (toEvaluate.detalle) {
      if (idsEvaluated.includes(selected.id)) {
        detalle = toEvaluate.detalle.map(item => {
          if (item.indicador === selected.id)
            item.nivel_cumplimiento = value

          return item
        })
      } else {
        detalle = toEvaluate.detalle
        detalle.push({
          indicador: selected.id,
          nivel_cumplimiento: value,
          list_id_files: []
        })
      }
    } else {
      detalle = [{
        indicador: selected.id,
        nivel_cumplimiento: value,
        list_id_files: []
      }]
    }

    let proposal = {
      ...toEvaluate,
      detalle
    }

    selected.nivel = value

    let newIndicadores = [...this.state.myProposal.indicadores.map(ind => {
      let copy = { ...ind };
      if (ind.id === this.state.selectedIndicator.id) {
        copy = { ...copy, cumplimiento: value };
      }
      return copy;
    })];

    this.setState({
      selectedIndicator: { ...selected },
      proposalToEvaluate: {
        ...proposal
      },
      myProposal: {
        ...this.state.myProposal,
        indicadores: newIndicadores
      }
    })
  }

  /**
   * Maneja la carga de archivos en el formulario de autoevaluación 
   * 
   * @param {Array} listFiles
  */
  handleLoadFiles = listFiles => {
    let indicator = this.state.selectedIndicator

    this.setState({
      selectedIndicator: {
        ...indicator,
        files: listFiles
      }
    })
  }

  /**
   * Actualiza la informacion de la propuesta a evaluar,
   * con una lista de id de los archivos cargados.
   * 
   * @param {Object} proposal
   */
  onUploadedFiles = (proposal = {}, newFile) => {
    Promise.all([
      this.loadProposals()
    ]).then(() => {
      let proposalToEvaluate = {
        ...this.state.proposalToEvaluate
      }
      let detalle = []
      let idAutoevaluacion = this.state.proposalToEvaluate.detalle ?
        this.state.proposalToEvaluate.detalle.map(item => item.indicador)
        : []

      proposalToEvaluate.propuesta = this.state.myProposal.id

      if (idAutoevaluacion.length === 0) {
        detalle = [{
          ...proposal
        }]
      }

      if (idAutoevaluacion.includes(this.state.selectedIndicator.id)) {
        detalle = proposalToEvaluate.detalle.map(item => {
          if (item.indicador === this.state.selectedIndicator.id) {
            item = {
              ...item,
              ...proposal
            }
          }

          return item
        })
      } else {
        detalle = proposalToEvaluate.detalle || []
        detalle.push(proposal)
      }

      proposalToEvaluate.detalle = detalle

      this.setState({
        proposalToEvaluate,
        myProposal: { ...proposalToEvaluate },
        selectedIndicator: {
          ...this.state.selectedIndicator,
          evidencia: [...this.state.selectedIndicator.evidencia, { ...newFile }]
        }
      })
    })
  }

  /**
   * Verifica si la propuesta ya se autoevaluo 
   * 
   * @returns Boolean
  */
  isMyProposalEvaluated = () => {
    const { openConvocatoria } = this.state
    let isProposalEvaluated = false
    if (!openConvocatoria) {
      isProposalEvaluated = true
    } else {
      isProposalEvaluated = this.state.myProposal.indicadores.filter(item =>
        item.cumplimiento === 'sin_evaluacion'
      ).length === 0 && !(this.state.myProposal.estatus_evaluacion === 'Sin Evaluacion' || this.state.myProposal.estatus_evaluacion === 'Autoevaluacion en proceso')
    }

    return isProposalEvaluated
  }

  /**
   * Asigna los valores del form para despues ser evaluados.
   * 
   * @param {Object} formData
   */
  validateEvaluationForm = (formData) => {
    this.setState({
      evaluation: { ...formData }
    }, () => this.validateEvaluation())
  }

  /** Valida los datos recibidos del formulario de evaluación. */
  validateEvaluation = () => {
    const { evaluation, selectedIndicator } = this.state

    let invalidData = []
    let isFormValid = false

    if (evaluation['comentario'] !== '') {
      invalidData.push(true)
    }

    if (evaluation['nivel_cumplimiento'] !== '') {
      invalidData.push(true)
    }

    if (evaluation['status'] !== '') {
      invalidData.push(true)
    }

    isFormValid = invalidData.filter(item => item === true).length === 3  

    this.setState({
      isFormEvaluationValid: isFormValid
    })
  }

  /** Actualiza la lista de archivos de evidencia. */
  handleUpdateListEvidences = () => {
    const idIndicator = this.state.selectedIndicator.id || undefined
    this.loadProposals()

    if (idIndicator !== undefined) {
      this.setSelectedIndicator(idIndicator)
    }
  }

  /**
   * Maneja los cambios de indicador al seleccionar las flechas del modal.
   * En caso de haber cambios no guardados muestra modal de confirmación.
   * 
   * @param {Boolean} moveToLeft
   */
  handleChangeSelectedIndicator = (moveToLeft = false) => {
    const { isFormEvaluationValid } = this.state

    if (isFormEvaluationValid) {
      this.confirmChangeIndicator()
    } else {
      this.changeSelectedInficator(moveToLeft, true)
    }
  }

  /** Cambia el indicador actual por el siguiente. */
  changeSelectedInficator = (moveToLeft = false, move = false) => {
    const {
      selectedIndicator, proposals,
      currentProposalEvaluation
    } = this.state
    let indicators = []
    let indicatorsNext = []
    let nextIndicatorSelected
    let currentEvaluation = currentProposalEvaluation.filter(proposalEvaluated => proposalEvaluated.id == selectedIndicator.idProposalSelectedIndicator)[0]

    proposals.forEach(function (proposal) {
      if (currentEvaluation.id === proposal.id) {
        proposal.indicadores.forEach(function (indicator) {
          if(move){
            indicators.push(indicator)
          }
          else{
            if(!indicator.isEvaluated){
              indicators.push(indicator)
            }
          }
          
        })
      }
    })


    if(indicators.length > 1){
      nextIndicatorSelected = indicators.map((item, index) => {
        if (item.id === selectedIndicator.id) {
          if (!moveToLeft) {
            if (indicators[index + 1])
              return indicators[index + 1].id
            else
              return indicators[0].id
          } else {
            if (indicators[index - 1])
              return indicators[index - 1].id
            else
              return indicators[indicators.length - 1].id
          }
        }
      }).filter(item => item !== undefined)[0]
  
      this.setSelectedIndicator(nextIndicatorSelected)

      return true
    }
    else{
      return false
    }    
  }

  /** Muestra modal de confirmación para cambiar indicador con cambios. */
  confirmChangeIndicator = () => {
    this.setState({
      showConfirmChangeSelectedIndicator: true
    })
  }

  /** Muestra modal de confirmación para cambiar indicador con cambios. */
  confirmEvaluateProposal = (currentEvaluation, save = false) => {
    const isAdmin = decode(localStorage.getItem('token')).role === 'Administrador'
    this.setState({
      showConfirmIndicatorsEvaluated: false,
      validateNextIndicator: false
    })
    if (
      isAdmin &&
      save &&
      currentEvaluation['isEvaluationCompleted'] &&
      this.setProposalStatus(currentEvaluation['indicadores'])
    ) {
      this.setState({
        showConfirmEvaluateProposal: true,
        currentProposalEvaluationToSave: currentEvaluation
      })
    }
    else if (
      save &&
      currentEvaluation['isEvaluationCompleted'] &&
      this.setProposalStatus(currentEvaluation['indicadores'])
    ) {
      this.evaluateProposal(currentEvaluation, true)
    }  else {
      this.evaluateProposal(currentEvaluation)
    }
  }

  /** Acciones extras para tabla de indicadores. */
  handleChangeIndicators = (selectedIndicator) => {
    return <div>
      <Button
        icon="fa-arrow-left"
        text="Anterior"
        type="secondary"
        title="Indicador Anterior"
        onClick={() => {
          let moveToLeft = true
          this.handleChangeSelectedIndicator(moveToLeft)
        }}
      />
      <Button
        icon="fa-arrow-right"
        iconRight={true}
        text="Siguiente"
        type="secondary"
        title="Siguiente Indicador"
        onClick={() => {
          let moveToLeft = false
          this.handleChangeSelectedIndicator(moveToLeft)
        }}
      />
    </div>
  }

  /** Manda la información con la validación del indicador. */
  validateIndicatorAutoevaluated = (currentIndicator) => {
    const {
      proposals, evaluation, currentProposalEvaluation,
      validateNextIndicator
    } = this.state
    let currentProposalEvaluationIndicators
    let modalVisible = false

    

    proposals.find(item => item.id === currentIndicator.propuesta).indicadores.find(item => item.id === currentIndicator.id).comentario_evaluacion = currentIndicator.comentario_evaluacion
    proposals.find(item => item.id === currentIndicator.propuesta).indicadores.find(item => item.id === currentIndicator.id).comentario_autoevaluacion = currentIndicator.comentario_autoevaluacion
    proposals.find(item => item.id === currentIndicator.propuesta).indicadores.find(item => item.id === currentIndicator.id).cumplimiento = currentIndicator.cumplimiento

    var currentEvaluation = currentProposalEvaluation.find(proposalEvaluated => proposalEvaluated.id == currentIndicator.idProposalSelectedIndicator)

    currentProposalEvaluationIndicators = currentEvaluation.indicadores
      ? currentEvaluation.indicadores
      : []

    // TO DO:
    // * Validar indicadores previamente guardados
    if (currentProposalEvaluationIndicators.length === 0) {
      currentProposalEvaluationIndicators.push({ ...evaluation })
    } else {
      currentProposalEvaluationIndicators = this.checkPreEvaluatedIndicators(evaluation, currentEvaluation)
    }

    // Verifica si el check para validar el siguiente
    // indicador esta seleccionado
    if (validateNextIndicator) {   
      modalVisible = this.changeSelectedInficator()
    } else {
      this.setState({ selectedIndicator: {} })
    }

    currentProposalEvaluation.filter(proposalEvaluated => proposalEvaluated.id == currentIndicator.idProposalSelectedIndicator)[0].indicadores = currentProposalEvaluationIndicators

    this.setState({
      evaluation: {},
      isModalEvaluationVisible: modalVisible,
      isFormEvaluationValid: false,
      currentProposalEvaluation: currentProposalEvaluation,
      proposals: proposals
    }, function () {
      this.checkCurrentProposal(currentEvaluation.id)
      this.setValidatedIndicator(currentEvaluation)
    })

  }

  /** Cambia el estatus de los indicadores a evaluado. */
  setValidatedIndicator = (currentEvaluation) => {
    const { proposals } = this.state
    let proposalsModified = []

    proposalsModified = proposals.map(proposal => {
      let copyProposal = { ...proposal }
      if (proposal.id === currentEvaluation.id) {
        copyProposal = {
          ...copyProposal,
          indicadores: proposal.indicadores.map(item => {
            let idsIndicators = currentEvaluation.indicadores.map(item => item.indicador)
            let copyIndicator = { ...item }
            if (idsIndicators.includes(copyIndicator.id)) {
              copyIndicator = {
                ...copyIndicator,
                isEvaluated: true
              }
            }

            return copyIndicator
          })
        }
      }

      return copyProposal
    })

    this.setState({
      proposals: proposalsModified
    })
  }

  /** Maneja la confirmación del cambio de indicador seleccionado
   * en el modal de validación.
   */
  handleConfirmChangeSelectedIndicator = () => {
    this.changeSelectedInficator()
    this.setState({
      showConfirmChangeSelectedIndicator: false,
      evaluation: {},
      isFormEvaluationValid: false
    })
  }

  /**
   * Verifica si la propesta se rechaza o valida.
   * 
   * @param {Array} indicators
   * @returns isProposalValid
   */
  setProposalStatus = (indicators = []) => {
    let isProposalValid = false
    let rejectedIndicators = indicators.filter(item => item.tipo === 'Normal' && item.status === 'Rechazado')

    isProposalValid = rejectedIndicators.length === 0

    return isProposalValid
  }

  /** 
   * Manda la evlauación de la propuesta a guardar en el sistema. 
   * 
   * @param {Boolean} saveProposal
  */
  evaluateProposal = (currentProposal ,saveProposal = false, completeProposal = false) => {
    const isAdmin = decode(localStorage.getItem('token')).role === 'Administrador'
    const {
      porcentajeCompensacion, proposals, proposalSelected,
    } = this.state
    let PropSelected = proposalSelected
    let currentEvaluation = {}
    let isValid = this.setProposalStatus(currentProposal['indicadores']) || false

    let compensaciones = []
    if(PropSelected && Object.keys(PropSelected).length === 0 && PropSelected.constructor === Object){
      PropSelected = proposals.filter(item => item.id === currentProposal.id)[0] || {}
    }
    if (isAdmin && saveProposal) {
      let normalIndicators = PropSelected.indicadores.filter(item => item.tipo === 'Normal')
      let turboIndicators = PropSelected.indicadores.filter(item => item.tipo === 'Turbo')

      normalIndicators.forEach(item => {
        item.compensacion = []
        let obj = calcularCompensacion(item, PropSelected.usuario.usuario)
        compensaciones.push({
          id_indicador: item.id,
          SM: obj.SM,
          M: obj.M,
          BM: obj.BM
        })
        item.compensacion.push({
          bajo_meta: obj.BM.toString(),
          id: 0,
          inaceptable: "0.00",
          indicador: item.id,
          meta:  obj.M.toString(),
          sobre_meta: obj.SM.toString()
        })
      })
    }

    let avance = !saveProposal

    if(isAdmin && !completeProposal && saveProposal)      
      avance = saveProposal

    let evaluation = {
      save: saveProposal,
      avance : avance,
      propuesta: currentProposal['id'],
      valido: isValid,
      detalle: currentProposal['indicadores'].map(indicator => {
        let compensacion = { "id_indicador": 0, "SM": 0, "M": 0, "BM": 0 }
        if (saveProposal) {
          let result = compensaciones.filter(item => item.id_indicador == indicator.indicador)
          if (result.length > 0) {
            compensacion = result[0]
          }
        }


        return {
          comentario: indicator.comentario,
          comentario_autoevaluacion: indicator.comentario_autoevaluacion,
          indicador: indicator.indicador,
          nivel_cumplimiento: indicator.nivel_cumplimiento,
          evaluado: indicator.status === 'Autorizado' ? true : false,
          compensaciones: compensacion
        }
      }),
      porcentaje: porcentajeCompensacion
    }

    if (saveProposal === true) {
      currentEvaluation = {}
    } else {
      currentEvaluation = currentProposal
    }

    PropuestaAPI.evaluateProposal(evaluation)
      .then(res => {
        // const proposalSelected = proposals.filter(item => item.id === res.data.id)[0] || {}
        const proposalSelected = PropSelected
        this.loadProposals()
        if(evaluation.avance && !saveProposal){
          toast.success('Avance guardada con éxito')
        }
        else{

          if (saveProposal === true && !isAdmin && !isValid) {
            toast.success('Evaluación guardada con éxito')
          }
          else if(saveProposal === true && isAdmin && completeProposal == false){
            this.setState({
              calcular_compensacion:true,
              isModalReportCompVisible: saveProposal && isValid,
              isEvaluationCompleted: false,
              checkAutorizado: false,
              checkRechazado: false,
              reportSelected: res.data,
              proposalSelected,
              showConfirmEvaluateProposal: saveProposal === false && isValid
            })
          }
          else if(saveProposal === true && isAdmin && completeProposal === true){
            this.setState({
              calcular_compensacion:true,
              isModalReportCompVisible: false,
              isEvaluationCompleted: false,
              checkAutorizado: false,
              checkRechazado: false,
              proposalSelected,
              showConfirmEvaluateProposal: false
            })
            toast.success('Evaluación guardada con éxito')
          }
        }
      })
      .catch((err) => {
        if (saveProposal === true) {
          toast.error('No se a podido guardar la evaluación.')
        } else {
          toast.error('No se a pudo cálcular la compensación.')
        }
      })
  }

  /**
   *  Verifica y clasifica las propuestas. 
   * 
   * @param {Array} proposals
   * @returns Array
  */
  handleVerifyProposals = (proposals) => {
    const { users, myProposal } = this.state
    const userToken = decode(localStorage.getItem('token'))
    let userInfo = users.filter(user =>
      user.usuario.id_auth === Number(userToken.uid)
    )[0] || {}
    const userRol = userInfo.grupo
    const isAdmin = userToken.role === 'Administrador'
    const isAreaDirector = userRol === USER_ROL.area_director
    let proposalsVerified = []

    proposals.forEach(function (proposal) {
      const isDirectBoss = proposal.usuario.id_jefe_directo === (
        userInfo.usuario ? userInfo.usuario.id_auth : 0
      )
      if (proposal.id !== myProposal.id) {
        if (isAdmin) {
          // Se asigna a la propuesta el rol de Administrador
          proposalsVerified.push({
            ...proposal,
            user_rol: 'Administrador'
          })
        } else if (isAreaDirector) {
          // Se asigna a la propuesta el rol de Director de Área
          proposalsVerified.push({
            ...proposal,
            user_rol: 'Director Area'
          })
        } else if (isDirectBoss) {
          // Se asigna a la propuesta el rol de Jefe Directo
          proposalsVerified.push({
            ...proposal,
            user_rol: 'Jefe Directo'
          })
        }
      }
    })

    return proposalsVerified
  }

  /**
   * Manejo del cambio del porcentaje de compensación.
   * 
   * @param {Event} target
   */
  handleChangePorcentaje = ({ target }) => {
    const valueProcentaje = parseInt(target.value, 10)

    if (valueProcentaje <= 100 && valueProcentaje > 0) {
      this.setState({
        porcentajeCompensacion: valueProcentaje,
        porcentajeCompensacionError: {
          isInvalid: false,
          message: ''
        }
      })
    } else {
      this.setState({
        porcentajeCompensacion: valueProcentaje,
        porcentajeCompensacionError: {
          isInvalid: true,
          message: 'El valor del pocentaje debe ser un número entre 1 y 100'
        }
      })
    }
  }

  /** Cierra el modal de confirmacion de porcentaje de compensación */
  closeConfirmPocentaje = () => {
    this.setState({
      showConfirmEvaluateProposal: false,
      porcentajeCompensacionError: {
        isInvalid: false,
        message: ''
      }
    })
  }

  /**
   * Checkbox para mantener modal abierto al guardar evaluación del indicador.
   */
  setCheckToValidateNextIndicator = () => {
    return (
      <label className="CheckModalValidation">
        <input
          type="checkbox"
          checked={this.state.validateNextIndicator}
          onChange={() =>
            this.setState({
              validateNextIndicator: !this.state.validateNextIndicator
            })
          }
        />
        Mantener ventana abierta al guardar
      </label>
    )
  }

  /** 
   * Modal de confirmación cuando los indicadores de una propuesta
   * estan evaluatdos en totalidad.
   */
  handleConfirmIndicatorsEvaluated = () => {
    this.setState({
      showConfirmIndicatorsEvaluated: false,
      isModalEvaluationVisible: false
    })
  }

  /** 
   * Confirma el cambio de propuesta al seleccionar un indicador que
   * no pertenece a la propuesta evaluada actualmente.
   */
  handleConfirmChangeProposal = () => {
    this.setPrevEvaluatedIndicatorsToDefault()
    this.setState({
      showConfirmChangeProposal: false,
      isEvaluationCompleted: false,
      currentProposalEvaluation: {}
    }, () => this.setSelectedIndicator(this.state.selectedIndicator.id))
  }

  /** 
   * Cancela el cambio de propuesta al seleccionar un indicador que
   * no pertenece a la propuesta evaluada actualmente.
   */
  handleCancelChangeProposal = () => {
    this.setState({
      showConfirmChangeProposal: false,
      selectedIndicator: {}
    })
  }

  /**
   * Maneja los cambios en el dropdown de `Periodo`
   * 
   * @param {Event}
   */
  handleChangePeriod = ({ target }) => {
    const { currentPeriod } = this.state
    const period = Number(target.value) === 0
      ? currentPeriod
      : Number(target.value)

    this.loadPeriodInfo(period)

    this.setState({
      selectedPeriod: period,
      myNominalReport: {},
      myProposal: {},
      proposalEvaluated: null,
      proposalToEvaluate: {},
      proposals: [],
      selectedIndicator: {},
      loadingData: true
    }, () => this.loadProposals())
  }

  /**
   * Asigna la información del periodo seleccionado
   * 
   * @param {Number} periodId
   */
  loadPeriodInfo = (periodId) => {
    ConvocatoriaAPI.get(periodId)
      .then(res => {
        this.setState({
          openConvocatoria: this.isConvocatoryOpen(res.data),
          convocatory: res.data
        })
      })
      .catch(err => toast.error('Ocurrió un error con la convocatoria seleccionada.'))
  }

  /**
   * Obtiene el periodo seleccionado del Dropdown 
   * 
   * @param {Number} selectedPeriod
  */
  getPeriodSelected = (selectedPeriod) => {
    const periods = this.state.listPeriods
    let period = ''
    period = periods.filter(item => item.id === selectedPeriod)

    if (period[0]) {
      return period[0].text
    } else {
      return 'Seleccionar periodo...'
    }
  }

  /** 
   * Asigna los indicadores previamente evaluados a no evaluados en
   * caso de cambiar de propuesta a evaluar.
   */
  setPrevEvaluatedIndicatorsToDefault = () => {
    const { currentProposalEvaluation, proposals } = this.state
    let listProposals = proposals.map(item => {
      if (item.id === currentProposalEvaluation.id) {
        let indicators = item.indicadores.map(indicator => {
          return {
            ...indicator,
            isEvaluated: false
          }
        })
        return {
          ...item,
          indicadores: indicators
        }
      } else {
        return item
      }
    })

    this.setState({ proposals: listProposals })
  }

  /** Carga de periodos registrados en la aplicación */
  loadPeriods = () => {
    ConvocatoriaAPI.getAll()
      .then(res =>
        this.setState({
          listPeriods: res.data
        }, () => this.getCurrentPeriod())
      )
      .catch(() => toast.error('Periodos no cargados'))
  }

  /** Obtiene el periodo en curso */
  getCurrentPeriod() {
    ConvocatoriaAPI.getCurrent()
      .then(res =>
        this.setState({
          openConvocatoria: this.isConvocatoryOpen(res.data.convocatoria),
          currentPeriod: res.data.convocatoria.id,
          selectedPeriod: res.data.convocatoria.id,
          convocatory: res.data.convocatoria
        }, () => this.loadUsersList())
      ).catch(err => this.loadUsersList())
  }

  /**
   * Verifica si la convocatoria está abierta para inicira la autoevaluación.
   * 
   * @param {Object} convocatory
   * @returns isOpen
   */
  isConvocatoryOpen = (convocatory = {}) => {
    let currentDate = moment(new Date())
    let convocatoryStartDate = moment(convocatory.fecha_inicio_evaluacion).subtract(1, 'day')
    let convocatoryEndDate = moment(convocatory.fecha_fin_evaluacion).add(1, 'day')
    let result = (currentDate >= convocatoryStartDate) && (currentDate <= convocatoryEndDate)
    return result
  }

  /**
   * Asigna a la propuesta seleccionada para visualizar su reporte
   * de nómina.
   * 
   * @param {Number} idProposal
   */
  handleOpenNominalReport = (idProposal) => {
    const { listNominalReports, proposals } = this.state
    const proposalSelected = proposals.filter(item => item.id === idProposal)[0] || {}
    const reportSelected = listNominalReports.filter(item => item.id === idProposal)[0] || {}

    if (reportSelected.id !== undefined) {
      this.setState({
        calcular_compensacion:false,
        isModalReportCompVisible: true,
        idReportCompSelected: idProposal,
        proposalSelected,
        reportSelected
      })
    }
  }

  /**
   * Abre el reporte de nómina de mi propuesta.
   * 
   * @param {Number} idProposal
   */
  handleOpenMyNominalReport = (idProposal) => {
    const { myNominalReport, myProposal } = this.state
    this.setState({
      calcular_compensacion:false,
      isModalReportCompVisible: true,
      idReportCompSelected: idProposal,
      proposalSelected: myProposal,
      reportSelected: myNominalReport
    })
  }

  /**Cierra el modal de reporte de nómina. */
  handleCloseNominalReport = () => {

    this.setState({
      calcular_compensacion:false,
      isModalReportCompVisible: false,
      showConfirmIndicatorsEvaluated: false,
      idReportCompSelected: null,
      proposalSelected: {},
      reportSelected: {}
    })
  }

  /**
   * Obtiene el nombre del usuario al que pertenece la propuesta
   * que está siendo evaluada.
   * 
   * @returns {String} `ownerProposal`
   */
  getCurrentProposalEvaluation = () => {
    const { proposals, currentProposalEvaluation } = this.state
    let currentProposal = proposals.filter(item =>
      item.id === currentProposalEvaluation.id
    )[0] || {}
    let ownerProposal = ` ${currentProposal.usuario.usuario.nombre}`
    return ownerProposal
  }

  /**
   * Modifica el indicador seleccionado y le asigna si tiene o no evidencias.
   * @param { Boolean } hasEvidence - Tiene evidencias el indicador.
   */
  setHasEvidence(hasEvidence) {
    let { selectedIndicator } = this.state;

    this.setState({
      selectedIndicator: { ...selectedIndicator, hasEvidence }
    });
  }

  /**
   * Borra las evidencias de la lista del indicador.
   * @param { Object } indicator 
   * @param { Array } evidences 
   */
  onDeleteItem(evidences) {
    let newSelected = {
      ...this.state.selectedIndicator,
      evidencia: [...evidences]
    };
    this.setState({ selectedIndicator: newSelected });
  }

  /**
   * Reemplaza la propuesta seleccionada con la información actualizada.
   * @param { Object } indicatorUpdated - Indicador acutalizado.
   */
  handleUpdateProposal = (indicatorUpdated) => {
    const proposalUpdated = { ...this.state.myProposal };

    proposalUpdated.indicadores = proposalUpdated.indicadores.map(ind => {
      let copyInd = { ...ind };
      if (ind.id === indicatorUpdated.id) {
        copyInd = { ...indicatorUpdated };
      }
      return copyInd;
    });

    this.setState({
      myProposal: proposalUpdated,
      selectedIndicator: indicatorUpdated,
    })
  }

  /**
 * Valida si la propuesta está en un estatus en el cual
 * el ususario puede hacer la validación de los indicadores.
 * 
 * @param {String} proposalStatus
 * @return {Boolean} `canValidate`
 */
isDisabledForMyUser = (proposalStatus) => {
  
  let openConvocatory = this.state.openConvocatoria
  const TOKEN = decode(localStorage.getItem('token'))
  let isDisabled = true

  if (openConvocatory) {
    if ((Array.isArray(TOKEN.role) ? TOKEN.role.includes('Administrador') : TOKEN.role === 'Administrador') && proposalStatus.estatus_evaluacion !== 'Sin Evaluacion') {
        // Si somos Admin y la propuesta ya fue autoevaluada podemos hacer la evaluación.
        isDisabled = false
    } else if ((Array.isArray(TOKEN.role) ? TOKEN.role.includes('Jefe Directo') : TOKEN.role  === 'Jefe Directo') && proposalStatus.estatus_evaluacion  === 'Evaluado' 
      || proposalStatus.estatus_evaluacion  === 'Evaluacion en proceso') {
        // Si somos jefe directo del usuario dueño de la propuesta y solo si
        // la propuesta ya fue autoevaluada podemos evaluarla.
        isDisabled = false
    } else if (
        (Array.isArray(TOKEN.role) ? TOKEN.role.includes('Director Area') : TOKEN.role  === 'Director Area') &&
        (proposalStatus.estatus_evaluacion  === 'Evaluacion Aceptada Jefe' || proposalStatus.estatus_evaluacion  === 'Evaluado'|| proposalStatus.estatus_evaluacion  === 'Evaluacion en proceso')
    ) {
        // Si somos directores de área y la propuesta ya fue autoevaluada o evaluada por
        // el jefe directo del dueño de la propuesta, podemos evaluarla nosotros.
        isDisabled = false
    }
      else {
          isDisabled = true
      }
  } else {
      isDisabled = true
  }

  return isDisabled
}

  componentDidMount() {
    this.loadPeriods()
  }

  render() {
    const {
      proposals, users, myProposal, loadingData,
      isEvaluating, isModalAttachFilesVisible,
      selectedIndicator, isModalEvaluationVisible,
      isFormEvaluationValid, showConfirmChangeSelectedIndicator,
      evaluation, isEvaluationCompleted, proposalEvaluated,
      porcentajeCompensacion, showConfirmEvaluateProposal,
      porcentajeCompensacionError, currentProposalEvaluation,
      isModalReportCompVisible, proposalSelected,
      showConfirmIndicatorsEvaluated, validateNextIndicator,
      showConfirmChangeProposal, currentPeriod, listPeriods,
      selectedPeriod, openConvocatoria, convocatory,
      reportSelected, myNominalReport, isModalMyReportCompVisible,
      calcular_compensacion, currentProposalEvaluationToSave,
      isTurboEvaluating
    } = this.state

    return (
      <Fragment>
        <Card title="Evaluación de objetivos">
          <Wrapper label="Periodo">
            <Select
              classes="input"
              name="periodo"
              value={selectedPeriod !== 0 ? selectedPeriod : currentPeriod}
              change={this.handleChangePeriod}
              items={listPeriods.sort(function (a, b) {
                return a.id - b.id
              })}
              disabled={listPeriods.length === 0}
              optionProps={{ value: "id", text: "nombre" }}
            />
          </Wrapper>
          {
            openConvocatoria
              ? <div className="ContainerMessageConvocatory">
                <span>El periodo de autoevaluación está abierto de</span>
                <span className="DateConvocatory">
                  {
                    moment(convocatory.fecha_inicio_evaluacion).format('LL')
                  }
                </span>
                <span>a</span>
                <span className="DateConvocatory">
                  {
                    moment(convocatory.fecha_fin_evaluacion).format('LL')
                  }
                </span>
              </div>
              : <div className="ContainerMessageConvocatory">
                <span>El periodo de autoevaluación está cerrado</span>
              </div>
          }
        </Card>
        {
          myProposal.id &&
            myProposal.length !== 0 ?
            <PropuestaWrapper
              noEmpleado={
                myProposal.usuario ? myProposal.usuario.usuario.id_empleado : ''
              }
              nombre={
                myProposal.usuario ? myProposal.usuario.usuario.nombre : ''
              }
            >
              <div className="mb-10 Align">
                <Button
                  icon="fa-file"
                  text="Ver reporte de compensación"
                  title="Ver el reporte de compensación de la propuesta"
                  type="secondary"
                  onClick={() => this.setState({ isModalMyReportCompVisible: true })}
                  disabled={myProposal.estatus_evaluacion !== 'Evaluacion Aceptada'}
                />
                <Button
                  text={`${isEvaluating ? "Quitar Autoevaluación" : "Autoevaluar"}`}
                  icon={`${isEvaluating ? "fa-close" : "fa-check"}`}
                  type={`${isEvaluating ? "is-danger" : "is-success"}`}
                  onClick={() => this.setState({ isEvaluating: !isEvaluating })}
                  disabled={this.state.isEvaluatedProposal || this.isMyProposalEvaluated()}
                  title={
                    this.isMyProposalEvaluated()
                      ? "La propuesta ya fue evaluada"
                      : "Abrir campos para evaluar propuesta"
                  }
                />
              </div>
              <IndicadorTabla
                indicadores={myProposal.indicadores ?
                  myProposal.indicadores.filter(indicator =>
                    indicator.tipo === 'Normal'
                  ).sort((a, b) => a.id - b.id)
                  : []
                }
                estatusEvaluacion={myProposal.estatus_evaluacion}
                isNormal={true}
                usuarios={users}
                autoEvaluacion={isEvaluating ? true : false}
                showModal={id => this.showModalAttachFiles(id)}
              />
              {
                myProposal.indicadores.filter(indicator =>
                  indicator.tipo === 'Turbo'
                ).length !== 0 &&
                <Fragment>
                  <span style={{ fontWeight: 600, fontSize: 18 }}>Indicadores turbo:</span>
                  <IndicadorTabla
                    indicadores={myProposal.indicadores ?
                      myProposal.indicadores.filter(indicator =>
                        indicator.tipo === 'Turbo'
                      ).sort((a, b) => a.id - b.id)
                      : []
                    }
                    estatusEvaluacion={myProposal.estatus_evaluacion}
                    usuarios={users}
                    isNormal={false}
                    isTurbo= {true}
                    autoEvaluacion={isEvaluating ? true : false}
                    showModal={id => {
                        this.setState({ isTurboEvaluating: true })
                        this.showModalAttachFiles(id)
                      }
                    }
                  />
                </Fragment>
              }

              {
                isEvaluating &&
                <div className="mb-10 Align">
                  <Button
                    text="Guardar borrador"
                    icon="fa-save"
                    type="is-warning"
                    disabled={!this.isProposalAvanceValid()}
                    onClick={this.handleSaveProposalBorrador}
                  />
                  <Button
                    text="Guardar y notificar evaluación"
                    icon="fa-save"
                    type="is-success"
                    disabled={!this.isProposalValid()}
                    onClick={this.handleSaveProposalEvaluation}
                  />
                </div>
              }
            </PropuestaWrapper>
            : !loadingData
              ? <p className="MyProposalEmpty">Su usuario no cuenta con una propuesta elaborada en este periodo</p>
              : ''
        }
        {
          !loadingData &&
          <Card>
            <h2 className="TitleSubProposals">Propuestas de empleados a cargo</h2>
          </Card>
        }
        {
          proposals.length !== 0 ?
            proposals.map(proposal => {
              return <PropuestaWrapper key={proposal.id}
                noEmpleado={
                  proposal.usuario ? proposal.usuario.usuario.id_empleado : 0
                }
                nombre={
                  proposal.usuario ? proposal.usuario.usuario.nombre : ''
                }
                evaluacionEstatus={proposal.estatus_evaluacion}
              >
                {
                  proposal.estatus_evaluacion == 'Evaluacion Aceptada' &&
                  <div className="mb-10 Align">
                    <Button
                      icon="fa-eye"
                      text="Compensación de la propuesta"
                      title="Ver el reporte de compensación de la propuesta"
                      type="secondary"
                      onClick={() => this.handleOpenNominalReport(proposal.id)}
                      disabled={!proposal.estatus_evaluacion.includes('Evaluacion Aceptada')}
                    />
                  </div>
                }
                <IndicadorTabla
                  indicadores={proposal.indicadores.filter(indicator =>
                    indicator.tipo === 'Normal'
                  ).sort((a, b) => a.id - b.id)
                  }
                  isNormal={true}
                  usuarios={users}
                  evaluacion={true}
                  showModal={id => this.showModalEvaluation(id)}
                  openConvocatoria={openConvocatoria}
                  estatusEvaluacion={proposal.estatus_evaluacion}
                  userRol={proposal.user_rol}
                />
                {
                  proposal.indicadores.filter(indicator =>
                    indicator.tipo === 'Turbo'
                  ).length !== 0 &&
                  <Fragment>
                    <span style={{ fontWeight: 600, fontSize: 18 }}>Indicadores turbo:</span>
                    <IndicadorTabla
                      indicadores={proposal.indicadores.filter(indicator =>
                        indicator.tipo === 'Turbo'
                      ).sort((a, b) => a.id - b.id)
                      }
                      isTurbo= {true}
                      usuarios={users}
                      evaluacion={true}
                      isNormal={false}
                      showModal={id => this.showModalEvaluation(id)}
                      openConvocatoria={openConvocatoria}
                      estatusEvaluacion={proposal.estatus_evaluacion}
                      userRol={proposal.user_rol}
                    />
                  </Fragment>
                }
                {
                  (currentProposalEvaluation.find(proposalEvaluated => proposalEvaluated.id == proposal.id)
                  || decode(localStorage.getItem('token')).role === 'Administrador') &&
                  openConvocatoria &&
                  !this.isDisabledForMyUser(proposal) &&
                  < Button
                    text="Guardar avance"
                    onClick={() => this.confirmEvaluateProposal(
                        currentProposalEvaluation.filter(proposalEvaluated => proposalEvaluated.id == proposal.id)[0]
                      )}
                    icon="fa-save"
                    type="is-warning"
                    title="Guardar avance"
                  />
                }
                {
                  ((currentProposalEvaluation.find(proposalEvaluated => proposalEvaluated.id == proposal.id) &&
                  currentProposalEvaluation.find(proposalEvaluated => proposalEvaluated.id == proposal.id).isEvaluationCompleted)
                  || decode(localStorage.getItem('token')).role === 'Administrador') &&
                  openConvocatoria &&
                  !this.isDisabledForMyUser(proposal) &&
                  < Button
                    text="Terminar revisión"
                    onClick={() => this.confirmEvaluateProposal(currentProposalEvaluation.find(proposalEvaluated => proposalEvaluated.id == proposal.id),
                      true
                      )}
                    icon="fa-save"
                    title="Guardar la evaluación de la propuesta"
                    //disabled={this.isDisabledForMyUser()}
                  />
                }
              </PropuestaWrapper>
            })
            : !loadingData
              ? <p className="SubProposalsEmpty">No hay propuestas para evaluar en este periodo</p>
              : ''
        }
        {
          isModalAttachFilesVisible &&
          <FormAutoevaluacion
            // onDeleteItem={ (listEvidences ) => this.onDeleteItem( listEvidences ) }
            proposalInfo={myProposal}
            indicator={selectedIndicator}
            isTurbo = {isTurboEvaluating}
            onClose={() => this.setState({ 
              isModalAttachFilesVisible: false,
              isTurboEvaluating: false 
            })}
            onInfoUpdated={indicatorUpdated => this.handleUpdateProposal(indicatorUpdated)}
          // onChangeEvidence={ hasEvidence => this.setHasEvidence( hasEvidence ) }
          // onChangeNivel={e => this.handleChangeNivel(e.target.value)}
          // onFilesLoaded={listFiles => this.handleLoadFiles(listFiles)}
          // onFilesUploaded={(proposal, newFile) => this.onUploadedFiles(proposal, newFile)}
          />
        }
        {
          isModalEvaluationVisible &&
          <Modal
            title="Validar indicador"
            isVisible={isModalEvaluationVisible}
            cancelText="Cancelar"
            onClose={this.closeModalEvaluation}
            confirmText="Guardar validación"
            onConfirm={() => this.validateIndicatorAutoevaluated(selectedIndicator)}
            onConfirmDisable={!isFormEvaluationValid }
            Actions={() => this.handleChangeIndicators(selectedIndicator)}
            Footer={this.setCheckToValidateNextIndicator}
            tooltipAceptar={"(*) Campos requeridos"}
          >
            <FormEvaluacion
              proposals={proposals}
              currentIndicator={selectedIndicator}
              currentEvaluation = {currentProposalEvaluation}
              currentProposalIndicators={
                currentProposalEvaluation
                  ? currentProposalEvaluation.indicadores
                  : []
              }
              validateForm={formData => this.validateEvaluationForm(formData)}
              formData={evaluation}
              onDeleteEvidences={this.handleUpdateListEvidences}
            />
          </Modal>
        }
        {
          showConfirmChangeSelectedIndicator &&
          <Confirmar
            yes={this.handleConfirmChangeSelectedIndicator}
            no={() => this.setState({ showConfirmChangeSelectedIndicator: false })}
          >
            <p className="ConfirmSubtitle">¿Está seguro de cambiar de indicador?</p>
            <p className="ConfirmDescription">Los cambios realizados no se guardaran.</p>
          </Confirmar>
        }
        {
          showConfirmChangeProposal &&
          <Confirmar
            yes={this.handleConfirmChangeProposal}
            no={this.handleCancelChangeProposal}
          >
            <p className="ConfirmSubtitle">¿Queres cambiar de propuesta?</p>
            <p className="ConfirmDescription">
              El indicador que seleccionaste pertenece a la propuesta de
              <span style={{ fontWeight: 700 }}>
                {this.getCurrentProposalEvaluation(currentProposalEvaluation.id)}
              </span>.
            </p>
            <p className="ConfirmDescription">
              Si cambias de propuesta la evaluación anterior no se guardará.
            </p>
          </Confirmar>
        }
        {
          isEvaluationCompleted &&
          validateNextIndicator &&
          showConfirmIndicatorsEvaluated === true &&
          <Confirmar
            yes={this.handleConfirmIndicatorsEvaluated}
          >
            <p className="ConfirmSubtitle">Todos los indicadores de esta propuesta han sido evaluados.</p>
            {/* <p className="ConfirmDescription">Los cambios realizados no se guardaran.</p> */}
          </Confirmar>
        }
        {
          showConfirmEvaluateProposal &&
          <Modal
            title="Asignar porcentaje de compensación"
            isVisible={showConfirmEvaluateProposal}
            cancelText="Cancelar guardado"
            confirmText="Ver cálculo de compensación"
            onConfirm={() => this.evaluateProposal(currentProposalEvaluationToSave,true)}
            onClose={this.closeConfirmPocentaje}
            onConfirmDisable={porcentajeCompensacionError.isInvalid}
          >
            <p>Favor de asignar porcentaje de compensación</p>
            <div className="column WapperAsignacionPorcentaje">
              <span className="WapperAsignacionPorcentajeNota">Nota:</span>
              <span className="WapperAsignacionPorcentajeMsg">Por default es 100%</span>
            </div>
            <input
              className="input"
              name="porcentajeCompensacion"
              type="number"
              value={porcentajeCompensacion}
              onChange={this.handleChangePorcentaje}
              max={100}
              min={1}
            />
            {
              porcentajeCompensacionError.isInvalid &&
              porcentajeCompensacionError.message !== '' &&
              <p className="WapperAsignacionPorcentajeErrorMsg">
                {porcentajeCompensacionError.message}
              </p>
            }
          </Modal>
        }
        {
          isModalReportCompVisible &&
          <Modal
            title="Cálculo de compensación de la propuesta"
            isVisible={isModalReportCompVisible}
            onConfirm={() => this.evaluateProposal(currentProposalEvaluationToSave, true, true)}
            confirmText="Guardar compensación"
            onConfirmDisable={proposalSelected.id === myProposal.id}
            cancelText="Cerrar reporte"
            onClose={this.handleCloseNominalReport}
            isLarge={true}
          >
            <ProposalCompReport
              selectedProposal={calcular_compensacion ? proposalSelected : null}
              proposal={reportSelected}
              normalIndicators={proposalSelected.indicadores.filter(item => item.tipo === 'Normal')}
              turboIndicators={proposalSelected.indicadores.filter(item => item.tipo === 'Turbo')}
            />
          </Modal>
        }
        {
          isModalMyReportCompVisible &&
          <Modal
            title="Reporte de compensación de mi propuesta"
            isVisible={isModalMyReportCompVisible}
            cancelText="Cerrar reporte"
            onClose={() => this.setState({ isModalMyReportCompVisible: false })}
            isLarge={true}
          >
            <ProposalCompReport
              selectedProposal={null}
              proposal={myNominalReport}
              normalIndicators={myProposal.indicadores.filter(item => item.tipo === 'Normal')}
              turboIndicators={myProposal.indicadores.filter(item => item.tipo === 'Turbo')}
            />
          </Modal>
        }
        {
          loadingData &&
          <Loader
            isFullscreen={true}
            isLoading={loadingData || isEvaluating}
            width="100px"
            height="100px"
          />
        }
      </Fragment>
    )
  }
}

export default AutoEvaluacion