import React, { Component } from 'react'

class Confirmar extends Component {
    render() {
        return (

            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content">
                    <div className="box">
                        <h3 className="title is-3 text-center">¿Seguro que desea relacionar estos indicadores?</h3>

                        <div className="buttons are-medium">
                            <button className="button is-success" onClick={this.props.yes}>
                                <span className="icon">
                                    <i className="fa fa-check"></i>
                                </span>
                                <span>Si</span>
                            </button>
                            <button className="button" onClick={this.props.no}>
                                <span className="icon">
                                    <i className="fa fa-ban"></i>
                                </span>
                                <span>No</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Confirmar;




