import React from 'react'
import { Button } from '../Button/Button'
import './Modal.css'

/**
 * @fileoverview ModalLayout, componente layout de un modal simple.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
export const Modal = ({
  isVisible = false,
  title = '',
  cancelText = '',
  confirmText = '',
  isLoading = false,
  onClose,
  onConfirm,
  children,
  isLarge = false,
  onConfirmDisable,
  zIndex = 40,
  closable = true,
  tooltipAceptar = '',
  Actions = () => <span></span>,
  Footer = () => <span></span>
}) => {
  const actionsComponent = <Actions />
  const actionsFooter = <Footer />
  return (
    <div className={isVisible ? "modal is-active" : "modal"} style={{ zIndex: `${zIndex}` }}>
      <div className="modal-background" onClick={ closable ? onClose : null } />
      <div className={`modal-card ${isLarge === true ? 'ModalLarge' : ''}`}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          {actionsComponent}
        </header>
        <section className="modal-card-body">
          {children}
        </section>
        <footer className="modal-card-foot">
          {onConfirm &&
            <Button
              disabled={onConfirmDisable}
              onClick={onConfirm}
              text={confirmText}
              icon="fa-check-circle"
              isLoading={isLoading}
              title={tooltipAceptar}
            />
          }
          {onClose &&
            <Button
              onClick={onClose}
              text={cancelText}
              type="is-danger"
              icon="fa-close"
              isLoading={isLoading}
            />
          }
          {actionsFooter}
        </footer>
      </div>
    </div>
  )
}