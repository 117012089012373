import { apiUrlIndicadores }  from "../../constants";
import { stringify } from "querystring";
import axios from 'axios';
import middleware from '../../middleware/auth';

var request = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {'Authorization': 'Bearer '+ localStorage.getItem('token'),'Content-Type':'application/json'}
})

var requestDownloadFile = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json'
    },
    responseType: 'blob'
})

export default class EvaluacionesAPI {
    static consultar(filters) {
        return middleware.hasAuth(() => request.get(`/evaluaciones/?${stringify(filters)}`));
    }

    static save_evaluacion(data) {
        return middleware.hasAuth(() => request.post(`/evaluaciones/create`, data));
    }

    static uploadFile(usuario, indicador, anio, mes, file, description = null, isText = false) {
        let form_data = new FormData()
        form_data.append('file', file)
        form_data.append('description', description)
        form_data.append('isText', isText)
        form_data.append('usuario', usuario)
        form_data.append('indicador', indicador)
        form_data.append('anio', anio)
        form_data.append('mes', mes)

        return middleware.hasAuth(() => request.post(`evaluacion/file/upload/`, form_data))
    }

    /**
     * Elimina el archivo de evidencia.
     * 
     * @param {Number} idEvidence
     */
    static deleteEvidence(idEvidence) {
        return middleware.hasAuth(() => request.delete(`evaluacion/file/delete/${idEvidence}`))
    }

    /**
     * Descarga los archivos de evidencia.
     * 
     * @param {String} path
     */
    static evidenceDownload(idEvidence) {
        return middleware.hasAuth(() => requestDownloadFile.get(`evaluacion/file/download/${idEvidence}`))
    }
}