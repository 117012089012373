import React, { Component, Fragment } from 'react'

// Styles
import './DragAndDrop.css'

// Components
import { Icon } from '../Icon/Icon'
import { toast } from 'react-toastify'

// Utilities
import moment from 'moment'

/**Componente para cargar archivos. */
class DragAndDrop extends Component {

  state = {
    isFileLoading: false,
    listFilesLoaded: [],
    listFilesNotLoaded: [],
  }

  /**
   * Procesa el archivo cargado y hace las validaciones
   * correspondientes.
   * 
   * @param {Event} event
   */
  handleProcessFileSelected = event => {
    let finalListLoaded = this.state.listFilesLoaded
    let finalListNotLoaded = this.state.listFilesNotLoaded
    let files = event.target.files
    let noFiles = files.length

    this.setState({
      isFileLoading: true
    })

    for (let i = 0, listItem; i < noFiles; i++) {
      listItem = files[i]
      let reader = new FileReader()
      let bufferString
      let dataFile

      reader.onerror = err => {
        toast.error('Ocurrio un error al cargar el archivo: ', listItem.name)
        finalListNotLoaded.push(listItem)
      }

      reader.onload = event => {
        bufferString = event.target.result
        dataFile = event.target.files
      }

      reader.onloadend = () => {
        let file = {
          name: listItem.name,
          type: listItem.type,
          size: `${Number(listItem.size / 1024 / 1024).toFixed(2)} Mb`,
          date: moment(new Date()).format('LLL'),
          data: bufferString
        }
        
        if(this.props.accepts && !this.props.accepts.includes(file.type)){
          finalListNotLoaded.push(file)
          toast.warn(`No es un archivo de tipo valído.`)
          return
        }

        // Verifica que el tamaño del archivo no sea mayor a 2Mb
        if (Number(listItem.size / 1024 / 1024).toFixed(2) < 2) {
          finalListLoaded.push(file)
          this.props.onUploadFile(listItem)
        } else {
          finalListNotLoaded.push(file)
          toast.warn(`El tamaño del archivo excede el tamaño permitido de 2Mb`)
          this.props.onUploadFileError()
        }

        if (i === noFiles - 1) {
          this.setState({
            isFileLoading: false,
            listFilesLoaded: finalListLoaded,
            listFilesNotLoaded: finalListNotLoaded
          })

          this.props.onFilesLoaded(finalListLoaded)
        }
      }
      reader.readAsArrayBuffer(listItem)
    }

    this.setState({
      isFileLoading: false
    })
  }

  render() {
    const { isFileLoading } = this.state

    return (
      <div className="AttachContainer">
        <input
          className="InputAttach"
          id="FileAttach"
          type="file"
          accept={this.props.accepts}
          name={this.props.name}
          onChange={this.handleProcessFileSelected}
          multiple={this.props.isMultiple || false}
        />
        {
          !isFileLoading ?
            <Fragment>
              <div className="IconAttachContainer">
                <Icon icon="fa-inbox" size="fa-2x" />
              </div>
              <span className="TitleAttach">
                {this.props.label || 'arrastra o da click aquí para cargar archivo'}
              </span>
            </Fragment>
            : <span className="TitleAttach">
              <Icon icon="fa-spinner" color="fa-pulse" />
              Cargando...
            </span>
        }
      </div>
    )
  }
}

export default DragAndDrop