import { apiUrlIndicadores } from "../../constants";
import axios from 'axios';
import middleware from '../../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlIndicadores,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    }
})

export default class GrupoIndicadoresAPI {

    static create(data) {
        return middleware.hasAuth(() => request.post('grupo_indicadores/create/', data));
    }

    static update(data,id) {
        return middleware.hasAuth(() => request.put(`grupo_indicadores/${id}/`, data));
    }

    static get_group_list_by_me(filters) {
        return middleware.hasAuth(() => request.get(`grupo_indicadores/?${stringify(filters)}`));
    }

    static get_group_list_by_users(filters, page) {
        return middleware.hasAuth(() => request.post(`grupo_indicadores/list/?${stringify(page)}`, filters));
    }

    static delete(id) {
        return middleware.hasAuth(() => request.delete(`grupo_indicadores/remove/${id}/`));
    }

    static asignacion(id, data){
        return middleware.hasAuth(() => request.post(`grupo_indicadores/asignacion/${id}/`, data))
    }

    static get_asignacion_by_group(id){
        return middleware.hasAuth(() => request.get(`grupo_indicadores/asignados/${id}`))
    }

    static get_usuarios_por_asignar(){
        return middleware.hasAuth(() => request.get(`grupo_indicadores/asignar/`))
    }

    static get_usuarios_por_asignar_administrador(filter){
        return middleware.hasAuth(() => request.get(`grupo_indicadores/asignar/administracion?${stringify(filter)}`))
    }
}

