import React, { Component } from 'react';
import './ModoPresentacion.css'
import FileLoader from '../../util/FileWrapper/FileLoader'
import {apiUrlBsc} from '../../../constants'

// Rutas para cargar los gifs de ayuda
const HELP_FONT_SIZE = `${apiUrlBsc}file/statics/6`
const HELP_COLUMNS = `${apiUrlBsc}file/statics/7`
const HELP_RELATIONS = `${apiUrlBsc}file/statics/8`


class ModoPresentacionAyuda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: "initial"
        }
    }

    render() {
        return (
            <div className="modal is-active">
                <div className="modal-background" onClick={this.props.cancel} />
                <div className="modal-card" style={{ width: "80%", height: "80%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Ayuda - Modo Presentación</p>

                        <button onClick={this.props.close}
                            className="delete"
                            aria-label="close">
                        </button>

                    </header>

                    <section className="modal-card-body" ref={this.refSection}>
                        <div>
                            <p className="title is-3">Aumentar, reducir o restablecer el tamaño del texto de la propuesta.</p>
                            <p className="subtitle is-5">Al presionar alguno de los botones de la siguiente tabla, realiza la acción especificada.</p>
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <button className="button is-warning"
                                                title="Aumentar el tamaño del texto">
                                                <span className="icon is-small">
                                                    <i className="fa fa-font"></i>
                                                </span>
                                                <span>+</span>
                                            </button>
                                        </td>
                                        <td>Aumenta el tamaño del texto de la propuesta, objetivos turbo y objetivos relacionados.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <button className="button is-warning"
                                                title="Aumentar el tamaño del texto">
                                                <span className="icon is-small">
                                                    <i className="fa fa-font"></i>
                                                </span>
                                                <span>-</span>
                                            </button>
                                        </td>
                                        <td>Reduce el tamaño del texto de la propuesta, objetivos turbo y objetivos relacionados.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <button className="button is-warning"
                                            title="Restablecer el tamaño del texto">
                                            <span className="icon is-small">
                                                <i className="fa fa-undo"></i>
                                            </span>
                                            <span>Restablecer el tamaño del texto</span>
                                        </button>
                                        </td>
                                        <td>Restablece el tamaño del texto a su estado original.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h5 className="title is-5">Demostración:</h5>
                            <FileLoader fileSrc={HELP_FONT_SIZE} fileType={/^(image\/).+/}/>
                        </div>
                        <hr />
                        <div>
                            <p className="title is-3">Ocultar o mostrar columnas de la propuesta.</p>
                            <p className="subtitle is-5">Al hacer doble clic sobre una columna de la propuesta, esta se oculta.</p>
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <button className="button is-small is-success">
                                                <span className="icon is-small">
                                                    <i className="fa fa-undo"></i>
                                                </span>
                                                <span>Restablecer columnas</span>
                                            </button>
                                        </td>
                                        <td>Restablece las columas de la propuesta a su estado original</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h5 className="title is-5">Demostración:</h5>
                            <FileLoader fileSrc={HELP_COLUMNS} fileType={/^(image\/).+/}/>
                        </div>
                        <hr />
                        <div>
                            <p className="title is-3">Ver objetivos relacionados.</p>
                            <p className="subtitle is-5">Al hacer clic sobre el ícono para ver indicadores relacionados, se muestra una ventana en dónde se visualizan dichos indicadores.</p>
                            <h5 className="title is-5">Demostración:</h5>
                            <FileLoader fileSrc={HELP_RELATIONS} fileType={/^(image\/).+/}/>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

}


export default ModoPresentacionAyuda;