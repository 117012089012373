import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import Loading from '../../util/loading/Loading'
import { toast } from 'react-toastify';
import Select from '../../util/select/Selct'
import { Wrapper } from "../../util/WrapperInput/WrapperInput";
import Dropdown from '../../util/select/Dropdown'

//Servicios
import UsuariosAPI from '../../../services/Usuario'
import UbicacionBaseAPI from '../../../services/UbicacionBase';

class SingleItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected : false
        }
    }
    render() {
        let data = this.props.data;
        let sync_pending = this.props.sync_pending;
        return (
            <tr>
                <td style={this.state.selected? {backgroundColor:"#79FF8D"}: sync_pending?{backgroundColor: "#fff479"}:{backgroundColor: "#ffffff"}}
                    title={sync_pending?"Pendiente de sincronización":data} 
                    onClick={() => {
                        this.setState({selected:! this.state.selected}) 
                        this.props.onClick()
                    }}>
                    {data}
                </td>
            </tr>
            
        );
    }
}

class ItemListNotAssigned extends React.Component {
    render() {
        let not_assigned = this.props.allItems;
        let assigned = this.props.items;
        let handleEvent = this.props.handleEvent;
    
        let listItems = not_assigned.map((itemObj) => {
            let exist = assigned.filter(obj_assigned => obj_assigned.ubicacion_base == itemObj.id)[0]
            if (exist) return null;
    
            return <SingleItem 
                key={itemObj.id}
                sync_pending={false}
                data={itemObj.nombre}
                onClick={() => handleEvent(itemObj.id)}
            />;
        });
  
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth scroll">
                <thead>
                    <tr>
                        <th style={{"text-align": "center",backgroundColor: "#99cc00"}}>Ubicaciones Base</th>
                    </tr>                    
                </thead>
                <tbody className='scroll'>
                    {listItems}
                </tbody>

            </table>
            
        );
    }
}

class ItemListAssigned extends React.Component {
    render() {
        let assigned = this.props.items;
        let handleEvent = this.props.handleEvent;
    
        let listItems = assigned.map((itemObj) => {    
            return <SingleItem 
                key={itemObj.id}
                sync_pending = {itemObj.id == 0}
                data={itemObj.ubicacion_base_nombre}
                onClick={() => handleEvent(itemObj.ubicacion_base)}
            />;
        });
  
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth scroll">
                <thead>
                    <tr>
                        <th style={{"text-align": "center", backgroundColor: "#99cc00"}}>Ubicaciones asignadas</th>
                    </tr>                    
                </thead>
                <tbody>
                    {listItems}
                </tbody>

            </table>
        );
    }
}

class UsuariosFsapUbicacionBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usuarios_fsap_list : [],
            usuario_fsap_selected : undefined,
            ubicacion_base_static : [],
            ubicaciones_base : [],
            ubicaciones_base_assigned : [],
            ubicaciones_base_to_assign : [],
            ubicaciones_base_to_unassign : [],
            isLoading : false,

        }
    }

    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    handleLoadData = () => {
        this.showLoading(true)

        Promise.all([
            UsuariosAPI.getUsuariosFSAP().then(response => {

                response.data.sort(function (a, b) {
                    if (a.nombre < b.nombre) {
                      return -1;
                    }
                    if (a.nombre > b.nombre) {
                      return 1;
                    }
                    return 0;
                });

                this.setState({
                    usuarios_fsap_list: response.data
                })
            }).catch(err => {
                console.log('Error al cargar usuarios: ', err)
                toast.error("Usuarios no cargados")
            }),
            UbicacionBaseAPI.getAll()
                .then( response => {

                    response.data.sort(function (a, b) {
                        if (a.nombre < b.nombre) {
                          return -1;
                        }
                        if (a.nombre > b.nombre) {
                          return 1;
                        }
                        return 0;
                    });

                    this.setState({
                        ubicacion_base_static: response.data,
                        ubicaciones_base: response.data
                    });
                })
                .catch( () => {
                toast.error('Ocurrió un error al cargar las ubicaciones');
            }),
        ]).then( res => {
            this.setState({ isLoading: false });
        })
    }

    componentWillMount() {
        this.handleLoadData()
    }

    handleSelectUsuarioFSAP = (user) => {
        this.showLoading(true)
        let state = {...this.state}
        let selected = user

        if(selected == 0)
        {
            this.setState({
                isLoading: false,
                usuario_fsap_selected : undefined,
                ubicaciones_base: state.ubicacion_base_static,
                ubicaciones_base_assigned : [],
                ubicaciones_base_to_assign : [],
                ubicaciones_base_to_unassign : [],
            })
        }
        else{
            let static_ubicaciones = state.ubicacion_base_static
            let ubicaciones_asignadas = []

            let filter = {"usuario_id":selected}

            Promise.all([
                UbicacionBaseAPI.getUbicacionesAsignadas(filter)
                .then( response => {

                    ubicaciones_asignadas = response.data.sort(function (a, b) {
                        if (a.ubicacion_base_nombre < b.ubicacion_base_nombre) {
                            return -1;
                        }
                        if (a.ubicacion_base_nombre > b.ubicacion_base_nombre) {
                            return 1;
                        }
                        return 0;
                    });

                    ubicaciones_asignadas.map((item) => {
                        static_ubicaciones = static_ubicaciones.filter(unassigned => unassigned.id !== item.ubicacion_base)                    
                    })
                })
                .catch( () => {
                    toast.error('Ocurrió un error al cargar las ubicaciones');
                })
            ]).then( res => {
                let usuario_selected = this.state.usuarios_fsap_list.find((usuario) =>{
                    if(usuario.id == user)
                        return usuario
                })
                this.setState({
                    isLoading: false,
                    usuario_fsap_selected : usuario_selected,
                    ubicaciones_base: static_ubicaciones,
                    ubicaciones_base_assigned : ubicaciones_asignadas,
                    ubicaciones_base_to_assign : [],
                    ubicaciones_base_to_unassign : [],
                })
            }) 
        }
    }

    handleSaveAsignacion = () => {
        this.showLoading(true)
        let state = {...this.state}
        let static_ubicaciones = state.ubicacion_base_static
        let ubicaciones_asignadas = state.ubicaciones_asignadas

        let data_save = state.ubicaciones_base_assigned
        let data_to_send = {
            'usuario' : state.usuario_fsap_selected.id,
            'data' : data_save
        }

        Promise.all([
            UbicacionBaseAPI.postUbicacionesAsignadas(data_to_send)
            .then( response => {

                ubicaciones_asignadas = response.data.sort(function (a, b) {
                    if (a.ubicacion_base_nombre < b.ubicacion_base_nombre) {
                        return -1;
                    }
                    if (a.ubicacion_base_nombre > b.ubicacion_base_nombre) {
                        return 1;
                    }
                    return 0;
                });

                ubicaciones_asignadas.map((item) => {
                    static_ubicaciones = static_ubicaciones.filter(unassigned => unassigned.id !== item.ubicacion_base)                    
                })
                toast.success('Asignación actualizada con exito');
            })
            .catch( (err) => {
                toast.error('Ocurrió un error al guardar la asignación');
            })
        ]).then( res => {
            this.setState({
                isLoading: false,
                usuario_fsap_selected : state.usuario_fsap_selected,
                ubicaciones_base: static_ubicaciones,
                ubicaciones_base_assigned : ubicaciones_asignadas,
                ubicaciones_base_to_assign : [],
                ubicaciones_base_to_unassign : [],
            })
        }) 

    }

    handleEventAsignar = ( itemId ) => {
        let state = {...this.state}
        let isInBoxOne = state.ubicaciones_base.filter(not_assigned => not_assigned.id == itemId)[0]        
        let isInBoxTwo = state.ubicaciones_base_to_assign.filter(assigned => assigned.id == itemId)[0]
        let listUbicacionSelectedToAssign = []

        if(isInBoxOne){
            if(isInBoxTwo){
                listUbicacionSelectedToAssign = state.ubicaciones_base_to_assign.filter(assigned => assigned.id !== itemId)
            }
            else{
                state.ubicaciones_base_to_assign.push(isInBoxOne)
                listUbicacionSelectedToAssign = state.ubicaciones_base_to_assign
            }
        }
        this.setState({
            ubicaciones_base_to_assign: listUbicacionSelectedToAssign
        });
    }

    handleEventDesasignar = ( itemId ) => {
        let state = {...this.state}
        let isInBoxOne = state.ubicaciones_base_assigned.filter(not_assigned => not_assigned.ubicacion_base == itemId)[0]        
        let isInBoxTwo = state.ubicaciones_base_to_unassign.filter(assigned => assigned.ubicacion_base == itemId)[0]
        let listUbicacionSelectedToUnassign = []

        if(isInBoxOne){
            if(isInBoxTwo)
                listUbicacionSelectedToUnassign = state.ubicaciones_base_to_unassign.filter(assigned => assigned.ubicacion_base !== itemId)
            else{
                state.ubicaciones_base_to_unassign.push(isInBoxOne)
                listUbicacionSelectedToUnassign = state.ubicaciones_base_to_unassign
            }
            
        }
                
        this.setState({
            ubicaciones_base_to_unassign: listUbicacionSelectedToUnassign
        });
    }

    handleEventAsignarButton= () => {
        let state = {...this.state}
        let ubicaciones_base_assigned = state.ubicaciones_base_assigned
        let ubicaciones_base = state.ubicaciones_base

        state.ubicaciones_base_to_assign.forEach(element => {
            let ubicacion_asignada = {
                "usuario": state.usuario_fsap_selected.id,
                "id": 0,
                "ubicacion_base": element.id,
                "ubicacion_base_nombre": element.nombre
            }
            ubicaciones_base_assigned.push(ubicacion_asignada)
            ubicaciones_base = ubicaciones_base.filter(item => item.id !== element.id)
        });

        ubicaciones_base_assigned.sort(function (a, b) {
            if (a.ubicacion_base_nombre < b.ubicacion_base_nombre) {
              return -1;
            }
            if (a.ubicacion_base_nombre > b.ubicacion_base_nombre) {
              return 1;
            }
            return 0;
        });

        ubicaciones_base.sort(function (a, b) {
            if (a.nombre < b.nombre) {
              return -1;
            }
            if (a.nombre > b.nombre) {
              return 1;
            }
            return 0;
        });

        this.setState({
            ubicaciones_base_to_assign: [],
            ubicaciones_base_assigned : ubicaciones_base_assigned,
            ubicaciones_base : ubicaciones_base,
            saveDisabled: false            
        });

    }

    handleEventDesasignarButton= () => {
        let state = {...this.state}
        let ubicaciones_base_assigned = state.ubicaciones_base_assigned
        let ubicaciones_base = state.ubicaciones_base

        state.ubicaciones_base_to_unassign.forEach(element => {
            let ubicacion_desasignada = {
                "id" : element.ubicacion_base,
                "nombre" : element.ubicacion_base_nombre
            }

            ubicaciones_base.push(ubicacion_desasignada)
            ubicaciones_base_assigned = ubicaciones_base_assigned.filter(item => item.ubicacion_base !== element.ubicacion_base)
        });

        ubicaciones_base_assigned.sort(function (a, b) {
            if (a.ubicacion_base_nombre < b.ubicacion_base_nombre) {
              return -1;
            }
            if (a.ubicacion_base_nombre > b.ubicacion_base_nombre) {
              return 1;
            }
            return 0;
        });

        ubicaciones_base.sort(function (a, b) {
            if (a.nombre < b.nombre) {
              return -1;
            }
            if (a.nombre > b.nombre) {
              return 1;
            }
            return 0;
        });

        this.setState({
            listUsersSelectedToUnassign: [],
            ubicaciones_base : ubicaciones_base,
            ubicaciones_base_assigned : ubicaciones_base_assigned,
            saveDisabled: false            
        });

    }

    render() {
        return (
            <Fragment>
                <Card title="Asignación de Ubicación Base" extraActions={
                    <Button
                    icon="fa-save"
                    type="is-success"
                    onClick={this.handleSaveAsignacion}
                    title="Guardar Asignación"
                    text="Guardar Asignación"
                    disabled={this.state.usuario_fsap_selected == undefined|| this.state.isLoading}
                />}>
                    <div className="columns">
                        <div className="column is-4">
                            <Dropdown
                                name="Usuario"
                                data={this.state.usuarios_fsap_list.map(user => {
                                    let copy = {
                                        id: user.id,
                                        value: user.nombre
                                    }
                                    return copy
                                }).sort((a, b) => {
                                    return a.value.localeCompare(b.value)
                                })}
                                value={this.state.usuario_fsap_selected ?this.state.usuario_fsap_selected.nombre : ''}
                                onChange={(user) => {this.handleSelectUsuarioFSAP(user)}}
                            />
                            {/* <Wrapper label="Usuarios FSAP">
                                <Select name="usuario_fsap"
                                    classes="input"
                                    optionProps={{ value: "id", text: "nombre" }}
                                    value={this.state.usuario_fsap_selected}
                                    items={this.state.usuarios_fsap_list}
                                    change={({target}) => {this.handleSelectUsuarioFSAP(target)}}
                                    label="Usuario FSAP"
                                    disabled={this.state.isLoading}
                                    
                                />
                            </Wrapper>  */}                                               
                        </div>
                    </div> 
                </Card>

                <Card >
                    <div>
                        <div className='columns'>
                            <div className="column is-5">
                                <div>
                                    {
                                        this.state.isLoading ?
                                        <Loading
                                        isFullscreen={false}
                                        isLoading={this.state.isLoading}
                                        width="100px"
                                        height="100px"
                                        />
                                        :
                                        <ItemListNotAssigned handleEvent={this.handleEventAsignar} items={this.state.ubicaciones_base_assigned} allItems={this.state.ubicaciones_base} />
                                    }
                                    
                                </div>
                            </div>
                            <div  className="column is-2" style={{"text-align":"center"}}>
                                <br/>
                                <br/>
                                <br/>
                                <button disabled={this.state.usuario_fsap_selected == undefined} className="button is-large is-success" onClick={this.handleEventAsignarButton}>
                                    <span className="icon is-big">
                                    &nbsp;&nbsp;<i className="fa fa-chevron-right"></i>&nbsp;&nbsp;
                                    </span>
                                </button>
                                <br/>
                                <br/>
                                <button className="button is-large is-danger" onClick={this.handleEventDesasignarButton}>
                                    <span className="icon is-big">
                                        <i className="fa fa-chevron-left"></i>
                                    </span>
                                </button>

                            </div>
                            <div className={"column is-5"}>
                                <div>
                                    {
                                        this.state.isLoading ?
                                        <Loading
                                        isFullscreen={false}
                                        isLoading={this.state.isLoading}
                                        width="100px"
                                        height="100px"
                                        />
                                        :
                                        <ItemListAssigned handleEvent={this.handleEventDesasignar} items={this.state.ubicaciones_base_assigned} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card> 
            </Fragment>
        )
    }
}



export default UsuariosFsapUbicacionBase;