import React, { Component, Fragment } from 'react';
import { Icon } from '../util/Icon/Icon'
import HasAccess from '../util/hasAccess/HasAccess';
import { Modal } from '../util/modal/ModalLayout';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de solicitudes de compensacion realizadas. */
export class TablaUsuarios extends Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            isModalAutorizar: false,
            isModalRechazar : false,
        }
    }

    render() {

        return (
            <div>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Número de empleado</th>
                            <th>Nombre</th>
                            <th>Puesto</th>
                            <th>Razón social</th>
                            <th>Área</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.usuarios.length === 0 ?
                            <tr>
                                <td
                                colSpan={7}
                                style={{
                                    textAlign: "center",
                                    height: "40px",
                                    fontSize: "1.3em",
                                    color: "#4c4c4c"
                                }}
                                >
                                No hay usuarios con montos disponibles
                                </td>
                            </tr> :
                            this.props.usuarios.map((usuario, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{usuario.usuario.id_empleado}</td>
                                        <td>{usuario.usuario.nombre}</td>
                                        <td>{usuario.puesto}</td>
                                        <td>{usuario.razon_social_nombre}</td>
                                        <td>{usuario.area_nombre}</td>
                                        <td>{
                                            <Fragment>
                                                <Icon
                                                    title="Detalles de usuario"
                                                    icon="fa-eye"
                                                    color="has-text-info"
                                                    click={() => {
                                                        this.props.detalle(usuario)
                                                    }}
                                                />
                                                <Icon
                                                    title="Edición de monto"
                                                    icon={"fas fa-edit"}
                                                    color="brack"
                                                    click={() => {this.props.descuento(usuario)}}
                                                />
                                            </Fragment>
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TablaUsuarios;