import React from 'react'

/**
 * @fileoverview Button, componente que renderiza un boton.
 *
 * @version 1
 *
 * @author Jesus Ramírez <jesus.ramirez@caffenio.com>
 */
export const Button = ({
  disabled = false,
  className = '',
  isLoading = false,
  iconRight = false,
  text,
  title = "",
  onClick,
  type = "is-success",
  icon
}) => {
  return (
    <button
      title={title}
      className={`button ${type} ${className}`}
      disabled={disabled || isLoading}
      onClick={onClick}
      style={{ margin: "0 3px" }}>
      {
        !iconRight &&
        <span className="icon is-small">
          <i className={`fa ${icon}`}></i>
        </span>
      }
      {
        text &&
        <span>{text}</span>
      }
      {
        iconRight &&
        <span className="icon is-small">
          <i className={`fa ${icon}`}></i>
        </span>
      }
    </button>
  )
}