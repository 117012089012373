import React from 'react'

// Components
import { Button } from '../../util/Button/Button'
import { InputSearch } from '../../util/Input/InputSearch'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'

export const FiltroConvocatorias = ({ click, onChange, inputValue }) => {
  return (
    <div>
      <div className="columns">
        <Wrapper label="Nombre de la convocatoria" extraClasses="is-three-quarters">
          <InputSearch
            name="convocatoria_search"
            placeholder="Buscar convocatoria"
            changeInput={onChange}
            value={inputValue}
          />
        </Wrapper>
        <div className="column is-flex" style={{ alignItems: 'flex-end' }} >
          <Button
            icon="fa-plus"
            type="is-success"
            onClick={() => click()}
            title="Lanzar nueva convocatoria"
            text="Lanzar nueva convocatoria"
          />
        </div>
      </div>
    </div>
  )
}
