import { apiUrlBsc } from '../constants'
import axios from 'axios'
import middleware from '../middleware/auth'
import { stringify } from 'querystring'

const request = axios.create({
  baseURL: apiUrlBsc,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
})

const apiService = 'bitacora'

class HistorialMovimientosAPI {
  static getAll(filters = {}) {
    return middleware.hasAuth(() =>
      request.get(`${apiService}/?${stringify(filters)}`)
    )
  }
}

export default HistorialMovimientosAPI