import React, {Fragment, Component} from 'react'
import axios from 'axios'

/**
 * Componente para la carga de la imagen principal
 * con las fechas de la convocatoria en curso.
 */
export default class FileLoader extends Component {
  state = {
    fileSrc: '',
    fileRgxType: null,
    loading: false,
    hasErrors: false
  }

  componentDidMount() {
    const request = axios.create({
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type':'application/json',
      }
    })

    this.setState({
      loading: true,
      fileRgxType: this.props.fileType
    })

    request.get(this.props.fileSrc, {responseType: 'arraybuffer'})
      .then(response => {
        if (this.state.fileRgxType.test(response.headers['content-type'])) {
          let image = `data: ${response.headers};base64,${Buffer.from(response.data, 'binary').toString('base64')}`
          this.setState({fileSrc: image})
        } else {
          this.setState({hasErrors: true})
        }
      })
      .catch(() => {
        this.setState({
          fileSrc: this.props.fileSrc,
          hasErrors: true
        })
      })
      .finally(() => this.setState({loading: false}))
  }

  render() {
    const {loading, hasErrors, fileSrc} = this.state
    const stylesMsg = {
      'fontSize': 24,
      'color': 'gray',
      'padding': '20px 10px'
    }

    return (
      <Fragment>
        {loading && <p style={{...stylesMsg}}>Cargando...</p>}
        {hasErrors && <p style={{...stylesMsg}}>Ocurrio un error</p>}
        <img src={fileSrc} alt="Fechas del semestre" style={{width: this.props.width || '100%'}}/>
      </Fragment>
    )
  }
}


