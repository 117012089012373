import React, { Component } from 'react';
// import Indicador from './indicador';
import PropTypes from 'prop-types';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import { HasPermission } from '../../../util/utileria';
import './Indicadores.css'


/**Clase que muestra las propuestas con sus opciones y la tabla de indicadores de la misma. */
class TablaGrupos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            grupo_indicador: this.props.grupo_indicador,
        }
    }


    render() {
        return (
            <div style={{ marginBottom: "24px" }}>
                {   
                    this.state.grupo_indicador.indicadores.length > 0 ?
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                                    <th style={BSCColumnStyle.indicador}>Indicador</th>
                                    <th style={BSCColumnStyle.comentario}>Comentario</th>
                                    <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                                    <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                                    <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                                    <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                                    <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar el objetivo" width="7%">Usuario que valida</abbr></th>
                                    <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación" width="7%">% Pond.</abbr></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.grupo_indicador.indicadores.map((obj, index) => {
                                        
                                        return <tr key={index}>
                                            <td className={`IndicadorCol`}>{obj.objetivo}</td>
                                            <td className={`IndicadorCol`}>{obj.indicador}</td>
                                            <td className={`IndicadorCol`}>{obj.comentario}</td>
                                            <td className={`IndicadorCol`}>{obj.sobre_meta}</td>
                                            <td className={`IndicadorCol`}>{obj.meta}</td>
                                            <td className={`IndicadorCol`}>{obj.bajo_meta}</td>
                                            <td className={`IndicadorCol`}>{obj.inaceptable}</td>
                                            <td className={`IndicadorCol`}>{obj.validate_user? obj.validate_user.nombre : 'Sin encargado'}</td>
                                            <td className={`IndicadorCol`}>{obj.ponderacion}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table> : ""
                }

            </div>
        )
    }
}

export default TablaGrupos;