import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class GerenciaAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('gerencia/'));
    }
    static create(data) {
        return middleware.hasAuth(() => request.post('gerencia/',data));
    }

    static edit(unit, id){
        return middleware.hasAuth(() => request.put(`/gerencia/${id}`,unit));
    }
}