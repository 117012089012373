//-react
import React, { Fragment } from "react";

//-third
import decode from 'jwt-decode';
import { toast } from "react-toastify";

//-utils
import './elaborarPropuestasBsc.css'
import NoItems from "../../util/noitems/NoItems";
import ModalComponent from "../../util/modal/Modal";
import AcceptModal from "../../util/acceptModal/AcceptModal";
import HasAccess from '../../util/hasAccess/HasAccess';
import ModoPresentacion from '../modoPresentacion/ModoPresentacion';
import ModoValidacion from '../modoValidacion/ModoValidacion';
import { generateExcel } from '../../../util/ExcelFunctions';

//--services
import UsuarioAPI from '../../../services/Usuario';
import IndicadorAPI from "../../../services/IndicadoresSvc";
import PropuestaAPI from '../../../services/Propuestas'
import ConvocatoriaAPI from '../../../services/Convocatorias'
import ComentariosAPI from '../../../services/Comentarios'
import GerenciaAPI from '../../../services/Gerencia'
import AreaAPI from '../../../services/Area'

//--my components
import ProposalForm from "./propuestaForm";
import Propuesta from "../../util/propuestas/propuesta";
import Propuestas from "../../util/propuestas/propuestas";
import Turbo from '../../util/propuestas/turbo';
import EditTurbo from './EditTurbo';
import { getTagClassByStatus, HasPermission } from '../../../util/utileria';
import Loading from '../../util/loading/Loading'
import PropuestaEdit from "../../util/propuestas/PropuestaEdit";
import { Button } from "../../util/Button/Button";
import { Modal } from '../../util/modal/ModalLayout';
import ObjetivoTurbo from '../ObjetivoTurbo/ObjetivoTurbo';
import HistorialIndicador from '../../util/propuestas/HistorialIndicador';
import MultiSelect from '../../util/MultiSelect/MultiSelect';
import { calcularCompensacion } from "../../../util/compensacion-util";
import { Wrapper } from "../../util/WrapperInput/WrapperInput";
import Select from "../../util/select/Selct";
import moment from "moment";
const IGNORE_COLUMNS = [
  "id", "codigo", "propuesta", "revision", "tipo", "validado",
  "version", "comentarios", "rel_indicador", "edited",
  "edited_jefe", "indicator_rel", "history", "objetivo_hasChanged",
  "indicador_hasChanged", "comentario_hasChanged", "sobre_meta_hasChanged",
  "meta_hasChanged", "bajo_meta_hasChanged", "inaceptable_hasChanged",
  "ponderacion_hasChanged", "indicator_rel_hasChanged", "validate_user_hasChanged",
  "cumplimiento", "cumplimiento_hasChanged", "ponderacionValid", "evidencia",
  "evidencia_hasChanged", "comentario_evaluacion_hasChanged", "evaluado",
  "evaluado_hasChanged", "compensacion_real", "compensacion_real_hasChanged",
  "compensacion_hasChanged", "comentario_evaluacion"
]

const LIST_ESTATUS_USUARIO = [
  {
    id: true,
    value: 'Activo'
  },
  {
    id: false,
    value: 'Inactivo'
  }
]

const LIST_ESTATUS_PROPUESTAS = [  
  { id: 1, value: "Revisión" },
  { id: 2, value: "Comentarios" },
  { id: 3, value: "Autorizado" }
]

/**
 * Componente de la pantalla solicitar propuestas o convocatorias.
 */
export default class ElaborarPropuestasBSC extends React.Component {
  proposalEmpty = {
    id: 0,
    objetivo: "", //objective
    indicador: "", //indicator
    comentario: "", //comment
    sobre_meta: "", //overgoal
    meta: "", //goal
    bajo_meta: "", //undergoal
    inaceptable: "", //innefficient
    ponderacion: "" //ponderatio
  };
  modalTitle = "";
  proposals = [];
  //------------------------------------------------------------------------------------------

  /**
   * Constantes para renderizar solo una notificación de cada tipo
   */
  toastSuccessId = null
  toastErrorId = null
  toastWarnId = null
  toastInfoId = null

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDownloadingExcel: false,
      filterText: "",
      convocatoria: {},
      openConvocatoria: false,
      isHistorialModalVisible: false,
      proposal: {},
      proposals: [],
      proposalsFiltered: [],
      listaUsuarios: [],
      listaUsuariosOriginal: [],
      myProposals: [],
      currentPeriod: 0,
      selectedPeriod: null,
      listPeriods: [],
      proposalsOriginal: [],
      indicador: {},
      objetivoTurbo: {},
      selectedUsers: [],
      indicadores_eliminar: [],
      comentarios: [],
      usuarios: [],
      usuario: {},
      gerencias: [],
      areas: [],
      estatus: [],
      areasOriginal: [],
      idPropuesta: 0,
      indicadoresTurbo: [],
      hasProposal: false,
      showModal: false,
      showModalAutorizacionMasiva: false,
      proposalsToAutorize: [],
      listEstatusUsuario: LIST_ESTATUS_USUARIO,
      selectedEstatusUsuario:undefined,
      editing: false,
      removeModal: false,
      showPropuesta: false,
      showPropuestaEdit: false,
      showCompensacion: false,
      showTurbo: false,
      showModoPresentacion: false,
      showModoValidacion: false,
      showEditTurbo: false,
      isHistorialModalVisible: false,
      isGerencial: false,
      filtros: {},
      filtros_proto: {
        usuario: { value: '', applied: false },
        objetivo: { value: '', applied: false },
        estatus: { value: '', applied: false },
        gerencia: { value: '', applied: false },
        area: { value: '', applied: false }
      },
      nuevoObjetivoTurbo: false,
      isLoadingTurboMasivo: false,
      solo_turbos: false
    };
  }

  /**
   * Filtra las areas segun las gerencias seleccionadas,
   * en caso de no tener gerencias seleccionadas, se
   * asignan todas las areas existentes.
   * 
   * @param {Array} gerencias
   * @returns filteredAreas
   */
  filteredAreas = (gerencias = []) => {
    let state = { ...this.state }
    let filteredAreas = []
    let selectedGerencias = state.filtros.gerencia || undefined

    if (selectedGerencias) {
      filteredAreas = gerencias.filter(gerenciaItem =>
        selectedGerencias.includes(gerenciaItem.id)
      ).map(gerencia => gerencia.areas).flat()
    } else {
      filteredAreas = gerencias.map(gerencia => gerencia.areas).flat()
    }

    return filteredAreas
  }

  /**
   * Obtiene a los usuarios, con propuestas activas.
   * 
   * @returns usuarios
   */
  getUsers() {
    let usuarios = []
    let proposals = this.state.proposalsOriginal

    usuarios = proposals.map(proposal => { return { ...proposal.usuario, ...proposal.usuario.usuario } })

    return usuarios
  }

  /**
   * Filtra los usuarios segun las areas seleccionadas,
   * en caso de no tener seleccionada ningun area se
   * devuelven todos los usuarios.
   * 
   * @returns filteredUsuarios
   */
  filteredUsuarios = () => {
    let state = { ...this.state }
    let filteredUsuarios = []
    let selectedAreas
    // let usuarios = this.getUsers()
    let usuarios = state.listaUsuariosOriginal
    state.filtros.area ? selectedAreas = state.filtros.area : selectedAreas = state.areas.map(area => area.id)

    filteredUsuarios = usuarios.filter(usuario => selectedAreas.includes(usuario.area))

    return filteredUsuarios
  }

  /**
   * Llamada inicial de los enpoints necesarios para
   * la carga de datos inicial.
   */
  handleLoadData = () => {
    this.showLoading(true)
    let gerencias = []
    let areas = []
    let areasOriginal = []

    Promise.all([
      UsuarioAPI.getUserFromToken()
        .then(response => {
          let isGerencial = false
          if(response.data.grupo_nombre === 'Director de área')
            isGerencial = true
          this.setState({ 
            usuario: response.data ,
            isGerencial: isGerencial,
            estatus : LIST_ESTATUS_PROPUESTAS
          })
        }).catch(err => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') }),
      UsuarioAPI.getAll().then(response => {
        this.setState({
          listaUsuarios: response.data,
          listaUsuariosOriginal: response.data
        })
      }).catch(err => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Lista de usuarios no cargada')
      }),
      GerenciaAPI.getAll()
        .then(response => {
          this.setState({
            gerencias: response.data
          })
        }).catch(() => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Gerencias no cargadas') }),
      AreaAPI.getAll()
        .then(response => {
          this.setState({
            areas: response.data,
            areasOriginal: response.data,
          })
        })
        .catch(() => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Areas no cargadas') }),
      ConvocatoriaAPI.getAll()
        .then(res =>
          this.setState({
            listPeriods: res.data
          }, () => this.getCurrentPeriod())
        )
        .catch(() => this.showLoading(false))
    ])
  }

  /** Obtiene el periodo abierto actual. */
  getCurrentPeriod = () => {
    let convocatoria = {};
    let currentPeriod = 0;
    let selectedPeriod = null;

    ConvocatoriaAPI.getCurrent()
      .then(({ data }) => {
        let { filtros } = this.state

        if (!(Object.keys(data.convocatoria).length === 0 && data.convocatoria.constructor === Object)) {
          filtros.periodo = data.convocatoria.id;
          convocatoria = data.convocatoria
          currentPeriod = data.convocatoria.id
          selectedPeriod = data.convocatoria.id
        }

        this.setState({
          openConvocatoria: this.isConvocatoryOpen(data.convocatoria),
          convocatoria,
          currentPeriod,
          selectedPeriod,
          filtros
        }, () => this.loadProposals())
      })
      .catch(err => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al cargar convocatoria')
        this.showLoading(false)
      })
  }

  /** Carga las propuestas */
  // loadProposals = () => {
  //   let state = { ...this.state }

  //   this.setEmptyProposals()
  //   this.showLoading(true)

  //   PropuestaAPI.getWithFilters(state.filtros)
  //     .then(res => {
  //       let token = decode(localStorage.getItem('token'));
  //       // const isPropAdmin = localStorage.role.split(',').includes('Administrador de objetivos');

  //       let propuestas = res.data.map(proposal => {
  //         let propuesta = undefined
  //         if (proposal.borrador === true) {
  //           if (proposal.usuario.usuario.id_auth === Number(token.uid)) {
  //             propuesta = proposal
  //           }
  //         } else {
  //           propuesta = proposal
  //         }

  //         return propuesta
  //       }).filter(propuesta => propuesta !== undefined)

  //       if (Object.keys(this.state.proposal).length !== 0) {
  //         let propuestaSeleccionada = res.data.filter(proposal => proposal.id === this.state.proposal.id)
  //         state.proposal = propuestaSeleccionada[0]
  //       }

  //       state.proposals = propuestas
  //       state.proposalsOriginal = propuestas
  //       state.proposalsFiltered = propuestas
  //       // state.proposalsFiltered = res.data

  //       let userProposal = this.getMyProposal(res.data) !== undefined
  //       if (userProposal) {
  //         // userProposal.IsUserProposal = true
  //         state.hasProposal = true
  //       }

  //       state.isLoading = false
  //       state.usuario = this.state.usuario
  //       this.setState(state);

  //       // let usuarios = this.filteredUsuarios()
  //       // this.setState({usuarios: usuarios})
  //       this.handleSetViewedChanges()
  //       // this.setState({
  //       //   proposalsFiltered: isPropAdmin ? res.data : res.data.filter(proposal =>
  //       //     areas.map(area => area.id).includes(proposal.usuario.area)
  //       //   ),
  //       //   // TODO ::
  //       //   hasProposal: this.getMyProposal(res.data) !== undefined,
  //       //   usuarios: this.filteredUsuarios()
  //       // })
  //       // this.showLoading(false)
  //     })
  //     .catch(err => {
  //       this.showLoading(false)
  //       if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al filtrar propuestas')
  //     })
  // }

  /** Carga las propuestas */
  loadProposals = () => {
    PropuestaAPI.getAll()
      .then(res => {
        let token = decode(localStorage.getItem('token'));
        let state = { ...this.state }
        let propuestas = res.data.map(proposal => {
          let propuesta = undefined
          if (proposal.borrador === true) {
            if (proposal.usuario.usuario.id_auth === Number(token.uid)) {
              propuesta = proposal
            }
          } else {
            propuesta = proposal
          }

          return propuesta
        }).filter(propuesta => propuesta !== undefined)
        if (Object.keys(this.state.proposal).length !== 0) {
          let propuestaSeleccionada = res.data.filter(proposal => proposal.id === this.state.proposal.id)
          state.proposal = propuestaSeleccionada[0]
        }
        state.proposals = propuestas
        state.proposalsOriginal = propuestas
        state.proposalsFiltered = propuestas

        let userProposal = this.getMyProposal(propuestas);
        if (userProposal) {
          state.hasProposal = true
          userProposal.IsUserProposal = true
        }

        this.setState(state);

        if (Object.keys(this.state.filtros).length !== 0) {
          this.applyFilters(this.state.filtros)
        }

        this.showLoading(false)
        this.handleSetViewedChanges()
      }).catch(err => {
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error('Error al cargar propuestas y usuarios')
        this.showLoading(false)
      })
  }

  /**
   * Busca en las propuestas dadas la propia.
   * 
   * @param {Array} proposals 
   * @returns Object
   */
  getMyProposal(proposals = []) {
    let token = decode(localStorage.getItem('token'));
    let myProposal = undefined
    myProposal = proposals.find(obj => obj.usuario.usuario.id_auth === Number(token.uid))

    return myProposal
  }

  /**
   * En caso de tener indicadores propios o de empleados a nuestro
   * cargo con cambios, se llama a endpoint para quitar la visualizacion
   * de los mismos.
   */
  handleSetViewedChanges = () => {
    let usrUIDToken = this.state.usuario.usuario.id_auth || 0
    let myProposals = this.state.proposalsOriginal.filter(proposal => proposal.usuario.usuario.id_auth === usrUIDToken)
    let subProposals = this.state.proposalsOriginal.filter(proposal => proposal.usuario.id_jefe_directo === usrUIDToken)
    let changedProposals = myProposals.map(proposal => proposal.indicadores)
      .flat().filter(proposal => proposal.edited === true)
    let changedProposalsSub = subProposals.map(proposal => proposal.indicadores)
      .flat().filter(proposal => proposal.edited_jefe === true)

    if (changedProposals.length !== 0) {
      this.setChangesViewed(changedProposals)
    }
    if (changedProposalsSub.length !== 0) {
      this.setChangesViewed(changedProposalsSub)
    }
  }

  componentWillMount() {
    this.handleLoadData()
  }

  /**
   * Llama al endpoint para dejar de ver los cambios en
   * las propuestas.
   * 
   * @param {Array} proposals
   */
  setChangesViewed = (proposals = []) => {
    let myProposals = proposals

    this.showLoading(true)

    Promise.all([
      myProposals.forEach((item) => {
        let data = {
          id_indicador: item.id
        }
        IndicadorAPI.setIndicadorViewed(data)
      })
    ]).catch(err => console.log('Error: ', err))

    this.showLoading(false)
  }

  /**
   * Métodos de utilería para el UX (Búsqueda, modales)
   * 
   * @param {Event} e
   */
  handleSearchInput = e => {
    let text = e.currentTarget.value;

    let list = this.proposals.filter(
      proposal =>
        proposal.objetivo.toLowerCase().includes(text.toLowerCase()) ||
        proposal.indicador.toLowerCase().includes(text.toLowerCase()) ||
        proposal.comentario.toLowerCase().includes(text.toLowerCase()) ||
        proposal.sobre_meta.toLowerCase().includes(text.toLowerCase()) ||
        proposal.meta.toLowerCase().includes(text.toLowerCase()) ||
        proposal.bajo_meta.toLowerCase().includes(text.toLowerCase()) ||
        proposal.inaceptable.toLowerCase().includes(text.toLowerCase()) ||
        proposal.ponderacion.toLowerCase().includes(text.toLowerCase())
    );
    let state = this.state;
    state.filterText = text;
    state.proposals = list;
    this.setState(state);
  };

  /**
   * Muestra el modal de creación así como especificar el título del mismo.
   * 
   * @param {Boolean} isEditing
   * @param {Object} data
   */
  showModal = (isEditing, data) => {
    this.modalTitle = (isEditing ? "Editar" : "Crear") + " propuesta";
    let state = { ...this.state };
    state.showModal = true;
    state.editing = isEditing;
    state.proposal = data;
    this.setState(state);
  };

  /**
   * Muestra modal para crear una nueva propuesta.
   */
  create = () => {
    let state = { ...this.state };
    state.showPropuesta = true;
    this.setState(state)
  };

  /**
   * Muestra el modal de edición así como especificar el título y el módulo a editar.
   * 
   * @param {Array} indicadores
   * @param {Boolean} isDraft
   */
  edit = (indicadores, isDraft) => {
    this.showLoading(true)
    let { proposal, indicadores_eliminar } = { ...this.state };
    let turboIndicators = proposal.indicadores.filter(indicator => indicator.tipo === 'Turbo')
    let proposalIndicators = indicadores

    proposal.indicadores = turboIndicators.concat(proposalIndicators)
    proposal.borrador = isDraft

    PropuestaAPI.edit(proposal.id, { ...proposal, indicadores_eliminar })
      .then(response => {
        let propuesta = response.data.propuesta
        let usuario = { ...propuesta.usuario, ...propuesta.usuario.usuario }
        let token = decode(localStorage.getItem('token'));

        if (usuario.id_auth === Number(token.uid)) {
          propuesta.IsUserProposal = true
        }

        this.setState({
          showPropuestaEdit: false,
          indicadores_eliminar: []
        }, () => { if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message) })

        
        this.setEmptyProposals()
        this.applyFilters(this.state.filtros)

      }).catch(err => {
        if (!toast.isActive(this.toastErrorId))
          this.toastErrorId = toast.error(`Ocurrió un error al actualizar la propuesta: ${err.response.data.message}`)
      })
  };

  /**
   * Agrega el indicador a una lista para poder ser eliminado.
   * 
   * @param {Number} id
   */
  eliminar = (id) => {
    let state = { ...this.state }
    state.indicadores_eliminar.push(id)
    this.setState(state)
  }

  /**
   * Habilita el modal para borrar y asignar el módulo a eliminar
   * 
   * @param {Object} data
   */
  openRemoveModal = data => {
    let state = { ...this.state };
    state.removeModal = true;
    state.proposal = data;
    this.setState(state);
  };

  /**
   * Realiza la petición del API ya sea para crear o editar.
   * 
   * @param {Object} data
   * @param {Boolean} isDraft
   */
  save = (data, isDraft) => {
    this.showLoading(true);

    let propuesta = {
      convocatoria: this.state.convocatoria.id,
      estatus: "Revisión",
      indicadores: data
    }

    propuesta.borrador = isDraft

    PropuestaAPI.create(propuesta)
      .then(response => {
        let state = { ...this.state };
        let propuesta = response.data.propuesta

        propuesta.IsUserProposal = true

        state.hasProposal = true;
        state.showPropuesta = false;
        // state.usuarios.push({ ...propuesta.usuario, ...propuesta.usuario.usuario })
        state.proposals.push(propuesta)

        this.setState(state, () => {
          if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
        });

        this.applyFilters(this.state.filtros)
        this.showLoading(false)

      })
      .catch(err => {
        this.showLoading(false);
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(err.response.data.message);
      })
  };

  /**
   * Guarda los cambios realizados en los objetivos turbo.
   * 
   * @param {Array} addedList
   * @param {Array} removedList
   * @param {Array} modifiedList
   */
  saveChangesOnTurbos = (addedList = [], removedList = [], modifiedList = []) => {
    if (removedList.length !== 0) {
      removedList.map(item => {
        IndicadorAPI.delete(item)
          .then(() => {
            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success('Indicadores eliminados correctamente!')
            this.setEmptyProposals()
            this.applyFilters(this.state.filtros)
          })
          .catch(err => {
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('No se pudierón eliminar los indicadores')
          })
      })
    }

    if (addedList.length !== 0) {
      IndicadorAPI.saveTurbo(addedList)
        .then(() => {
          if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(`Indicadores añadidos`)
          this.setEmptyProposals()
          this.applyFilters(this.state.filtros)
        })
        .catch(err => {
          if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('No se puedierón agregar los indicadores')
        })
    }

    if (modifiedList.length !== 0) {
      modifiedList.map(item => {
        IndicadorAPI.editTurbo(item.id, item)
          .then(() => {
            if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(`Indicadores editados`)
            this.setEmptyProposals()
            this.applyFilters(this.state.filtros)
          })
          .catch(err => {
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('No se pudierón modificar los indicadores')
          })
      })
    }

    this.setState({
      showTurbo: false
    })

  }

  /**
   * Guarda los comentarios realizados a una propuesta.
   * 
   * @param {Object} comentario
   */
  saveComentarios = comentario => {
    this.showLoading(true);
    ComentariosAPI.save(comentario)
      .then(response => {
        this.showLoading(false);
        let state = { ...this.state }
        state.proposal.estatus = "Comentarios"

        let indicador = state.proposal.indicadores.find(indicador => {
          return indicador.id === comentario.indicador
        })

        if (indicador.all_comentarios) indicador.all_comentarios = [...indicador.comentarios, ...indicador.all_comentarios]
        indicador.comentarios = [response.data];
        indicador.validado = false;

        this.setState(state, () => {
          if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success("Comentario agregado correctamente")
        })

      }).catch(err => {
        this.showLoading(false);
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error("Error al agregar el comentario");
      })
  }

  /**
   * Manda a validar un indicador de una propuesta.
   * 
   * @param {Number} id
   */
  validarIndicador = id => {
    this.showLoading(true)
    IndicadorAPI.validate(id)
      .then(() => {
        if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success("Objetivo validado correctamente")
        this.setEmptyProposals()
        this.applyFilters(this.state.filtros)
      }).catch(() => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error("Error al valida el indicador");
      })
  }

  /**
   * Consume el endpoint para borrar un módulo
   */
  remove = () => {
    this.showLoading(true)
    IndicadorAPI.delete({ id: this.state.proposal.id, activo: false })
      .then(response => {
        let state = { ...this.state };
        state.proposals.splice(
          state.proposals.findIndex(t => t.id === state.proposal.id),
          1
        );
        this.proposals = state.proposals;
        state.proposal = null;
        state.removeModal = false;
        this.setState(state);
        this.showLoading(false)
        if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success("Propuesta eliminado");
      })
      .catch(error => {
        console.error("Error: ", error)
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error("error al eliminar indicador");
        this.showLoading(false);
      });
  };

  /**
   * Muestra/Oculta loader.
   * 
   * @param {Boolean} isLoading
   */
  showLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  }

  /**
   * Muestra/Oculta el calculo de la ponderacion de las propuestas.
   */
  handleToggleCompensacion() {
    let { proposalsFiltered, showCompensacion } = this.state
    let proposals = []
    let isCompensacionVisible = !showCompensacion

    if (isCompensacionVisible === true) {
      proposals = proposalsFiltered.map(proposal => {
        let copyProposal = { ...proposal }
        copyProposal.indicadores = proposal.indicadores.map(indicator => {
          let copyIndicator = { ...indicator }
          if (copyIndicator.tipo === 'Normal') {
            copyIndicator.compensacion = calcularCompensacion(copyIndicator, copyProposal.usuario)
          }
          return copyIndicator
        })

        return copyProposal
      })
    } else {
      proposals = proposalsFiltered.map(proposal => {
        let copyProposal = { ...proposal }
        copyProposal.indicadores = proposal.indicadores.map(indicator => {
          let copyIndicator = { ...indicator }
          if (copyIndicator.tipo === 'Normal') {
            delete copyIndicator.compensacion
          }
          return copyIndicator
        })

        return copyProposal
      })
    }

    this.setState({
      showCompensacion: isCompensacionVisible,
      proposalsFiltered: proposals
    });
  }

  /**
   * Abre modal para editar objetivos turbo de una propuesta.
   * 
   * @param {Objetct} propuesta 
   */
  handlerClickNuevoTurbo(propuesta) {
    let state = { ...this.state };
    state.showTurbo = true;
    state.proposal = propuesta;
    this.setState(state);
  }

  /**
   * Borra los datos de las propuestas almacenados en el estado.
   */
  setEmptyProposals = () => {
    this.setState({
      proposals: [],
      proposalsFiltered: [],
      myProposals: [],
      objetivoTurbo: {}
    })
  }

  /**
   * Maneja el cambio al seleccionar un estatus de usuario de las lista.
   * 
   * @param {Event}
   */
  handleChangeEstatusUsuario = ({ target }) => {
    
    let filtros = { ...this.state.filtros }
    const status = target.value 
    
    if (status != '' && status != undefined) {
      filtros.estatus_usuario = status
    }
    else{
      delete filtros.estatus_usuario
    }
    this.setState({
      filtros,
      selectedEstatusUsuario: status
    }, () => this.applyFilters(filtros))
  }

  /**
   * Llama a la API de propuestas con los filtros seleccionados.
   * 
   * @param {Object} filters
   */
  applyFilters = (filters = {}) => {
    let { filtros} = this.state
    this.setEmptyProposals()
    this.showLoading(true)

    PropuestaAPI.getWithFilters(filtros)
      .then(res => {
        const isPropAdmin = localStorage.role.split(',').includes('Administrador de objetivos');
        let token = decode(localStorage.getItem('token'));
        let proposal = {}

        let propuestas = res.data.map(proposal => {
          let propuesta = undefined
          if (proposal.borrador === true) {
            if (proposal.usuario.usuario.id_auth === Number(token.uid)) {
              propuesta = proposal
            }
          } else {
            propuesta = proposal
          }

          return propuesta
        }).filter(propuesta => propuesta !== undefined)
        // proposalsFiltered: isPropAdmin ? res.data : res.data.filter(proposal =>
        //   areas.map(area => area.id).includes(proposal.usuario.area)

        if (Object.keys(this.state.proposal).length !== 0) {
          let propuestaSeleccionada = res.data.filter(proposal => proposal.id === this.state.proposal.id)
          proposal = propuestaSeleccionada.length > 0 ? propuestaSeleccionada[0] : {}
        }

        this.setState({
          proposalsFiltered: propuestas,
          proposal : proposal,
          hasProposal: this.getMyProposal(propuestas) !== undefined,
          usuarios: this.filteredUsuarios()
        })
        this.showLoading(false)
      })
      .catch(err => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al filtrar propuestas')
      })
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de usuarios.
   * 
   * @param {Array} userList
   */
  handleChangeUser = (userList = []) => {
    let filtros = { ...this.state.filtros }

    if (userList.length === 0) {
      delete filtros.usuario
    } else {
      filtros.usuario = userList.map(user => user.id).join(",")
    }

    this.setState({ filtros })
    this.showLoading(true)
    this.applyFilters(filtros)
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de estatus.
   * 
   * @param {Array} estatusList
   */
  handleChangeEstatus = (estatusList = []) => {
    let filtros = { ...this.state.filtros }

    if (estatusList.length === 0) {
      delete filtros.estatus
    } else {
      filtros.estatus = estatusList.map(estatus => {
        if (estatus.value === 'Autorizado')
          return 'Validado'
        else
          return estatus.value
      }).join(",")
    }

    this.setState({ filtros })
    this.showLoading(true)

    this.applyFilters(filtros)
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de gerencia.
   * 
   * @param {Array} gerenciaList
   */
  handleChangeGerencia = (gerenciaList = []) => {
    let filtros = { ...this.state.filtros }
    let gerenciasIds = []
    let listAreas = []
    let listAreasOriginal = this.state.areasOriginal
    let listUsers = []
    let listUsersOriginal = this.state.listaUsuariosOriginal

    if (gerenciaList.length === 0) {
      delete filtros.gerencia
      delete filtros.area
      delete filtros.usuario
      listAreas = listAreasOriginal
      listUsers = listUsersOriginal
    } else {
      gerenciasIds = gerenciaList.map(gerencia => gerencia.id)
      listAreas = this.getAreasFilteredByManagement(gerenciaList, listAreasOriginal)
      listUsers = this.getUsersFilteredByArea(listAreas, listUsersOriginal)
      filtros.gerencia = gerenciaList.map(gerencia => gerencia.id).join(",")
    }

    this.setState({
      filtros,
      areas: listAreas,
      listaUsuarios: listUsers
    }, () => {
      this.applyFilters(filtros)
      this.showLoading(true)
    })
  }

  /**
   * Filtra las áreas según la lista de gerencias.
   * 
   * @param {Array} listManagements 
   * @param {Array} listAreasOriginal
   * @returns areasList
   */
  getAreasFilteredByManagement(listManagements = [], listAreasOriginal = []) {
    let areasList = []
    listManagements.forEach(management =>
      listAreasOriginal.forEach(area => {
        if (area.gerencia === management.id)
          areasList.push(area)
      })
    )

    return areasList
  }

  /**
   * Filtra los usuarios según la lista de áreas.
   * 
   * @param {Array} listAreas
   * @param {Array} listUsersOriginal
   * @returns usersList
   */
  getUsersFilteredByArea(listAreas = [], listUsersOriginal = []) {
    let usersList = []
    listAreas.forEach(area =>
      listUsersOriginal.forEach(user => {
        if (user.area === area.id)
          usersList.push(user)
      })
    )

    return usersList
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de areas.
   * 
   * @param {Array} areasList
   */
  handleChangeArea = (areasList = []) => {
    let filtros = { ...this.state.filtros }
    let listUsers = []
    let listUsersOriginal = this.state.listaUsuariosOriginal

    if (areasList.length === 0) {
      delete filtros.area
      delete filtros.usuario
      listUsers = this.getUsersFilteredByArea(this.state.areas, listUsersOriginal)
    } else {
      listUsers = this.getUsersFilteredByArea(areasList, listUsersOriginal)
      filtros.area = areasList.map(area => area.id).join(",")
    }

    this.setState({
      filtros,
      listaUsuarios: listUsers
    }, () => {
      this.applyFilters(filtros)
      this.showLoading(true)
    })
  }

  /**
   * Asigna los valores editados del objetivo turbo
   * al estado del componente.
   * 
   * @param {Event} e
   */
  handleTurboChange = (e) => {
    let indicador = { ...this.state.indicador }
    let name = e.target.name
    let value = e.target.value

    indicador[name] = value

    this.setState({ indicador: indicador })
  }

  /**
   * Maneja los cambios al introducir un valor
   * al input de objetivo(en proceso).
   * 
   * @param {Event} e
   */
  handleChangeObjetivo = (e) => {
    let filtros = { ...this.state.filtros }
    let value = e.target.value
    let objetivos = this.state.proposalsFiltered
    let filteredProposal = []

    filtros.objetivo = value
    if (value !== '') {
      filteredProposal = this.setCoincidences(value, objetivos)
    } else {
      filteredProposal = this.state.proposalsOriginal
    }

    this.setState({
      filtros,
      proposalsFiltered: filteredProposal
    })
  }

  /**
   * Filtra la lista de datos del campo, segun el texto introducido al campo.
   * 
   * @param {String} content
   */
  setCoincidences = (content = '', listProposals = []) => {
    let value = content.toLocaleLowerCase()
    let expression = new RegExp(value, 'g')
    let listValues = listProposals

    let listItems = listValues.map(proposal => {
      let matchProposals = []
      matchProposals = proposal.indicadores.map(indicador => {
        let regexString = indicador.objetivo
        let coincidences = regexString.toLowerCase().match(expression) || 0

        if (coincidences.length > 0) return indicador
      })
      let noUndefinedValues = matchProposals.filter(item => item !== undefined)
      if (noUndefinedValues.length !== 0) return proposal
    })

    if (listItems.length !== 0) {
      return listItems.filter(item => item !== undefined)
    } else {
      return listProposals
    }
  }

  /**
   * Obtiene los comentarios previos de una propuesta
   * 
   * @param {Number} id
   */
  getPreviousComments = (id) => {
    let state = { ...this.state };

    ComentariosAPI.getAll(id)
      .then(response => {
        let comentarios = response.data;

        let propuesta = state.proposals.find(proposal => {
          return proposal.indicadores.find(indicador => indicador.id === id)
        })

        let indicador = propuesta.indicadores.find(indicador => indicador.id === id)
        let lastComentario = indicador.comentarios[0];

        indicador.all_comentarios = comentarios.filter(comentario => comentario.id !== lastComentario.id);

        this.setState(state);
      })
      .catch(err => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(`Ocurrió un error al cargar los comentarios anteriores: ${err.response.message}`)
      })
  }

  /**
   * Autoriza la propuesta.
   * 
   * @param {Number} id Id de la propuesta a autorizar.
   */
  autorizar = id => {
    
    this.showLoading(true)
    PropuestaAPI.authorize(id)
      .then(response => {
        let { proposals } = { ...this.state }

        proposals = proposals.map(item => {
          if (item.id === id) item = { ...item, ...response.data }
          return item
        })

        this.setState({ proposals: proposals })

        if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success(response.data.message)
        
        this.setEmptyProposals()
        this.applyFilters(this.state.filtros)
      })
      .catch(err => {
        if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(`Ocurrió un error al autorizar la propuesta: ${err.response.data.message}`)
      })
  }

  /**
   * Crea un nuevo objetivo turbo.
   */
  handleCreateObjetivoTurbo = () => {
    this.setState({ isLoadingTurboMasivo: true })
    let idsUsuarios = this.state.selectedUsers.map(user => user.id)
    let idsPropuestas = this.state.proposalsFiltered.filter(proposal => proposal.convocatoria.id === this.state.convocatoria.id)
      .map(proposal => {
        let copyProposal = {
          id: proposal.id,
          userId: proposal.usuario.usuario.id
        }

        return copyProposal
      })

    let ids = idsUsuarios.length
    if (
      this.checkObjetivoTurbo(this.state.objetivoTurbo) &
      ids > 1
    ) {
      let nuevoObjetivoTurbo = {
        propuestas: idsPropuestas.filter(proposal => idsUsuarios.includes(proposal.userId)).map(proposal => proposal.id),
        ...this.state.objetivoTurbo
      }

      IndicadorAPI.saveMultiple(nuevoObjetivoTurbo).then(() => {
        if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success('Objetivo turbo creado!')
        this.setState({
          nuevoObjetivoTurbo: false,
          isLoadingTurboMasivo: false
        })
        this.setEmptyProposals()
        this.handleLoadData()
      }).catch(() => {
        if (!toast.isActive(this.toastWarnId)) this.toastWarnId = toast.warn('Ocurrio un error al crear el objetivo turbo')
        this.setState({ isLoadingTurboMasivo: false })
      })

    } else {
      if (!toast.isActive(this.toastWarnId)) this.toastWarnId = toast.warn('Favor de llenar todos los campos correctamente y seleccionar al menos 2 usuarios')
      this.setState({ isLoadingTurboMasivo: false })
    }
  }

  /**
   * Valida el indicador turbo.
   * 
   * @param {Object} objetivo Indicador turbo.
   * @returns isValid
   */
  checkObjetivoTurbo(objetivo) {
    let checkObjetivo = { ...objetivo }
    let isValid = false
    let objetivoLength = Object.keys(checkObjetivo).length

    for (let i = 0; i < objetivoLength; i++) {
      let property = checkObjetivo[Object.keys(checkObjetivo)[i]]
      let key = Object.keys(checkObjetivo)[i]
      if (key === 'ponderacion') {
        let value = checkObjetivo[key]
        let patron = /^[0-9]+$/
        let intValue = parseInt(value, 10)
        if (
          !patron.test(value) ||
          intValue > 100 ||
          intValue <= 0
        ) {
          isValid = false
        } else {
          isValid = true
        }
      } else {
        if (
          property === null ||
          property === '' ||
          objetivoLength !== 5
        ) {
          isValid = false
        } else {
          isValid = true
        }
      }
      if (!isValid) return isValid
    }
    return isValid
  }

  /**
   * Autorización masiva de las propuestas en pantalla.
   */
  handleAutorizacionMasiva = () => {
    let state = { ...this.state }
    let proposals = {
      propuestas: state.proposalsToAutorize.map(proposal => proposal.id)
    }

    this.showLoading(true)

    PropuestaAPI.masiveAuthorize(proposals).then(() => {
      if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success('Las propuestas fueron autorizadas correctamente')

      this.setState({
        showModalAutorizacionMasiva: false,
        proposalsToAutorize: []
      })

      this.setEmptyProposals()
      this.handleLoadData()
      this.showLoading(false)
    }).catch(err => {
      this.showLoading(false)
      if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(`Ocurrió un error al autorizar las propuestas`)
    })
  }

  /**
   * Abre modal de indicador turbo.
   */
  handleOpenModalObjetivoTurbo = () => {
    this.setState({ nuevoObjetivoTurbo: true })
  }

  /**
   * Cierra modal de nuevo indicador turbo.
   */
  handleCloseModalNuevoObjetivoTurbo = () => {
    this.setState({ nuevoObjetivoTurbo: false })
  }

  /**
   * Abre el modal para la autorización masiva.
   */
  handleOpenModalAutorizacionMasiva = () => {
    let state = { ...this.state }
    let proposals = state.proposalsFiltered.filter(proposal => !proposal.autorizada)

    this.setState({
      showModalAutorizacionMasiva: true,
      proposalsToAutorize: proposals
    })
  }

  /**
   * Cierra modal de autorización masiva de propuestas
   */
  handleCloseModalAutorizacionMasiva = () => {
    this.setState({
      showModalAutorizacionMasiva: false,
      proposalsToAutorize: []
    })
  }

  /**
   * Actualiza la informacion del objetivo turbo y la lista de
   * usuarios asignados a dicho objetivo.
   * 
   * @param {Object} info Información del objetivo turbo.
   */
  handleChangeIndicador = (info = {}) => {
    this.setState({
      objetivoTurbo: { ...info.indicador },
      selectedUsers: info.users
    })
  }

  /**
   * Abre el modal del historial de un indicador.
   * 
   * @param {Object} proposal Propuesta a desplegar en el modal.
   */
  handeOpenModalHistorial = (proposal = {}) => {
    this.setState({
      proposal: proposal,
      isHistorialModalVisible: true
    })
  }

  /**
   * Cierra el modal del historial de un indicador.
   */
  handleCloseModalHistorial = () => {
    this.setState({
      proposal: {},
      isHistorialModalVisible: false
    })
  }

  /**
   * Genera y descarga un excel con las propuestas.
   */
  handleDownloadProposals = () => {
    let proposals = this.state.proposalsFiltered
    
    this.setState({ isDownloadingExcel: true })
    try {
      let [infoRows, columnNames] = this.formatProposalsToExcel(proposals)
      generateExcel('Propuestas', infoRows, columnNames)
      this.setState({ isDownloadingExcel: false })
    } catch (err) {
      if (!toast.isActive(this.toastInfoId)) this.toastInfoId = toast.info('No se generó el archivo Excel')
    }
  }

  /**
   * Obtiene las columnas necesarias para generar el Excel de Propuestas.
   * 
   * @param {Array} proposal 
   * @param {String} type 
   */
  getNecesaryColumns(proposal = [], type = '') {
    return proposal.indicadores.filter(indicador => indicador.tipo === type).map(indicador => {
      let keys = Object.keys(indicador)
      let values = keys.map(key => {
        if (!IGNORE_COLUMNS.includes(key)) {
          if (key === 'validate_user' || key === 'compensacion') {
            if (key === 'validate_user' && type === 'Normal') {
              let usuario = this.state.listaUsuariosOriginal.filter(user => user.id === indicador.validate_user)
              return usuario[0] ? usuario[0].usuario.nombre : 'Sin asignación'
            }
            if (key === 'compensacion' && type === 'Normal' && this.state.showCompensacion) {
              if (!HasPermission(['Administrador de objetivos']) || (HasPermission(['Administrador de objetivos']) && (proposal.usuario.id === this.state.usuario.id || proposal.usuario.jefe_directo === this.state.usuario.usuario.id))) {
                let compensacion = calcularCompensacion(indicador, proposal.usuario)
                return `SM = ${compensacion['SM']} \n M = ${compensacion['M']} \n BM = ${compensacion['BM']}`
              } else {
                return ` `
              }
            }
          } else {
            return indicador[key]
          }
        }
      }).filter(value => value !== undefined && value !== '')
      return values
    })
  }

  /**
   * Da formato a las propuestas, retorna un arreglo con el set de
   * datos formateado, los nombres de las hojas, los nombres de 
   * las colunas y las columnas a ignorar.
   * 
   * @param {Array} proposals Arreglo de propuestas
   * @returns {Array} `[infoRows,columnsNames]`
   */
  formatProposalsToExcel(proposals = []) {
    // debugger
    let infoRows = []
    const columnsNames = this.state.solo_turbos ? 
    {
      turbos: [
        "OBJETIVO", "INDICADOR", "COMENTARIO",
        "SOBRE META", "PONDERACIÓN"
      ]
    } :
    {
      normales: [
        "OBJETIVO", "INDICADOR", "COMENTARIO", "SOBRE META",
        "META", "BAJO META", "INACEPTABLE",
        "PONDERACIÓN", "USUARIO QUE VALIDA"
      ],
      turbos: [
        "OBJETIVO", "INDICADOR", "COMENTARIO",
        "SOBRE META", "PONDERACIÓN"
      ]
    }

    if (proposals[0].indicadores[0].compensacion && this.state.showCompensacion) {
      columnsNames.normales.push("COMPENSACIÓN")
    }

    proposals.map(proposal => {
      if((this.state.solo_turbos && proposal.indicadores.filter(indicador => indicador.tipo === 'Turbo').length > 0) || !this.state.solo_turbos){
        let proposalInfo = {}
        let normales = this.state.solo_turbos ? [] : this.getNecesaryColumns(proposal, 'Normal')
        let turbos = this.getNecesaryColumns(proposal, 'Turbo')
        let info = {
          codigo: proposal.indicadores.filter(indicador => indicador.tipo === 'Normal')[0].codigo.substring(0, 4),
          nombre_usuario: proposal.usuario.usuario.nombre,
          estatus: proposal.estatus,
          ponderacion_total: proposal.indicadores.filter(indicador =>
            indicador.tipo === 'Turbo'
          ).reduce((acc, ind) => acc + ind.ponderacion, 0)
        }

        proposalInfo.proposals = {
          normales,
          turbos
        }

        proposalInfo.info = info

        infoRows.push(proposalInfo)

      }      
    })

    return [infoRows, columnsNames]
  }

  /**
   * Retorna los botones para el modal de autorizacion masiva.
   * 
   * @returns Buttons
   */
  footerModalAutorizacionMasiva = () => {
    const PROPOSALS = this.state.proposalsToAutorize
    const CANCEL_TEXT = PROPOSALS.length === 0 ?
      'Cerrar' : 'No, cancelar'
    return <Fragment>
      <Button
        onClick={this.handleCloseModalAutorizacionMasiva}
        text={CANCEL_TEXT}
        type="is-danger"
        icon="fa-close"
      />
      <Button
        disabled={PROPOSALS.length === 0}
        onClick={this.handleAutorizacionMasiva}
        text="Si, autorizar"
        icon="fa-check-circle"
      />
    </Fragment>
  }

  /**
   * Retorna el componente para renderizar el tag de los tipos de propuestas.
   * 
   * @param {String} typeProposal
   * @returns Component
   */
  tagProposalType(typeProposal) {
    let tagText = ''
    let iconClass = ''
    let colorClass = ''
    let colorBorder = ''

    switch (typeProposal) {
      case 'propia':
        tagText = 'Propuesta propia'
        iconClass = 'fa-star'
        colorClass = 'has-text-warning'
        colorBorder = 'rgb(244, 226, 0)'
        break
      case 'jefe':
        tagText = 'Jefe de propuesta'
        iconClass = 'fa-info-circle'
        colorClass = 'has-text-info'
        colorBorder = 'rgb(0, 165, 244)'
        break
      case 'asignado':
        tagText = 'Propuesta asignada'
        iconClass = 'fa-info-circle'
        colorClass = 'has-text-success'
        colorBorder = 'rgb(0, 203, 34)'
        break
      default:
        return
    }

    return <span
      className={`tag is-light ml-10`}
      style={{
        border: `1px solid ${colorBorder}`
      }}
      title={`Tipo de propuesta`}
    >
      <span
        className={`icon is-small ${colorClass}`}
      >
        <i className={`fa ${iconClass}`} />
      </span>
      <span style={{ marginLeft: '3px' }}>{tagText}</span>
    </span>
  }

  /**
   * Obtiene el componente tag segun el tipo de propuesta.
   * `Propuesta propia`,`Propuesta de usuario asignado`, `Propuesta de usuario subordinado`.
   * 
   * @param {Array} userProposals 
   * @param {Array} subsProposals 
   * @param {Array} othersProposals 
   * @param {Number} idProposal 
   * @returns Component
   */
  getTagByProposalType(userProposals = [], subsProposals = [], othersProposals = [], idProposal) {
    let proposalType = ''
    // if (userProposals.includes(idProposal)) {
    //   proposalType = 'propia'
    // } else if (subsProposals.includes(idProposal)) {
    //   proposalType = 'jefe'
    // }
    if (othersProposals.includes(idProposal)) {
      proposalType = 'asignado'
    }

    return this.tagProposalType(proposalType)
  }

  /**
   * Cierra modal edicion de propuesta
   */
  handleCloseModalEditProposal = () => {
    this.setState({
      showPropuestaEdit: false
    })
  }

  /**
   * Cierra modal de modo presentacion
   */
  handleCloseModalModoPresentacion = () => {
    this.setState({
      showModoPresentacion: false,
      proposal: {}
    })
  }

  /**
   * Maneja los cambios en el Select de `Periodo`
   * 
   * @param {Event} target
   */
  handleChangePeriod = ({ target }) => {
    const { currentPeriod } = this.state
    const period = Number(target.value) === 0
      ? currentPeriod : Number(target.value)
    let filtros = { ...this.state.filtros }

    if (period !== 0) {
      filtros.periodo = period

      this.loadPeriodInfo(period)

      this.setState({
        selectedPeriod: period,
        filtros,
      }, () => {
        this.applyFilters(filtros)
        this.showLoading(true)
      })
    } else {
      toast.error('Debe seleccionar un periodo válido')
      return
    }
  }

  /**
   * Asigna la información del periodo seleccionado.
   * 
   * @param {Number} periodId
   */
  loadPeriodInfo = (periodId) => {
    ConvocatoriaAPI.get(periodId)
      .then(res => {
        this.setState({
          openConvocatoria: this.isConvocatoryOpen(res.data),
          convocatoria: res.data,
          selectedPeriod: res.data.id,
        })
      })
      .catch(err => {
        toast.error('Ocurrio un error con la convocatoria seleccionada')
        console.log(err)
      })
  }

  /**
   * Verifica si la convocatoria está abierta para dar de alta una propuesta.
   * 
   * @param {Object} convocatory
   * @return isOpen
   */
  isConvocatoryOpen = (convocatory = {}) => {
    if(convocatory.fecha_inicio_alta == undefined){
      return false
    }
    let currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    let convocatoryStartDate = moment(convocatory.fecha_inicio_alta).format('YYYY-MM-DD HH:mm:ss')
    let convocatoryEndDate = moment(convocatory.fecha_fin_alta).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss')

    return (currentDate >= convocatoryStartDate) && (currentDate <= convocatoryEndDate)
  }

  render() {
    const {
      currentPeriod, listPeriods, usuario,
      proposalsFiltered, openConvocatoria,
      selectedPeriod, hasProposal, solo_turbos, isGerencial, selectedEstatusUsuario, listEstatusUsuario
    } = this.state
    const usrIdToken = Number(decode(localStorage.getItem('token')).uid)
    const isOpenConvocatoria = openConvocatoria
    const isAdmin = localStorage.role.split(',').includes('Administrador')
    const isPropAdmin = localStorage.role.split(',').includes('Administrador de objetivos');
    const userProposals = proposalsFiltered.filter(
      proposal => proposal.IsUserProposal || proposal.usuario.usuario.id_auth === usrIdToken
    ).map(proposal => proposal.id)
    const subsProposals = proposalsFiltered.filter(
      proposal => proposal.usuario.id_jefe_directo === usrIdToken
    ).map(proposal => proposal.id)
    const othersProposals = isPropAdmin ? proposalsFiltered : proposalsFiltered.filter(
      proposal => proposal.usuario.usuarios_asignados.includes(usuario.usuario.id)
    ).map(proposal => proposal.id)


    return (
      <Fragment>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title is-size-4"> Propuestas </p>
            <span className="card-header-icon" aria-label="more options">
                {
                  (isAdmin || isGerencial) &&
                  <Button
                    icon="fa-reply-all"
                    onClick={this.handleOpenModalAutorizacionMasiva}
                    text="Autorización masiva"
                    type="is-warn"
                    disabled={
                      proposalsFiltered.length === 0 ||
                      !openConvocatoria
                    }
                  />
                }
                

              <HasAccess roles={["Administrador"]}>
                <Button
                  icon="fa-fire"
                  onClick={this.handleOpenModalObjetivoTurbo}
                  text="Agregar objetivo turbo masivo"
                  type="is-info"
                  disabled={
                    proposalsFiltered.length === 0 ||
                    !openConvocatoria
                  }
                />
              </HasAccess>

              <Button
                icon="fa-plus-circle"
                onClick={this.create.bind(this)}
                text="Nueva propuesta"
                disabled={false}//{!openConvocatoria || hasProposal || selectedPeriod == null}
              />

              <CompensacionButton
                showCompensacion={this.state.showCompensacion}
                toggleCompensacion={this.handleToggleCompensacion.bind(this)}
                disabled={proposalsFiltered.length === 0}
              />

              <Button
                text="Descargar propuestas"
                icon="fa-file-excel-o"
                onClick={this.handleDownloadProposals}
                type="secondary"
                disabled={
                  this.state.isDownloadingExcel ||
                  proposalsFiltered.length === 0
                }
              />
            </span>
          </header>

          <div className="card-content">
            <div className="content">
              <div>
                <h5 className="title is-5 has-text-grey">Filtrar por:</h5>
              </div>
              <div className="columns">
                <MultiSelect
                  label="Gerencia"
                  name="gerencia"
                  disabled={this.state.gerencias.length === 0}
                  value={this.state.filtros_proto.gerencia.value}
                  items={this.state.gerencias.map(gerencia => {
                    let newGerencia = { ...gerencia }
                    newGerencia.value = gerencia.nombre

                    return newGerencia
                  })}
                  onChangeValue={gerenciaList => this.handleChangeGerencia(gerenciaList)}
                />

                <MultiSelect
                  label="Área"
                  name="area"
                  disabled={this.state.areas.length === 0}
                  value={this.state.filtros_proto.area.value}
                  items={this.state.areas.map(area => {
                    let newArea = { ...area }
                    newArea.value = area.nombre

                    return newArea
                  })}
                  onChangeValue={areasList => this.handleChangeArea(areasList)}
                />

                <MultiSelect
                  label="Usuario"
                  name="usuario"
                  disabled={this.state.listaUsuarios.length === 0}
                  items={this.state.listaUsuarios.map(usuario => {
                    let newUsuario = { ...usuario }
                    newUsuario.id = usuario.usuario.id
                    newUsuario.value = usuario.usuario.nombre

                    return newUsuario
                  })}
                  onChangeValue={userList => this.handleChangeUser(userList)}
                />
                <Wrapper label="Estatus Usuario">
                  <Select
                    label="Estatus Usuario"
                    classes="input"
                    name="estatus_usuario"
                    value={selectedEstatusUsuario}
                    change={this.handleChangeEstatusUsuario}
                    items={listEstatusUsuario}
                    disabled={listEstatusUsuario.length === 0}
                    optionProps={{ value: "id", text: "value" }}
                  />
                </Wrapper>   
              </div>
              <div className="columns">
                <MultiSelect
                  label="Estatus Propuesta"
                  name="estatus"
                  disabled={this.state.estatus.length === 0}
                  items={this.state.estatus.map(estatus => {
                    let newEstatus = { ...estatus }
                    newEstatus.value = estatus.value

                    return newEstatus
                  })}
                  onChangeValue={estatusList => this.handleChangeEstatus(estatusList)}
                />

                <Wrapper label="Periodo">
                  <Select
                    classes="input"
                    name="periodo"
                    value={selectedPeriod !== 0 ? selectedPeriod : currentPeriod}
                    change={this.handleChangePeriod}
                    items={listPeriods.sort(function (a, b) {
                      return a.id - b.id
                    })}
                    disabled={listPeriods.length === 0}
                    optionProps={{ value: "id", text: "nombre" }}
                  />
                </Wrapper>

                <Wrapper label="Objetivos turbo">
                  <input
                      className='switch'
                      type='checkbox'
                      checked= {solo_turbos}
                      name='auto'
                      onChange={() => {}}
                  />
                  <label
                      htmlFor='Objetivos turbo'
                      onClick={() => {
                        this.setState({solo_turbos : !solo_turbos})
                        this.applyFilters()
                      }}
                  />
                </Wrapper>
              </div>
            </div>
          </div>
        </div>

        <HasAccess roles={["Administrador"]}>
          {
            this.state.showTurbo &&
            <Turbo
              show={this.state.showTurbo}
              propuesta={this.state.proposal}
              showCompensacion={this.state.showCompensacion}
              cancel={() => { this.setState({ showTurbo: false }) }}
              save={(added, removed, modified) => this.saveChangesOnTurbos(added, removed, modified)} />
          }
        </HasAccess>

        {
          this.state.isLoading ?
            <Loading
              isFullscreen={true}
              isLoading={this.state.isLoading}
              width="100px"
              height="100px"
            />
            :
            this.state.proposalsFiltered.length > 0 ?
              (
                this.state.proposalsFiltered.map(obj => {
                  if((solo_turbos && obj.indicadores.filter(ind => ind.tipo === 'Turbo').length > 0) || !solo_turbos){
                    return (
                      <div className="card" key={obj.id}>
                        <div className="card-content CardProposal">
                          <div className="mb-10">
                            <div className="Row">
                              <span className="title is-5" style={{ marginBottom: "0" }}>{obj.usuario.usuario.id_empleado} - {obj.usuario.usuario.nombre}</span>
                              <span
                                className={`tag ${getTagClassByStatus(obj.estatus)} ml-10`}
                                title={`Estatus de la propuesta: ${obj.estatus === "Validado" ? "Autorizada" : obj.estatus}`}
                              >
                                {obj.estatus === 'Validado' ? "Autorizada" : obj.estatus}
                              </span>
                              {this.getTagByProposalType(userProposals, subsProposals, othersProposals, obj.id)}
                              {
                                obj.IsUserProposal &&
                                obj.borrador &&
                                <span
                                  className={`tag is-light ml-10`}
                                  style={{
                                    background: 'rgb(255, 152, 152)',
                                    color: 'white',
                                    border: '1px solid #ff5757'
                                  }}
                                  title={`Tipo de propuesta`}
                                >
                                  <span
                                    className={`icon is-small`}
                                    style={{ color: 'white !important' }}
                                  >
                                    <i className={`fa fa-info-circle`} />
                                  </span>
                                  <span style={{ marginLeft: '3px' }}>Borrador</span>
                                </span>
                              }
                            </div>
  
                            <div className="is-pulled-right is-large Row">
                              { 
                                !solo_turbos &&
                                (obj.autorizada && isAdmin) ?
                                  (
                                    <Button
                                      text="Objetivos turbo"
                                      onClick={() => this.handlerClickNuevoTurbo(obj)}
                                      className="is-small"
                                      icon="fa-plus-circle"
                                      type="is-warning"
                                      disabled={isAdmin ? false : !openConvocatoria}
                                    />
                                  ) : ('')
                              }
  
                              {/* Solo podemos validar una propuesta solo:
                                Si es una propuesta que no es nuestra y si somos jefe directo del usuario dueño de la propuesta,
                                o si somos un usuario con rol Administrador
                            */}
                              {
                                !solo_turbos &&
                                (isAdmin || (!obj.IsUserProposal &&
                                  subsProposals.includes(obj.id))) &&
                                <Button
                                  text="Validar propuesta"
                                  onClick={() => {
                                    let propuesta = { ...obj }
                                    let normalIndicators = propuesta.indicadores.filter(item => item.tipo === "Normal")
                                    propuesta.indicadores = normalIndicators;
                                    this.setState({ showModoValidacion: true, proposal: propuesta })
                                  }}
                                  className="is-small"
                                  icon="fa-check"
                                  disabled={isAdmin ? false : (!openConvocatoria || obj.estatus === "Validado")}
                                  type="is-success"
                                />
                              }
                              {
                                !solo_turbos &&
                                <Button
                                  text="Modo presentación"
                                  onClick={() => this.setState({ showModoPresentacion: true, proposal: obj })}
                                  className="is-small"
                                  icon="fa-image"
                                  type="is-info"
                                />
                              }

                              
  
                              {
                                // El usuario puede editar siempre y cuando
                                // exista una convocatoria (openConvocatoria) en curso
                                // y la propuesta no esté autorizada aún                                
                                !solo_turbos &&
                                isAdmin || ((isOpenConvocatoria && !obj.autorizada) &&
                                  ((userProposals.includes(obj.id) || subsProposals.includes(obj.id)))) ? (
                                  <Button
                                    text="Editar"
                                    onClick={() => this.setState({ showPropuestaEdit: true, proposal: obj })}
                                    className="is-small"
                                    icon="fa-edit"
                                    type="is-warning"
                                    disabled={isAdmin ? false : !openConvocatoria}
                                  />
                                ) : ('')
                              }
                              {
                                !solo_turbos &&
                                <HasAccess roles={['Administrador']}>
                                  <Button
                                    text={obj.autorizada && obj.estatus === 'Validado' ? "Quitar autorización" : "Autorizar"}
                                    onClick={() => this.autorizar(obj.id)}
                                    className="is-small"
                                    icon={obj.autorizada ? "fa fa-unlock-alt" : "fa fa-lock"}
                                    disabled={isAdmin ? false : !openConvocatoria}
                                  />
                                </HasAccess>
                              }
  
                              
                            </div>
                          </div>
  
                          <Propuestas
                            isPropAdmin={isPropAdmin}
                            propuesta={obj}
                            showHistorial={(proposal) => this.handeOpenModalHistorial(proposal)}
                            showCompensacion={this.state.showCompensacion}
                            editTurbo={indicador => this.setState({ indicador: indicador, showEditTurbo: true })}
                            saveComentarios={(comentario) => this.setState({ proposal: obj }, this.saveComentarios(comentario))}
                            getPrevious={this.getPreviousComments}
                            comentarios={this.state.comentarios}
                            usuario={this.state.usuario.usuario}
                            usuarioCompleto={this.state.usuario}
                            usuarios={this.state.listaUsuariosOriginal}
                            userProposals={userProposals}
                            subsProposals={subsProposals}
                            isOpenConvocatoria={openConvocatoria}
                            isAdmin={isAdmin}
                            onlyTurbos={solo_turbos}
                          />
  
                        </div> {/* div card-content */}
                      </div>
                    )
                  }
                  
                })
              )
              : (
                !this.state.isLoading &&
                <Fragment>
                  <div style={{ textAlign: "center" }}>
                    <span className="tag is-warning is-large mt-10">
                      <NoItems itemName={this.state.filtros.periodo !== undefined ? "propuestas en este periodo" : "propuestas, seleccione un periodo"} />
                    </span>
                  </div>
                </Fragment>
              )
        }

        { /** Modales */}
        {
          this.state.showModal && (
            <ModalComponent
              showModal={this.state.showModal}
              modalTitle={this.modalTitle}
              cancel={() => this.setState({ showModal: false, proposal: null })}
            >
              <ProposalForm
                isEditing={this.state.editing}
                proposal={this.state.proposal}
                save={this.save}
              />
            </ModalComponent>
          )
        }
        <AcceptModal
          isActive={this.state.removeModal}
          done={() => this.remove()}
          cancel={() => {
            let state = { ...this.state };
            state.removeModal = false;
            this.setState(state);
          }}
          modalTitle={"Eliminar propuesta"}
        />

        {/** Elaborar propuesta */}
        {
          this.state.showPropuesta &&
          <Propuesta show={this.state.showPropuesta}
            cancel={() => this.setState({ showPropuesta: false })}
            save={(indicators, isDraft) => this.save(indicators, isDraft)}
            indicadores={[]}
            usuario={this.state.usuario}
            showCompensacion={this.state.showCompensacion}>
            <CompensacionButton
              showCompensacion={this.state.showCompensacion}
              toggleCompensacion={this.handleToggleCompensacion.bind(this)} />
          </Propuesta>
        }

        {/** Editar propuesta */}
        {
          Object.keys(this.state.proposal).length > 0 &&
          this.state.showPropuestaEdit &&
          <PropuestaEdit
            cancel={this.handleCloseModalEditProposal}
            save={(indicators, isDraft) => this.edit(indicators, isDraft)}
            eliminar={this.eliminar}
            propuesta={this.state.proposal}
            usuario={this.state.usuario}
            showCompensacion={this.state.showCompensacion}
          >
            <CompensacionButton
              showCompensacion={this.state.showCompensacion}
              toggleCompensacion={this.handleToggleCompensacion.bind(this)}
            />
          </PropuestaEdit>
        }

        {
          this.state.showModoPresentacion &&
          <ModoPresentacion
            propuesta={this.state.proposal}
            propuestas={this.state.proposals}
            cancel={this.handleCloseModalModoPresentacion}
            usuario={this.state.usuario}
            usuarios={this.state.listaUsuariosOriginal}
            showModalEditNormal={() => this.setState({ showPropuestaEdit: true })}
            showModalEditTurbo={() => this.setState({ showTurbo: true })}
            userProposals={userProposals}
            subsProposals={subsProposals}
            isOpenConvocatoria={isOpenConvocatoria}
            isAdmin={isAdmin}
          />
        }

        {
          this.state.showModoValidacion &&
          <ModoValidacion
            show={this.state.showModoValidacion}
            propuesta={this.state.proposal}
            cancel={() => this.setState({ showModoValidacion: false })}
            saveComentarios={this.saveComentarios}
            validarIndicador={this.validarIndicador}
            getPrevious={this.getPreviousComments}
            comentarios={this.state.comentarios}
          />
        }

        {
          this.state.showEditTurbo &&
          <EditTurbo
            close={() => this.setState({ showEditTurbo: false })}
            IsEditing={true}
            save={() => {
              let indicador = { ...this.state.indicador }
              IndicadorAPI.editTurbo(indicador.id, indicador)
                .then(() => {
                  if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success("Se actualizó el indicador turbo")

                  this.setState({ showEditTurbo: false })
                  this.setEmptyProposals()
                  this.handleLoadData()
                })
                .catch(err => {
                  if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error("Hubo un error al actualizar el indicador turbo: " + err.response.data.message)
                })
            }}
            indicador={this.state.indicador}
            change={this.handleTurboChange}
          />
        }

        {
          this.state.nuevoObjetivoTurbo &&
          <Modal
            title="Nuevo objetivo turbo"
            isVisible={this.state.nuevoObjetivoTurbo}
            onClose={this.handleCloseModalNuevoObjetivoTurbo}
            cancelText="Cancelar"
            confirmText="Guardar y notificar"
            onConfirm={this.handleCreateObjetivoTurbo}
            isLoading={this.state.isLoadingTurboMasivo}
          >
            <ObjetivoTurbo
              onChangeInfo={info => this.handleChangeIndicador(info)}
              filter = {this.state.filtros}
            />
          </Modal>
        }

        {
          this.state.showModalAutorizacionMasiva &&
          <Modal
            title="Autorización masiva"
            isVisible={this.state.showModalAutorizacionMasiva}
            Footer={this.footerModalAutorizacionMasiva}
          >
            {
              this.state.proposalsToAutorize.length === 0 ?
                <span>Todas las propuestas ya se encuentran autorizadas.</span> :
                <Fragment>
                  <span>¿Está seguro que desea autorizar las propuestas de las siguientes personas?</span>
                  <br />
                  <div>
                    {
                      this.state.proposalsToAutorize.map(proposal => {
                        return <p key={proposal.id}>{`- ${proposal.usuario.usuario.nombre}`}</p>
                      })
                    }
                  </div>
                </Fragment>
            }
          </Modal>
        }

        {
          this.state.isHistorialModalVisible &&
          <Modal
            extraClasses="HistoryModal"
            title="Historial de cambios"
            isVisible={this.state.isHistorialModalVisible}
            confirmText="Cerrar historial"
            onConfirm={this.handleCloseModalHistorial}
          >
            <HistorialIndicador proposal={this.state.proposal} />
          </Modal>
        }
      </Fragment >
    );
  }
}

/**
 * Componente para ver/ocultar la columna compensación
 * @param {Object} props 
 */
function CompensacionButton(props) {
  return (
    <Button
      type="is-dark"
      text={`${props.showCompensacion ? "Ocultar" : "Ver"} compensación`}
      onClick={(e) => {
        e.stopPropagation();
        props.toggleCompensacion()
      }}
      icon={props.showCompensacion ? "fa fa-eye-slash" : "fa fa-eye"}
      disabled={props.disabled}
    />
  )
}
