import React from 'react'

/**
 * Componente para ver tabla de descripción de compensaciones
 * de una propuesta.
 * 
 * @param {Object} proposalInfo
 * @param {Array} turbos
 */
export function ExtraInfoCompensationTable({ proposalInfo, turbos }) {
  return (
    <div className="ExtraInfoWrapper">
      {/* <p className="ExtraInfoComentarios">Comentarios</p> */}
      <div className="ExtraInfoRow">
        <p className="ExtraInfoDescr">Compensación Autorizada</p>
        <p className="ExtraInfoValue">{parseFloat(proposalInfo.compensacion_autorizada).toFixed(2)}</p>
        {/* <p className="ExtraInfoComment">Suma de compensación real</p> */}
      </div>
      <div className="ExtraInfoRow">
        <p className="ExtraInfoDescr">% Cumplimiento</p>
        <p className="ExtraInfoValue">{proposalInfo.cumplimiento}</p>
        {/* <p className="ExtraInfoComment">Suma de compensación real/compensación total</p> */}
      </div>
      <div className="ExtraInfoRow">
        <p className="ExtraInfoDescr">Compensación real</p>
        <p className="ExtraInfoValue">{parseFloat(proposalInfo.compensacion_ordinaria).toFixed(2)}</p>
        {/* <p className="ExtraInfoComment">Suma de obejtivos turbo y compensación real</p> */}
      </div>
      {
        Number(proposalInfo.compensacion_turbo) > 0 &&
        turbos.filter(item => item.evaluado).map((item, index) => {
          return (
            <div key={index} className="ExtraInfoRow">
              <p className="ExtraInfoDescr">{`Turbo ${++index}`}</p>
              <p className="ExtraInfoValue">{parseFloat(item.compensacion_real).toFixed(2)}</p>
              {/* <p className="ExtraInfoComment">
                Multiplica el valor del objetivo turbo por la suma de compensación real, solo aparece si el usuario tiene objetivo turbo
              </p> */}
            </div>
          )
        })
      }
      {
        Number(proposalInfo.compensacion_turbo) > 0 &&
        <div className="ExtraInfoRow">
          <p className="ExtraInfoDescr">Compensación Turbos Total</p>
          <p className="ExtraInfoValue">{parseFloat(proposalInfo.compensacion_turbo).toFixed(2)}</p>
          {/* <p className="ExtraInfoComment">
            Multiplica el valor del objetivo turbo por la suma de compensación real, solo aparece si el usuario tiene objetivo turbo
          </p> */}
        </div>
      }
      {
        Number(proposalInfo.compensacion_turbo) > 0 &&
        <div className="ExtraInfoRow">
          <p className="ExtraInfoDescr">Total</p>
          <p className="ExtraInfoValue">{parseFloat(Number(proposalInfo.compensacion_ordinaria) + Number(proposalInfo.compensacion_turbo)).toFixed(2)}</p>
          {/* <p className="ExtraInfoComment">Suma de obejtivos turbo y compensación real</p> */}
        </div>
      }
      {
        Number(proposalInfo.ajuste_proporcional) > 0 &&
        <div className="ExtraInfoRow">
          <p className="ExtraInfoDescr">Ajuste proporcional según carta oferta</p>
          <p className="ExtraInfoValue">{proposalInfo.ajuste_proporcional}</p>
          {/* <p className="ExtraInfoComment">El monto lo asigna administrador y se muestra solo en caso de ser necesario</p> */}
        </div>
      }
      {
        Number(proposalInfo.ajuste_proporcional) > 0 &&
        <div className="ExtraInfoRow">
          <p className="ExtraInfoDescr">Total Ajustado</p>
          <p className="ExtraInfoValue">
            {
              parseFloat(Number(proposalInfo.compensacion_total)).toFixed(2)
            }
          </p>
          {/* <p className="ExtraInfoComment">El monto lo asigna administrador y se muestra solo en caso de ser necesario</p> */}
        </div>
      }
    </div>
  )
}
