import React, { useEffect } from 'react'

// Components
import { Button } from '../Button/Button'

// Styles
import { BSCColumnStyle } from '../../../util/bsc-column-style'
import './IndicadorTabla.css'

/**
 * Componente para rendericar una tabla con la información
 * de los indicadores que se pasen en la propiedad `indicadores`.
 * 
 * @param {Array} indicadores
 * @param {Boolean} isNormal
 * @returns Componente IndicadorTabla
 */
export const IndicadorTabla = ({
  indicadores, isNormal = true, usuarios,
  evaluacion, showModal, autoEvaluacion,
  estatusEvaluacion, openConvocatoria = true,
  isTurbo = false, userRol = ''
}) => {

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th style={BSCColumnStyle.indice} width="7%">#</th>
          <th style={BSCColumnStyle.objetivo}>Objetivo</th>
          <th style={BSCColumnStyle.indicador}>Indicador</th>
          <th style={BSCColumnStyle.comentario}>Comentario</th>
          <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
          {
            isNormal &&
            <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
          }
          {
            isNormal &&
            <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
          }
          {
            isNormal &&
            <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
          }
          {
            usuarios &&
            <th style={BSCColumnStyle.encargado}>
              <abbr title="Usuario encargado de validar el objetivo" width="7%">
                Usuario que valida
              </abbr>
            </th>
          }
          <th style={BSCColumnStyle.ponderacion}>
            <abbr title="Ponderación" width="7%">% Pond.</abbr>
          </th>
          {
            evaluacion &&
            <th style={BSCColumnStyle.ponderacion}>
              <abbr title="Acciones disponibles para los indicadores" width="7%">
                Evaluación
              </abbr>
            </th>
          }
          {
            autoEvaluacion &&
            <th style={BSCColumnStyle.ponderacion}>
              <abbr title="Acciones disponibles para los indicadores" width="7%">
                Acciones
              </abbr>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        {
          indicadores.map((indicador, index )=> {
            return <tr key={indicador.id}>
              <td className="ProposalColAutoEvaluation">{index + 1}</td>
              <td className="ProposalColAutoEvaluation">{indicador.objetivo}</td>
              <td className="ProposalColAutoEvaluation">{indicador.indicador}</td>
              <td className="ProposalColAutoEvaluation">{indicador.comentario}</td>
              <td className="ProposalColAutoEvaluation">{indicador.sobre_meta}</td>
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.meta}</td>
              }
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.bajo_meta}</td>
              }
              {
                isNormal &&
                <td className="ProposalColAutoEvaluation">{indicador.inaceptable}</td>
              }
              {
                usuarios &&
                <td>{handleGetUserValidate(indicador, usuarios)}</td>
              }
              <td>{indicador.ponderacion}</td>
              {
                evaluacion  &&
                <td>
                  {
                    <Button
                      icon={
                        `${
                        indicador.cumplimiento === 'sin_evaluacion' && indicador.tipo !== 'Turbo'
                          ? "fa-ban"
                          : indicador.isEvaluated 
                            ? "fa-check"
                            : estatusEvaluacion == 'Evaluacion en proceso'?
                              "fa-check"
                              : "fa-plus"
                        }`
                      }
                      title={
                        `${
                        indicador.cumplimiento === 'sin_evaluacion' && indicador.tipo !== 'Turbo'
                          ? "El indicador aún no a sido autoevaluado"
                          : indicador.isEvaluated
                            ? "Objetivo evaluado"
                            : "Objetivo sin evaluar"
                        }`
                      }
                      text={
                        `${
                        indicador.cumplimiento === 'sin_evaluacion' && indicador.tipo !== 'Turbo'
                          ? "Sin autoevaluación"
                          : indicador.isEvaluated
                            ?"Evaluado":
                             estatusEvaluacion == 'Evaluacion en proceso'?
                              indicador.comentario_evaluacion?
                                "Evaluado"
                                : "Evaluar"
                              :"Evaluar"
                            
                        }`
                      }
                      type="secondary"
                      onClick={() => showModal(indicador.id)}
                      disabled={isDisabledForMyUser(userRol, estatusEvaluacion, openConvocatoria)}
                    />
                  }
                </td>
              }
              {
                autoEvaluacion &&
                <td>
                  {
                    <Button
                      icon={
                        `${(
                          indicador.cumplimiento !== 'sin_evaluacion' &&
                          indicador.evidencia.length !== 0
                        ) ? "fa-check"
                          : "fa-list"}`
                      }
                      title={
                        `${(
                            indicador.cumplimiento !== 'sin_evaluacion' &&
                            indicador.evidencia.length !== 0
                          ) ? "Objetivo evaluado"
                          : "Evaluar objetivo"}`
                      }
                      text={
                        `${(
                          indicador.cumplimiento !== 'sin_evaluacion' &&
                          indicador.evidencia.length !== 0
                        ) ? "Objetivo evaluado"
                          : "Evaluar"}`
                      }
                      type="secondary"
                      onClick={() => showModal(indicador.id)}
                      disabled={indicador.evaluado}
                    />
                  }
                </td>
              }


            </tr>
          })
        }
      </tbody>
    </table>
  )
}

/**
 * Obtiene el nombre del usuario encargado de validar el objetivo.
 * 
 * @param {Object} indicador
 * @param {Array} usuarios
 * @returns {String} `validate`
 */
const handleGetUserValidate = (indicador, usuarios) => {
  let id_usuario_validate = undefined
  let validate = 'Sin asignación'
  if (usuarios.length === 0)
    return validate
  if (indicador.validate_user !== undefined) {
    if (indicador.validate_user !== null) {
      validate = usuarios.filter(user => user.id === indicador.validate_user)[0]
      if (validate != undefined)
        validate = validate.usuario.nombre
    }
  } else if (indicador.propuesta.indicadores) {
    id_usuario_validate = indicador.propuesta.indicadores.filter(indicator =>
      indicator.codigo === indicador.codigo
    )[0].validate_user

    if (id_usuario_validate !== null) {
      let userValidate = usuarios.filter(user => user.id === id_usuario_validate)[0]
      if (userValidate.usuario)
        validate = userValidate.usuario.nombre
    }
  }

  return validate
}

/**
 * Valida si la propuesta está en un estatus en el cual
 * el ususario puede hacer la validación de los indicadores.
 * 
 * @param {String} userRol
 * @param {String} proposalStatus
 * @param {Boolean} openConvocatory
 * @return {Boolean} `canValidate`
 */
const isDisabledForMyUser = (userRol, proposalStatus, openConvocatory) => {
  let isDisabled = true

  if (openConvocatory) {
    if (userRol === 'Administrador' && proposalStatus !== 'Sin Evaluacion') {
      // Si somos Admin y la propuesta ya fue autoevaluada podemos hacer la evaluación.
      isDisabled = false
    } else if (userRol === 'Jefe Directo' && proposalStatus === 'Evaluado') {
      // Si somos jefe directo del usuario dueño de la propuesta y solo si
      // la propuesta ya fue autoevaluada podemos evaluarla.
      isDisabled = false
    } else if (
      userRol === 'Director Area' &&
      (proposalStatus === 'Evaluacion Aceptada Jefe' || proposalStatus === 'Evaluado')
    ) {
      // Si somos directores de área y la propuesta ya fue autoevaluada o evaluada por
      // el jefe directo del dueño de la propuesta, podemos evaluarla nosotros.
      isDisabled = false
    }
     else {
      isDisabled = true
    }
  } else {
    isDisabled = true
  }

  return isDisabled
}
