import React, { Component, Fragment } from 'react'
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import { toast } from 'react-toastify';
import UsuarioAPI from '../../../services/Usuario';
import Loader from '../../util/loading/Loading'

class UsuariosIndicadores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sincronizando: false,
            loading: false
        }
    }

    handleUsersFortiaInport = () => {
        this.setState({ 
            loading: true 
        });
        UsuarioAPI.postSincronizarUsuarios().then(response => {
            this.setState({ 
                loading: false 
            });
            toast.success(response.data.message);
        }).catch(err => {
            this.setState({ 
                loading: false 
            });
            let message = "Error al sincronizar usuarios";
            if (err && err.response && err.response.data) {
                message = err.response.data.message;
            }
            toast.error(message);
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <Fragment>
                <Card title="Sincronización de usuarios" >
                    <Button
                        icon={this.state.sincronizando ? "fa fa-spinner fa-spin" : "fa-cloud-download"}
                        type="is-info"
                        onClick={this.handleUsersFortiaInport}
                        title="Descargar usuarios FORTIA"
                        text="Descargar usuarios FORTIA"
                        disabled={this.state.sincronizando}
                    />

                    <label>  El proceso puede tardar unos minutos</label>
                </Card>
                {
                    this.state.loading &&
                    <Loader
                        isFullscreen={true}
                        isLoading={this.state.loading}
                        width="100px"
                        height="100px"
                    />
                }
            </Fragment>

            
        )
    }
}

export default UsuariosIndicadores;