import React, { Component, Fragment } from 'react'

// Services
import UserAPI from '../../services/Usuario'

// Styles
import './Usuario.css'

// Utils
import Excel from 'exceljs'
import { generateBasicExcel } from '../../util/ExcelFunctions'
import Loading from '../util/loading/Loading';

// Components
import DragAndDrop from '../util/DragAndDrop/DragAndDrop'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { toast } from 'react-toastify'
import { ExcelFormatRules } from './ExcelFormatRules'
import { Modal } from '../util/modal/ModalLayout'
import { LayoutErrors } from './LayoutErrors'

/** Componente para cargar archivos. */
class UserUpload extends Component {

    state = {
        isRulesVisibles: false,
        isModalLayoutErrorsVisible: false,
        layoutErrors: [],
        isLoading: false
    }

    /**
     * Carga la información para que la aplicación
     * la procese.
     * 
     * @param {Array} data
     */
    handleUploadData = (data = []) => {
        toast.info('Actualización en progreso');
        this.setState({ isLoading: true });
        UserAPI.loadLayout(data).then(() => {
            toast.success('Actualización completada');
            this.setState({ isLoading: false });
            this.props.loadComplete();
        }).catch(err => {
            if ( err.response.data ) {
                //this.downloadExcel(err.response.data);
                this.showModalLayoutErrors(err.response.data, true);
            }
            toast.warn('Algunos de los datos son incorrectos. Favor de revisar archivo descargado.');
            this.setState({ isLoading: false });
        })
    }

    /**
     * Muestra el modal con una lista de los errores del layout.
     * 
     * @param {Array} errors
     */
    showModalLayoutErrors = (errors, resultApi = false) => {
        const listErrors = this.getMessageErrors(errors, resultApi)
        this.setState({
            layoutErrors: listErrors,
            isModalLayoutErrorsVisible: true
        })
    }

    /**
     * Retorna los mensajes de error encontrados en el layout de usuarios.
     * 
     * @param {Array} errors 
     * @returns Errors
     */
    getMessageErrors(errors = [], resultApi = false) {
        let listErrors = []

        if ( errors.message ) {
            return [{
                'fila': '0',
                'message': [ errors.message ]
            }];
        }

        if(resultApi === true){
            let errores = []
            errors.forEach((item, index) => {
                let messages = []
                if (item.mensaje.length > 1){
                    messages = item.mensaje.split(',')
                    messages.forEach(element => {
                        if(element !==' ')
                            errores.push({
                                'fila': index + 2,
                                'message': [element]
                            })
                    });
                    
                }                
            });
            errors = errores
            errores = []

            errors.forEach(error => {
                let existError
                existError = errores.find(rowError => JSON.stringify(rowError.message) === JSON.stringify([error.message]))

                if(existError){
                    errores.find(rowError => JSON.stringify(rowError.message) === JSON.stringify([error.message])).fila = existError.fila +', '+ error.fila
                }
                else{
                    errores.push({
                        'fila': error.fila,
                        'message': [error.message]
                    })
                }   
                
            });

            errors = errores

        }

        listErrors = errors.map((item, index) => {
            let messages = []
            if (item.mensaje) {
                if (item.mensaje.length > 1)
                    messages = item.mensaje.split(',')
            } else if (item.fila) {
                messages = item.message
            }

            return {
                'fila': item.fila || index + 2,
                'message': messages.filter(item => item !== ' ')
            }
        })

        return listErrors.filter(item => item.message.length !== 0)
    }

    /**
     * Descarga la respuesta del servidor a un archivo Excel.
     * 
     * @param {Array} data 
     */
    downloadExcel(data = []) {
        try {
            let [excelProps, infoRows, columnsNames] = this.formatUsersData(data)
            generateBasicExcel(excelProps, infoRows, columnsNames)
        } catch (err) {
            toast.warn('No se pudo generar el archivo Excel')
        }
    }

    /**
   * Formatea la información data para generar excel.
   * 
   * @param {Array} usersList
   * @returns [dataSet,sheetNames,columnsNames,skipColumns]
   */
    formatUsersData(usersList = []) {
        let dataSet = []
        let excelProps = {
            sheetName: 'Usuarios',
            excelName: 'Usuarios'
        }

        let columnsNames = []

        dataSet = usersList.map(user => {
            let userInfo = {
                region: user.region,
                segmento: user.segmento,
                gerencia: user.gerencia,
                razon_social: user.razon_social,
                ubicacion_base: user.ubicacion_base,
                area: user.area,
                grupo: user.grupo,
                jefe_directo: user.jefe_directo,
                monto: user.monto,
                politica: user.politica,
                puesto: user.puesto,
                email: user.email,
                nombre: user.nombre,
                numero_empleado: user.numero_empleado,
                mensaje: user.mensaje
            }
            return userInfo
        })

        columnsNames = (Object.keys(dataSet[0]))

        return [excelProps, dataSet, columnsNames]
    }

    /**
     * Maneja la carga de los archivos.
     * 
     * @param {Array} listLoaded
     */
    handleListFiles = (listLoaded) => {
        const fileInfo = listLoaded[listLoaded.length - 1]
        const validFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const data = listLoaded[listLoaded.length - 1].data
        const workbook = new Excel.Workbook()
        const rows = []

        // Se verifica que la extencion del archivo sea .XLSX
        if (fileInfo.type !== validFileType)
            return toast.error('La extension del layout debe ser de tipo *.XLSX')

        workbook.xlsx.load(data).then(book => {
            const sheet = book.getWorksheet(1)

            sheet.eachRow(row => rows.push(row.values))

            if (!this.checkLayoutFormat(rows[0]))
                throw 'El archivo cargado no tiene las columnas correctas'

            const rowsData = this.getFormatRowsData(rows)

            // Verifica que el archivo contenga información
            if (rowsData.length === 0)
                throw 'El archivo no contiene información de usuarios'

            // Se verifica que no existan campos vacíos
            // y el formato de los campos Email, Nombre, No Empleado
            const [hasErrors, rowsWithErrors] = this.hasErrors(rowsData)
            if (hasErrors) {
                this.setState({
                    layoutErrors: rowsWithErrors
                }, () => this.showModalLayoutErrors(rowsWithErrors))
            } else {
                this.handleUploadData(rowsData)
            }

        }).catch(err => {
            console.log(err)
            if (typeof err === 'object')
                toast.error('Ocurrio un problema')
            if (typeof err === 'string')
                toast.error(err)

        }).finally(() => {
            const element = document.getElementById('FileAttach')
            element.value = ''
        })
    }

    /**
     * Verifica la estructura de las columas del layout cargado.
     * 
     * @param {Array} columns 
     * 
     * @returns isValid
     */
    checkLayoutFormat(columns = []) {
        const correctColumns = [
            'NUMERO EMPLEADO', 'NOMBRE', 'PUESTO', 'GERENCIA',
            'AREA', 'REGION', 'SEGMENTO', 'RAZON SOCIAL',
            'UBICACION BASE', 'MONTO', 'POLITICA',
            'GRUPO', 'EMAIL', 'JEFE DIRECTO', 'ESTATUS' 
        ]
        let isValid = false
        let columnsNotFounded = []
        columns = columns.map(item => item.trim())
        correctColumns.forEach(function (item) {
            if (!columns.includes(item))
                columnsNotFounded.push(item)
        })
        isValid = columnsNotFounded.length === 0

        

        if(!isValid){
            let error = {}
            let columnsError = columnsNotFounded.join(', ')
            error.message = 'No se ha encontrado la columna: ' + columnsError + ' en el archivo.'
            this.showModalLayoutErrors(error);
        }

        return isValid
    }

    /**
     * Da formato al arreglo de renglones, para convertirlo
     * en arreglo de objetos.
     * 
     * @param {Array} rows 
     */
    getFormatRowsData(rows = []) {
        let formattedRows = []

        rows.forEach((row, index) => {
            if (typeof (row[7]) === 'object')
                row[7] = row[7].text
            if (typeof (row[13]) === 'object')
                row[13] = row[13].text
            if (index !== 0) {
                formattedRows.push({
                    numero_empleado: row[1],
                    nombre: row[2] ? row[2].trim() : '',
                    puesto: row[3] ? row[3].trim() : '',
                    gerencia: row[4] ? row[4].trim() : '',
                    area: row[5] ? row[5].trim() : '',
                    region: row[6] ? row[6].trim() : '',
                    razon_social: row[8] ? row[8].trim() : '',
                    ubicacion_base: row[9] ? row[9].trim() : '',
                    segmento: row[7] ? row[7].trim() : '',
                    grupo: row[12] ? row[12].trim() : '',
                    jefe_directo: row[14] ? row[14].trim() : '',
                    monto: row[10],
                    politica: row[11],
                    email: row[13] ? row[13].trim() : '',
                    estatus: row[15] ? row[15].trim().toLowerCase()  : '',
                })
            }
        })

        return formattedRows
    }

    /**
     * Verifica que el `Field` cumpla con los limites
     * permitidos que se establecieron.
     * 
     * @param {Number} min
     * @param {Number} max
     * @param {String} fieldType
     * 
     * @returns errorMsg
     */
    checkFieldLimit(field, min, max, fieldType, fieldName) {
        let errorMsg = ''
        fieldName = fieldName.replace('_', ' ')
        if (fieldType === 'string') {
            if (field.length < min || field.length > max)
                errorMsg = `La columna ${fieldName.toUpperCase()} debe tener entre ${min} y ${max} de carateres.`
        } else if (fieldType === 'number') {
            if (field < min || field > max)
                errorMsg = `El campo ${fieldName.toUpperCase()} debe ser un número entre ${min} y ${max}.`
        }

        return errorMsg
    }

    /**
     * Verifica que el `Email` cumpla con un patron de email.
     * 
     * @param {String} email 
     */
    checkEmailField(email = '') {
        let emailString = ''
        if (typeof (email) === 'object')
            emailString = email.text
        else
            emailString = email
        let errorMsg = ''
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString))
            errorMsg = `Columna EMAIL debe de contar con formato correcto: "nombre.apellido@caffenio.com".`

        return errorMsg
    }

    /**
     * Verifica que el `Nombre` solo contenga letras.
     * 
     * @param {String} name 
     */
    checkNameField(name = '') {
        let errorMsg = ''
        if (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(name))
            errorMsg = `La columna NOMBRE solo debe de contener letras.`

        return errorMsg
    }

    /**
     * Verifica que el `Estatus` solo contenga letras.
     * 
     * @param {String} estatus 
     */
     checkEstatusField(estatus = '') {
        let errorMsg = ''
        if (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(estatus))
            errorMsg = `La columna ESTATUS solo debe contener letras`

        return errorMsg
    }

    /**
     * Verifica que el `Estatus` solo contenga activo o inactivo.
     * 
     * @param {String} estatus 
     */
     checkEstatusContainField(estatus = '') {
        let errorMsg = ''
        if (estatus.toLowerCase() !== 'activo' && estatus.toLowerCase() !== 'inactivo')
            errorMsg = `La columna ESTATUS solo acepta los valores Activo | Inactivo`

        return errorMsg
    }

    /**
     * Verifica que el `Número de empleado` solo contenga números.
     * 
     * @param {Strgin} noEmployee 
     */
    checkNoEmployeeField(noEmployee) {
        let errorMsg = ''
        if (isNaN(Number(noEmployee)))
            errorMsg = `La columna NUMERO EMPLEADO solo debe contener números`

        return errorMsg
    }

    /**
     * Valida campos vacios leidos del Excel,
     * y el formato de los campos `Email`, `Nombre`, `No. Empleado`
     * 
     * @param {Array} rows 
     * @returns Boolean
     */
    hasErrors(rows = []) {
        let hasErrors
        let rowsWithErrors = []

        rows.forEach((row, rowIndex) => {
            const objectKeys = Object.keys(row)
            let currentRowColumns = []
            // debugger
            objectKeys.forEach(item => {
                let rowValue = row[item]
                if (rowValue === '' || rowValue === undefined) {
                    item = item.replace('_', ' ')
                    currentRowColumns.push(`La columna ${item.toUpperCase()} se encuentra vacía`)
                } else {
                    switch (item) {
                        case 'area':
                            let errorAreaMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorAreaMsg !== '')
                                currentRowColumns.push(errorAreaMsg)
                            break
                        case 'ubicacion_base':
                            let errorUbicacionMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorUbicacionMsg !== '')
                                currentRowColumns.push(errorUbicacionMsg)
                            break
                        case 'region':
                            let errorRegionMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorRegionMsg !== '')
                                currentRowColumns.push(errorRegionMsg)
                            break
                        case 'segmento':
                            let errorSegmentoMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorSegmentoMsg !== '')
                                currentRowColumns.push(errorSegmentoMsg)
                            break
                        case 'razon_social':
                            let errorRazonSocialMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorRazonSocialMsg !== '')
                                currentRowColumns.push(errorRazonSocialMsg)
                            break
                        case 'grupo':
                            let errorGroupMsg = this.checkFieldLimit(row[item], 3, 50, 'string', item)
                            if (errorGroupMsg !== '')
                                currentRowColumns.push(errorGroupMsg)
                            break
                        case 'jefe_directo':
                            let errorDirectBossMsg = this.checkFieldLimit(row[item], 2, 50, 'string', item)
                            if (errorDirectBossMsg !== '')
                                currentRowColumns.push(errorDirectBossMsg)
                            break
                        case 'monto':
                            let errorAmountMsg = this.checkFieldLimit(row[item], 0, 999999, 'number', item)
                            if (errorAmountMsg !== '')
                                currentRowColumns.push(errorAmountMsg)
                            break
                        case 'politica':
                            let errorPolicyMsg = this.checkFieldLimit(row[item], 1, 9, 'number', item)
                            if (errorPolicyMsg !== '')
                                currentRowColumns.push(errorPolicyMsg)
                            break
                        case 'puesto':
                            let errorJobTitleMsg = this.checkFieldLimit(row[item], 2, 60, 'string', item)
                            if (errorJobTitleMsg !== '')
                                currentRowColumns.push(errorJobTitleMsg)
                            break
                        case 'email':
                            let errorEmailMsgLimit = this.checkFieldLimit(row[item], 16, 50, 'string', item)
                            let errorEmailMsg = ''
                            if (errorEmailMsgLimit !== '')
                                currentRowColumns.push(errorEmailMsgLimit)
                            else
                                errorEmailMsg = this.checkEmailField(row[item])
                            
                            if (errorEmailMsg !== '')                                
                                currentRowColumns.push(errorEmailMsg)
                            break
                        case 'nombre':
                            let errorNameMsgLimit = this.checkFieldLimit(row[item], 2, 50, 'string', item)
                            let errorNameMsg = ''
                            if (errorNameMsgLimit !== '')
                                currentRowColumns.push(errorNameMsgLimit)
                            else
                                errorNameMsg = this.checkNameField(row[item])
                            if (errorNameMsg !== '')
                                currentRowColumns.push(errorNameMsg)
                            
                            break
                        case 'numero_empleado':
                            let errorNoEmployeeMsgLimit = this.checkFieldLimit(row[item], 1, 999999, 'number', item)
                            let errorNoEmployeeMsg = ''
                            if (errorNoEmployeeMsgLimit !== '')
                                currentRowColumns.push(errorNoEmployeeMsgLimit)
                            else
                                errorNoEmployeeMsg = this.checkNoEmployeeField(row[item])
                            if (errorNoEmployeeMsg !== '')
                                currentRowColumns.push(errorNoEmployeeMsg)
                            
                            break
                        case 'estatus':
                            let errorEstatusMsg = this.checkEstatusField(row[item])
                            let errorEstatusContainMsg = this.checkEstatusContainField(row[item])
                            if (errorEstatusMsg !== '')
                                currentRowColumns.push(errorEstatusMsg)
                            if (errorEstatusContainMsg !== '')
                                currentRowColumns.push(errorEstatusContainMsg)
                            break
                    }
                }
            })

            if (currentRowColumns.length !== 0){

                currentRowColumns.forEach(error => {
                    let existError
                    existError = rowsWithErrors.find(rowError => JSON.stringify(rowError.message) === JSON.stringify([error]))

                    if(existError){
                        rowsWithErrors.find(rowError => JSON.stringify(rowError.message) === JSON.stringify([error])).fila = existError.fila +', '+ (rowIndex + 2).toString()
                    }
                    else{
                        rowsWithErrors.push({
                            'fila': (rowIndex + 2).toString(),
                            'message': [error]
                        })
                    }   
                    
                });
            }
                
        })

        hasErrors = rowsWithErrors.length !== 0
        // debugger

        return [hasErrors, [...new Set(rowsWithErrors)]]
    }


    /**
     * Obtiene la información del archivo cargado:
     *  - `Nombre del archivo`
     *  - `Tamaño`
     *  - `etc...`
     * 
     * @param {Object} file
     */
    handleUploadFile = (file) => {
        // console.log('Carga: ', file)
    }

    handleCloseModalLayoutErrors = () => {
        this.setState({
            isModalLayoutErrorsVisible: false,
            layoutErrors: []
        })
    }

    render() {
        const {
            isRulesVisibles, isModalLayoutErrorsVisible,
            layoutErrors
        } = this.state

        return (
            <Fragment>
                <div className="DescriptionAndRulesWrapper">
                    <h2>
                        Para cargar el archivo de layout de usuarios éste deberá de contar
                        con el formato correcto y los campos necesarios.
                    </h2>
                    <p className="DescriptionAndRulesButtonWrapper">
                        <span
                            className="DescriptionAndRulesButton"
                            onClick={() => this.setState({ isRulesVisibles: !isRulesVisibles })}
                        >
                            {`${isRulesVisibles ? 'Ocultar reglas y formato.' : 'Ver reglas y formato.'}`}
                        </span>
                    </p>
                    {
                        isRulesVisibles &&
                        <ExcelFormatRules />
                    }
                </div>
                <Wrapper label="Archivo">
                    <DragAndDrop
                        name="layout_users"
                        label="Arrastra o da click aquí para cargar layout de usuarios"
                        accepts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onFilesLoaded={listLoaded => this.handleListFiles(listLoaded)}
                        onUploadFile={file => this.handleUploadFile(file)}
                        isMultiple={false}
                    />
                </Wrapper>
                {
                    isModalLayoutErrorsVisible &&
                    <Modal
                        isVisible={isModalLayoutErrorsVisible}
                        title="Errores del archivo Excel cargado"
                        onClose={this.handleCloseModalLayoutErrors}
                        cancelText="Cerrar"
                    >
                        <LayoutErrors errors={layoutErrors} />
                    </Modal>
                }
                {
                    this.state.isLoading &&
                    <Loading
                        isFullscreen={ true }
                        isLoading={ this.state.isLoading }
                        width="100px"
                        height="100px"
                    />
                }
            </Fragment>
        )
    }
}

export default UserUpload