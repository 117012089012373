import React, { Component } from "react";
import Propuesta from './Propuesta'
import BuscarIndicadores from './BuscarIndicadores';
import Confirmar from './Confirmar';
import { getTagClassByStatus } from '../../../util/utileria';
import { toast } from "react-toastify";
import NoItems from "../../util/noitems/NoItems";
import Loading from "../../util/loading/Loading";

// Services
import PropuestasAPI from '../../../services/Propuestas';
import IndicadoresAPI from '../../../services/IndicadoresSvc';
import UsuarioAPI from '../../../services/Usuario';
import { Modal } from "../../util/modal/ModalLayout";
import { IndicadorTabla } from "../../util/Indicadores/IndicadorTabla";

/**
 * FLUJO:
 * Al usuario se le presenta su propuesta ------------------------------------------------------------> [Propuesta]
 * Usuario selecciona uno de sus indicadores ---------------------------------------------------------> 
 * Se abre un modal para buscar indicadores de otras áreas por código --------------------------------> [ModalBuscarIndicadores]
 * Usuario ingresa el código -------------------------------------------------------------------------> 
 * Al usuario se le presenta el indicador que encontró o muestra un mensaje de que no fue encontrado ->
 * Usuario selecciona el indicador externo ----------------------------------------------------------->
 * El sistema pregunta si desea confirmar la relación entre los dos indicadores ----------------------> [Confirmar]
 * El usuario hace clic en confirmar ----------------------------------------------------------------->
 * Sistema muestra un mensaje de éxito o error ------------------------------------------------------->
 */

/**
 * Componente para le modulo de Relación entre objetivos.
 */
class Relacion extends Component {
    state = {
        propuestas: [],
        propuesta: {
            convocatoria: {},
            estatus: '',
            id: 0,
            indicadores: [],
            usuario: { usuario: { nombre: '' } },
        },
        listUsers: [],
        showBuscador: false,
        showRelatedIndicators: false,
        relatedIndicators: [],
        showConfirmar: false,
        from_indicador: {},
        to_indicador: {},
        codigo: '',
        isFindLoading: false,
        isLoading: false,
        error: false
    }

    /**
     * Constantes para renderizar solo una notificación de cada tipo
     */
    toastSuccessId = null
    toastErrorId = null
    toastWarnId = null
    toastInfoId = null

    handleLoadProposals = () => {
        PropuestasAPI.getAll()
            .then(response => {
                this.setState({
                    propuestas: response.data,
                    propuesta: response.data,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response) {
                    if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(`Error al cargar la propuesta: ${err.response.data.message}`)
                }
                else this.toastErrorId = toast.error(`Error: ${err.message}`)
            })
    }

    handleLoadUsersList = () => {
        UsuarioAPI.getAll().then(response => {
            this.setState({ listUsers: response.data })
        }).catch(err => {
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error(`Usuarios no cargados`)
        })
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.handleLoadProposals()
        this.handleLoadUsersList()
    }

    handleInputChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        this.setState({ [name]: value })
    }

    showBuscador = (indicador) => {
        this.setState({
            showBuscador: true,
            from_indicador: indicador
        })
    }

    closeBuscador = () => {
        this.setState({
            to_indicador: {},
            from_indicador: {},
            codigo: '',
            showBuscador: false,
            error: false
        })
    }

    // gertFormattedCodigo(codigo = '') {
    //     let formattedCodigo = codigo.trim().replace(/ /g, "")
    //     let patron = /^\d$(\-)?/

    //     console.log('Coincide: ', patron.test(formattedCodigo))
    //     // console.log('Coincide: ', codigo.trim().replace(/ /g, ""))

    //     return formattedCodigo
    // }

    getIndicador = (codigo = '') => {
        // this.gertFormattedCodigo(codigo.trim().replace(/ /g, ""))
        this.setState({ isFindLoading: true }, () => {
            IndicadoresAPI.find(codigo.trim().replace(/ /g, ""))
                .then(response => {
                    let state = { ...this.state }
                    let propuesta = { ...state.propuesta }
                    let to_indicador = response.data

                    state.to_indicador = to_indicador
                    state.isFindLoading = false

                    if (to_indicador.propuesta.usuario.usuario.id === propuesta.usuario.usuario.id) {
                        state.error = true
                    } else {
                        state.error = false
                    }

                    this.setState(state)
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ to_indicador: {}, isFindLoading: false })
                    if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('No se pudo encontrar el indicador')
                })
        })
    }

    saveRelacion = () => {
        let data = {
            'from_indicador': this.state.from_indicador.id,
            'to_indicador': this.state.to_indicador.id,
        }

        IndicadoresAPI.saveRelation(data)
            .then(() => {
                if (!toast.isActive(this.toastSuccessId)) this.toastSuccessId = toast.success("Se guardó la relación de los indicadores")
                this.handleLoadProposals()
                this.setState({
                    showConfirmar: false,
                    showBuscador: false,
                    to_indicador: {},
                    codigo: ''
                })
            })
            .catch(err => {
                this.setState({ showConfirmar: false })
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error("Error al guardar la relación de los indicadores")
                console.log(err)
            })
    }

    handleShowRelatedIndicators = (indicators) => {
        this.setState({
            relatedIndicators: indicators,
            showRelatedIndicators: true
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title is-size-4">
                            Relación entre objetivos
                        </p>
                    </header>
                </div>

                {
                    this.state.propuestas.length > 0 ? (
                        this.state.propuestas.map((propuesta, index) => {
                            return (
                                <div className="card mt-10 mb-10" key={index}>
                                    <div className="card-content">
                                        <div className="content">
                                            <div className="mb-10">
                                                <span className="title is-5" style={{ marginBottom: "0" }}>{propuesta.usuario.usuario.id_empleado} - {propuesta.usuario.usuario.nombre}</span>
                                                <span className={`tag ${getTagClassByStatus(propuesta.estatus)} ml-10`} title={`Estatus de la propuesta: ${propuesta.estatus}`}>{propuesta.estatus}</span>
                                                {
                                                    propuesta.usuario.grupo !== 1 && propuesta.estatus !== "Validado" && // Si la propuesta no pertenece a un jefe de área (grupo_id=1) y el estatus de la propuesta es distinta a "Validado"
                                                    <span className={`tag is-warning ml-10`}>
                                                        Los objetivos de esta propuesta no se pueden relacionar por que la propuesta no ha sido validada aún.
                                                    </span>
                                                }
                                            </div>
                                            <Propuesta
                                                propuesta={propuesta}
                                                usuarios={this.state.listUsers}
                                                showBuscador={(indicador) => this.setState({ propuesta: propuesta, from_indicador: indicador, showBuscador: true })}
                                                showRelatedIndicators={(indicators) => this.handleShowRelatedIndicators(indicators)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                            !this.state.isLoading &&
                            <div style={{ textAlign: "center" }}>
                                <span className="tag is-warning is-large mt-10">
                                    <NoItems itemName={"propuestas"} />
                                </span>
                            </div>
                        )

                }

                {
                    this.state.showBuscador &&
                    <BuscarIndicadores
                        usuarios={this.state.listUsers}
                        error={this.state.error}
                        isLoading={this.state.isFindLoading}
                        codigo={this.state.codigo}
                        from_indicador={this.state.from_indicador}
                        to_indicador={this.state.to_indicador}
                        propuesta={this.state.propuesta}
                        change={this.handleInputChange}
                        getIndicador={() => this.getIndicador(this.state.codigo)}
                        close={this.closeBuscador}
                        confirmar={() => this.setState({ showConfirmar: true })}
                    />
                }

                {
                    this.state.showConfirmar &&
                    <Confirmar
                        yes={this.saveRelacion}
                        no={() => this.setState({ showConfirmar: false })}
                    />
                }

                {
                    this.state.showRelatedIndicators &&
                    <Modal
                        isVisible={this.state.showRelatedIndicators}
                        isLarge={true}
                        cancelText="Cerrar"
                        onClose={() => this.setState({ showRelatedIndicators: false, propuesta: {}, relatedIndicators: [] })}
                        title="Indicadores relacionados"
                    >
                        {
                            this.state.relatedIndicators.length !== 0 ?
                                this.state.relatedIndicators.map(indicator => {
                                    return <div key={indicator.id}>
                                        <p>{`${indicator.codigo} - ${indicator.usuario_nombre}`}</p>
                                        <IndicadorTabla
                                            indicadores={[indicator]}
                                            isNormal={false}
                                        // usuarios={this.state.listUsers}
                                        />
                                    </div>
                                })
                                : <span>Éste indicador no esta relacionado con ningún otro</span>
                        }
                    </Modal>
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />

            </div>
        )
    }
}

export default Relacion;

