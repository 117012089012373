
//-react
import React, { Fragment } from "react";
import { toast } from 'react-toastify';

// - componentes
import { Button } from "../util/Button/Button";
import HasAccess from '../util/hasAccess/HasAccess';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading'
import SolicitudesRealizadas from "./SolicitudesRealizadas";
import CompensacionPendiente from "./CompensacionPendiente";
import SolicitudDetalle from "./SolicitudDetalle";
import { Modal } from '../util/modal/ModalLayout';
import { Card } from '../util/Card/Card';
import MultiSelect from '../util/MultiSelect/MultiSelect';
import { DatePickerRange } from '../util/DatePicker/DatePickerRange'
import { generateBasicExcel } from '../../util/ExcelFunctions';
import moment, { isDate } from 'moment'

// - Services
import SolicitudesCompensacionApi from '../../services/SolicitudesCompensacion';
import PropuestasApi from '../../services/Propuestas';
import UsuarioAPI from '../../services/Usuario';
import AreaAPI from '../../services/Area'
import RazonSocialAPI from '../../services/RazonSocial';
import { set } from "lodash";

const ESTATUS = [
    {
      id: 1,
      value: 'Pendiente'
    },
    {
      id: 2,
      value: 'Terminada'
    },
    {
      id: 3,
      value: 'Rechazada'
    }
  ]

export default class SolicitudesCompensacion extends React.Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
          solicitudes: [],
          solicitudSelected: {},
          listaSolicitudesFiltradas: [],
          currentPageItems: [],
          currentPage: 1,
          pageSize: 10,
          compensacionesPendientes:[],
          listaUsuarios: [],
          areas: [],
          periodos: [],
          quincenas: [],
          puestos: [],
          razon_social: [],
          estatus: [],
          fecha_inicio: null,
          fecha_fin: null,
          isLoading: false,
          filtros: {},
          selectedAll: false,
          multiSelected: false,
          isModalNuevaSolicitudVisible: false,
          isModalDetailVisible: false,
          isDownloadingExcel: false,
          isModalSinPendientes: false,
          isModalAutorizarMulti: false,
          isModalRechazarMulti: false,
        }
    }

    componentDidMount() {
        this.setState({ 
            estatus: ESTATUS,
            isLoading: true
        })

        this.handleLoadInitialData()
    }

    /**Realiza la carga inicial de los datos. */
    handleLoadInitialData = () => {

        Promise.all([            
            UsuarioAPI.getAll().then(response => {
                
                var usuarios = response.data
                var puestos = []
                usuarios.map((usuario) => {
                    if(!puestos.includes(usuario.puesto)){
                        puestos.push(usuario.puesto)
                    }
                })
                this.setState({
                    puestos: puestos,
                    listaUsuarios: response.data
                })
            }).catch(err => {
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Lista de usuarios no cargada')
            }),
            AreaAPI.getAll()
            .then(response => {
                this.setState({
                    areas: response.data,
                })
            }).catch(() => { if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Areas no cargadas') }),
            RazonSocialAPI.getAll()
            .then( response => {
                this.setState({
                    razon_social: response.data,
                })
            }).catch( () => {
                toast.error('Ocurrió un error al cargar las razones');
            }),
            SolicitudesCompensacionApi.getAll()
            .then( response => {
                var solicitudes = response.data
                var periodos = []
                var quincenas = []                
                
                solicitudes.map((solicitud) => {
                    if(!periodos.includes(solicitud.periodo)){
                        periodos.push(solicitud.periodo)
                    }
                    if(!quincenas.includes(solicitud.fecha_solicitud_deposito)){
                        quincenas.push(solicitud.fecha_solicitud_deposito)
                    }

                    solicitud.selected = false
                })

                solicitudes = solicitudes.sort(function (a, b) {
                    if (a.estatus > b.estatus) {
                      return 1;
                    }
                    if (a.estatus < b.estatus) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });

                this.setState({
                    periodos: periodos,
                    quincenas: quincenas,
                    solicitudes : solicitudes,
                    isLoading: false
                })
            }).catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al cargar a las solicitudes');
            }),
        ]).finally(() => toast.success('Carga de información completada'));
    }

    /**
     * Carga los periodos con compensaciones pendientes para modal de nueva solicitud 
     * */
    chargeCompensacionesPendientes = () =>{
        this.setState({ isLoading: true })
        PropuestasApi.getProposalCompensationPending()
        .then( response => {
            var compensaciones = response.data

            compensaciones.forEach(function (element) {
                element.disponible_constante = element.compensacion_sobrante;
                element.monto_solicitado = 0;
              });
            
            if(compensaciones.length == 0){
                this.setState({
                    isModalSinPendientes:true,                    
                    isLoading: false,
                })
            }
            else{
                this.setState({
                    compensacionesPendientes : compensaciones,
                    isLoading: false,
                    isModalNuevaSolicitudVisible:true
                })
            }
        })
        .catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al cargar a los periodos pendientes');
        })
    }

    /**Carga el detalle de una solicitud */
    chargeSolicitudSeleccionada = (solicitud) => {
        this.setState({
            solicitudSelected : solicitud,
            isModalDetailVisible : true
        })
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de usuarios.
     * 
     * @param {Array} userList
     */
    handleChangeUser = (userList = []) => {
        let filtros = { ...this.state.filtros }

        if (userList.length === 0) {
            delete filtros.usuario
        } else {
            filtros.usuario = userList.map(user => user.id).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de periodos.
     * 
     * @param {Array} periodosList
     */
     handleChangePeriodos = (periodosList = []) => {
        let filtros = { ...this.state.filtros }

        if (periodosList.length === 0) {
            delete filtros.periodo
        } else {
            filtros.periodo = periodosList.map(periodo => periodo.value).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de quincenas.
     * 
     * @param {Array} quincenasList
     */
     handleChangeQuincenas = (quincenasList = []) => {
        let filtros = { ...this.state.filtros }

        if (quincenasList.length === 0) {
            delete filtros.quincena
        } else {
            filtros.quincena = quincenasList.map(quincena => quincena.value).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de Areas.
     * 
     * @param {Array} areasList
     */
     handleChangeArea = (areasList = []) => {
        let filtros = { ...this.state.filtros }

        if (areasList.length === 0) {
            delete filtros.area
        } else {
            filtros.area = areasList.map(area => area.id).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de puesto.
     * 
     * @param {Array} puestosList
     */
     handleChangePuestos = (puestosList = []) => {
        let filtros = { ...this.state.filtros }

        if (puestosList.length === 0) {
            delete filtros.puesto
        } else {
            filtros.puesto = puestosList.map(puesto => puesto.value).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de razon social.
     * 
     * @param {Array} razonList
     */
     handleChangeRazon = (razonList = []) => {
        let filtros = { ...this.state.filtros }

        if (razonList.length === 0) {
            delete filtros.razon_social
        } else {
            filtros.razon_social = razonList.map(razon_social => razon_social.id).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
     * Maneja los cambios al seleccionar un item de la
     * lista de estatus.
     * 
     * @param {Array} estatusList
     */
     handleChangeEstatus = (estatusList = []) => {
        let filtros = { ...this.state.filtros }

        if (estatusList.length === 0) {
            delete filtros.estatus
        } else {
            filtros.estatus = estatusList.map(estatus => estatus.value).join(",")
        }

        this.setState({ filtros: filtros })
        this.applyFilters(filtros)
    }

    /**
   * Manjea los cambios en las fechas.
   * 
   * @param {Object} target
   */
    handleChangeDates = (target) => {
        let filtros = { ...this.state.filtros }
        let field = target.name
        let date = target.value

        if (date === '') {
            delete filtros[field]
        } else {
            filtros[field] = date
            if (filtros['fecha_fin'] === '') {
                delete filtros['fecha_fin']
            }
            if (filtros['fecha_inicio'] === '') {
                delete filtros['fecha_inicio']
            }
        }

        this.setState({ 
            fecha_inicio: filtros['fecha_inicio'],
            fecha_fin: filtros['fecha_fin'],
            filtros: filtros 
        }, () => {
            if (moment(date).isAfter('2000-01-01'))
              this.handleValidateDates()
          })

        
        this.applyFilters()

    }

   /**
   * Llama a la API de solicitudes con los filtros seleccionados.
   * 
   * @param {Object} filters
   */
    applyFilters = (filtros) => {  
        this.showLoading(true)
    
        SolicitudesCompensacionApi.getFilters(filtros)
        .then(res => {
            let solicitudes = res.data
            solicitudes = solicitudes.sort(function (a, b) {
                if (a.estatus === b.estatus){
                    if(a.id > b.id)
                        return -1
                    if(a.id < b.id)
                        return 1
                    return 0
                }
                if (a.estatus > b.estatus) {
                    return 1;
                  }
                  if (a.estatus < b.estatus) {
                    return -1;
                  }                  
            });

            this.setState({
                solicitudes: solicitudes,
                currentPage: 1 
            })
            this.setPage()
            this.showLoading(false)
        })
        .catch(err => {
            if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al filtrar solicitudes')
        })
    }

    /**
     * Aplica filtros en la tabla al cambiar de estatus una solicitud y la tabla esta filtrada por estatus
     */
    applyFilterChangeStatus = () => {
        let filtros = this.state.filtros
        let solicitudes = this.state.solicitudes
        let statusFiltered = filtros.estatus ? filtros.estatus.split(',') : []

        if(statusFiltered.length > 0) {            

            solicitudes = solicitudes.filter(item => statusFiltered.indexOf(item.estatus) !== -1)            
        }
        else{
            solicitudes = solicitudes.sort(function (a, b) {
                if (a.estatus === b.estatus){
                    if(a.id > b.id)
                        return -1
                    if(a.id < b.id)
                        return 1
                    return 0
                }
                if (a.estatus > b.estatus) {
                    return 1;
                  }
                  if (a.estatus < b.estatus) {
                    return -1;
                  }                  
            });            
        }

        solicitudes.map(item => item.selected = false)
    
        this.setState({
            solicitudes : solicitudes,
            multiSelected : false
        })

        this.setPage()

    }

    /**Descarga excel con lista de solicitudes */
    handleDownloadSolicitudes = () => {
        let solicitudes = this.state.solicitudes
        const excelMeta = {
            excelName: "Solicitudes de compensación",
            sheetName: "Solicitudes de compensación"
          }
    
        this.setState({ isDownloadingExcel: true })
        try {
            let [infoRows, columnNames] = this.formatSolicitudToExcel(solicitudes)
            generateBasicExcel(excelMeta, infoRows, columnNames)
            this.setState({ isDownloadingExcel: false })
        } catch (err) {
            if (!toast.isActive(this.toastInfoId)) this.toastInfoId = toast.info('No se generó el archivo Excel')
        }
    }

    /**
   * Da formato a las solicitudes, retorna un arreglo con el set de
   * datos formateado, los nombres de las hojas, los nombres de 
   * las colunas y las columnas a ignorar.
   * 
   * @param {Array} solicitudes Arreglo de solicitudes
   * @returns {Array} `[infoRows,columnsNames]`
   */
     formatSolicitudToExcel(solicitudes = []) {
        // debugger
        let infoRows = []
        const columnsNames = [
            "NUMERO DE EMPLEADO", "NOMBRE","FECHA DE SOLICITUD","PERIODO", "MONTO SOLICITADO", "QUINCENA INDICADA", "ACCIÓN", "RAZÓN SOCIAL", "ÁREA/DEPARTAMENTO", "PUESTO"
        ]

        solicitudes.map(solicitud => {

            let info = {
                numero_empleado : solicitud.usuario.usuario.id_empleado,
                nombre_usuario: solicitud.usuario.usuario.nombre,
                fecha_solicitud: solicitud.fecha_solicitud,
                periodo: solicitud.periodo,
                monto: solicitud.monto_solicitado_total,
                quincena: solicitud.fecha_solicitud_deposito,
                accion: solicitud.estatus,
                razon_social: solicitud.usuario.razon_social_nombre,
                area: solicitud.usuario.area_nombre,
                puesto: solicitud.usuario.puesto
            }

            infoRows.push(info)     
        })

        return [infoRows, columnsNames]
    }

    /**
     * Completa una solicitud 
     * @param {*} solicitud 
     */
    terminarSolicitud = (solicitud) => {

        let solicitudes = this.state.solicitudes
        let currentPageItems = this.state.currentPageItems

        solicitudes.find(item => item.id == solicitud.id).estatus = 'Terminada'
        currentPageItems.find(item => item.id == solicitud.id).estatus = 'Terminada'

        SolicitudesCompensacionApi.terminar(solicitud.id)
        .then( response => {
            this.setState({ isLoading: false });
            toast.success('Solicitud terminada.')            
            this.applyFilterChangeStatus()
        })
        .catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al terminar la solicitudes');
        }) 

    }

    /**
     * Rechaza una solicitud 
     * @param {*} solicitud 
     */
     rechazarSolicitud = (solicitud) => {

        let solicitudes = this.state.solicitudes
        let currentPageItems = this.state.currentPageItems

        solicitudes.find(item => item.id == solicitud.id).estatus = 'Rechazada'
        currentPageItems.find(item => item.id == solicitud.id).estatus = 'Rechazada'

        SolicitudesCompensacionApi.rechazar(solicitud.id)
        .then( response => {
            toast.success('Solicitud Rechazada.')
            this.applyFilterChangeStatus()
            this.setState({ isLoading: false });
            
        })
        .catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al rechazar la solicitudes');
        })

        

    }

    /**
     * Completa las solicitudes seleccionadas
     */
    terminarSolicitudMasiva = () => {
        this.showLoading(true)
        let solicitudes = this.state.solicitudes        
        let currentPageItems = this.state.currentPageItems
        let solicitudesSelected = []

        solicitudes.map(solicitud => {
            if(solicitud.selected == true){
                solicitud.estatus = 'Terminada'
                let pageSolicitud = currentPageItems.find(item => item.id == solicitud.id)
                if(pageSolicitud !== undefined)
                    currentPageItems.find(item => item.id == solicitud.id).estatus = 'Terminada'
                solicitudesSelected.push(solicitud.id)
            }
        })

        if(solicitudesSelected.length == 0){            
            this.showLoading(false)
            toast.error('No a seleccionado ninguna solicitud');
        }            
        else{
            SolicitudesCompensacionApi.terminarMulti(solicitudesSelected)
            .then( response => {
                this.showLoading(false)
                toast.success('Solicitudes terminadas.')
                this.applyFilterChangeStatus()
                this.setState({
                    selectedAll :false
                })
                
            })
            .catch( err => {
                this.showLoading(false);
                toast.error('Ocurrió un error al terminar las solicitudes');
            }) 
        }
    }

    /**
     * Completa las solicitudes seleccionadas
     */
     rechazarSolicitudMasiva = () => {
        this.showLoading(true)
        let solicitudes = this.state.solicitudes        
        let currentPageItems = this.state.currentPageItems
        let solicitudesSelected = []

        solicitudes.map(solicitud => {
            if(solicitud.selected == true){
                solicitud.estatus = 'Rechazada'
                let pageSolicitud = currentPageItems.find(item => item.id == solicitud.id)
                if(pageSolicitud !== undefined)
                    currentPageItems.find(item => item.id == solicitud.id).estatus = 'Rechazada'
                solicitudesSelected.push(solicitud.id)
            }
        })

        if(solicitudesSelected.length == 0){            
            this.showLoading(false)
            toast.error('No a seleccionado ninguna solicitud');
        }            
        else{
            SolicitudesCompensacionApi.rechazarMulti(solicitudesSelected)
            .then( response => {
                this.showLoading(false)
                toast.success('Solicitudes rechazadas.')
                this.applyFilterChangeStatus()
                this.setState({
                    selectedAll :false
                })
                
            })
            .catch( err => {
                this.showLoading(false);
                toast.error('Ocurrió un error al rechazar las solicitudes');
            }) 
        }
    }

    /**
     * Completa una solicitud 
     * @param {*} solicitud 
     */
    checkAllSolicitudes = (selected) => {

        let solicitudes = this.state.solicitudes

        solicitudes.map(solicitud => {
            if(solicitud.estatus == "Pendiente"){
                solicitud.selected = selected
            }
        })

        this.setState({
            solicitudes: solicitudes,
            selectedAll: selected,
            multiSelected: selected
        })
    }

    /**
     * Muestra/Oculta loader.
     * 
     * @param {Boolean} isLoading
     */
    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

     /**Valida si la fecha final es mayor o igual a la inicial. */
    handleValidateDates = () => {
        let filtros = { ...this.state.filtros }
        const dateStart = filtros['fecha_inicio']
        const dateEnd = filtros['fecha_fin']
        const diff = moment(dateEnd).diff(dateStart, 'days')

        if (
        dateStart !== undefined &&
        diff < 0
        ) {
            filtros['fecha_fin'] = dateStart
            
            this.setState({
                fecha_inicio: dateStart,
                fecha_fin: dateStart,
                filtros: {
                ...filtros
                }
            })
        }


        this.applyFilters(filtros)
    }

    /**
    * Se selecciona la página y se actualizan los elementos de la lista/tabla
    */ 
    setPage = () => {
        let solicitudes = this.state.solicitudes;
        let page = this.state.currentPage;
        let pageSize = this.state.pageSize
 
        let pager = this.getPager(solicitudes.length, page, pageSize);
        let currentPageItems = solicitudes.slice(pager.startIndex, pager.endIndex + 1);
 
        this.setState({ 
            currentPageItems: currentPageItems
        });
 
    }
 
    /**
     * Se obtiene el paginador, es decir, cuantas páginas habrán
     * Se asigna la primera página como la actual
     * Se obtienen los elementos que serán mostrados en la primera página
     */
    getPager = (totalItems, currentPage, pageSize) =>  {
        currentPage = currentPage || 1;
 
        pageSize = pageSize || 10;
 
        var totalPages = Math.ceil(totalItems / pageSize);
 
        var startPage, endPage;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
 
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
 
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
 
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    
    render() {
        let { solicitudes, isLoading } = this.state
        let roles = localStorage.getItem('role').split(',');

        return (
            <div>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title is-size-4">Solicitud de compensación</p>
                        <span className="card-header-icon" aria-label="more options">
                            {
                                !roles.includes("Usuario Nomina") &&

                                <Button
                                    icon="fa-plus-circle"
                                    onClick={() => {
                                        this.chargeCompensacionesPendientes()                                    
                                    }}
                                    text="Nueva solicitud"
                                />
                            }
                            {
                                roles.includes("Usuario Nomina") &&

                                <Button
                                    text="Descargar solicitudes"
                                    icon="fa-file-excel-o"
                                    onClick={this.handleDownloadSolicitudes}
                                    type="secondary"
                                    disabled={
                                        solicitudes.length === 0
                                    }
                                />
                            }
                            
                        </span>
                    </header>

                    <HasAccess roles={["Usuario Nomina", "Administrador"]}>

                        <div className="card-content">
                            <div className="content">
                                <div>
                                    <h5 className="title is-5 has-text-grey">Filtrar por:</h5>
                                </div>
                                <div className="columns">
                                    <MultiSelect
                                        label="Periodos"
                                        name="periodos"
                                        disabled={this.state.periodos.length === 0}
                                        items={this.state.periodos.map((periodo, index) => {
                                            let newPeriodo = { ...periodo }
                                            newPeriodo.value = periodo
                                            newPeriodo.id = index
    
                                            return newPeriodo
                                        })}
                                        onChangeValue={periodosList => this.handleChangePeriodos(periodosList)}
                                    />
                                    <MultiSelect
                                        label="Quincena solicitada"
                                        name="quincenas"
                                        disabled={this.state.quincenas.length === 0}
                                        items={this.state.quincenas.map((quincena, index) => {
                                            let newQuincena = { ...quincena }
                                            newQuincena.value = quincena
                                            newQuincena.id = index
    
                                            return newQuincena
                                        })}
                                        onChangeValue={quincenaList => this.handleChangeQuincenas(quincenaList)}
                                    />
                                    <MultiSelect
                                        label="Estatus"
                                        name="estatus"
                                        items={this.state.estatus}
                                        onChangeValue={estatusList => this.handleChangeEstatus(estatusList)}
                                    />
                                    <MultiSelect
                                        label="Usuario"
                                        name="usuario"
                                        disabled={this.state.listaUsuarios.length === 0}
                                        items={this.state.listaUsuarios.map(usuario => {
                                            let newUsuario = { ...usuario }
                                            newUsuario.id = usuario.usuario.id
                                            newUsuario.value = usuario.usuario.id_empleado + " - " +usuario.usuario.nombre

                                            return newUsuario
                                        })}
                                        onChangeValue={userList => this.handleChangeUser(userList)}
                                    />
                                </div>
                                <div className="columns">
                                    <DatePickerRange
                                        disabledStart={isLoading}
                                        disabledEnd={isLoading}
                                        nameStart="fecha_inicio"
                                        nameEnd="fecha_fin"
                                        changeInput={({ target }) => this.handleChangeDates(target)}
                                        valueStart={
                                            this.state.fecha_inicio === undefined ?
                                            ''
                                            : moment(this.state.fecha_inicio).format("YYYY-MM-DD")
                                        }
                                        valueEnd={
                                            this.state.fecha_fin === undefined ?
                                            ''
                                            : moment(this.state.fecha_fin).format("YYYY-MM-DD")
                                        }
                                        validation={e => this.handleValidateDates(e)}
                                    />
                                    <MultiSelect
                                        label="Área"
                                        name="area"
                                        disabled={this.state.areas.length === 0}
                                        items={this.state.areas.map(area => {
                                            let newArea = { ...area }
                                            newArea.value = area.nombre

                                            return newArea
                                        })}
                                        onChangeValue={areasList => this.handleChangeArea(areasList)}
                                    />
                                    <MultiSelect
                                        label="Puesto"
                                        name="puesto"
                                        disabled={this.state.puestos.length === 0}
                                        items={this.state.puestos.map((puesto, index) => {
                                            let newPuesto = { ...puesto }
                                            newPuesto.value = puesto
                                            newPuesto.id = index

                                            return newPuesto
                                        })}
                                        onChangeValue={puestosList => this.handleChangePuestos(puestosList)}
                                    />
                                    <MultiSelect 
                                        name="razon_social"
                                        label= "Razon Social"
                                        disabled={this.state.razon_social.length === 0}
                                        items={this.state.razon_social.map(razon => {
                                            let newRazon = { ...razon }
                                            newRazon.value = razon.nombre
                                            newRazon.id = razon.id
                                            return newRazon
                                        })}
                                        onChangeValue={razonList => this.handleChangeRazon(razonList)}
                                    />
                                </div>

                            </div>
                        </div>
                    </HasAccess>
                </div>
                <br />
                <Card extraClasses="mt-10" >
                {
                    <Fragment>
                        <div>
                            <h5 className="title is-5 has-text-grey">Solicitudes realizadas</h5>
                        </div>
                        <br />
                        <HasAccess roles={["Usuario Nomina"]}>
                            <div className="columns">
                                <div className="column is-9">
                                    Seleccionar todas: &nbsp;
                                    <input
                                        type='checkbox'
                                        checked= {this.state.selectedAll}
                                        name='selectAll'
                                        onChange={() => {
                                            this.checkAllSolicitudes(!this.state.selectedAll)
                                        }}
                                        disabled= {this.state.solicitudes.filter(item => item.estatus == 'Pendiente').length < 2}
                                    />  

                                </div>
                                <div className="column is-3">
                                    <Button
                                        text="Confirmar selecionados"
                                        onClick={() => {
                                            this.setState({
                                                isModalAutorizarMulti: true
                                            })
                                        }}
                                        className="is-small"
                                        icon="fa-check-circle"
                                        type="is-success"
                                        disabled= {!this.state.multiSelected}
                                    />
                                    <Button
                                        text="Rechazar selecionados"
                                        onClick={() => {
                                            this.setState({
                                                isModalRechazarMulti: true
                                            })
                                        }}
                                        className="is-small"
                                        icon="fa-times-circle"
                                        type="is-danger"
                                        disabled= {!this.state.multiSelected}
                                    />

                                </div>
                                
                            </div>
                        </HasAccess>
                        <SolicitudesRealizadas
                            solicitudes={this.state.currentPageItems}
                            seleccionar = {(index, selected) => {
                                let solicitudes = this.state.solicitudes
                                let selectedAll = selected 

                                solicitudes.map((solicitud) => {
                                    if(solicitud.id == index){
                                        solicitud.selected = selected
                                    }
                                    if(solicitud.estatus == "Pendiente")
                                        selectedAll = solicitud.selected ? selectedAll : solicitud.selected
                                })

                                let multiSelected = false
                                if(solicitudes.filter(item => item.selected).length > 1)
                                    multiSelected = true

                                this.setState({
                                    solicitudes: this.state.solicitudes,
                                    selectedAll: selectedAll,
                                    multiSelected: multiSelected
                                })

                            }}
                            terminar={(solicitud) => {
                                this.showLoading(true)
                                this.terminarSolicitud(solicitud)
                            }}
                            rechazar = {
                                (solicitud) => {
                                this.showLoading(true)
                                this.rechazarSolicitud(solicitud)
                            }}
                            detalle = {(solicitud) => {
                                this.chargeSolicitudSeleccionada(solicitud)
                            }}
                        />
                        <Pagination
                            items={this.state.solicitudes}
                            pageSize={this.state.pageSize}
                            initialPage= {this.state.currentPage}
                            onChangePage={(items, currentPage) => {
                                this.setState({ 
                                    currentPageItems: items,
                                    currentPage: currentPage 
                                })}
                            }
                        />
                    </Fragment>
                }
                </Card>

                {
                    this.state.isModalNuevaSolicitudVisible &&

                    <CompensacionPendiente
                        compensaciones={this.state.compensacionesPendientes}
                        cancel= {() => {
                            this.setState({
                                isModalNuevaSolicitudVisible : false
                            })
                        }}
                        save = {() => {
                            this.handleLoadInitialData()
                            this.setState({
                                isModalNuevaSolicitudVisible : false
                            })
                        }}
                    />
                }
                {
                    this.state.isModalDetailVisible &&
                    <SolicitudDetalle
                        solicitud={this.state.solicitudSelected}
                        cerrar= {() => {
                            this.setState({
                                isModalDetailVisible : false
                            })
                        }}
                    />

                }
                {
                    this.state.isModalSinPendientes &&
                    <Modal
                        title="Periodos con compensaciones pendientes de solicitar"
                        confirmText="Aceptar"
                        onConfirm={() =>{
                            this.setState({
                                isModalSinPendientes: false
                            })
                        }}
                        isVisible={this.state.isModalSinPendientes}
                    >
                        <p>Por el momento no cuentas con compensaciones pendientes para solicitar.</p>                        
                        
                    </Modal>
                }

                {
                    this.state.isModalAutorizarMulti &&
                    <Modal
                        title="Confirmar solicitudes"
                        cancelText="Cancelar"
                        onClose={() => {
                            this.setState({
                                isModalAutorizarMulti: false
                            })
                        }}
                        confirmText="Aceptar"
                        onConfirm={() =>{
                            this.setState({
                                isModalAutorizarMulti: false
                            })
                            this.terminarSolicitudMasiva()                            
                        }}
                        isVisible={this.state.isModalAutorizarMulti}
                    >
                        <p>¿Está seguro que desea confirmar las solicitudes seleccionadas?</p>                        
                        
                    </Modal>
                }

{
                    this.state.isModalRechazarMulti &&
                    <Modal
                        title="Rechazar solicitudes"
                        cancelText="Cancelar"
                        onClose={() => {
                            this.setState({
                                isModalRechazarMulti: false
                            })
                        }}
                        confirmText="Aceptar"
                        onConfirm={() =>{
                            this.setState({
                                isModalRechazarMulti: false
                            })
                            this.rechazarSolicitudMasiva()                            
                        }}
                        isVisible={this.state.isModalRechazarMulti}
                    >
                        <p>¿Está seguro que desea rechazar las solicitudes seleccionadas?</p>                        
                        
                    </Modal>
                }

                               

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

          );
    }

}
