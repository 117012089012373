import React, { Component } from 'react'
import Select from './Selct';
import MultiSelect from '../util/MultiSelect/MultiSelect'
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';

class UserForm extends Component {

    state = {
        valid: {
            nombre: false,
            email: false,
            id_empleado: false,
            jefe_directo: false,
            usuarios_asignados: false,
            puesto: false,
            monto: false,
            politica: false,
            grupo: false,
            area: false,
            gerencia: false,
            ubicacion_base: false,
            razon_social: false,
            region: false,
            segmento: false
        },
        error: {
            nombre: false,
            email: false,
            id_empleado: false,
            jefe_directo: false,
            usuarios_asignados: false,
            puesto: false,
            monto: false,
            politica: false,
            grupo: false,
            area: false,
            gerencia: false,
            ubicacion_base: false,
            razon_social: false,
            region: false,
            segmento: false,
        },
        gerencias: [],
        ubicaciones: [],
        razones_sociales: [],
        areas: [],
        regiones: [],
        segmentos: [],
        formValid: false,
        selectedUsers: [],
        userInfo: {
            use_by_contact:false
        },
        loadingData: false,
        listUsers: []
    }

    componentDidMount() {
        let IsEditing = this.props.IsEditing;

        if (IsEditing) {
            let valid = { ...this.state.valid };
            const { usuario } = this.props;

            Object.keys(valid).map(key => {
                valid[key] = true
            });

            this.setState({
                valid: valid,
                userInfo: {
                    ...this.props.usuario,
                    region: usuario.usuario.region,
                    segmento: usuario.usuario.segmento,
                    use_by_contact: usuario.usuario.use_by_contact
                }
            })
        }

        this.setState({
            gerencias: this.props.gerencias,
            ubicaciones: this.props.ubicaciones,
            razones_sociales: this.props.razones_sociales,
            areas: this.props.areas,
            regiones: this.props.regiones,
            segmentos: this.props.segmentos,
            listUsers: this.props.usuariosMultiSelect,
            
        });

    }

    handleInputBlur = e => {
        if ( !e ) return;
        let name = e.target.name;
        let value = e.target.value;

        let error = { ...this.state.error };
        let valid = { ...this.state.valid };

        switch ( name ) {
            case "monto":
                if (value.trim().length === 0) {
                    valid[name] = false
                    error[name] = true
                } else if ((name === "monto") && (value.length > 8 || value < 0)) {
                    valid[name] = false
                    error[name] = true
                } else if ((name === "id_empleado") && ((parseInt(value) === 0 || parseInt(value) <= 0 || parseInt(value) > 32767) || value.length === 0)) {
                    valid[name] = false
                    error[name] = true
                } else if ((name === "politica") && ((parseInt(value) === 0 || parseInt(value) <= 0) || value.length > 1)) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            case "email":
                const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!re.test(value)) {
                    valid[name] = false
                    error[name] = true
                }
                break;
            default:
                valid[name] = true
                error[name] = false
                if (value === '' || null) {
                    valid[name] = false
                    error[name] = true
                }
                break;
        }

        this.setState({ valid, error })
    }

    handleValidateData = () => {
        const user = { ...this.state.userInfo };
        let valid = { ...this.state.valid };
        let error = { ...this.state.error };
        
        for (const [name, value] of Object.entries( user )) {
            valid[ name ] = true;
            error[ name ] = false;

            switch ( name ) {
                case "monto":
                    if (value.trim().length === 0) {
                        valid[name] = false
                        error[name] = true
                    } else if ((name === "monto") && (value.length > 8 || value < 0)) {
                        valid[name] = false
                        error[name] = true
                    } else if ((name === "id_empleado") && ((parseInt(value) === 0 || parseInt(value) <= 0 || parseInt(value) > 32767) || value.length === 0)) {
                        valid[name] = false
                        error[name] = true
                    } else if ((name === "politica") && ((parseInt(value) === 0 || parseInt(value) <= 0) || value.length > 1)) {
                        valid[name] = false
                        error[name] = true
                    }
                    break;
                case "email":
                    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!re.test(value)) {
                        valid[name] = false
                        error[name] = true
                    }
                    break;
                default:
                    valid[name] = true
                    if (value === '' || value === null) {
                        valid[name] = false
                        error[name] = true
                    }
                    break;
            }
    
        }
        this.setState({ valid, error });
        return [ valid, error ];
    }

    validateForm = () => {
        let [ valid, error ] = this.handleValidateData();
        let formValid = this.state.formValid;

        formValid = true;

        Object.keys(valid).map(key => {
            if (!valid[key]) {
                formValid = false
                error[key] = true
            }
        });

        this.setState({ formValid });

        if ( formValid ) {
            this.props.save( this.state.userInfo );
        } else {
            toast.error('El formulario es incorrecto.');
        }
    }

    handleChangeInput = e => {
        let name = e.target.name;
        let value = e.target.value;
        let user = { ...this.state.userInfo };

        user[name] = value;

        if ( name === 'jefe_directo' ) {
            let usuarioFiltrado = this.props.users.filter( user => user.id === Number( value ) )[0];
            user.jefe_directo = Number( value );
            user.jefe_directo_nombre = usuarioFiltrado ? usuarioFiltrado.nombre : '';
            user.usuarios_asignados = user.usuarios_asignados !== undefined ? user.usuarios_asignados.filter( user => user !== Number( value ) ) : [];
        }

        if ( name === 'area' ) {
            user.area_nombre = this.state.areas.find( ar => ar.id === Number( value ) ).nombre;
        }

        if ( name === 'segmento' ) {
            user.area_nombre = this.state.segmentos.find( sg => sg.id === Number( value ) ).nombre;
        }

        this.setState({ userInfo: { ...user } })
    }

    handleChangeGerencia = e => {
        const { value } = e.target;
        const copyAreas = [ ...this.props.areas ];
        let copyUserInfo = { ...this.state.userInfo };
        let listAreas = [];
        if ( Number( value ) !== 0 ){
            copyUserInfo.gerencia = value;
            copyUserInfo.gerencia_nombre = this.state.gerencias.find( gr => gr.id === Number( value ) ).nombre;
            listAreas = copyAreas.filter( ar => ar.gerencia === Number( value ) );
            copyUserInfo.area = '';
            copyUserInfo.area_nombre = '';
        } else {
            copyUserInfo.area = null;
            copyUserInfo.area_nombre = '';
            copyUserInfo.gerencia = null;
            copyUserInfo.gerencia_nombre = '';
        }
        this.setState({
            areas: listAreas,
            userInfo: copyUserInfo
        });
    }

    handleChangeRegion = e => {
        const { value } = e.target;
        const regionSelected = this.props.regiones.find( reg => reg.id === Number( value ) );
        const copySegmentos = [ ...this.props.segmentos ];
        let copyUserInfo = { ...this.state.userInfo };
        let listSegmentos = [];
        
        if ( Number( value ) !== 0 ) {
            copyUserInfo.region = value;
            listSegmentos = copySegmentos.filter( seg => regionSelected.segmentos.includes( seg.id ) );
            copyUserInfo.segmento = null;
        } else {
            copyUserInfo.segmento = null;
            copyUserInfo.region = null;
        }

        this.setState({
            segmentos: listSegmentos,
            userInfo: copyUserInfo,
        });
    }

    handleChangeSegmento = e => {
        const { value } = e.target;
        let copyUserInfo = { ...this.state.userInfo };
        copyUserInfo.segmento = value;
        this.setState({ userInfo: copyUserInfo });
    }

    handleChangeUbicacion = e => {
        const { value } = e.target;
        let copyUserInfo = { ...this.state.userInfo };
        copyUserInfo.ubicacion_base = value;
        if ( value !== '' ) {
            copyUserInfo.ubicacion_base_nombre = this.state.ubicaciones.find( ub =>ub.id === Number( value ) ).nombre
        }
        this.setState({ userInfo: copyUserInfo });
    }

    handleChangeRazonSocial = e => {
        const { value } = e.target;
        let copyUserInfo = { ...this.state.userInfo };
        copyUserInfo.razon_social = value;
        if ( value !== '' ) {
            copyUserInfo.razon_social_nombre = this.state.razones_sociales.find( rz => rz.id === Number( value ) ).nombre
        }
        this.setState({ userInfo: copyUserInfo });
    }

    handleChangeInputUsuarios = userList => {
        let copyUserInfo = { ...this.state.userInfo };

        if ( userList.length !== 0 ) {
            copyUserInfo.usuarios_asignados = userList.map( ur => ur.id );
            this.setState({ userInfo: copyUserInfo });
        }
    }

    render() {
        let valid = { ...this.state.valid };
        let error = { ...this.state.error };

        const asignados = this.state.userInfo.usuarios_asignados ? this.state.userInfo.usuarios_asignados : []

        let selectedUsers = this.state.listUsers.filter(user =>
            asignados.includes(user.id) &&
            user.id !== Number(this.state.userInfo.jefe_directo)
        ).map(usuario => {
            let user = {
                id: usuario.id,
                value: usuario.nombre
            }
            return user
        })

        return (
            <div>
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.formTitle}</p>
                            <button className="delete" aria-label="close" onClick={this.props.close}></button>
                        </header>
                        <section className="modal-card-body">
                            <div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre</label>
                                        <div className="control">
                                            <input className={error.nombre ? "input is-danger" : "input"} type="text" placeholder="Nombre"
                                                autoFocus={true}
                                                name="nombre"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.nombre}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.nombre && <p className="help is-danger">Este campo no debe estar vacío</p>}
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">E-mail</label>
                                        <div className="control">
                                            <input className={error.email ? "input is-danger" : "input"} type="text" placeholder="E-mail"
                                                name="email"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.email}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}

                                            />
                                        </div>
                                        {error.email && <p className="help is-danger">Este campo no puede estar vacío ni tener un formato inválido</p>}
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Número de empleado</label>
                                        <div className="control">
                                            <input className={error.id_empleado ? "input is-danger" : "input"} type="number" placeholder="Número de empleado"
                                                name="id_empleado"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.id_empleado}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.id_empleado && <p className="help is-danger">Este campo no puede estar vacío, tener cero como valor o superar su valor máximo permitido</p>}
                                    </div>
                                </div>

                                <Select name="jefe_directo"
                                    optionProps={{ value: "id", text: "nombre" }}
                                    value={this.state.userInfo.jefe_directo}
                                    disabled={ this.state.loadingData }
                                    items={this.props.usuariosList}
                                    change={this.handleChangeInput}
                                    blur={this.handleInputBlur}
                                    label="Jefe Directo"
                                    error={error.jefe_directo}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />

                                <MultiSelect
                                    name="usuarios_asignados"
                                    label="Usuarios que pueden ver su BSC"
                                    items={this.state.listUsers.map(usuario => {
                                        let newUsuario = { ...usuario }
                                        newUsuario.value = `${usuario.id_empleado} - ${usuario.nombre}`

                                        return newUsuario
                                    })}
                                    disabled={ this.state.loadingData }
                                    blur={this.handleInputBlur}
                                    selectedItems={selectedUsers}
                                    onChangeValue={usuariosList => this.handleChangeInputUsuarios(usuariosList)}
                                />

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Puesto</label>
                                        <div className="control">
                                            <input className={error.puesto ? "input is-danger" : "input"} type="text" placeholder="Puesto"
                                                name="puesto"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.puesto}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.puesto && <p className="help is-danger">Este campo no debe estar vacío</p>}
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Monto</label>
                                        <div className="control">
                                            <input className={error.monto ? "input is-danger" : "input"} type="number" placeholder="Monto"
                                                maxLength="6"
                                                name="monto"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.monto}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.monto && <p className="help is-danger">Este campo no debe estar vacío, tener más de 6 dígitos o ser menor a cero</p>}
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Política</label>
                                        <div className="control">
                                            <input className={error.politica ? "input is-danger" : "input"} type="number" placeholder="Política"
                                                name="politica"
                                                value={this.state.userInfo.politica}
                                                onChange={this.handleChangeInput}
                                                disabled={ this.state.loadingData }
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.politica && <p className="help is-danger">Este campo no debe estar vacío, tener más de 1 dígito o tener cero como valor</p>}
                                    </div>
                                </div>

                                <Select name="grupo"
                                    optionProps={{ value: "id", text: "name" }}
                                    value={this.state.userInfo.grupo}
                                    disabled={ this.state.loadingData }
                                    items={this.props.grupos}
                                    change={this.handleChangeInput}
                                    blur={this.handleInputBlur}
                                    label="Grupo"
                                    error={error.grupo}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                <Select name="gerencia"
                                    disabled={ this.state.loadingData }
                                    optionProps={{ value: "id", text: "nombre" }}
                                    value={this.state.userInfo.gerencia}
                                    items={this.state.gerencias}
                                    change={this.handleChangeGerencia}
                                    blur={this.handleInputBlur}
                                    label="Gerencia"
                                    error={error.gerencia}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />

                                <Select name="area"
                                    value={this.state.userInfo.area}
                                    disabled={ this.state.areas.length === 0 || this.state.loadingData}
                                    optionProps={{ value: "id", text: "nombre" }}
                                    items={this.state.areas}
                                    change={this.handleChangeInput}
                                    blur={this.handleInputBlur}
                                    label="Área"
                                    error={error.area}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                <Select name="region"
                                    value={this.state.userInfo.region}
                                    disabled={ this.state.loadingData }
                                    optionProps={{ value: "id", text: "nombre"}}
                                    items={this.state.regiones}
                                    change={this.handleChangeRegion}
                                    blur={this.handleInputBlur}
                                    label="Región"
                                    error={error.region}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                <Select name="segmento"
                                    value={this.state.userInfo.segmento}
                                    disabled={ this.state.segmentos.length === 0 || this.state.loadingData}
                                    optionProps={{ value: "id", text: "nombre" }}
                                    items={this.state.segmentos}
                                    change={this.handleChangeSegmento}
                                    blur={this.handleInputBlur}
                                    label="Segmento"
                                    error={error.segmento}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                <Select name="ubicacion_base"
                                    value={this.state.userInfo.ubicacion_base}
                                    disabled={ this.state.ubicaciones.length === 0 || this.state.loadingData}
                                    optionProps={{ value: "id", text: "nombre" }}
                                    items={this.state.ubicaciones}
                                    change={this.handleChangeUbicacion}
                                    blur={this.handleInputBlur}
                                    label="Ubicación"
                                    error={error.ubicacion_base}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                <Select name="razon_social"
                                    value={this.state.userInfo.razon_social}
                                    disabled={ this.state.razones_sociales.length === 0 || this.state.loadingData}
                                    optionProps={{ value: "id", text: "nombre" }}
                                    items={this.state.razones_sociales}
                                    change={this.handleChangeRazonSocial}
                                    blur={this.handleInputBlur}
                                    label="Razón Social"
                                    error={error.razon_social}
                                    errorMessage="Este campo debe tener una opción seleccionada"
                                />
                                 <div className="column">
                                    <div className="field">
                                        <label className="label">Nivel administrativo</label>
                                        <div className="control">
                                            <input className={error.nivel ? "input is-danger" : "input"} min={1} type="number" placeholder=""
                                                name="nivel"
                                                disabled={ this.state.loadingData }
                                                value={this.state.userInfo.nivel}
                                                onChange={this.handleChangeInput}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        {error.nivel && <p className="help is-danger">Este campo no debe estar vacío</p>}
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Usar como contacto de correos</label>
                                        <div className="control">
                                        <input   
                                                type="checkbox"
                                                name="status"
                                                className="switch"
                                                checked={this.state.userInfo.use_by_contact}
                                                onChange={() => {}}
                                            />
                                            <label htmlFor="status" onClick={() => {
                                                let userInfo = this.state.userInfo
                                                userInfo.use_by_contact = !this.state.userInfo.use_by_contact
                                                this.setState({
                                                    userInfo : userInfo
                                                })
                                            }}
                                        />   
                                        </div>
                                        {error.politica && <p className="help is-danger">Este campo no debe estar vacío, tener más de 1 dígito o tener cero como valor</p>}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                className="button is-success"
                                onClick={this.validateForm}
                                disabled={this.state.loadingData}
                            >
                                Guardar
                            </button>
                            <button
                                className="button is-danger"
                                onClick={this.props.close}
                                disabled={this.state.loadingData}
                            >
                                Cancelar
                            </button>
                        </footer>
                    </div>
                </div>
                <Loading
                    isFullscreen={ true }
                    isLoading={this.state.loadingData}
                    width="100px"
                    height="100px"
                />
            </div>
        )
    }
}

export default UserForm;