import React, { Component } from 'react';
import { validate } from './../../../util/validation-rules';

export default class IndicadorTurbo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: {
                objetivoValid: false,
                indicadorValid: false,
                sobreMetaValid: false,
                ponderacionValid: false
            },
            value: '',
            rows: this.props.maxRows || 1,
            minRows: 1,
            maxRows: 10,
        }
    }

    handlePondFocus(e) {
        e.target.select();
    }

    /**
     * Valida que el campo sea valido/invalido y asigna un valor booleano
     * @param {*} e 
     */
    validation(e) {
        let target = e.target;
        let validationName = target.attributes.validationname.value;
        let rule = target.attributes.rule.value;
        let state = { ...this.state };

        state.validation[validationName] = !this.applyRule(rule, target);

        this.setState(state, () => this.formValid())
    }

    /**
     * Aplica la regla de validación especificada
     * @param {*} rule 
     * @param {*} target 
     */
    applyRule(rule, target) {
        switch (rule) {
            case "notEmpty":
                return validate.isEmpty(target)
            case "isBetween":
                return !validate.isBetween(0, 100, target)
            default:
                break;
        }
    }

    /**
     * Valida si existe una propiedad igual a false en el state
     * para identificar si el formulario es válido
     */
    formValid() {
        let validation = { ...this.state.validation };
        let valid = true;

        for (const key in validation) {
            if (validation.hasOwnProperty(key)) {
                valid = validation[key] ? true : false;
                if (!valid) break;
            }
        }

        this.props.onValidation(valid)
    }

    handleChange = (event) => {
        const textareaLineHeight = 24;
        const { minRows, maxRows } = this.state;

        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        this.setState({
            rows: currentRows < maxRows ? currentRows : maxRows,
        });

        this.props.camposChange(event)
    }

    render() {
        let indicador = this.props.data;
        let eliminar = this.props.eliminar !== undefined ? this.props.eliminar : true

        return (
            <tbody>
                <tr>
                    <td>
                        <textarea
                            rows={this.state.rows}
                            className={this.state.validation.objetivoValid ? "textarea" : "textarea is-danger"}
                            maxLength="300"
                            rule="notEmpty"
                            validationname="objetivoValid"
                            name="objetivo"
                            value={indicador.objetivo}
                            onChange={this.handleChange}
                            onBlur={this.validation.bind(this)}
                        />
                        {this.state.validation.objetivoValid || <p className="help is-danger">El campo no puede estar vacío</p>}
                    </td>
                    <td>
                        <textarea
                            rows={this.state.rows}
                            className={this.state.validation.indicadorValid ? "textarea" : "textarea is-danger"}
                            maxLength="300"
                            rule="notEmpty"
                            validationname="indicadorValid"
                            name="indicador"
                            value={indicador.indicador}
                            onChange={this.handleChange}
                            onBlur={this.validation.bind(this)}
                        />
                        {this.state.validation.indicadorValid || <p className="help is-danger">El campo no puede estar vacío</p>}
                    </td>
                    <td>
                        <textarea
                            rows={this.state.rows}
                            className={this.state.validation.comentarioValid ? "textarea" : "textarea is-danger"}
                            maxLength="300"
                            rule="notEmpty"
                            validationname="comentarioValid"
                            name="comentario"
                            value={indicador.comentario}
                            onChange={this.handleChange}
                            onBlur={this.validation.bind(this)}
                        />
                        {this.state.validation.comentarioValid || <p className="help is-danger">El campo no puede estar vacío</p>}
                    </td>
                    <td>
                        <textarea
                            rows={this.state.rows}
                            className={this.state.validation.sobreMetaValid ? "textarea" : "textarea is-danger"}
                            maxLength="50"
                            rule="notEmpty"
                            validationname="sobreMetaValid"
                            name="sobre_meta"
                            value={indicador.sobre_meta}
                            onChange={this.handleChange}
                            onBlur={this.validation.bind(this)}
                        />
                        {this.state.validation.sobreMetaValid || <p className="help is-danger">El campo no puede estar vacío</p>}
                    </td>
                    <td>
                        <textarea
                            className={this.state.validation.ponderacionValid ? "textarea" : "textarea is-danger"}
                            rows={this.state.rows}
                            maxLength="3"
                            rule="isBetween"
                            validationname="ponderacionValid"
                            name="ponderacion"
                            value={indicador.ponderacion}
                            onChange={(e) => { this.props.pondChange(e.target.value) }}
                            onBlur={this.validation.bind(this)}
                            onFocus={this.handlePondFocus}
                        />

                        {
                            this.state.validation.ponderacionValid ||
                            <p className="help is-danger">El valor de la ponderación es inválida. Verifique que el valor sea mayor a cero o no sobrepase el porcentaje máximo para el indicador.</p>
                        }

                    </td>
                    {
                        this.props.showCompensacion &&
                        <td>
                            <div className="tags are-medium">
                                {
                                    Object.keys(indicador.compensacionObj).map((key, i) => {
                                        return <span className="tag is-info" key={i}>{key + "=" + indicador.compensacionObj[key]}</span>
                                    })
                                }
                            </div>
                        </td>
                    }
                    {
                        eliminar &&
                            eliminar === true ?
                            <td className="has-text-centered">
                                <a className="button is-danger"
                                    title="Eliminar"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.remove();
                                    }
                                    }>
                                    <span className="icon">
                                        <i className="fa fa-trash" />
                                    </span>
                                </a>
                            </td> : ''
                    }
                </tr>
                {/* <tr>
                    <td colSpan={6}>
                        <strong style={{ color: "red" }}>Los objetivos turbo son prioridad, para obtener el {Number(indicador.ponderacion) || 0}% adicional del resultado del BSC, se debe cumplir con el objetivo.</strong>
                    </td>
                </tr> */}
            </tbody>
        )
    }
}