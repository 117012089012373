import React, { Fragment } from 'react'

// Compoents
import { Table } from '../../util/Table/Table'
import { calcularCompensacion } from "../../../util/compensacion-util"
/**
 * Componente para ver tabla de indicadores y su compensación.
 * 
 * @param {Array} indicators 
 */
export function IndicatorsCompensationTable({ selectedProposal, indicators }) {

  const COLUMNS_COLORS = {
    blue: '#0000FF',
    lightGreen: '#99CC00',
    green: '#99CC00',
    darkGreen: '#339966',
    yellow: '#FFFF00',
    red: '#FF0000',
    orange: '#FFC000'
  }

  const COLUMNS = [
    {
      id: 1,
      title: "Objetivo",
      value: "objetivo",
      size: "20%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 2,
      title: "Indicador",
      value: "indicador",
      size: "20%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 3,
      title: "Comentario",
      value: "comentario",
      size: "20%",
      color: COLUMNS_COLORS.green
    },
    {
      id: 4,
      title: "SM",
      renderClass: row => <td className={
        `${row.item.cumplimiento === 'sobre_meta' ? "LevelReachedSM" : ""}`
      }>
        {row.item.sobre_meta}
      </td>,
      size: "5%",
      color: COLUMNS_COLORS.blue,
      fontColor: 'white'
    },
    {
      id: 5,
      title: "M",
      renderClass: row => <td className={
        `${row.item.cumplimiento === 'meta' ? "LevelReachedM" : ""}`
      }>
        {row.item.meta}
      </td>,
      size: "5%",
      color: COLUMNS_COLORS.darkGreen,
      fontColor: 'white'
    },
    {
      id: 6,
      title: "BM",
      renderClass: row => <td className={
        `${row.item.cumplimiento === 'bajo_meta' ? "LevelReachedBM" : ""}`
      }>
        {row.item.bajo_meta}
      </td>,
      size: "5%",
      color: COLUMNS_COLORS.yellow
    },
    {
      id: 7,
      title: "I",
      renderClass: row => <td className={
        `${row.item.cumplimiento === 'inaceptable' ? "LevelReachedI" : ""}`
      }>
        {row.item.inaceptable}
      </td>,
      size: "5%",
      color: COLUMNS_COLORS.red,
      fontColor: 'white'
    },
    {
      id: 8,
      title: "%",
      render: row => row.item.cumplimiento.toUpperCase().replace(/_/g, ' '),
      size: "5%",
      color: COLUMNS_COLORS.lightGreen
    },
    {
      id: 9,
      title: "Compensación",
      render: row => {
        let obj = selectedProposal !== null ? calcularCompensacion(row.item, selectedProposal.usuario.usuario) : null
        return (
          <div >
            <p className="ResultCumplimiento">{`SM: ${obj!==null ? obj.SM : row.item.compensacion[0].sobre_meta}`}</p>
            <p className="ResultCumplimiento">{`M: ${obj!==null ? obj.M : row.item.compensacion[0].meta}`}</p>
            <p className="ResultCumplimiento">{`BM: ${obj!==null ? obj.BM : row.item.compensacion[0].bajo_meta}`}</p>
          </div>
        )
      },
      size: "10%",
      color: COLUMNS_COLORS.orange
    },
    {
      id: 10,
      title: "Comp. Real",
      // value: "compensacion_real",
      render: row => {
        let Compensacionreal= row.item.compensacion_real
        if(Compensacionreal === "0.00"){
          if(row.item.cumplimiento === 'sobre_meta'){
            Compensacionreal = row.item.compensacion[0].sobre_meta
          }else if(row.item.cumplimiento === 'meta'){
            Compensacionreal = row.item.compensacion[0].meta
          }else if(row.item.cumplimiento === 'bajo_meta'){
            Compensacionreal = row.item.compensacion[0].bajo_meta
          }
        }
        return Compensacionreal
      },
      size: "5%",
      color: COLUMNS_COLORS.orange
    }
  ]

  return (
    <Fragment>
      <Table
        columns={COLUMNS}
        dataSet={indicators}
        noDataText="Propuesta sin indicadores"
      />
    </Fragment>
  )
}
