import React from 'react';
import { Button } from '../../util/Button/Button'
import './Evaluacion.css'

export function EvaluacionesTable({ data, loading, setDetails, showModal, confirmAvanceEvaluacion,  confirmTerminarEvaluacion}) {
    if (!data) return '';

    return (
        <>
        {
            data.map(obj => {
                let userDateTables = [];

                Object.keys(obj).map((userKey, userIndex) => {
                    const userDate = obj[userKey];

                    if(userDate.length == 0){
                        return;
                    }

                    const id_empleado = userDate[0].usuario_id_empleado;
                    const month = userDate[0].mes;
                    const year = userDate[0].anio;

                    let evaluacion_completa = true
                    let evaluacion_terminada = true

                    userDate.forEach((element) => {
                        if(!element.evaluado){
                            evaluacion_terminada = false
                        }
                        if(element.nivel_cumplimiento_id == null){
                            evaluacion_completa = false
                        }
                    });

                    userDateTables.push(<>
                        <div className="columns">
                            <div className="column is-6">
                                <span className="is-size-6 has-text-weight-semibold">
                                    {id_empleado} - {userKey}
                                </span>
                            </div>
                        </div>
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" key={`${userIndex}`}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#99cc00" }} width="5%">#</th>
                                    <th style={{ backgroundColor: "#99cc00" }}>Objetivo</th>
                                    <th style={{ backgroundColor: "#99cc00" }}>Indicador</th>
                                    <th style={{ backgroundColor: "#99cc00" }} width="15%">Comentario</th>
                                    <th style={{ backgroundColor: "#0000ff", color: "white" }} width="10%"><abbr title="Sobre meta">SM</abbr></th>
                                    <th style={{ backgroundColor: "#339966", color: "black" }} width="10%"><abbr title="Meta">M</abbr></th>
                                    <th style={{ backgroundColor: "#ffff00", color: "black" }} width="10%"><abbr title="Bajo meta">BM</abbr></th>
                                    <th style={{ backgroundColor: "#ff0000", color: "black" }} width="10%"><abbr title="Inaceptable">I</abbr></th>
                                    <th style={{ backgroundColor: "#99cc00" }}>Usuario que valida</th>
                                    <th style={{ backgroundColor: "#ffc000" }} width="10%"><abbr title="Ponderación">% Pond.</abbr></th>
                                    <th style={{ backgroundColor: "#ffc000" }} width="10%"><abbr title="Ponderación">Evaluación</abbr></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userDate.map((row, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td className={`IndicadorCol`}>{idx + 1}</td>
                                            <td className={`IndicadorCol`}>{row.objetivo}</td>
                                            <td className={`IndicadorCol`}>{row.indicador}</td>
                                            <td className={`IndicadorCol`}>{row.comentario}</td>
                                            <td className={`IndicadorCol`}>{row.sobre_meta}</td>
                                            <td className={`IndicadorCol`}>{row.meta}</td>
                                            <td className={`IndicadorCol`}>{row.bajo_meta}</td>
                                            <td className={`IndicadorCol`}>{row.inaceptable}</td>
                                            <td className={`IndicadorCol`}>{row.usuario_valida}</td>
                                            <td className={`IndicadorCol`}>{row.ponderacion}</td>
                                            <td className={`IndicadorCol`}>
                                                {
                                                    <Button
                                                    icon={
                                                        `${
                                                            row.id_evaluacion == null && row.nivel_cumplimiento_id == null
                                                            ? "fa-plus"
                                                            : row.nivel_cumplimiento_id != null 
                                                              ? row.id_evaluacion != null && row.evaluado ?
                                                                "fa-check":"fa-plus"
                                                              :"fa-plus"
                                                          }`
                                                    }
                                                    title={
                                                        `${
                                                            row.id_evaluacion == null && row.nivel_cumplimiento_id == null
                                                            ? "El indicador aún no ha sido evaluado"
                                                            : row.nivel_cumplimiento_id != null 
                                                              ? row.id_evaluacion != null && row.evaluado ?
                                                              "El indicador ya ha sido evaluado"
                                                              : "El indicador se está evaluando"
                                                            :"El indicador aún no ha sido evaluado"
                                                          }`
                                                    }
                                                    text={
                                                        `${
                                                            row.id_evaluacion == null && row.nivel_cumplimiento_id == null
                                                            ? "Evaluar"
                                                            : row.nivel_cumplimiento_id != null 
                                                              ? row.id_evaluacion != null && row.evaluado?
                                                                "Evaluado"
                                                                :"Evaluacion en proceso"
                                                              : "Evaluar"
                                                        }`
                                                    }
                                                    type="secondary"
                                                    onClick={() => showModal(row, userDate, idx, userKey, userIndex)}
                                                    disabled={
                                                        row.id_evaluacion == null && row.nivel_cumplimiento_id == null
                                                        ? false
                                                        : row.nivel_cumplimiento_id != null 
                                                            ? row.id_evaluacion != null && row.evaluado ?
                                                            true
                                                            :false
                                                        : false
                                                    }
                                                  />
                                                }
                                            </td>
                                            
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                        <div className="columns">                            
                            { !evaluacion_terminada &&
                                < Button
                                    text="Guardar avance"
                                    onClick={() => confirmAvanceEvaluacion(userDate, userKey)}
                                    icon="fa-save"
                                    type="is-warning"
                                    title="Guardar avance"
                                />
                            }
                            {
                                !evaluacion_terminada && evaluacion_completa &&
                                < Button
                                    text="Terminar revisión"
                                    onClick={() => confirmTerminarEvaluacion(userDate, userKey)}
                                    icon="fa-save"
                                    title="Guardar la evaluación"
                                />
                            }
                        </div>
                        <br/>
                        <br/>
                    </>)
                    
                })

                return userDateTables;
            })
        }
        </>
    )
}