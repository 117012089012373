import React, { Component, Fragment } from 'react'

// Components
import { Card } from '../../util/Card/Card'
import { FiltroConvocatorias } from './FiltroConvocatorias'
import { TablaConvocatorias } from './TablaConvocatorias'
import { Modal } from '../../util/modal/ModalLayout'
import { FormConvocatoria } from './FormConvocatoria'
import { Icon } from '../../util/Icon/Icon'
import Loader from '../../util/loading/Loading'

import Confirmar from '../../util/propuestas/Confirmar';

// Services
import ConvocatoriaAPI from '../../../services/Convocatorias'
import { toast } from 'react-toastify'

class Convocatoria extends Component {
  state = {
    isModalConvocatoriaVisible: false,
    formTitle: '',
    confirmText: '',
    convocatoriaText: '',
    convocatoria: {},
    convocatoriaInitial: {},
    convocatorias: [],
    convocatoriasFiltered: [],
    isLoading: true
  }

  /**
   * Abre modal para Editar/Crear una nueva convocatoria.
   * 
   * @param {Boolean} isVisible
   * @param {String} title
   * @param {String} confirmText
   * @param {Object} convocatoriaProp
   */
  handleToggleModalConvocatoria = (
    isVisible, title, confirmText, convocatoriaProp
  ) => {
    const { convocatoria, convocatoriaInitial } = this.state
    let convocatoriaValues = {}
    let initialConvocatoria = {}

    if (isVisible) {
      if (convocatoriaProp) {
        convocatoriaValues = convocatoriaProp
        initialConvocatoria = convocatoriaProp
      } else {
        convocatoriaValues = convocatoria
        initialConvocatoria = convocatoriaInitial
      }
    }

    this.setState({
      isModalConvocatoriaVisible: isVisible,
      formTitle: title,
      confirmText,
      convocatoria: convocatoriaValues,
      convocatoriaInitial: initialConvocatoria
    })
  }

  /**
   * Guarda la edición o creación de una convocatoria.
   */
  handleSaveConvocatoria = () => {
    const { convocatoria } = this.state
    const listNames = this.state.convocatorias.filter(item => {
      if (item.id !== convocatoria.id)
        return item.nombre
    })

    if (listNames.includes(convocatoria.nombre)) {
      toast.warn('El nombre de la convocatoria ya existe!')
    } else {
      ConvocatoriaAPI.create(convocatoria).then(res => {
        // const convocatoriaActualizada = res.data
        // let listaConvocatorias = this.state.convocatorias
        // const listIds = listaConvocatorias.map(item => item.id)

        // if (listIds.includes(convocatoriaActualizada.id)) {
        //   for (let i = 0; i < listaConvocatorias.length; i++) {
        //     if (listaConvocatorias[i].id === convocatoriaActualizada.id) {
        //       listaConvocatorias.splice(i, 1, convocatoriaActualizada)
        //     }
        //   }
        // } else {
        //   listaConvocatorias.push(convocatoriaActualizada)
        // }

        this.setState({
          isModalConvocatoriaVisible: false,
          convocatoriaText: '',
          convocatoria : {}
          // convocatorias: listaConvocatorias.sort((a, b) => a.id - b.id)
        }, () => this.loadConvocatorias())
        toast.success('Convocatoria guardada')
      }).catch(err => {
        toast.error(`${err.response.data.message || 'Error al guardar convocatoria'}`)
      })
    }

  }

  /**
   * Publica una convocatoria.
   */
   handlePublicateConvocatoria = (convocatoriaProp) => {

    ConvocatoriaAPI.publicate(convocatoriaProp.id).then(res => {

      this.setState({
        isModalConvocatoriaVisible: false,
        convocatoriaText: ''
        // convocatorias: listaConvocatorias.sort((a, b) => a.id - b.id)
      }, () => this.loadConvocatorias())
      toast.success('Convocatoria guardada')
    }).catch(err => {
      toast.error(`${err.response.data.message || 'Error al guardar convocatoria'}`)
    })
    

  }

  /**
   * Maneja los cambios en los inputs.
   * 
   * @param {Object} target
   */
  handelInputChange = ({ target }) => {
    const { convocatoria } = this.state
    let { name, value } = target
    let formValues = {
      ...convocatoria,
      [name]: value
    }

    this.setState({
      convocatoria: formValues
    })
  }

  /**
   * Valida los campos vacios de la convocatoria.
   * 
   * @param {Object} convocatoria
   */
  validateConvocatoria = (convocatoria = {}) => {
    const { convocatoriaInitial } = this.state
    let isInvalid = true
    let keys = Object.keys(convocatoria)
    let values = []
    let limit = convocatoria.id ? 13 : 8

    if (keys.length === limit) {
      values = keys.map(key => {
        if ((convocatoria[key] !== '') && (convocatoria[key] !== convocatoriaInitial[key]))
          return true
      })
      isInvalid = (
        values.filter(value => value === true).length === 0 ||
        (convocatoria['nombre'] === '' || convocatoria['nombre'] === null)
      )
    }

    return isInvalid
  }

  /**
   * Maneja los cambios en el input para buscar una convocatoria.
   * 
   * @param {Event} target
   */
  handleChange = ({ target }) => {
    this.setState({ convocatoriaText: target.value })
    this.filterConvocatorias(target.value)
  }

  /**
   * Filtra las convocatorias segun el input.
   * 
   * @param {String} convocatoria
   */
  filterConvocatorias = convocatoria => {
    let filteredConvocatorias = []
    const { convocatorias } = this.state

    filteredConvocatorias = convocatorias.filter(item =>
      item.nombre.toLowerCase().match(convocatoria.toLocaleLowerCase())
    )

    this.setState({
      convocatoriasFiltered: filteredConvocatorias
    })
  }

  /**
   * Llama a API de Convocatorias para cargar los datos.
   */
  loadConvocatorias = () => {
    ConvocatoriaAPI.getAll().then(res => {
      const { convocatoriasFiltered } = this.state

      if (convocatoriasFiltered.length !== 0) {
        let convocatorias = res.data.sort((a, b) => a.id - b.id)
        let updatedFilteredConvocatorias = []
        for (let i = 0; i < convocatoriasFiltered.length; i++) {
          updatedFilteredConvocatorias.concat(convocatorias.filter(item =>
            item.id === convocatoriasFiltered[i].id
          ))
        }
        this.setState({
          convocatorias,
          convocatoriasFiltered: convocatorias,
          isLoading: false
        })
      } else {
        let convocatorias = res.data.sort((a, b) => a.id - b.id)
        this.setState({
          convocatorias,
          convocatoriasFiltered: convocatorias,
          isLoading: false
        })
      }
    }).catch(err => {
      toast.error('Las convocatorias no se cargaron!')
      this.setState({ isLoading: false })
    })
  }

  /**
   * Maneja los cambios en la informacion del formulario de convocatoria.
   * 
   * @param {Object} data
   */
  handleChangeFormData = (data = {}) => {
    let formData = {
      ...data
    }
    this.setState({
      convocatoria: formData
    })
  }

  componentDidMount() {
    this.loadConvocatorias()
  }

  render() {
    const {
      isModalConvocatoriaVisible, formTitle,
      confirmText, convocatoria, convocatorias,
      convocatoriasFiltered, convocatoriaText,
      isLoading
    } = this.state
    const listaConvocatorias = convocatoriasFiltered

    const COLUMNS = [
      {
        id: 1,
        title: 'Nombre convocatoria',
        value: 'nombre',
      },
      {
        id: 2,
        title: 'Inicio periodo para propuestas',
        value: 'fecha_inicio_alta',
      },
      {
        id: 3,
        title: 'Fin periodo para propuestas',
        value: 'fecha_fin_alta',
      },
      {
        id: 4,
        title: 'Inicio periodo para evaluaciones',
        value: 'fecha_inicio_evaluacion',
      },
      {
        id: 5,
        title: 'Fin periodo para evaluaciones',
        value: 'fecha_fin_evaluacion',
      },
      {
        id: 6,
        title: 'Estatus',
        value: 'publicada',
      },
      {
        id: 7,
        title: 'Acciones',
        render: ({ item }) => {
          return (
          <Fragment>
            <Icon
              title="Editar convocatoria"
              icon="fa-edit"
              color="has-text-info"
              click={() => this.handleToggleModalConvocatoria(true, 'Editar convocatoria', 'Guardar edición', item)}
            />
            {
              item.fecha_publicacion != null &&
              item.publicada == 'Pendiente' &&
              <Icon
                title="Enviar convocatoria"
                icon="fa-envelope-o"
                color="has-text-info"
                click={() => this.setState({
                  verConfirmarEnvio:true,
                  convocatoriaAction : item
                })}
              />
            }
            
          </Fragment>
          )
        }
      }
    ]

    return (
      <Fragment>
        <Card title="Convocatorias" >
          <FiltroConvocatorias
            click={
              () => this.handleToggleModalConvocatoria(true, 'Nueva convocatoria', 'Enviar solicitud')
            }
            onChange={this.handleChange}
            inputValue={convocatoriaText}
          />
        </Card>
        <br />
        <Card>
          <TablaConvocatorias
            data={listaConvocatorias.map(item => {
              let copy = { ...item }
              if (copy.nombre === null || copy.nombre === '')
                copy.nombre = `${copy.year}-${copy.semestre}`
              copy.publicada = item.publicada ? "Enviada" : "Pendiente"

              return copy
            })}
            columns={COLUMNS}
          />
        </Card>
        {
          isModalConvocatoriaVisible &&
          <Modal
            title="Convocatoria"
            onClose={() => this.handleToggleModalConvocatoria(false)}
            cancelText="Cerrar"
            confirmText={confirmText}
            onConfirm={this.handleSaveConvocatoria}
            isVisible={isModalConvocatoriaVisible}
            onConfirmDisable={this.validateConvocatoria(convocatoria)}
          >
            <FormConvocatoria
              data={convocatoria}
              title={formTitle}
              changeData={data => this.handleChangeFormData(data)}
            />
          </Modal>
        }
        {
          isLoading &&
          <Loader
            isFullscreen={true}
            isLoading={isLoading}
            width="100px"
            height="100px"
          />
        }
        {
          this.state.verConfirmarEnvio &&
          <Confirmar
            yes={() => {
              this.setState({ verConfirmarEnvio: false },() => {
                this.handlePublicateConvocatoria(this.state.convocatoriaAction)
              })
            }}
            no={() => this.setState({ verConfirmarEnvio: false })}>

            <h5 className="title is-5">
              ¿Seguro que desea enviar la convocatoria?
            </h5>

          </Confirmar>
        }
      </Fragment>
    )
  }
}

export default Convocatoria
