import * as React from 'react';
import logo from '../../assets/images/logo-colores-negro.png';
import Auth from '../../services/Auth';
import UsuarioAPI from '../../services/Usuario'
import decode from 'jwt-decode';
import { restablecerPass } from "../../constants";
import { toast } from 'react-toastify';
import { Modal } from '../util/modal/ModalLayout';
import './Login.css'

/**
 * Componente encargado de mostrar el formulario para realizar un inicio de sesión en el sistema.
 */
export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            intentos: 0,
            showForgotPassword: false,
            isLoading: false,
            showSelectionPerfil:false
        }
    }

    // Método a ejecutar si se presiona una tecla en el componente
    enterFunc = (event) => {
        if (event.keyCode === 13) { // Si la tecla presionada fue Enter se realiza el bloque
            if (this.state.password && this.state.email) this.login();
        }
    }

    // Se inicia un escuchador en el componente para el uso de alguna tecla
    componentDidMount() {
        document.addEventListener("keydown", this.enterFunc, false);
    }
    // Se elimina el escuchador en el componente para el uso de alguna tecla
    componentWillUnmount() {
        document.removeEventListener("keydown", this.enterFunc, false);
    }

    loadBSC(){
        localStorage.setItem("perfil_selected", "bsc");
        if(window.caches != undefined){
            window.caches.keys().then(function(names) {
                for (let name of names)
                window.caches.delete(name);
                });
        }
        window.location.reload(true);
    }

    loadVariable(){
        localStorage.setItem("perfil_selected", "variable");
        if(window.caches != undefined){
            window.caches.keys().then(function(names) {
                for (let name of names)
                window.caches.delete(name);
                });
        }
        window.location.reload(true);
    }

    // Método encargado de realizar la petición al API para el inicio de sesión
    // y el guardado de las credenciales correspondientes en caso de ser exitoso
    login() {
        toast.dismiss()
        Auth.login(this.state.email, this.state.password).then(response => {
            let token = response.data.access_token;
            let tokenObj = decode(token);
            let today = new Date();

            localStorage.setItem("expires_in", (today.getTime() / 1000) + response.data.expires_in)
            localStorage.setItem("token", token);
            localStorage.setItem("tokenExpiration", tokenObj.exp);
            localStorage.setItem("role", tokenObj.role);
            localStorage.setItem("permissions", tokenObj.permissions);

            UsuarioAPI.authCreate().then(response => {
                if(response.data.have_access_montos){                    
                    localStorage.setItem("have_access_montos", response.data.have_access_montos);
                }

                if (response.data.valid) {

                    let rol_indicadores = null
                    let rol_bsc = null

                    if (typeof tokenObj.role === 'object' && Array.isArray(tokenObj.role)) {
                        rol_indicadores = (tokenObj.role.includes("Evaluador de Indicadores Variables")||tokenObj.role.includes("Consulta")||tokenObj.role.includes("FSAP")||tokenObj.role.includes("Administrador KPIs")||tokenObj.role.includes("Autorizador"))
                        rol_bsc = (tokenObj.role.includes("Jefe Directo")||tokenObj.role.includes("Usuario")||tokenObj.role.includes("Administrador"))
                    } else if (typeof tokenObj.role === 'string') {
                        rol_indicadores = tokenObj.role === "Administrador KPIs" || tokenObj.role === "Evaluador de Indicadores Variables" || tokenObj.role === "Consulta" || tokenObj.role === "FSAP" || tokenObj.role === "Autorizador";
                        rol_bsc = tokenObj.role === "Jefe Directo" || tokenObj.role === "Usuario" || tokenObj.role === "Administrador"
                    }
                    
                    if(rol_bsc && rol_indicadores){
                        this.setState({showSelectionPerfil:true});
                    }
                    else if(rol_indicadores) {
                        this.loadVariable()
                    }
                    else if(rol_bsc) {
                        this.loadBSC()
                    }               

                } else {
                    this.setState({ isLoading: false })
                    localStorage.clear();
                    toast.error(response.data.message);
                }

            }).catch(err => {
                this.setState({ isLoading: false })
                localStorage.clear();
                console.log(err.response)
                if (err.response) toast.error("Ocurrió un error al iniciar sesión, inténtelo de nuevo");
                else toast.error(`Ocurrió un error al iniciar sesión: ${err.message}`);

            });

        }).catch(err => {
            let state = { ...this.state }
            state.isLoading = false;

            if (err.response) {
                let data = err.response.data

                state.intentos = this.state.intentos + 1;
                if (state.intentos > 2) state.showForgotPassword = true;
                if (data.error === "invalid_grant") toast.error("Credenciales incorrectas, favor de verificarlas");
                else toast.error(`Ocurrió un error al iniciar sesión, inténtelo de nuevo - ${data.error}`);
            } else {
                toast.error(`Ocurrió un error: ${err.message}`);
            }

            this.setState(state)

        });
    }

    render() {
        return (
            <div>
                <div className="columns">
                    <div className="column is-3" />
                    <div className="column is-6">
                        <br /><br /><br />
                        <div className="card ">
                            <div className="card-content">
                                <div className="has-text-centered">
                                    <figure className="container-image">
                                        <img className="image-logo" src={logo} alt="CAFFENIO" width="213" height="51"/>
                                    </figure>
                                    <h3 className="title is-3">BSC</h3>
                                </div>
                                <br />

                                {
                                    !this.state.showForgotPassword &&
                                    <div>
                                        
                                        <div className="FieldHorizontal field is-horizontal">
                                            <div className="field-label">
                                                <label className="label" htmlFor="email">Usuario</label>
                                            </div>
                                            <div className="field-body">
                                                <input
                                                    className="input"
                                                    id="email"
                                                    type={'email'}
                                                    value={this.state.email}
                                                    autoFocus={true}
                                                    onChange={(e) => {
                                                        let state = { ...this.state };
                                                        state.email = e.currentTarget.value;
                                                        this.setState(state);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="FieldHorizontal field is-horizontal">
                                            <div className="field-label">
                                                <label className="label" htmlFor="password">Contraseña</label>
                                            </div>
                                            <div className="field-body">
                                                <input
                                                    className="input"
                                                    id="password"
                                                    type={'password'}
                                                    value={this.state.password}
                                                    onChange={(e) => {
                                                        let state = { ...this.state };
                                                        state.password = e.currentTarget.value;
                                                        this.setState(state);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="has-text-right">
                                            <button
                                                className={this.state.isLoading ? "button is-success is-loading" : "button is-success"}
                                                disabled={!this.state.password || !this.state.email || this.state.isLoading}
                                                onClick={() => this.setState({ isLoading: true }, () => this.login())}
                                            >
                                                Iniciar sesión
                                        </button>
                                        </div>
                                        <div className="has-text-centered">
                                            <p>
                                                <strong>¿No recuerdas tu contraseña?</strong>
                                            </p>
                                            <p>
                                                <a href={restablecerPass}>Restablecer contraseña</a>
                                            </p>
                                        </div>

                                    </div>


                                }
                                {
                                    this.state.showSelectionPerfil &&

                                    <Modal
                                        title="Perfil de aplicación"
                                        isVisible={this.state.showSelectionPerfil}
                                    >
                                        <div>
                                            <p className="has-text-centered">
                                                <strong>Seleccione el perfil de la aplicación que desea utilizar</strong>
                                            </p>

                                            <div className="buttons" style={{ justifyContent: "center", marginTop: "15px" }}>
                                                <button className="button is-success is-large is-rounded"
                                                    onClick={this.loadBSC}
                                                >
                                                    BSC
                                                </button>
                                                <button className="button is-success is-large is-rounded"
                                                    onClick={this.loadVariable}
                                                >
                                                    Indicadores variables
                                                </button>
                                            </div>
                                        </div>
                                    </Modal>
                                    
                                }
                                {
                                    this.state.showForgotPassword &&
                                    <div>
                                        <p className="has-text-centered">
                                            <strong>¿No recuerdas tu contraseña?</strong>
                                        </p>

                                        <div className="buttons" style={{ justifyContent: "center", marginTop: "15px" }}>
                                            <button className="button is-success is-large is-rounded"
                                                onClick={() => window.location.assign(restablecerPass)}
                                            >
                                                Reestablecer contraseña
                                            </button>
                                            <button className="button is-success is-large is-rounded"
                                                onClick={() => {
                                                    this.setState({ intentos: 0, showForgotPassword: false, email: "", password: "" })
                                                }}
                                            >
                                                Intentar de nuevo
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div> {/** div.card-content */}

                        </div> {/** div.card */}
                    </div> { /** div.column is-6 */}
                </div> {/** div.columns */}


            </div>
        )
    }
}