import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../util/Button/Button';
import { Modal } from '../util/modal/ModalLayout';
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import Select from '../util/select/Selct'
import SolicitudesCompensacionApi from '../../services/SolicitudesCompensacion';
import Loading from '../util/loading/Loading'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de solicitudes de compensacion realizadas. */
export class SolicitudDetalle extends Component {
    state = {
    }

    

    render() {
        let solicitud = this.props.solicitud
        return (
            <div className="modal is-active" >
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "50%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Detalle de solicitud</p>
                        
                        <button onClick={this.props.cerrar} className="delete" title="Cerrar" aria-label="close"></button>
                    </header>

                    <section className="modal-card-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Número/Nombre de empleado">
                                            <p>{solicitud.usuario.usuario.id_empleado + " - " +solicitud.usuario.usuario.nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-4" style={{backgroundColor: "#E7E7E7"}}>
                                        <Wrapper label="Fecha de solicitud">
                                            <p>{solicitud.fecha_solicitud}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-2" style={{backgroundColor: "#E7E7E7"}}>
                                        <Wrapper label="Periodo">
                                            <p>{solicitud.periodo}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Área / Departamento">
                                            <p>{solicitud.usuario.area_nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-6">
                                        <Wrapper label="Puesto">
                                            <p>{solicitud.usuario.puesto}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6">
                                        <Wrapper label="Razón Social">
                                            <p>{solicitud.usuario.razon_social_nombre}</p>
                                        </Wrapper>
                                    </div>
                                    <div className="column is-6">
                                        <Wrapper label="Fecha solicitada para depósito">
                                            <p>{solicitud.fecha_solicitud_deposito}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-6" />
                                    <div className="column is-6">
                                        <Wrapper label="Monto solicitado">
                                            <p>{formatter.format(solicitud.monto_solicitado_total)}</p>
                                        </Wrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-content">
                            <table className="table is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Periodo aplicado</th>
                                        <th>Monto disponible</th>
                                        <th>Monto solicitado</th>
                                        <th>Monto restante en el periodo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        solicitud.detalle &&
                                        solicitud.detalle.map((detalle, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{detalle.propuesta.convocatoria.year} - {detalle.propuesta.convocatoria.semestre}</td>
                                                    <td>{formatter.format(detalle.monto_disponible)}</td>
                                                    <td>{formatter.format(detalle.monto_solicitado)}</td>
                                                    <td>{formatter.format(detalle.monto_restante)}</td>
                                                </tr>
                                            )
                                        })
                                    }                        
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </section>
                </div>
            </div>

        )
    }
}

export default SolicitudDetalle;