import React from 'react';

//Componente para manejo de paginación de elementos de una lista/tabla
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {}};
    }
 
    componentWillMount() {
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }
 
    componentDidUpdate(prevProps, prevState) {
        if (this.props.items !== prevProps.items) {
            this.setPage(this.props.initialPage);
        }
    }
 
    // Se selecciona la página y se actualizan los elementos de la lista/tabla en el
    // componente padre por medio del método onChangePage que se recibió en props.
    setPage(page) {
        var { items, pageSize } = this.props;
        var pager = this.state.pager;

        pager = this.getPager(items.length, page, pageSize);
 
        if (page < 1 || page > pager.totalPages) {
            this.setState({ pager: pager });
            return;
        }
 
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
 
        this.setState({ pager: pager });
 
        this.props.onChangePage(pageOfItems, page);
    }
 
    // Se obtiene el paginador, es decir, cuantas páginas habrán
    // Se asigna la primera página como la actual
    // Se obtienen los elementos que serán mostrados en la primera página
    getPager(totalItems, currentPage, pageSize) {
        currentPage = currentPage || 1;
 
        pageSize = pageSize || 10;
 
        var totalPages = Math.ceil(totalItems / pageSize);
 
        var startPage, endPage;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
 
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
 
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
 
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
 
    render() {
        var pager = this.state.pager;
 
        // Si no hay más de 10 elementos no se muestran los controles de páginas.
        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }
 
        return (
            <nav className="pagination" role="navigation" aria-label="pagination" style={{"width": "100%"}}>
                <ul className="pagination-list" style={{listStyle: "none"}}>
                    <li>
                        <a 
                            className='pagination-link' 
                            disabled={pager.currentPage === 1}
                            onClick={() => this.setPage(1)}>
                            Primera
                        </a>
                    </li>
                    <li>
                        <a 
                        className='pagination-link' 
                        disabled={pager.currentPage === 1}
                        onClick={() => this.setPage(pager.currentPage - 1)}>
                            Anterior
                        </a>
                    </li>
                    {pager.pages[0] !== 1 &&
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>              
                    }
                    {pager.pages.map((page, index) =>
                        <li key={index} >
                            <a 
                                className={pager.currentPage === page ? 'pagination-link has-text-white has-background-primary' : 'pagination-link'} 
                                onClick={() => this.setPage(page)}>
                                {page}
                            </a>
                        </li>
                    )}
                    {pager.pages[pager.pages.length - 1] !== pager.totalPages &&
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>              
                    }
                    <li>
                        <a 
                            className='pagination-link' 
                            disabled={pager.currentPage === pager.totalPages}
                            onClick={() => this.setPage(pager.currentPage + 1)}>
                            Siguiente
                        </a>
                    </li>
                    <li>
                        <a 
                            className='pagination-next' 
                            disabled={pager.currentPage === pager.totalPages} 
                            onClick={() => this.setPage(pager.totalPages)}>
                            Última
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Pagination;