import React, { Component, Fragment } from 'react'

// Styles
import './Evaluacion.css'

// Utilitites
import { toast } from 'react-toastify'

// Components
import Select from '../../util/select/Selct'
import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import { Button } from '../../util/Button/Button'
import { IndicadorTablaModal } from '../../util/Indicadores/IndicadorTablaModal'
import { ListEvidences } from './ListEvidences'

import Confirmar from '../../util/propuestas/Confirmar'
import DragAndDrop from '../../util/DragAndDrop/DragAndDrop'
import { set } from 'lodash'


import EvaluacionesAPI from '../../../services/indicadores_variables/Evaluaciones';


const LEVEL = [
    {
      id: 1,
      value: 1,
      text: 'SOBRE META'
    },
    {
      id: 2,
      value: 2,
      text: 'META'
    },
    {
      id: 3,
      value: 3,
      text: 'BAJO META'
    },
    {
      id: 4,
      value: 4,
      text: 'INACEPTABLE'
    }
  ]

const TIPO_EVIDENCIA = [
    {
      id: 1,
      value: false,
      text: 'Archivo'
    },
    {
      id: 2,
      value: true,
      text: 'Texto'
    }
  ]


/** Componente para Formulario de evaluación de la autoevaluación. */
class FormEvaluacion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            idEvidenceToDelete: null,
            listEvidences: [],
            selectedIndicator: props.currentIndicator,	
            currentNextIndicator: null,
            currentPrevIndicator: null,
            currentIndicadorIndex: props.currentIndicadorIndex,
			currentUserData: props.currentUserData,
			validateNextIndicator: false,
            user: {},
            isConfirmModalVisible: false,
            comentario: props.currentIndicator.comentario_evaluacion,
            nivel_cumplimiento: props.currentIndicator.nivel_cumplimiento_id,
            status: '',
            closeModalEvaluation: props.closeModalEvaluation,
            idEvidenceToDelete: null,
            tipoEvidencia : null,
            descripcion_evidencia : null,            
            currentUserKey: props.currentUserKey,
            currentUserIndex: props.currentUserIndex,
			saveDisabled: props.currentIndicator.nivel_cumplimiento_id > 0
        }
    }

    /**
     * Carga el archivo y lo sube a BD.
     * 
     * @param {Blob} file
    */
    handleUploadFile = file => {

        let usuario = this.state.selectedIndicator.usuario_id
        let indicador = this.state.selectedIndicator.id
        let anio = this.state.selectedIndicator.anio
        let mes = this.state.selectedIndicator.mes

        EvaluacionesAPI.uploadFile(usuario, indicador, anio, mes, file)
        .then((result) => {
            let indicador = this.state.selectedIndicator
            indicador.evidencias.push(result.data)
            this.setState({
                selectedIndicator: indicador,
				tipoEvidencia: ""

            })
            if (!toast.isActive(this.toastSuccessId))
            	this.toastSuccessId = toast.success('Evidencia cargada');
        }).catch(err => {
            this.setState({ isLoadingInfo: false,
				tipoEvidencia: "" });
            if (!toast.isActive(this.toastErrorId))
            	this.toastErrorId = toast.error('El archivo no se cargo');
        });
    }

	handleUploadFileError = () => {

		this.setState({
			tipoEvidencia: ""
		})
    }

    handleUploadEvidenceText = () => {
    
        const { descripcion_evidencia } = this.state;
    
        let description = descripcion_evidencia
        let isText = true

        let usuario = this.state.selectedIndicator.usuario_id
        let indicador = this.state.selectedIndicator.id
        let anio = this.state.selectedIndicator.anio
        let mes = this.state.selectedIndicator.mes
    
        if(description && description !== ''){
            EvaluacionesAPI.uploadFile( usuario, indicador, anio, mes, null, description, isText )
            .then((result) => {
                let indicador = this.state.selectedIndicator
                indicador.evidencias.push(result.data)
                this.setState({
                    selectedIndicator: indicador,
					tipoEvidencia: ""
                })
                if (!toast.isActive(this.toastSuccessId))
                	this.toastSuccessId = toast.success('Evidencia cargada');
            }).catch(err => {
                this.setState({ isLoadingInfo: false,
					tipoEvidencia: "" });
                if (!toast.isActive(this.toastErrorId))
                	this.toastErrorId = toast.error('La evidencia no se cargo');
            });
        }
        else{
          this.setState({ isLoadingInfo: false ,
			tipoEvidencia: ""});
          this.toastErrorId = toast.error('La evidencia de texto no puede ir vacía');
        }
    }

    /** Abre el modal de confirmación para eliminar la evidencia. */
    showConfirmDeleteEvidence = (itemId) => {
        this.setState({
            idEvidenceToDelete: itemId,
            isConfirmModalVisible: true
        });
    }

    handleCloseConfirmDeleteEvidence = () => {
        this.setState({
            idEvidenceToDelete: null,
            isConfirmModalVisible: false
        });
    }

    /** Manda a eliminar la evidencia seleccionada. */
    handleDeleteEvidence = () => {
        this.setState({ isLoadingInfo: true });
        const { idEvidenceToDelete } = this.state;

        EvaluacionesAPI.deleteEvidence(idEvidenceToDelete)
        .then(() => {
            let indicador = this.state.selectedIndicator
            let evidencias = this.state.selectedIndicator.evidencias
            let index_to_remove = evidencias.findIndex(evidencia => evidencia.id === idEvidenceToDelete)
            if (index_to_remove !== -1) {
                evidencias.splice(index_to_remove, 1);
            }

            indicador.evidencias = evidencias

            this.setState({
                selectedIndicator : indicador,
                idEvidenceToDelete: null,
                isConfirmModalVisible: false,
            });

            if (!toast.isActive(this.toastSuccessId))
            this.toastSuccessId = toast.success('Evidencia eliminada.');
        })
        .catch(() => {
            this.setState({
                idEvidenceToDelete: null,
                isConfirmModalVisible: false,
            });
            if (!toast.isActive(this.toastErrorId))
            this.toastErrorId = toast.error('No se pudo eliminar la evidencia.');
        })
    }

    /**
   * Maneja las descargas de los archivos de evidencias.
   * 
   * @param {String} path
   */
    handleDownloadFile = (path, name = '') => {
        EvaluacionesAPI.evidenceDownload(path)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', name)
            document.body.appendChild(link)
            link.click()
            toast.success('Archivo descargado!');
            this.setState({ isFileLoading: false });
        })
        .catch(err => {
            if (err.request.status === 404) {
            toast.error('El archivo no existe')
            } else {
            toast.error('El archivo no se pudo descargar')
            }
            this.setState({ isFileLoading: false });
        })
    }

    /**
     * Maneja los cambios en el campo de comentarios.
     * 
     * @param {Event} target
     */
    handleChangeComment = ({ target }) => {
        this.setState({
        comentario: target.value
        })
    }

    /**
     * Maneja los cambios al seleccionar una opción de la lista.
     * 
     * @param {Event} target
     */
    handleChangeLevel = ({ target }) => {

		let valid = target.value != ''
		
		this.setState({ 
			nivel_cumplimiento: parseInt(target.value),
			saveDisabled: valid
		})
    }

    /** Abre el modal de confirmación para eliminar la evidencia. */
    showConfirmDeleteEvidence = (itemId) => {
      this.setState({
        idEvidenceToDelete: itemId,
        isConfirmModalVisible: true
      })
    }

    /** Cierra el modal de confirmación para eliminar evidencias. */
    handleCloseConfirmDeleteEvidence = () => {
      this.setState({
        isConfirmModalVisible: false,
        idEvidenceToDelete: null
      })
    }

	/**
	 * Elimina el archivo seleccionado de la lista.
	 * 
	 * @param {Number} idEvidence
	 */
	popEvidenceFromList = (idEvidence) => {
		const evidences = this.state.listEvidences
		let updatedListEvidences = []

		updatedListEvidences = evidences.filter(item => item.id !== idEvidence)

		this.setState({
		listEvidences: updatedListEvidences
		}, () => this.handleCloseConfirmDeleteEvidence())
	}

	save = () => {
        let state = {...this.state}; // Clonar el estado actual
        let indicador = state.selectedIndicator; // Obtener la propiedad 'data' del estado

		let body = {
			"usuario": indicador.usuario_id,
			"indicador": indicador.id,
			"nivel_cumplimiento": state.nivel_cumplimiento,
			"comentario": state.comentario,
			"evaluado": false
		};

		EvaluacionesAPI.save_evaluacion(body).then((response) => {
			let result = response.data;
			indicador.id_evaluacion = result.id;
			indicador.nivel_cumplimiento_id = result.nivel_cumplimiento;
			indicador.comentario_evaluacion = result.comentario;
			indicador.evaluado = result.evaluado;

			this.setState({
                selectedIndicator: indicador, // Actualizar el estado con los nuevos datos
				saveLoading: false
            },() => {
				toast.success("Avance de evaluación guardado correctamente");
				if(state.validateNextIndicator)					
					this.changeNextIndicator()				
				else
					this.props.closeModalEvaluation()
					
                
            })

		}).catch((err) => {
			toast.error(err.message)
			this.setState({
				saveLoading: false
            })
		})
	}

	showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

	changeNextIndicator = () => {
        
        let state = {...this.state}
        let UserData = state.currentUserData
        let indicadorIndex = UserData.length - 1 == state.currentIndicadorIndex ? 0 : state.currentIndicadorIndex + 1

        let nextIndicator = null
        let prevIndicator = null
        let currentIndicador = UserData[indicadorIndex]

		let comentario = currentIndicador.comentario_evaluacion ? currentIndicador.comentario_evaluacion : ""
		let nivel_cumplimiento = currentIndicador.nivel_cumplimiento_id ? currentIndicador.nivel_cumplimiento_id : undefined

        if(UserData.length > 1){
            if(indicadorIndex > 0)
                prevIndicator = UserData[indicadorIndex - 1]
            if(indicadorIndex == 0)
                prevIndicator = UserData[UserData.length-1]
            if(indicadorIndex < UserData.length-1)
                nextIndicator = UserData[indicadorIndex + 1]
            if(indicadorIndex == UserData.length-1)
                nextIndicator = UserData[0]
        }

		let saveDisabled = currentIndicador.nivel_cumplimiento_id > 0
		
        this.setState({
            selectedIndicator : currentIndicador,
            currentNextIndicator: nextIndicator,
            currentPrevIndicator: prevIndicator,
            currentIndicadorIndex: indicadorIndex,
			comentario: comentario,
			nivel_cumplimiento: nivel_cumplimiento,
			saveDisabled: saveDisabled
        });
    }

    changePrevIndicator = () => {
        let state = {...this.state}
        let UserData = state.currentUserData
        let indicadorIndex = state.currentIndicadorIndex == 0 ? UserData.length - 1 : state.currentIndicadorIndex - 1

        let nextIndicator = null
        let prevIndicator = null
        let currentIndicador = UserData[indicadorIndex]

		let comentario = currentIndicador.comentario_evaluacion ? currentIndicador.comentario_evaluacion : ""
		let nivel_cumplimiento = currentIndicador.nivel_cumplimiento_id ? currentIndicador.nivel_cumplimiento_id : undefined

        if(UserData.length > 1){
            if(indicadorIndex > 0)
                prevIndicator = UserData[indicadorIndex - 1]
            if(indicadorIndex == 0)
                prevIndicator = UserData[UserData.length-1]
            if(indicadorIndex < UserData.length-1)
                nextIndicator = UserData[indicadorIndex + 1]
            if(indicadorIndex == UserData.length-1)
                nextIndicator = UserData[0]
        }

        this.setState({
            isModalEvaluationVisible: true,
            selectedIndicator : currentIndicador,
            currentNextIndicator: nextIndicator,
            currentPrevIndicator: prevIndicator,
            currentIndicadorIndex: indicadorIndex,
			comentario: comentario,
			nivel_cumplimiento: nivel_cumplimiento
        });
    }

  	render() {
		const {
		selectedIndicator, 
		comentario, nivel_cumplimiento,
		isConfirmModalVisible
		} = this.state

		return (
			<div className="modal is-active">
				<div className="modal-background" />
					<div className="modal-card" style={{ width: "50%" }}>
		
					<header className="modal-card-head">
						<p className="modal-card-title">Evaluar indicador</p>
						<span>
							<div>
								<Button
									icon="fa-arrow-left"
									text="Anterior"
									type="secondary"
									title="Indicador Anterior"
									onClick={this.changePrevIndicator}
								/>
								<Button
									icon="fa-arrow-right"
									iconRight={true}
									text="Siguiente"
									type="secondary"
									title="Siguiente Indicador"
									onClick={this.changeNextIndicator}
								/>
							</div>
						</span>
					<button onClick={this.props.closeModalEvaluation} className="delete" title="Cerrar" aria-label="close"></button>
					</header>
		
					<section className="modal-card-body">
						
						<Fragment>
							<div className="HeaderContentEvalueate">
							<h2 className="HeaderContentEvalueateUser">
								{
								`${selectedIndicator.usuario}`
								}
							</h2>
							
								<IndicadorTablaModal
									indicadores={[selectedIndicator]}
									isNormal={true}
								/>
							</div>
							
							<Wrapper label="Nivel de cumplimiento">
								<Select
									classes="SelectEvaluate"
									items={LEVEL}
									optionProps={{ value: 'value', text: 'text' }}
									value={nivel_cumplimiento}
									change={this.handleChangeLevel}
								/>
							</Wrapper>

							<Wrapper label="Comentarios de evaluación">
							<textarea
								className="TextAreaEvaluate"
								value={comentario}
								onChange={this.handleChangeComment}
								maxLength={250}
								placeholder="Agregue un comentario"
							/>
							</Wrapper>

							<Wrapper label="Tipo de evidencia">
								<Select
									classes="SelectEvaluate"
									items={TIPO_EVIDENCIA}
									value={this.state.tipoEvidencia}
									optionProps={{ value: 'value', text: 'text' }}
									change={ e => this.setState({ tipoEvidencia: e.target.value }) }
								/>
							</Wrapper>

							<Wrapper label="Evidencia">
								{this.state.tipoEvidencia == 'true' &&

								<div className="column">
									<textarea
									className="TextAreaEvaluate"
									value={this.state.descripcion_evidencia}
									onChange={ e => this.setState({ descripcion_evidencia: e.target.value }) }
									maxLength={250}
									placeholder="Agregue un comentario"
									/>
									<Button
									text="Guardar evidencia"
									icon="fa-save"
									type="is-warning"
									onClick={this.handleUploadEvidenceText}
									/>
								</div>
								
								
								}
								{this.state.tipoEvidencia == 'false' &&
								<div className="WrappetAttachDropZone">
									<DragAndDrop
										onFilesLoaded={() => console.log("test")}
										onUploadFile={file => this.handleUploadFile(file)}
										onUploadFileError = {this.handleUploadFileError}
										name="indicator_evidences"
										isMultiple={false}
									/>
									<span className="InfoAttach">Tamaño límite por archivo 2Mb</span>
								</div>
								}

								
							</Wrapper>
							
							<Wrapper label="Evidencias cargadas">
								<ListEvidences
									dataSet={ selectedIndicator.evidencias }
									onDeleteItem={itemId => this.showConfirmDeleteEvidence(itemId)}
									onDownloadItem={(path, name) => this.handleDownloadFile(path, name)}
								/>
							</Wrapper>
							{
								isConfirmModalVisible &&
								<Confirmar
								yes={this.handleDeleteEvidence}
								no={this.handleCloseConfirmDeleteEvidence}
								>
								<p className="ConfirmSubtitle">¿Está seguro que desea eliminar esta evidencia?</p>
								<p className="ConfirmDescription">Las evidencias eliminadas no se podrán recuperar.</p>
								</Confirmar>
							}
							

							{
							isConfirmModalVisible &&
							<Confirmar
								yes={this.handleDeleteEvidence}
								no={this.handleCloseConfirmDeleteEvidence}
							>
								<p className="ConfirmSubtitle">¿Está seguro de eliminar ésta evidencia?</p>
								<p className="ConfirmDescription">Las evidencias eliminadas no se podrán recuperar.</p>
							</Confirmar>
							}
						</Fragment>
					</section>
		
					<footer className="modal-card-foot">
								
						{/* Botón para guardar*/}
						<Button
							className={`${this.state.saveLoading ? "is-loading" : ""}`}
							disabled={!this.state.saveDisabled || this.state.saveLoading}
							onClick={() => {
								
							this.setState({
								saveLoading: true
							},() => {this.save()})
							
							}}
							text="Guardar evaluación"
							icon="fa-check"
						/>

						{/* Botón para cancelar */}
						<Button
							onClick={this.props.closeModalEvaluation}
							disabled={this.state.saveLoading}
							text="Cancelar"
							type="is-danger"
							icon="fa-ban"
						/>

						<label className="CheckModalValidation">
							<input
							type="checkbox"
							checked={this.state.validateNextIndicator}
							onChange={() =>
								this.setState({
								validateNextIndicator: !this.state.validateNextIndicator
								})
							}
							/>
							Mantener ventana abierta al guardar
						</label>
					</footer>
		
				</div>
			</div>
		)
	}
}

export default FormEvaluacion
