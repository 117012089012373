
//-react
import React, { Fragment } from "react";
import { toast } from 'react-toastify';

// - componentes
import GerenciasTable from "./GerenciaTable";
import GerenciaCreate from "./GerenciaCreate";

import { Button } from "../util/Button/Button";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading'
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import { Modal } from '../util/modal/ModalLayout';
import { Card } from '../util/Card/Card';
import MultiSelect from '../util/MultiSelect/MultiSelect';
import { InputSearch } from '../util/Input/InputSearch'
import { Table } from '../util/Table/Table'

// - Services
import GerenciaAPI from '../../services/Gerencia';
import { set } from "lodash";


export default class AdminGerencias extends React.Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            gerencias: [],
            gerenciasFiltered: [],
            gerenciaSelected: {},
            searchValue: "",
            currentPageItems: [],
            currentPage: 1,
            pageSize: 10,
            isModalCreateShow: false,
            isModalEditShow: false
            
        }
    }

    componentDidMount() {
        this.handleLoadInitialData()
    }

    /**Realiza la carga inicial de los datos. */
    handleLoadInitialData = () => {

        Promise.all([  
            GerenciaAPI.getAll()
            .then(response => {
                this.setState({
                    gerencias: response.data,
                    gerenciasFiltered : response.data
                })
            }).catch(() => {                 
                this.setState({ isLoading: false });
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Gerencias no cargadas') }),
        ]).finally(() =>{
            
            this.setState({ isLoading: false });
            toast.success('Carga de información completada')
        });
    }

    handleChangeSearch = ({ target }) => {
        let gerenciasFiltered = this.state.gerencias.filter(item =>
            item.nombre.toLowerCase().match(target.value.toLocaleLowerCase())
        )

        this.setState({
            gerenciasFiltered: gerenciasFiltered,
            currentPage: 1,
            searchValue: target.value
        },this.setPage())
    }


    save = (gerencia) => {
        if(gerencia){

            GerenciaAPI.create(gerencia)
            .then( response => {

                let newGerencia = response.data

                let gerencias = this.state.gerencias
                let gerenciasFiltered = this.state.gerenciasFiltered

                gerenciasFiltered.push(newGerencia)

                this.setState({ 
                    gerenciasFiltered: gerenciasFiltered,
                    isModalCreateShow : false,
                    isLoading: false 
                }, this.setPage);
                toast.success('Gerencia guardada correctamente')
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al crear la gerencia');
            })
        }
        else{
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al crear la gerencia');
        }
        
    }

    edit = (gerencia) => {
        if(gerencia){

            GerenciaAPI.edit(gerencia, gerencia.id)
            .then( response => {
                this.setState({
                    isModalEditShow : false
                })
                this.setState({ isLoading: false });
            })
            .catch( err => {
                this.setState({ isLoading: false });
                toast.error('Ocurrió un error al actualizar la gerencia');
            })
        }
        else{
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al actualizar la gerencia');
        }

        this.setState({ isLoading: false });
        toast.success('Gerencia guardada correctamente')

    }

    /**
     * Muestra/Oculta loader.
     * 
     * @param {Boolean} isLoading
     */
    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    /**
    * Se selecciona la página y se actualizan los elementos de la lista/tabla
    */ 
    setPage = () => {
        let gerencias = this.state.gerenciasFiltered;
        let page = this.state.currentPage;
        let pageSize = this.state.pageSize
 
        let pager = this.getPager(gerencias.length, page, pageSize);
        let currentPageItems = gerencias.slice(pager.startIndex, pager.endIndex + 1);
 
        this.setState({ 
            currentPageItems: currentPageItems
        });
 
    }
 
    /**
     * Se obtiene el paginador, es decir, cuantas páginas habrán
     * Se asigna la primera página como la actual
     * Se obtienen los elementos que serán mostrados en la primera página
     */
    getPager = (totalItems, currentPage, pageSize) =>  {
        currentPage = currentPage || 1;
 
        pageSize = pageSize || 10;
 
        var totalPages = Math.ceil(totalItems / pageSize);
 
        var startPage, endPage;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
 
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
 
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
 
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    
    render() {

        return (
            <div>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title is-size-4">Gerencias</p>
                        <span className="card-header-icon" aria-label="more options">
                            <Button
                                icon="fa-plus-circle"
                                onClick={() => {    
                                    this.setState({
                                        isModalCreateShow: true
                                    })                              
                                }}
                                text="Nueva Gerencia"
                            />
                        </span>
                    </header>
                    <div className="card-content">
                        <div className="content">
                            <div>
                                <h5 className="title is-5 has-text-grey">Filtrar por:</h5>
                            </div>
                            <div className="columns">
                                <Wrapper label="Nombre de gerencia" extraClasses="is-three-quarters">
                                    <InputSearch
                                        name="gerencia_search"
                                        placeholder="Buscar Gerencia"
                                        changeInput={this.handleChangeSearch}
                                        value={this.state.searchValue}
                                    />
                                </Wrapper>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <Card extraClasses="mt-10" >
                {
                    <Fragment>
                        <GerenciasTable
                            gerencias= {this.state.currentPageItems}
                            editStatus = {this.edit}
                            edit= {(gerencia) => {
                                this.setState({
                                    gerenciaSelected : gerencia,
                                    isModalEditShow : true
                                })
                            }}
                        />                
                        <br/>
                        <Pagination
                            items={this.state.gerenciasFiltered}
                            pageSize={this.state.pageSize}
                            initialPage= {this.state.currentPage}
                            onChangePage={(items, currentPage) => {
                                this.setState({ 
                                    currentPageItems: items,
                                    currentPage: currentPage 
                                })}
                            }
                        />
                    </Fragment>
                }
                </Card>

                {
                    this.state.isModalCreateShow &&
                    <GerenciaCreate
                        gerencia = {{
                            id :0,
                            nombre : null
                        }}
                        save = {this.save}
                        cancel = {() => {
                            this.setState({
                                isModalCreateShow : false
                            })
                        }}
                    />
                }
                {
                    this.state.isModalEditShow &&
                    <GerenciaCreate
                        gerencia = {this.state.gerenciaSelected}
                        save = {this.edit}
                        cancel = {() => {
                            this.setState({
                                isModalEditShow : false
                            })
                        }}
                    />
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

          );
    }

}
