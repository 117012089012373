import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class RazonSocialAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('razones_sociales/'));
    }
}