export const validate = {
    isEmpty: (target) => {
        return target.value.trim().length <= 0
    },
    isBetween: (start, finish, target) => {
        return (target.value > start && target.value <= finish) ? true : false;
    },
    isValueEmpty: (value) => {
        return value.trim().length <= 0
    },
}