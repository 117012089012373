import React, { Fragment, Component } from 'react'

// Services
import GerenciasAPI from '../../../services/Gerencia'
import UsuariosAPI from '../../../services/Usuario'
import AreaAPI from '../../../services/Area'

// Utilities
import { generateBasicExcel } from '../../../util/ExcelFunctions'

// Components
import { Card } from '../../util/Card/Card'
import { Button } from '../../util/Button/Button'
import { toast } from 'react-toastify'
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import Select from '../../util/select/Selct'

import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import { FiltersWrapper } from '../../util/FiltersWrapper/FiltersWrapper'
import { Table } from '../../util/Table/Table'
import Pagination from '../../util/pagination/Pagination'
import Loader from '../../util/loading/Loading'
import PropuestasAPI from '../../../services/Propuestas'
import ConvocatoriaAPI from '../../../services/Convocatorias'

const NIVEL_CUMPLIMIENTO = [
  {
    id: 1,
    value: '0 - 25',
    nivel: '0,25'
  },
  {
    id: 2,
    value: '25 - 50',
    nivel: '25,50'
  },
  {
    id: 3,
    value: '50 - 75',
    nivel: '50,75'
  },
  {
    id: 4,
    value: '75 - 100',
    nivel: '75,100'
  }
]

/** Módulo de reporte de Compensación Total */
class CompensacionTotal extends Component {

  state = {
    loadingExcel: false,
    isLoading: true,
    currentPageItems: [],
    listReportsFiltered: [],
    listGerencias: [],
    selectedGerencias: [],
    areas: [],
    areasOriginal: [],
    selectedAreas: [],
    listUsers: [],
    listUsersOriginal: [],
    selectedUsers: [],
    currentPeriod: undefined,
    listEstatusUsuario: LIST_ESTATUS_USUARIO,
    selectedEstatusUsuario:undefined,
    listPeriodos: [],
    selectedPeriodos: [],
    listNivelesCumplimiento: [],
    selectedNivelesCumplimiento: [],
    selectedCompensacion: [],
    listUsersCompensacion: [],
    listFilteredUsersCompensacion: [],
    filtros: {},
    reportsOriginals: [],
    periodosSeleccionados: []
  }

  toastDownloadExcelSuccess = null
  toastDownloadExcelError = null

  /** Carga de los reportes de nómina. */
  loadReports = (filters = {}) => {
    let { areas } = this.state
    let reportsOriginals = []
    let listReportsFiltered = []
    let listNivelesCumplimiento = NIVEL_CUMPLIMIENTO
    let isLoading = false;

    filters.compensacionTotal = true
    PropuestasAPI.nominalReport(filters)
      .then(res => {
        reportsOriginals = res.data;
        listReportsFiltered = res.data.filter(report =>
          areas.map(area => area.id).includes(report.usuario.area)
        )
        this.setState({
          reportsOriginals,
          listReportsFiltered,
          listNivelesCumplimiento,
          isLoading
        })
      })
      .catch(err => {
        toast.error('Reporte no cargado.')
        this.setState({ isLoading: false })
      })
  }


  /**Descarga de Excel de reporte de compensación total. */
  handleDownloadExcel = () => {
    this.setState({ loadingExcel: true })
    const { listReportsFiltered } = this.state
    try {
      const excelMeta = {
        excelName: "Reporte Compensación Total",
        sheetName: "Reporte Compensación Total"
      }

      let [data, columns] = this.getFormattedData( listReportsFiltered )

      generateBasicExcel(excelMeta, data, columns)
      if (!toast.isActive(this.toastFileDownloadSuccess))
        this.toastFileDownloadSuccess = toast.success('Excel descargado!')

      this.setState({ loadingExcel: false })
    } catch (err) {
      if (!toast.isActive(this.toastFileDownloadError))
        this.toastFileDownloadError = toast.error('No se descargó el archivo Excel!')
      this.setState({ loadingExcel: false })
    }
  }

  /**
   * Da formato a los reportes filtrados para generar un Excel.
   * 
   * @param {Array} reports 
   * 
   * @returns Array
   */
  getFormattedData(reports = []) {
    let columns = []

    let data = reports.map(report => {
      return {
        numero_de_empleado: report.usuario.usuario.id_empleado,
        nombre: report.usuario.usuario.nombre,
        gerencia: report.usuario.gerencia_nombre,
        area: report.usuario.area_nombre,
        region: report.usuario.usuario.region_nombre,
        segmento: report.usuario.usuario.segmento_nombre,
        ubicacion_base: report.usuario.ubicacion_base_nombre,
        razon_social: report.usuario.razon_social_nombre,
        compensacion_autorizada: report.cumplimiento > 0
        ? Math.round(report.compensacion_autorizada)
        : Math.round(parseFloat(report.usuario.monto * report.usuario.politica / 1000).toFixed(2)),
        compensacion_real: report.estatus_evaluacion !== 'Evaluacion Aceptada' ? 0 : report.compensacion_ordinaria,
        porcentaje_de_cumplimiento: report.estatus_evaluacion !== 'Evaluacion Aceptada' ? 0 : report.cumplimiento,
        turbo: report.estatus_evaluacion !== 'Evaluacion Aceptada' ? 0 : report.compensacion_turbo,
        ajuste_de_proporcion_segun_carta_oferta: report.estatus_evaluacion !== 'Evaluacion Aceptada' ? 0 : report.ajuste_proporcional,
        total: report.estatus_evaluacion !== 'Evaluacion Aceptada' ? 0 : report.compensacion_total,
      }
    })

    columns = Object.keys(data[0])

    return [data, columns]
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de gerencia.
   * 
   * @param {Array} gerenciaList
   */
  handleChangeGerencia = (gerenciaList = []) => {
    let filtros = { ...this.state.filtros }
    let gerenciasIds = []
    let listAreas = []
    let listAreasOriginal = this.state.areasOriginal
    let listUsers = []
    let listUsersOriginal = this.state.listUsersOriginal

    if (gerenciaList.length === 0) {
      delete filtros.gerencia
      delete filtros.area
      delete filtros.usuario
      listAreas = listAreasOriginal
      listUsers = listUsersOriginal
    } else {
      gerenciasIds = gerenciaList.map(gerencia => gerencia.id)
      listAreas = this.getAreasFilteredByManagement(gerenciaList, listAreasOriginal)
      listUsers = this.getUsersFilteredByArea(listAreas, listUsersOriginal)
      filtros.gerencia = gerenciaList.map(gerencia => gerencia.id).join(",")
    }

    this.setState({
      filtros,
      isLoading: true,
      areas: listAreas,
      listUsers
    }, () => this.applyFilters(filtros))
  }

  /**
   * Filtra las áreas según la lista de gerencias.
   * 
   * @param {Array} listManagements 
   * @param {Array} listAreasOriginal
   * @returns areasList
   */
  getAreasFilteredByManagement(listManagements = [], listAreasOriginal = []) {
    let areasList = []
    listManagements.forEach(management =>
      listAreasOriginal.forEach(area => {
        if (area.gerencia === management.id)
          areasList.push(area)
      })
    )

    return areasList
  }

  /**
   * Filtra los usuarios según la lista de áreas.
   * 
   * @param {Array} listAreas
   * @param {Array} listUsersOriginal
   * @returns usersList
   */
  getUsersFilteredByArea(listAreas = [], listUsersOriginal = []) {
    let usersList = []
    listAreas.forEach(area =>
      listUsersOriginal.forEach(user => {
        if (user.area === area.id)
          usersList.push(user)
      })
    )

    return usersList
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de areas.
   * 
   * @param {Array} areasList
   */
  handleChangeArea = (areasList = []) => {
    let filtros = { ...this.state.filtros }
    let listUsers = []
    let listUsersOriginal = this.state.listUsersOriginal

    if (areasList.length === 0) {
      delete filtros.area
      delete filtros.usuario
      listUsers = this.getUsersFilteredByArea(this.state.areas, listUsersOriginal)
    } else {
      listUsers = this.getUsersFilteredByArea(areasList, listUsersOriginal)
      filtros.area = areasList.map(area => area.id).join(",")
    }

    this.setState({
      filtros,
      isLoading: true,
      listUsers
    }, () => this.applyFilters(filtros))
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de usuarios.
   * 
   * @param {Array} userList
   */
  handleChangeUser = (userList = []) => {
    let filtros = { ...this.state.filtros }

    if (userList.length === 0) {
      delete filtros.usuario
    } else {
      filtros.usuario = userList.map(user => user.id).join(",")
    }

    this.setState({
      filtros,
      isLoading: true
    })

    this.applyFilters(filtros)
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de periodos.
   * 
   * @param {Array} periodsList
   */
  handleChangePeriodo = (periodsList = []) => {
    let filtros = { ...this.state.filtros }
    let periodosSeleccionados = []

    if (periodsList.length === 0) {
      delete filtros.periodo
    } else {
      filtros.periodo = periodsList.map(period => period.id).join(",")
    }
    periodosSeleccionados = periodsList

    this.setState({
      filtros,
      periodosSeleccionados,
      isLoading: true
    })

    this.applyFilters(filtros)
  }

  /**
   * Maneja los cambios al seleccionar un item de la
   * lista de niveles.
   * 
   * @param {Array} levelsList
   */
  handleChangeNivel = (levelsList = []) => {
    let filtros = { ...this.state.filtros }

    if (levelsList.length === 0) {
      delete filtros.nivel
    } else {
      let levels = levelsList.map(nivel => {
        let level = NIVEL_CUMPLIMIENTO.filter(item =>
          item.id === nivel.id
        )[0].nivel.split(',').map(number => Number(number))

        return level
      })

      let minLevel = Math.min.apply(null, levels.flat())
      let maxLevel = Math.max.apply(null, levels.flat())
      // debugger
      filtros.nivel = [minLevel, maxLevel].join(',')
    }

    this.setState({
      filtros,
      isLoading: true
    })

    this.applyFilters(filtros)
  }

  /**
   * Llama a la API de propuestas con los filtros seleccionados.
   * 
   * @param {Object} filters
   */
  applyFilters = (filters = {}) => {
    this.loadReports(filters)
  }

  componentDidMount() {
    let listGerencias = []
    let areas = []
    let areasOriginal = []
    let listUsers = []
    let listUsersOriginal = []
    let listPeriodos = []
    let currentPeriod = null
    let filtros = {...this.state.filtros}
    let periodosSeleccionados = []

    GerenciasAPI.getAll()
    .then(({data}) => {
      listGerencias = data
      let gerenciasIds = listGerencias.map(x => x.id)
      return AreaAPI.getAll(gerenciasIds)
    })
    .then(({data}) => {
      areas = data
      areasOriginal = data
      return UsuariosAPI.getAll()
    })
    .then(({data}) => {
      listUsers = data
      listUsersOriginal = data
      return ConvocatoriaAPI.getAll()
    })
    .then(({data}) => {
      listPeriodos = data
      return ConvocatoriaAPI.getCurrent()
    })
    .then(({data}) => {
      if(!(Object.keys(data.convocatoria).length === 0 && data.convocatoria.constructor === Object)){
        currentPeriod = data.convocatoria.id
        filtros.periodo = '' + data.convocatoria.id
        periodosSeleccionados.push({id: data.convocatoria.id, value: data.convocatoria.nombre})
      }

      this.setState({
        listGerencias, areas, areasOriginal, listUsers, listUsersOriginal,
        listPeriodos, currentPeriod, filtros, periodosSeleccionados
      }, () => this.loadReports(this.state.filtros))
    })
    .catch(() => toast.error('Hubo un error al cargar los datos para los filtros'))
  }

  /**
   * Maneja el cambio al seleccionar un estatus de usuario de las lista.
   * 
   * @param {Event}
   */
   handleChangeEstatusUsuario = ({ target }) => {
     
    let filtros = { ...this.state.filtros }
    const status = target.value     

    if (status != '' && status != undefined) {
      filtros.estatus = status
    }
    else{
      delete filtros.estatus
    }
    this.setState({
      filtros,
      isLoading :true,
      selectedEstatusUsuario: status
    }, () => this.applyFilters(filtros))
  }

  render() {
    const {
      loadingExcel, listGerencias, areas, listUsers,
      listPeriodos, listNivelesCumplimiento, listEstatusUsuario, selectedEstatusUsuario,
      listFilteredUsersCompensacion, listReportsFiltered,
      isLoading, currentPageItems
    } = this.state

    const COLUMNS = [
      {
        id: 1,
        title: "Número de empleado",
        render: row => row.item.usuario.usuario.id_empleado,
        size: "5%"
      },
      {
        id: 2,
        title: "Nombre",
        render: row => row.item.usuario.usuario.nombre,
        size: "20%"
      },
      {
        id: 3,
        title: "Puesto",
        render: row => row.item.usuario.puesto,
        size: "20%"
      },
      {
        id: 4,
        title: "Área",
        render: row => row.item.usuario.area_nombre,
        size: "20%"
      },
      {
        id: 5,
        title: "Comp. Autorizada",
        render: row => {
          return row.item.cumplimiento ? row.item.compensacion_autorizada : parseFloat(row.item.usuario.monto * row.item.usuario.politica / 1000).toFixed(2)
          // return row.item.cumplimiento ? 
          // parseFloat((parseFloat(row.item.compensacion_ordinaria) * 100) / parseFloat(row.item.cumplimiento)).toFixed(2) :
          // parseFloat(row.item.usuario.monto * row.item.usuario.politica / 1000).toFixed(2)
        },
        size: "5%"
      },
      {
        id: 6,
        title: "Comp. Real",
        render: row => {
          return row.item.estatus_evaluacion === 'Evaluacion Aceptada' ? 
          parseFloat(row.item.compensacion_ordinaria).toFixed(2) :
          parseFloat(0).toFixed(2)
        },
        size: "5%"
      },
      {
        id: 7,
        title: "% de cumplimiento",
        render: row => {
          return row.item.estatus_evaluacion === 'Evaluacion Aceptada' ? 
          parseFloat(row.item.cumplimiento).toFixed(2) :
          parseFloat(0).toFixed(2)
        },
        size: "5%"
      },
      {
        id: 8,
        title: "Turbo",
        render: row => {
          return row.item.estatus_evaluacion === 'Evaluacion Aceptada' ? 
          parseFloat(row.item.compensacion_turbo).toFixed(2) :
          parseFloat(0).toFixed(2)
        },
        size: "5%"
      },
      {
        id: 9,
        title: "Ajuste proporcional según carta oferta",
        render: row => {
          return row.item.estatus_evaluacion === 'Evaluacion Aceptada' ? 
          parseFloat(row.item.ajuste_proporcional).toFixed(2) :
          parseFloat(0).toFixed(2)
        },
        size: "5%"
      },
      {
        id: 10,
        title: "Total",
        render: row => {
          return row.item.estatus_evaluacion === 'Evaluacion Aceptada' ? 
          parseFloat(row.item.compensacion_total).toFixed(2) :
          parseFloat(0).toFixed(2)
        },
        value: "compensacion_total",
        size: "5%"
      }
    ]

    return (
      <Fragment>
        <Card
          title="Reporte de compensación total"
          extraActions={
            <Button
              text="Descargar Excel"
              title="Descargar Excel de Reporte de Compensación Total"
              type="Secondary"
              icon="fa-file-excel-o"
              onClick={this.handleDownloadExcel}
              disabled={loadingExcel || currentPageItems.length === 0}
            />
          }
        >
          <FiltersWrapper>
            <MultiSelect
              label="Gerencia"
              name="gerencia"
              items={listGerencias.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={listGerencias.length === 0}
              onChangeValue={(itemList) => this.handleChangeGerencia(itemList)}
            />
            <MultiSelect
              label="Área"
              name="area"
              items={areas.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              disabled={areas.length === 0}
              onChangeValue={(itemList) => this.handleChangeArea(itemList)}
            />
            <MultiSelect
              label="Usuario"
              name="user"
              items={listUsers.map(user => {
                let newUser = { ...user }
                newUser.value = `${user.usuario.id_empleado} - ${user.usuario.nombre}`

                return newUser
              })}
              disabled={listUsers.length === 0}
              onChangeValue={(itemList) => this.handleChangeUser(itemList)}
            />
            <MultiSelect
              label="Periodo"
              name="periodo"
              items={listPeriodos.map(item => {
                let newItem = { ...item }
                newItem.value = item.nombre

                return newItem
              })}
              selectedItems={[...this.state.periodosSeleccionados]}
              disabled={listPeriodos.length === 0}
              onChangeValue={(itemList) => this.handleChangePeriodo(itemList)}
            />
            <MultiSelect
              label="Nivel Cumplimiento"
              name="nivel"
              items={listNivelesCumplimiento.map(item => item)}
              disabled={listNivelesCumplimiento.length === 0}
              onChangeValue={(itemList) => this.handleChangeNivel(itemList)}
            />
             
            <Wrapper label="Estatus Usuario">
              <Select
                label="Estatus Usuario"
                classes="input"
                name="estatus_usuario"
                value={selectedEstatusUsuario}
                change={this.handleChangeEstatusUsuario}
                items={listEstatusUsuario}
                disabled={listEstatusUsuario.length === 0}
                optionProps={{ value: "id", text: "value" }}
              />
            </Wrapper> 
          </FiltersWrapper>
        </Card>
        <br />
        <Card>
          <Table
            columns={COLUMNS}
            dataSet={currentPageItems}
            noDataText="Reporte vacío"
            loading={isLoading}
          />
          <Pagination
            items={listReportsFiltered}
            pageSize={10}
            onChangePage={items => this.setState({ currentPageItems: items })}
          />
        </Card>
        {
          isLoading &&
          <Loader
            isFullscreen={true}
            isLoading={isLoading}
            width="100px"
            height="100px"
          />
        }
      </Fragment>
    )
  }
}

export default CompensacionTotal

const LIST_ESTATUS_USUARIO = [
  {
    id: true,
    value: 'Activo'
  },
  {
    id: false,
    value: 'Inactivo'
  }
]
