import { apiUrlBsc }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {'Authorization': 'Bearer '+localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class AreaAPI {
    static getAll(gerenciaArr=null) {
        return middleware.hasAuth(() => request.get('area/', {
            params: {
                gerenciaArr: gerenciaArr !== null ? gerenciaArr.toString() : gerenciaArr
            }
        }));
    }

    static getWithFilters(filters = {}) {
        return middleware.hasAuth(() => request.get(`/area/?${stringify(filters)}`));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/Test/${id}`));
    }
    

    static create(data) {
        return middleware.hasAuth(() => request.post('area/',data));
    }

    static edit(unit, id){
        return middleware.hasAuth(() => request.put(`/area/${id}`,unit));
    }

    static delete(id){
        return middleware.hasAuth(() => request.delete(`Test/${id}`));
    }
}