import React from 'react'

// Utilities
import { getTagClassByStatus } from '../../../util/utileria'

/**
 * Componente wrapper para las propuestas.
 * 
 * @param {Object} props
 * 
 * @returns PropuestaWrapper
 */
export const PropuestaWrapper = ({
  children,
  noEmpleado = 0,
  nombre = '',
  estatus,
  evaluacionEstatus,
}) => {
  return (
    <div className="card mt-10 mb-10">
      <div className="card-content">
        <div className="content">
          <div className="mb-10">
            <span className="title is-5" style={{ marginBottom: "0" }}>{noEmpleado} - {nombre}</span>
            {
              estatus &&
              <span
                className={`tag ${getTagClassByStatus(estatus)} ml-10`}
                title={`Estatus de la propuesta: ${estatus}`}
              >
                {estatus}
              </span>
            }
            {
              evaluacionEstatus &&
              <span
                className={`tag ml-10`}
                title={
                  `Estatus de la propuesta: ${getProposalEvaluationTag(evaluacionEstatus)}`
                }
              >
                {getProposalEvaluationTag(evaluacionEstatus)}
              </span>
            }
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

/**
 * Obtiene el texto del estatus actual de la evaluación.
 * 
 * @param {String} evaluationStatus
 * @returns {String} `tagText`
 */
function getProposalEvaluationTag(evaluationStatus) {
  let tagText = ''

  switch (evaluationStatus) {
    case 'Sin Evaluacion':
      tagText = 'Sin evaluación'
      break
    case 'Evaluado':
      tagText = 'Autoevaluación realizada'
      break
    case 'Evaluacion Aceptada Jefe':
      tagText = 'Evaluación aceptada por jefe directo'
      break
    case 'Evaluacion Aceptada Director Area':
      tagText = 'Evaluación aceptada por director de área'
      break
    case 'Evaluacion Aceptada':
      tagText = 'Evaluación Autorizada'
      break
    case 'Autoevaluacion en proceso':
      tagText = 'Sin evaluación'
      break
    case 'Evaluacion en proceso':
      tagText = 'Autoevaluación realizada'
      break
  }

  return tagText
}
