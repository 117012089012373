import React, { Component, Fragment } from 'react'

// Services
import UsersAPI from '../../../services/Usuario'
import AreasAPI from '../../../services/Area'
import HistorialAPI from '../../../services/HistorialMovimientos'

// Components
import { Card } from '../../util/Card/Card'
import { Table } from '../../util/Table/Table'
import Pagination from '../../util/pagination/Pagination'
import { FiltersWrapper } from '../../util/FiltersWrapper/FiltersWrapper'
import MultiSelect from '../../util/MultiSelect/MultiSelect'
import { toast } from 'react-toastify'
import { DatePickerRange } from '../../util/DatePicker/DatePickerRange'
import { Button } from '../../util/Button/Button'
import Loader from '../../util/loading/Loading'

// Utils
import { generateBasicExcel } from '../../../util/ExcelFunctions'
import moment, { isDate } from 'moment'

/**Módulo para ver historial de movimientos de los usuarios. */
class HistorialMovimientos extends Component {

  state = {
    currentPageItems: [],
    loadingTable: true,
    loadingExcel: false,
    listAreas: [],
    selectedAreas: [],
    listUsers: [],
    selectedUsers: [],
    listFilteredUsers: [],
    listMovements: [],
    selectedMovements: [],
    listHistory: [],
    filteredListHistory: [],
    filters: {},
    rangeDates: {
      fecha_inicio: "",
      fecha_fin: ""
    }
  }

  toastFileDownloadSuccess = null
  toastFileDownloadError = null

  /**Carga inicial del historial de movimientos de la aplicación. */
  loadInitialHistory = (filters = {}) => {
    HistorialAPI.getAll(filters)
      .then(res => {
        let movimientos_type = this.getMovementsFromHistory(res.data)

        this.setState({
          listHistory: res.data,
          filteredListHistory: res.data,
          loadingTable: false,
          listMovements: movimientos_type
        })
      })
      .catch(err => {
        toast.error('Historial no cargado.')
        this.setState({
          loadHistorial: false,
          loadingTable: false
        })
      })
  }

  /**Carga del historial de movimientos de la aplicación
   * según filtros aplicados.
  */
  loadHistorial = (filters = {}) => {
    HistorialAPI.getAll(filters)
      .then(res => this.setState({
        filteredListHistory: res.data,
        loadingTable: false
      }))
      .catch(err => {
        toast.error('Historial no cargado.')
        this.setState({
          loadHistorial: false,
          loadingTable: false
        })
      })
  }

  /**
   * Obtener lista de movimientos del historial.
   * 
   * @param {Array}
   * @return Array
   */
  getMovementsFromHistory = (history) => {
    let listItems = [...new Set(history.map(item => item.accion))]

    let finalList = listItems.map((item, index) => {
      return {
        id: index,
        value: item
      }
    })

    return finalList
  }

  /**Carga de las áreas registradas en la aplicación. */
  loadAreas = () => {
    AreasAPI.getAll()
      .then(res => this.setState({ listAreas: res.data }))
      .catch(err => toast.error('Areas no cargadas.'))
  }

  /**Carga de los usuarios de la aplicación. */
  loadUsers = () => {
    UsersAPI.getAll()
      .then(res => this.setState({
        listUsers: res.data,
        listFilteredUsers: res.data
      }))
      .catch(err => toast.error('Usuarios no cargados.'))
  }

  /**
   * Applicación de los filtros seleccionados.
   * 
   * @param {Object} filters
   */
  applyFilters = (filters = {}) => {
    this.setState({ loadingTable: true })
    this.loadHistorial(filters)
  }

  /**
   * Manjea los cambios en la lista de areas.
   * 
   * @param {Array} listAreas
   */
  handleChangeArea = (listAreas = []) => {
    let listUsers = this.state.listUsers
    let filters = { ...this.state.filters }
    let filteredUsers = []

    if (listAreas.length > 0) {
      filters.area = listAreas.map(area => area.id).join(",")
      for (let i = 0; i < listAreas.length; i++) {
        for (let j = 0; j < listUsers.length; j++) {
          if (listAreas[i].id === listUsers[j].area) {
            filteredUsers.push(listUsers[j])
          }
        }
      }
    } else if (listAreas.length === 0) {
      delete filters.area
      filteredUsers = listUsers
    }

    delete filters.usuario

    this.setState({
      filters,
      selectedUsers: [],
      listFilteredUsers: filteredUsers,
      selectedAreas: listAreas
    })

    this.applyFilters(filters)
  }

  /**
   * Manjea los cambios en la lista de usuarios.
   * 
   * @param {Array} listUsers
   */
  handleChangeUser = (listUsers = []) => {
    let filters = { ...this.state.filters }

    if (listUsers.length === 0) {
      delete filters.usuario
    } else {
      filters.usuario = listUsers.map(user => user.id).join(",")
    }

    this.setState({
      filters,
      selectedUsers: listUsers
    })

    this.applyFilters(filters)
  }

  /**
   * Manjea los cambios en la lista de movimientos.
   * 
   * @param {Array} listMovements
   */
  handleChangeMovement = (listMovements = []) => {
    let filters = { ...this.state.filters }

    if (listMovements.length === 0) {
      delete filters.accion
    } else {
      filters.accion = listMovements.map(movement => movement.value).join(",")
    }
    this.setState({
      filters,
      selectedMovements: listMovements
    })

    this.applyFilters(filters)
  }

  /**
   * Manjea los cambios en las fechas.
   * 
   * @param {Object} target
   */
  handleChangeDates = (target) => {
    let filters = { ...this.state.filters }
    let range = { ...this.state.rangeDates }
    let field = target.name
    let date = target.value

    if (date === '') {
      delete filters[field]
      delete range[field]
    } else {
      range[field] = date
      if (range['fecha_fin'] === '') {
        delete filters['fecha_fin']
        delete range['fecha_fin']
      }
      if (range['fecha_inicio'] === '') {
        delete filters['fecha_inicio']
        delete range['fecha_inicio']
      }
      filters = {
        ...filters,
        ...range
      }
    }


    this.setState({
      filters,
      rangeDates: range
    }, () => {
      if (moment(date).isAfter('2000-01-01'))
        this.handleValidateDates()
    })
  }

  /**Valida si la fecha final es mayor o igual a la inicial. */
  handleValidateDates = () => {
    const { filters } = this.state
    const dateStart = filters['fecha_inicio']
    const dateEnd = filters['fecha_fin']
    const diff = moment(dateEnd).diff(dateStart, 'days')

    if (
      dateStart !== undefined &&
      diff < 0
    ) {
      filters['fecha_fin'] = dateStart
      this.setState({
        rangeDates: {
          ...filters
        },
        filters: {
          ...filters
        }
      })
    }


    this.applyFilters(filters)
  }

  /**Descarga de Excel de plan estratégico. */
  handleDownloadReport = () => {
    this.setState({ loadingExcel: true })
    const { filteredListHistory } = this.state
    try {
      const excelMeta = {
        excelName: "Historial de Movimientos",
        sheetName: "Historial de Movimientos"
      }

      let [rows, columns] = this.formattedInfo(filteredListHistory)

      generateBasicExcel(excelMeta, rows, columns)
      if (!toast.isActive(this.toastFileDownloadSuccess))
        this.toastFileDownloadSuccess = toast.success('Excel descargado!')

      this.setState({ loadingExcel: false })
    } catch (err) {
      console.log(err)
      if (!toast.isActive(this.toastFileDownloadError))
        this.toastFileDownloadError = toast.error('No se descargó el archivo Excel!')
      this.setState({ loadingExcel: false })
    }
  }

  /**
   * Obtiene la información de los renglones procesados
   * para darle formato al archivo Excel.
   * 
   * @param {Array} infoRows
   * 
   * @returns Array
   */
  formattedInfo = (infoRows = []) => {
    let columns = []
    let rows = []

    rows = infoRows.map(item => {
      return {
        nombre_usuario: item.usuario.usuario.nombre,
        accion: item.accion,
        fecha: item.fecha,
        modulo: item.modulo,
        detalle: item.detalle,
        puesto: item.usuario.puesto,
        monto: item.usuario.monto,
        jefe_directo: item.usuario.jefe_directo_nombre,
        grupo: item.usuario.grupo_nombre,
        correo: item.usuario.usuario.email
      }
    })

    columns = Object.keys(rows[0])

    return [rows, columns]
  }

  componentDidMount() {
    this.loadInitialHistory()
    this.loadAreas()
    this.loadUsers()
  }

  render() {
    const {
      currentPageItems, listMovements, listAreas,
      filteredListHistory, listFilteredUsers, rangeDates,
      loadingExcel, loadingTable, selectedUsers
    } = this.state
    const COLUMNS = [
      {
        id: 1,
        title: "Nombre usuario",
        render: row => row.item.usuario.usuario.nombre,
        size: "20%"
      },
      {
        id: 2,
        title: "Fecha",
        render: row => moment(row.item.fecha).format("LL"),
        size: "20%"
      },
      {
        id: 3,
        title: "Tipo movimiento",
        value: "accion",
        size: "15%"
      },
      {
        id: 4,
        title: "Detalle",
        value: "detalle",
        size: "45%"
      }
    ]

    return (
      <Fragment>
        <Card
          title="Reporte de movimientos"
          extraActions={
            <Button
              text="Descargar Excel"
              title="Descargar Excel de reporte de Historial de Movimientos"
              icon="fa-file-excel-o"
              onClick={this.handleDownloadReport}
              disabled={
                loadingExcel ||
                filteredListHistory.length === 0 ||
                loadingTable
              }
              type="secondary"
            />
          }
        >
          <FiltersWrapper>
            <DatePickerRange
              disabledStart={loadingTable}
              disabledEnd={loadingTable}
              nameStart="fecha_inicio"
              nameEnd="fecha_fin"
              changeInput={({ target }) => this.handleChangeDates(target)}
              valueStart={
                rangeDates.fecha_inicio === undefined ?
                  ''
                  : moment(rangeDates.fecha_inicio).format("YYYY-MM-DD")
              }
              valueEnd={
                rangeDates.fecha_fin === undefined ?
                  ''
                  : moment(rangeDates.fecha_fin).format("YYYY-MM-DD")
              }
              validation={e => this.handleValidateDates(e)}
            />
            <MultiSelect
              label="Área"
              name="area"
              items={listAreas.map(area => {
                let newArea = { ...area }
                newArea.value = area.nombre

                return newArea
              })}
              disabled={listAreas.length === 0}
              onChangeValue={itemList => this.handleChangeArea(itemList)}
            />
            <MultiSelect
              label="Usuario"
              name="empleado"
              items={listFilteredUsers.map(user => {
                let newUser = { ...user }
                newUser.value = user.usuario.nombre

                return newUser
              })}
              selectedItems={selectedUsers}
              disabled={listFilteredUsers.length === 0}
              onChangeValue={itemList => this.handleChangeUser(itemList)}
            />
            <MultiSelect
              label="Tipo Movimiento"
              name="tipo_movimiento"
              items={listMovements.map(item => {
                let newMovimiento = { ...item }
                newMovimiento.value = item.value

                return newMovimiento
              })}
              onChangeValue={itemList => this.handleChangeMovement(itemList)}
            />
          </FiltersWrapper>
        </Card>
        <br />
        <Card>
          <Table
            columns={COLUMNS}
            dataSet={currentPageItems}
            noDataText="Historial vacío"
            loading={loadingTable}
          />
          <Pagination
            items={filteredListHistory}
            pageSize={10}
            onChangePage={items => this.setState({ currentPageItems: items })}
          />
        </Card>
        {
          loadingTable &&
          <Loader
            isFullscreen={true}
            isLoading={loadingTable}
            width="100px"
            height="100px"
          />
        }
      </Fragment>
    )
  }
}

export default HistorialMovimientos

const ACTIONS = [
  {
    id: 1,
    value: "Actualizar Propuesta"
  },
  {
    id: 2,
    value: "Autorizo Propuesta"
  },
  {
    id: 3,
    value: "Elimino objetivo"
  },
  {
    id: 4,
    value: "Nueva Relacion"
  }
]
