import React, { Fragment } from 'react'

// Styles
import './AutoEvaluacion.css'

// Components
import { ListEvidenceItem } from './ListEvidenceItem'

export const ListEvidences = ({ dataSet = [], onDeleteItem, onDownloadItem }) => {
  return (
    <Fragment>
      {
        dataSet.length !== 0 ?
          <ul className="ListEvidences">
            {
              dataSet.map(itemEvidence => {
                return <ListEvidenceItem
                  key={itemEvidence.id}
                  item={itemEvidence}
                  deleteEvidence={id => onDeleteItem(id)}
                  onDownloadFile={(id, name) => onDownloadItem(id, name)}
                />
              })
            }
          </ul>
          : <span>Sin evidencias</span>
      }
    </Fragment>
  )
}