import React, { Component } from 'react';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import Comentario from '../../util/propuestas/comentario';
import { validate } from '../../../util/validation-rules';
import { ModalComentarios } from '../Comentarios/ModalComentarios';
import Confirmar from '../../util/propuestas/Confirmar'

class Propuesta extends Component {
    constructor(props) {
        super(props);

        this.comentario = {
            indicador: 0,
            comentario: "",
            error: false
        } 

        this.state = {
            showModalComentarios: false,
            isConfirmModalVisible : false,
            indicador: {
                bajo_meta: "",
                comentario: "",
                comentarios: [],
                id: 0,
                inaceptable: "",
                indicador: "",
                meta: "",
                objetivo: "",
                ponderacion: 0,
                propuesta: 0,
                revision: null,
                sobre_meta: "",
                tipo: "",
                version: 0
            },
            comentario: {
                indicador: 0,
                comentario: "",
                error: false
            }
        }
    }

    render() {
        let indicadores = this.props.indicadores && this.props.indicadores.filter(indicador => indicador.tipo === "Normal")
        return (
            <div>
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                            <th style={BSCColumnStyle.indicador}>Indicador</th>
                            <th style={BSCColumnStyle.comentario}>Comentario</th>
                            <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                            <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                            <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                            <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                            <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación" width="7%">% Pond.</abbr></th>
                            {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                            <th style={BSCColumnStyle.acciones} width="10%">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            indicadores &&
                            indicadores.map((obj, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{obj.objetivo}</td>
                                        <td>{obj.indicador}</td>
                                        <td>{obj.comentario}</td>
                                        <td>{obj.sobre_meta}</td>
                                        <td>{obj.meta}</td>
                                        <td>{obj.bajo_meta}</td>
                                        <td>{obj.inaceptable}</td>
                                        <td>{obj.ponderacion}</td>
                                        {this.props.showCompensacion && <td>Compensación</td>}
                                        <td>

                                            <div className="mv-inline mv-margin">
                                                <button className="button is-success tooltip" data-tooltip="Validar objetivo" disabled={obj.validado}
                                                    onClick={ () => this.validateIndicador(obj) }>
                                                    <span className="icon">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="mv-inline mv-margin">
                                                <button className="button is-danger tooltip" data-tooltip="Rechazar objetivo" disabled={obj.validado}
                                                    onClick={() => {
                                                        this.setState({ indicador: obj }, () => this.toggleModalComentarios())
                                                    }}>
                                                    <span className="icon">
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    this.state.showModalComentarios &&
                    <ModalComentarios
                        change={this.handleComentarioChange}
                        save={this.saveComentario}
                        getPrevious={this.props.getPrevious}
                        toggle={this.toggleModalComentarios}
                        indicador={this.state.indicador}
                        comentario={this.state.comentario}
                        comentarios={this.props.comentarios}
                    />
                }
                {
                    this.state.isConfirmModalVisible &&
                    <Confirmar
                    yes={() => {
                        this.props.validarIndicador(this.state.indicador.id)
                        let state = {...this.state}
                        state.isConfirmModalVisible = false
                        this.setState(state);
                    }}
                    no={this.handleCloseValidarIndicador}
                    >
                        <p className="ConfirmSubtitle">¿Seguro que desea validar el indicador?</p>
                    </Confirmar>
                }
                
            </div>
        )
    }

    handleComentarioChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let state = { ...this.state };

        state.comentario[name] = value;
        state.comentario.error = false;
        
        if(validate.isEmpty(e.target)) {
            state.comentario.error = true
        }

        this.setState(state);
    }

    saveComentario = () => {
        let state = {...this.state}
        state.comentario.indicador = state.indicador.id;

        if(validate.isValueEmpty(state.comentario.comentario)) {
            state.comentario.error = true
        }

        this.setState(state, () => {
            if(!state.comentario.error) {
                this.props.saveComentario(state.comentario)
                this.limpiarComentario()
            }
        })
    }

    validateIndicador = (indicador) => {
        let state = {...this.state}
        state.isConfirmModalVisible = true
        state.indicador = indicador
        this.setState(state);
    }

    handleCloseValidarIndicador = () => {
        let state = {...this.state}
        state.isConfirmModalVisible = false
        this.setState(state);
    }

    toggleModalComentarios = () => {
        this.setState({ showModalComentarios: !this.state.showModalComentarios })
    }

    limpiarComentario() {
        let state = {...this.state}
        state.comentario = {...this.comentario}
        this.setState(state);
    }
}

// const ModalComentarios = (props) => {
//     /**
//      * props:
//      * - toggle()              : [OK] funcion que abre y cerra el modal
//      * - show                  : [OK] [true, false]
//      * - {indicador}           : [OK] objeto indicador
//      * - save()                : [OK] función que manda a guardar el comentario
//      * - getPreviousComments() : hace una llamada para obtener los comentarios anteriores para ese indicador
//      */

//     return (
//         <div className={props.show ? "modal is-active" : "modal"} >
//             <div className="modal-background" />
//             <div className="modal-card">

//                 <header className="modal-card-head">
//                     <p className="modal-card-title">Agregar comentarios</p>
//                     <button onClick={props.toggle} className="delete" aria-label="close"></button>
//                 </header>

//                 <section className="modal-card-body">
//                     <div>
//                         <textarea className={props.comentario.error ? "textarea is-danger" : "textarea"}
//                             autoFocus={true}
//                             placeholder="Comentario"
//                             name="comentario"
//                             value={props.comentario.comentario}
//                             onChange={props.change}
//                         />
//                         { props.comentario.error && <p className="help is-danger">El comentario no puede estar vacío</p>}
//                     </div>
//                     <div className="mv-c-margin-top">
//                         <div className="buttons are-small">
//                             <button className="button is-success" disabled={props.comentario.error} onClick={props.save}>Guardar</button>
//                             {/* <button className="button" onClick={props.toggle}>Cerrar</button> */}
//                         </div>
//                     </div>
//                     <hr />
//                     {
//                         props.indicador.comentarios.length > 0 &&
//                         <div className="mv-c-margin-top">
//                             <h5 className="title is-5">Ultimo comentario: </h5>
//                             <div>

//                                 {
//                                     props.indicador.comentarios.map((comentario, index) => {
//                                         return (
//                                             <Comentario comentario={comentario} key={index} />
//                                         )
//                                     })
//                                 }

//                             </div>
//                             <div style={{marginTop: "10px"}}>
//                                 <button className="button" disabled>Cargar comentarios anteriores</button>
//                             </div>
//                         </div>
//                     }
//                 </section>
//             </div>
//         </div>
//     )
// }

export default Propuesta;