import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../util/Button/Button';
import { Modal } from '../util/modal/ModalLayout';
import { Wrapper } from '../util/WrapperInput/WrapperInput'
import Select from '../util/select/Selct'
import SolicitudesCompensacionApi from '../../services/SolicitudesCompensacion';
import Loading from '../util/loading/Loading'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**Tabla de solicitudes de compensacion realizadas. */
export class CompensacionPendiente extends Component {
    state = {
        totalSolicitado: 0,
        compensaciones: this.props.compensaciones,
        fechasDeposito: [],
        fechaDepositoSelected: null,
        isModalSolicitudDateShow: false,
        isLoading: false
    }

    /**Actualiza los valores de la tabla de compensaciones pendientes segun el monto solicitado
     * @param {Int} pIndex
     * @param {object} item
     */
    handleChangeInput = (pIndex, item) => {
        let{compensaciones, totalSolicitado} = this.state
        var pendiente = parseFloat(compensaciones[pIndex].disponible_constante) 
        var solicitado = parseFloat(item.target.value)
        totalSolicitado = 0

        if(solicitado < 0){            
            toast.error('El monto solicitado debe ser mayor a 0');

            compensaciones.forEach(function (comp, index) {
                if(index >= pIndex){
                    comp.compensacion_sobrante = comp.disponible_constante
                    comp.monto_solicitado = 0
                }
                totalSolicitado = comp.monto_solicitado ? totalSolicitado + parseFloat(comp.monto_solicitado) : totalSolicitado
            });

            this.setState({
                totalSolicitado: totalSolicitado,
                compensaciones: compensaciones
            })
        }
        else if(pendiente < solicitado){
            toast.error('Monto insuficiente');

            compensaciones.forEach(function (comp, index) {
                if(index >= pIndex){
                    comp.compensacion_sobrante = comp.disponible_constante
                    comp.monto_solicitado = 0
                }
                totalSolicitado = comp.monto_solicitado ? totalSolicitado + parseFloat(comp.monto_solicitado) : totalSolicitado
            });

            this.setState({
                totalSolicitado: totalSolicitado,
                compensaciones: compensaciones
            })

        }else{
            compensaciones[pIndex].compensacion_sobrante = compensaciones[pIndex].disponible_constante - item.target.value
            compensaciones[pIndex].monto_solicitado = item.target.value

            compensaciones.map(comp => {
                totalSolicitado = comp.monto_solicitado ? totalSolicitado + parseFloat(comp.monto_solicitado) : totalSolicitado
            })

            this.setState({
                totalSolicitado: totalSolicitado,
                compensaciones: compensaciones
            })
        }

    }

    /**Carga los valores al dropdown de fechas disponibles para deposito con las siguientes disponibles*/
    handleSetFechasDeposito = () => {
        const dateNow = new Date();
        const day = dateNow.getDate();
        let mount = dateNow.getMonth() + 1;
        let year = dateNow.getFullYear();
        let fechasDepositoNew = []

        mount = (day >= 21 && day <= 31) ? (mount + 1) > 12 ? 1 : mount + 1  : mount;

        year = mount > 12 ? year + 1 : year
        
        fechasDepositoNew.push({value: year+'-'+mount+'-10' , text: '10/'+mount+'/'+year}, {value:year+'-'+mount+'-25' , text: '25/'+mount+'/'+year})

        this.setState({
            fechasDeposito: fechasDepositoNew
        })

    }

    /**Crea una nueva solicitud  */
    save = () => {
        this.setState({ isLoading: true });

        let solicitud = {};
        let propuestas = [];

        solicitud.fecha_solicitud_deposito = this.state.fechaDepositoSelected
        solicitud.monto_solicitado_total = parseFloat(this.state.totalSolicitado)

        this.state.compensaciones.map(comp => {
            if(comp.monto_solicitado > 0){
                let propuesta = {}
                propuesta.propuesta_id = comp.id
                propuesta.monto_solicitado = parseFloat(comp.monto_solicitado)
                propuestas.push(propuesta)
            }
        })
        solicitud.propuestas_solicitadas = propuestas

        SolicitudesCompensacionApi.create(solicitud)
        .then( response => {
            this.setState({
                isModalSolicitudDateShow: false,
                isLoading: false 
            })
            this.props.save();
        })
        .catch( err => {
            this.setState({ isLoading: false });
            toast.error('Ocurrió un error al crear la solicitudes');
        })

    }

    render() {
        const{compensaciones, totalSolicitado, fechasDeposito, fechaDepositoSelected} = this.state
        return (
            <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "60%" }}>

                    <header className="modal-card-head">
                        <p className="modal-card-title">Periodos con compensación pendiente de solicitar</p>
                        
                        <button onClick={this.props.cancel} className="delete" title="Cerrar" aria-label="close"></button>
                    </header>

                    <section className="modal-card-body">
                        
                        <table className="table is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Periodo</th>
                                    <th>Monto total</th>
                                    <th>Monto obtenido</th>
                                    <th>Monto disponible</th>
                                    <th>Monto a solicitar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    compensaciones &&
                                    compensaciones.map((compensacion, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{compensacion.convocatoria.year} - {compensacion.convocatoria.semestre}</td>
                                                <td>{formatter.format(compensacion.compensacion_autorizada)}</td>
                                                <td>{formatter.format(compensacion.compensacion_total)}</td>
                                                <td>{formatter.format(compensacion.compensacion_sobrante)}</td>
                                                <td>
                                                    <input className="input" type="number" placeholder="Monto solicitado"
                                                        name="monto_solicitado"
                                                        max={compensacion.disponible_constante}
                                                        value = {compensacion.monto_solicitado}
                                                        disabled = {compensaciones[index - 1] ? parseFloat(compensaciones[index - 1].compensacion_sobrante) > 0 : false}
                                                        onChange={e =>{
                                                            this.handleChangeInput(index, e)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }                        
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>MONTO TOTAL A SOLICITAR</td>
                                    <td style={{"background-color":"#A0FE61"}}>{formatter.format(totalSolicitado)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </section>

                    <footer className="modal-card-foot">
                    {/* Botón para guardar y notificar a Jefe directo */}
                        <Button
                            onClick={() => {
                                this.handleSetFechasDeposito()
                                this.setState({
                                    isModalSolicitudDateShow : true
                                })
                            }}
                            text="Guardar y Notificar"
                            icon="fa-check"
                            disabled = {
                                this.state.totalSolicitado == 0
                            }
                        />
                    
                    {/* Botón para cancelar la edición de la propuesta */}
                        <Button
                            onClick={()=> {
                                this.props.cancel()
                            }}
                            text="Cancelar"
                            type="is-danger"
                            icon="fa-ban"
                        />
                    </footer>

                </div>
                {
                    this.state.isModalSolicitudDateShow &&
                    <Modal
                        title="Seleccione fecha de depósito"
                        onClose={() => {
                            this.setState({
                                isModalSolicitudDateShow: false
                            })
                        }}
                        cancelText="Cancelar"
                        confirmText="Solicitar"
                        onConfirm={() =>{
                            this.save()
                        }}
                        onConfirmDisable={this.state.fechaDepositoSelected == null}
                        isVisible={this.state.isModalSolicitudDateShow}
                    >
                        
                        <Wrapper label="Fechas disponibles">
                            <Select
                                classes="input"                                
                                name="fechaDisponible"
                                items={fechasDeposito}
                                value={fechaDepositoSelected}
                                optionProps={{ value: 'value', text: 'text' }}
                                change={ e => this.setState({ fechaDepositoSelected: e.target.value }) }
                            />
                        </Wrapper>
                        
                    </Modal>
                }

                <Loading
                    isFullscreen={true}
                    isLoading={this.state.isLoading}
                    width="100px"
                    height="100px"
                />
            </div>

            

            

        )
    }
}

export default CompensacionPendiente;