import { apiUrlBsc } from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrlBsc,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    }
})
export default class ConvocatoriaAPI {

    /** Obtiene un listado de las convocatorias del sistema. */
    static getAll() {
        return middleware.hasAuth(() => request.get('convocatoria/'));
    }

    /**
     * Obtiene la información de la convocatoria especificada.
     * 
     * @param {Number} id
     */
    static get(id) {
        return middleware.hasAuth(() => request.get(`/convocatoria/${id}`));
    }

    /** Obtiene la convocatoria en curso. */
    static getCurrent() {
        return middleware.hasAuth(() => request.get('convocatoria/current/'));
    }

    /**
     * Crea una nueva convocatoria.
     * 
     * @param {Object} data 
     */
    static create(data) {
        return middleware.hasAuth(() => request.post('convocatoria/create/', data));
    }

    /**
     * publica una convocatoria convocatoria.
     * 
     * @param {Object} data 
     */
     static publicate(id) {
        return middleware.hasAuth(() => request.put(`/convocatoria/${id}/publicar/`));
    }

    static edit(unit, id) {
        return middleware.hasAuth(() => request.put(`/Test`, unit));
    }

    static delete(id) {
        return middleware.hasAuth(() => request.delete(`Test/${id}`));
    }
}