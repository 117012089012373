import React from 'react';
import './Menu.css';
import MenuInterior from '../menuInterior/MenuInterior';
import MenuVariable from '../menuInterior/MenuVariable';

/**
 * Componente que forma la barra lateral izquierda del sistema, donde está situado el menú
 */
export default class Menu extends React.Component {
    render() {
        
        let perfil_selected = localStorage.getItem('perfil_selected');

        return (
            <aside className="column is-2 aside hero is-fullheight is-hidden-mobile menu SideMenu">
                {
                    perfil_selected == 'bsc' &&
                    <MenuInterior />
                }
                {
                    perfil_selected == 'variable' &&
                    <MenuVariable />
                }

            </aside>
        )
    }
}