import React, { Component, Fragment } from 'react'
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import { Button } from '../../util/Button/Button'
import { Card } from '../../util/Card/Card'
import { toast } from 'react-toastify';

import { Wrapper } from '../../util/WrapperInput/WrapperInput'
import Confirmar from '../../util/propuestas/Confirmar';
import Select from '../../util/select/Selct'
import Loading from '../../util/loading/Loading'

import GrupoIndicadoresAPI from '../../../services/indicadores_variables/GrupoIndicadores'


import './indicadores_asignacion.css'

class SingleItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected : false
        }
    }
    render() {
        let data = this.props.data;
        return (
            <tr>
                <td style={this.state.selected? {backgroundColor:"#79FF8D"}:{backgroundColor: "#ffffff"}} onClick={() => {
                    this.setState({selected:! this.state.selected}) 
                    this.props.onClick()
                    }}>
                    {data.nombre}
                </td>
            </tr>
            
        );
    }
}
  
class ItemListNotAssigned extends React.Component {
    render() {
        let not_in_list = this.props.allItems;
        let in_list = this.props.items;
        let handleEvent = this.props.handleEvent;
    
        let listItems = in_list.map((itemObj) => {
            let exist = not_in_list.filter(obj_not_in_list => obj_not_in_list.usuario.id == itemObj.usuario.id)[0]
            if (exist) return null;
    
            return <SingleItem 
                key={itemObj.usuario.id}
                data={itemObj.usuario}
                onClick={() => handleEvent(itemObj.usuario.id)}
            />;
        });
  
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth scroll">
                <thead>
                    <tr>
                        <th style={{"text-align": "center",backgroundColor: "#99cc00"}}>Personas sin asignar</th>
                    </tr>                    
                </thead>
                <tbody className='scroll'>
                    {listItems}
                </tbody>

            </table>
            
        );
    }
}

class ItemListAssigned extends React.Component {
    render() {
        let not_in_list = this.props.allItems;
        let in_list = this.props.items;
        let handleEvent = this.props.handleEvent;
    
        let listItems = in_list.map((itemObj) => {
            let exist = not_in_list.filter(obj_in_list => obj_in_list.usuario.id == itemObj.usuario.id)[0]
            if (exist) return null;
    
            return <SingleItem 
                key={itemObj.usuario.id}
                data={itemObj.usuario}
                onClick={() => handleEvent(itemObj.usuario.id)}
            />;
        });
  
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth scroll">
                <thead>
                    <tr>
                        <th style={{"text-align": "center", backgroundColor: "#99cc00"}}>Personas asignadas</th>
                    </tr>                    
                </thead>
                <tbody>
                    {listItems}
                </tbody>

            </table>
        );
    }
}

class AsignacionIndicadores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listUsersAssigned : [],
            listUsersNotAssigned : this.props.listUsers,
            listUsersSelectedToAssign : [],
            listUsersSelectedToUnassign : [],
            allUsers: this.props.listUsers,
            grupos_indicadores : this.props.grupos_indicadores,
            indicadorSelected : 0,        
            isConfirmNotificationVisible: false,
            saveLoading: false,
            saveDisabled : true,
            isLoading : false

        }
        
        this.handleEventAsignar = this.handleEventAsignar.bind(this);
        this.handleEventDesasignar = this.handleEventDesasignar.bind(this);
    }

    handleSelectIndicador = ({ target }) => {
        let state = {...this.state}
        state.isLoading = true

        state.indicadorSelected = Number(target.value)
        this.setState(state, () => {
            GrupoIndicadoresAPI.get_asignacion_by_group(state.indicadorSelected)
            .then(response => {
                let listUsersAssigned = response.data
                this.setState({
                    listUsersAssigned:[], 
                    listUsersSelectedToAssign : listUsersAssigned,
                    listUsersSelectedToUnassign : [], 
                    listUsersNotAssigned : state.allUsers,
                    isLoading: false
                },() => {                    
                    if(this.state.listUsersSelectedToAssign.length > 0)
                        this.handleEventAsignarFirstCharge()
                })
            }).catch(err => { 
                if (!toast.isActive(this.toastErrorId)) this.toastErrorId = toast.error('Error al obtener info de usuario actual') 
            })
        
        })
    }

    handleEventAsignar = ( itemId ) => {
        let state = {...this.state}
        let isInBoxOne = state.listUsersNotAssigned.filter(not_assigned => not_assigned.usuario.id == itemId)[0]        
        let isInBoxTwo = state.listUsersSelectedToAssign.filter(assigned => assigned.usuario.id == itemId)[0]
        let listUsersSelectedToAssign = []

        if(isInBoxOne){
            if(isInBoxTwo)
                listUsersSelectedToAssign = state.listUsersSelectedToAssign.filter(assigned => assigned.usuario.id !== itemId)
            else{
                state.listUsersSelectedToAssign.push(isInBoxOne)
                listUsersSelectedToAssign = state.listUsersSelectedToAssign
            }
        }
        this.setState({
            listUsersSelectedToAssign: listUsersSelectedToAssign
        });
    }

    handleEventDesasignar = ( itemId ) => {
        let state = {...this.state}
        let isInBoxOne = state.listUsersAssigned.filter(not_assigned => not_assigned.usuario.id == itemId)[0]        
        let isInBoxTwo = state.listUsersSelectedToUnassign.filter(assigned => assigned.usuario.id == itemId)[0]
        let listUsersSelectedToUnassign = []

        if(isInBoxOne){
            if(isInBoxTwo)
                listUsersSelectedToUnassign = state.listUsersSelectedToUnassign.filter(assigned => assigned.usuario.id !== itemId)
            else{
                state.listUsersSelectedToUnassign.push(isInBoxOne)
                listUsersSelectedToUnassign = state.listUsersSelectedToUnassign
            }
            
        }
                
        this.setState({
            listUsersSelectedToUnassign: listUsersSelectedToUnassign
        });
    }
    handleEventAsignarFirstCharge= () => {
        let state = {...this.state}
        let listUsersAssigned = state.listUsersAssigned
        let listUsersNotAssigned = state.listUsersNotAssigned

        state.listUsersSelectedToAssign.forEach(element => {
            listUsersAssigned.push(element)
            listUsersNotAssigned = listUsersNotAssigned.filter(user => user.usuario.id !== element.usuario.id)
        });

        this.setState({
            listUsersSelectedToAssign: [],
            listUsersAssigned : listUsersAssigned,
            listUsersNotAssigned : listUsersNotAssigned,
            saveDisabled: true            
        });

    }

    handleEventAsignarButton= () => {
        let state = {...this.state}
        let listUsersAssigned = state.listUsersAssigned
        let listUsersNotAssigned = state.listUsersNotAssigned

        state.listUsersSelectedToAssign.forEach(element => {
            listUsersAssigned.push(element)
            listUsersNotAssigned = listUsersNotAssigned.filter(user => user.usuario.id !== element.usuario.id)
        });

        listUsersAssigned.sort(function (a, b) {
            if (a.usuario.nombre < b.usuario.nombre) {
              return -1;
            }
            if (a.usuario.nombre > b.usuario.nombre) {
              return 1;
            }
            return 0;
        });

        listUsersNotAssigned.sort(function (a, b) {
            if (a.usuario.nombre < b.usuario.nombre) {
              return -1;
            }
            if (a.usuario.nombre > b.usuario.nombre) {
              return 1;
            }
            return 0;
        });

        this.setState({
            listUsersSelectedToAssign: [],
            listUsersAssigned : listUsersAssigned,
            listUsersNotAssigned : listUsersNotAssigned,
            saveDisabled: false            
        });

    }

    handleEventDesasignarButton= () => {
        let state = {...this.state}
        let listUsersAssigned = state.listUsersAssigned
        let listUsersNotAssigned = state.listUsersNotAssigned

        state.listUsersSelectedToUnassign.forEach(element => {
            listUsersNotAssigned.push(element)
            listUsersAssigned = listUsersAssigned.filter(user => user.usuario.id !== element.usuario.id)
        });

        listUsersAssigned.sort(function (a, b) {
            if (a.usuario.nombre < b.usuario.nombre) {
              return -1;
            }
            if (a.usuario.nombre > b.usuario.nombre) {
              return 1;
            }
            return 0;
        });

        listUsersNotAssigned.sort(function (a, b) {
            if (a.usuario.nombre < b.usuario.nombre) {
              return -1;
            }
            if (a.usuario.nombre > b.usuario.nombre) {
              return 1;
            }
            return 0;
        });

        this.setState({
            listUsersSelectedToUnassign: [],
            listUsersAssigned : listUsersAssigned,
            listUsersNotAssigned : listUsersNotAssigned,
            saveDisabled: false            
        });

    }

    save = () => {
        let state = {...this.state}

        this.props.save(state.indicadorSelected, state.listUsersAssigned, state.listUsersNotAssigned)        

    }

    showLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    render(){
        return (
            <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "50%" }}>
      
                <header className="modal-card-head">
                    <p className="modal-card-title">Asignación de indicadores a personas</p>
                  <button onClick={() => {this.setState({verConfirmarCerrar:true})}} className="delete" title="Cerrar" aria-label="close"></button>
                </header>
      
                <section className="modal-card-body">
                    {
                        this.state.verConfirmarCerrar &&
                        <Confirmar
                            yes={this.props.cancel}
                            no={() => this.setState({ verConfirmarCerrar: false })}>

                            <h5 className="title is-5">
                            ¿Seguro que desea cerrar la ventana?
                            </h5>
                            <p>Los datos capturados se perderán</p>

                        </Confirmar>
                    }

                    <Card >
                        <div>
                            <div className="columns is-4">
                                <Wrapper label="Selecciona indicador">
                                    <Select
                                    classes="input"
                                    name="indicador"
                                    value={this.state.indicadorSelected}
                                    change={this.handleSelectIndicador}
                                    items={this.state.grupos_indicadores.filter(obj => !obj.borrador)}
                                    optionProps={{ value: "id", text: "nombre" }}
                                    />
                                </Wrapper>
                            </div>

                        </div>
                    </Card>

                    <Card >
                        <div>
                            <div className='columns'>
                                <div className="column is-5">
                                    <div>
                                        {
                                            this.state.isLoading ?
                                            <Loading
                                            isFullscreen={false}
                                            isLoading={this.state.isLoading}
                                            width="100px"
                                            height="100px"
                                            />
                                            :
                                            <ItemListNotAssigned handleEvent={this.handleEventAsignar} items={this.state.listUsersNotAssigned} allItems={this.state.listUsersAssigned} />
                                        }
                                        
                                    </div>
                                </div>
                                <div  className="column is-2" style={{"text-align":"center"}}>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <button disabled={this.state.indicadorSelected === 0} className="button is-large is-success" onClick={this.handleEventAsignarButton}>
                                        <span className="icon is-big">
                                        &nbsp;&nbsp;<i className="fa fa-chevron-right"></i>&nbsp;&nbsp;
                                        </span>
                                    </button>
                                    <br/>
                                    <br/>
                                    <button className="button is-large is-danger" onClick={this.handleEventDesasignarButton}>
                                        <span className="icon is-big">
                                            <i className="fa fa-chevron-left"></i>
                                        </span>
                                    </button>

                                </div>
                                <div className={"column is-5"}>
                                    <div>
                                        {
                                            this.state.isLoading ?
                                            <Loading
                                            isFullscreen={false}
                                            isLoading={this.state.isLoading}
                                            width="100px"
                                            height="100px"
                                            />
                                            :
                                            <ItemListAssigned handleEvent={this.handleEventDesasignar} items={this.state.listUsersAssigned} allItems={this.state.listUsersNotAssigned} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>         
        
                </section>
      
                <footer className="modal-card-foot">
                            
                  {/* Botón para guardar*/}
                  <Button
                    className={`${this.state.saveLoading ? "is-loading" : ""}`}
                    disabled={this.state.saveDisabled || this.state.indicadorSelected === 0}
                    onClick={() => {
                      this.setState({
                        saveLoading: true
                      },() => {this.save()})
                      
                    }}
                    text="Guardar"
                    icon="fa-check"
                  />

                  {/* Botón para cancelar */}
                  <Button
                    onClick={() => {this.setState({verConfirmarCerrar:true})}}
                    text="Cancelar"
                    type="is-danger"
                    icon="fa-ban"
                  />
                </footer>
      
              </div>
            </div>
          )
    }
}

AsignacionIndicadores.defaultProps = {
    data: {},
}

export default AsignacionIndicadores;