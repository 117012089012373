import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { ToastContainer } from 'react-toastify';
import Login from './components/login/Login';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AppContainer from './components/app-container/app-container';

//-styles
import './index.css';
import './css/mystyle.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
import 'react-datepicker/dist/react-datepicker.css';

// Componente principal de la aplicación
class App extends React.Component {
    render() {
        // Se revisa si hay información de un inicio de sesión en localStorage y si aún es válida
        let isLogged = localStorage.getItem("token") && localStorage.getItem("perfil_selected") && localStorage.getItem("tokenExpiration") > Date.now() / 1000;
        return (
            // Se engloba toda la aplicación en un BrowserRouter para un manejo de rutas
            <BrowserRouter>
                <Switch>
                    {/* Si se ocupa algo antes de entrar a la aplicación aquí se ponen las rutas */}
                    <Route component={isLogged ? AppContainer : AppLogin} />
                </Switch>
            </BrowserRouter>
        )
    }
}

// Componente sin estado con el único fin de encapsular el componente Login junto al 
// contenedor de los toast para su uso.
const AppLogin = () => (
    <div className="App">
        <Login />
        <ToastContainer />
    </div>
)

// El componente App se dibujará en el elemento root del DOM
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
