import React, { Component } from 'react';
// import Indicador from './indicador';
import PropTypes from 'prop-types';
import Comentario from './comentario';
import { BSCColumnStyle } from '../../../util/bsc-column-style';
import {
    calcularCompensacion,
    validarTotalesIndicadores
} from '../../../util/compensacion-util';
import HasAccess from '../hasAccess/HasAccess';
import { toast } from 'react-toastify';
import Actions from '../actions/Actions';
import { HasPermission } from '../../../util/utileria';
import { ModalComentarios } from '../../propuestas/Comentarios/ModalComentarios';
import { validate } from '../../../util/validation-rules';
import decode from 'jwt-decode';

import './propuesta.css'

/**Clase que muestra las propuestas con sus opciones y la tabla de indicadores de la misma. */
class Propuestas extends Component {
    constructor(props) {
        super(props);

        this.comentario = {
            indicador: 0,
            comentario: "",
            error: false
        }

        this.Indicador = {
            propuesta: 0,
            objetivo: "",
            indicador: "",
            comentario: "",
            sobre_meta: "",
            meta: "",
            bajo_meta: "",
            inaceptable: "",
            revision: "",
            ponderacion: 0,
            ponderacionValid: true,
            compensacion: "",
            compensacionObj: {},
            tipo: "Normal"
        }

        this.state = {
            indicadores: [],
            indicadoresRelacionados: [],
            indicador: {},
            totalPonderacion: 0,
            totalCompensacion: 0,
            maxIndicadores: 6,
            verNotificacion: true,
            agregarDisabled: false,
            verCompensacion: false,
            validForm: false,
            proposalHasRelations: false,
            indicadoresValidation: [{
                valid: false,
                indexIndicador: 0
            }],
            showCodigo: false,
            showComentario: false,
            showRelacionados: false,
            showAgregarComentario: false,
            comentario: {
                indicador: 0,
                comentario: "",
                error: false
            }
        }
    }

    /**
     * Asigna una propiedad extra con terminacion `hasChanged`
     * con valor `false` por cada propiedad en el objeto.
     * 
     * @param {Array} indicadoresToChange
     */
    setDefaultHasChangedValue = (indicadoresToChange = []) => {
        let indicadoresDefault = indicadoresToChange.map(item => {
            Object.keys(item).map(key => {
                // debugger
                if (
                    key !== 'comentarios' & key !== 'rel_indicador' &
                    key !== 'codigo' & key !== 'edited' &
                    key !== 'history' & key !== 'id' &
                    key !== 'propuesta' & key !== 'revision' &
                    key !== 'tipo' & key !== 'validado' &
                    key !== 'version' & key !== 'edited_jefe'
                ) {
                    item[`${key}_hasChanged`] = false
                }
            })
            return { ...item }
        })

        let checkedIndicadores = this.checkChanges([...indicadoresDefault])

        this.setState({ indicadores: checkedIndicadores })
    }

    /**
     * Recorre los indicadores y el historial para verificar si hay
     * cambios en alguna de sus propiedades, en caso de haber
     * asigna a la propiedad con terminacion `hasChanged` el valor `true`.
     * 
     * @param {Array} indicadoresToChange
     */
    checkChanges(indicadoresToChange = []) {
        let indicadoresTotales = []
        let historyCopy = {}
        let indicador = {}
        for (let j = 0; j < indicadoresToChange.length; j++) {
            indicador = indicadoresToChange[j]
            const historyIndex = () => {
                let index
                if (indicador.edited === true & indicador.edited_jefe === true) {
                    index = 1
                } else if (indicador.edited === true || indicador.edited_jefe === true) {
                    index = 2
                } else {
                    index = 0
                }
                return index
            }
            let index = historyIndex()
            historyCopy = indicadoresToChange[j].history[index]
            if (historyCopy !== undefined) {
                if (historyCopy.id === indicador.id) {
                    let objectKeys = Object.keys(historyCopy)
                    objectKeys.map(key => {
                        if (
                            historyCopy[key] !== indicador[key] &
                            key !== 'comentarios' &
                            key !== 'rel_indicador'
                        ) {
                            indicador[`${key}_hasChanged`] = true
                        }
                    })
                }
                indicador.history[index] = historyCopy
            }
            indicadoresTotales.push(indicador)
        }
        return indicadoresTotales
    }

    componentDidMount() {
        let indicadoresNormales = [
            ...this.props.propuesta.indicadores.filter(obj => obj.tipo === "Normal")
        ]
        let hasRelations = indicadoresNormales.filter(indicador => indicador.rel_indicador.length > 0)
        let indicadoresProps = this.props.propuesta.indicadores

        this.setDefaultHasChangedValue(indicadoresProps)

        if (hasRelations) this.setState({ proposalHasRelations: hasRelations })
    }

    /**
     * Maneja los cambios en el input de comentarios.
     * 
     * @param {Event} e
     */
    handleComentarioChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let state = { ...this.state };

        state.comentario[name] = value;
        state.comentario.error = false;

        if (validate.isEmpty(e.target)) {
            state.comentario.error = true
        }

        this.setState(state);
    }

    /**Abre/Cierra el modal para hacer comentarios. */
    toggleModalComentarios = () => {
        this.setState({ showAgregarComentario: !this.state.showAgregarComentario })
    }

    /**Guarda el comentario realizado en el indicador. */
    saveComentario = () => {
        let state = { ...this.state }
        state.comentario.indicador = state.indicador.id;

        if (validate.isValueEmpty(state.comentario.comentario)) {
            state.comentario.error = true
        }

        this.setState(state, () => {
            if (!state.comentario.error) {
                this.props.saveComentarios(state.comentario)
                this.limpiarComentario()
            }
        })

    }

    /**Borra el comentario de un indicador. */
    limpiarComentario() {
        let state = { ...this.state }
        state.comentario = { ...this.comentario }
        this.setState(state);
    }

    /**
     * Calcula las compensaciones de los indicadores de la propuesta.
     * 
     * @param {Array} indicadores
     * @returns [Array] `indicadores`
     */
    calcularCompensaciones = (indicadores, canWatchComp) => {
        return indicadores.map(indicador => {
            indicador.compensacion = calcularCompensacion(indicador, this.props.propuesta.usuario, canWatchComp)
            return indicador;
        })
    }

    /**
     * Verifica si una propiedad de mis propuestas o de mis usuarios 
     * a cargo a cambiado.
     * 
     * @param {Boolean} isUserProposal 
     * @param {Boolean} isDirectBoss 
     * @param {Object} obj 
     * @returns {Array} [`property`,`user`]
     */
    paramHasChanged(isUserProposal, isDirectBoss, obj) {
        let property
        let user
        if (isUserProposal === true) {
            property = obj.edited
            user = isUserProposal
        } else if (isDirectBoss === true) {
            property = obj.edited_jefe
            user = isDirectBoss
        } else {
            property = false
            user = false
        }

        return property === true && user === true
    }

    render() {
        const usrIdToken = this.props.usuario.id_auth

        const onlyTurbos = this.props.onlyTurbos

        const isAdmin = HasPermission(['Administrador'])
        const isUserProposal = this.props.propuesta.usuario.usuario.id_auth === usrIdToken
        const isDirectBoss = this.props.propuesta.usuario.id_jefe_directo === usrIdToken

        const canWatchComp = isUserProposal || isDirectBoss || isAdmin

        let indicadores = this.calcularCompensaciones(this.state.indicadores.filter(obj => obj.tipo === "Normal"), canWatchComp)
        const usuario = this.props.propuesta.usuario;
        indicadores = validarTotalesIndicadores(indicadores, usuario).sort(function (a, b) { return a.id - b.id });
        const indicadoresTurbo = this.state.indicadores.filter(obj => obj.tipo === "Turbo").sort(function (a, b) { return a.id - b.id })
        const propuestaValida = this.props.propuesta.estatus === "Validado" ? true : false

        return (
            <div style={{ marginBottom: "24px" }}>
                {   !onlyTurbos &&
                    indicadores.length > 0 ?
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                                    <th style={BSCColumnStyle.indicador}>Indicador</th>
                                    <th style={BSCColumnStyle.comentario}>Comentario</th>
                                    <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                                    <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                                    <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                                    <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                                    <th style={BSCColumnStyle.encargado}><abbr title="Usuario encargado de validar el objetivo" width="7%">Usuario que valida</abbr></th>
                                    <th style={BSCColumnStyle.ponderacion}><abbr title="Ponderación" width="7%">% Pond.</abbr></th>
                                    {this.props.showCompensacion && <th style={BSCColumnStyle.compensacion}><abbr title="Compensación">Comp.</abbr></th>}
                                    <th width="10%" style={BSCColumnStyle.acciones}>Acciones</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    indicadores.map((obj, index) => {
                                        const usuario_validate = obj.validate_user !== null ?
                                            this.props.usuarios.filter(user => user.id === obj.validate_user)[0]
                                            : undefined
                                        let hasComentarios = (obj.comentarios && obj.comentarios.length > 0) ? true : false;
                                        let isAdmin = HasPermission(['Administrador'])

                                        let validaciones = {
                                            codigo: (this.props.propuesta.IsUserProposal && this.props.propuesta.estatus === "Validado"),
                                            comentarios: (!propuestaValida && hasComentarios),
                                            relacionados: (obj.rel_indicador && obj.rel_indicador.length > 0),
                                            isAdmin: isAdmin
                                        }

                                        const hasChanged = this.paramHasChanged(isUserProposal, isDirectBoss, obj)

                                        return <tr key={index}>
                                            <td className={`ProposalCol ${
                                                obj.objetivo_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.objetivo}</td>
                                            <td className={`ProposalCol ${
                                                obj.indicador_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.indicador}</td>
                                            <td className={`ProposalCol ${
                                                obj.comentario_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.comentario}</td>
                                            <td className={`ProposalCol ${
                                                obj.sobre_meta_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.sobre_meta}</td>
                                            <td className={`ProposalCol ${
                                                obj.meta_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.meta}</td>
                                            <td className={`ProposalCol ${
                                                obj.bajo_meta_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.bajo_meta}</td>
                                            <td className={`ProposalCol ${
                                                obj.inaceptable_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.inaceptable}</td>
                                            <td className={`ProposalCol ${
                                                obj.validate_user_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{usuario_validate ? usuario_validate.usuario.nombre : 'Sin encargado'}</td>
                                            <td className={`${
                                                obj.ponderacion_hasChanged && hasChanged
                                                    ? "IndicadorObjetivo" : ""}`
                                            }>{obj.ponderacion}</td>
                                            {
                                                this.props.showCompensacion
                                                && (
                                                    <td className={`${
                                                        obj.compensacion_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>
                                                        <div className="tags are-medium">
                                                            {
                                                                Object.keys(obj.compensacion).map((key, i) => {
                                                                    if(!HasPermission(['Administrador de objetivos']) || (HasPermission(['Administrador de objetivos']) && (this.props.propuesta.usuario.id === this.props.usuarioCompleto.id || this.props.propuesta.usuario.jefe_directo === this.props.usuario.id))) {
                                                                        return <span className="tag is-info" key={i}>{key + "=" + obj.compensacion[key]}</span>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                )
                                            }
                                            {
                                                <td>
                                                    <Actions title={"Acciones"}>
                                                        {
                                                            (validaciones.codigo || isAdmin) &&
                                                            <a className="dropdown-item" onClick={() => this.setState({ showCodigo: true, indicador: obj })}>
                                                                <span className="icon">
                                                                    <i className="fa fa-share"></i>
                                                                </span>
                                                                <span>Compartir código</span>
                                                            </a>
                                                        }
                                                        {
                                                            validaciones.comentarios &&
                                                            <a className="dropdown-item" onClick={() => this.setState({ indicador: obj, showComentario: true })}>
                                                                <span className="icon">
                                                                    <i className="fa fa-comment"></i>
                                                                </span>
                                                                <span>Ver comentarios</span>
                                                            </a>

                                                        }
                                                        {
                                                            validaciones.relacionados &&
                                                            <a className="dropdown-item" onClick={() => this.setState({ showRelacionados: true, indicadoresRelacionados: obj.rel_indicador })}>
                                                                <span className="icon">
                                                                    <i className="fa fa-compress"></i>
                                                                </span>
                                                                <span>Ver indicadores relacionados</span>
                                                            </a>
                                                        }
                                                        {
                                                            validaciones.isAdmin &&
                                                            <a className="dropdown-item" onClick={() => this.setState({ showAgregarComentario: true, indicador: obj })}>
                                                                <span className="icon">
                                                                    <i className="fa fa-comments"></i>
                                                                </span>
                                                                <span>Agregar comentario</span>
                                                            </a>
                                                        }
                                                        {
                                                            validaciones.isAdmin &&
                                                            <a className="dropdown-item" onClick={() => this.props.showHistorial(obj)}>
                                                                <span className="icon">
                                                                    <i className="fa fa-eye"></i>
                                                                </span>
                                                                <span>Ver historial</span>
                                                            </a>
                                                        }
                                                    </Actions>
                                                </td>
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table> : ""
                }
                {
                    indicadoresTurbo.length > 0
                        ? (
                            <div>
                                <div className="has-text-danger">
                                    <span className="icon is-small">
                                        <i className="fa fa-line-chart"></i>
                                    </span>
                                    <span className="title is-6 has-text-danger" style={{ marginLeft: "5px" }}>Indicadores turbo - </span>
                                    <span>{indicadoresTurbo.reduce((acc, ind) => acc + ind.ponderacion, 0)}% adicional del resultado BSC</span>
                                </div>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: "#99cc00" }}>Objetivo</th>
                                            <th style={{ backgroundColor: "#99cc00" }}>Indicador</th>
                                            <th style={{ backgroundColor: "#99cc00" }} width="15%">Comentario</th>
                                            <th style={{ backgroundColor: "#0000ff", color: "white" }} width="15%"><abbr title="Sobre meta">SM</abbr></th>
                                            <th style={{ backgroundColor: "#ffc000" }} width="15%"><abbr title="Ponderación">% Pond.</abbr></th>
                                            <HasAccess roles={["Administrador"]}>
                                                <th style={{ backgroundColor: "#ffc000" }} width="10%">Acciones</th>
                                            </HasAccess>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            indicadoresTurbo.map((obj, index) => {
                                                let isAdmin = HasPermission(['Administrador'])

                                                const hasChanged = this.paramHasChanged(isUserProposal, isDirectBoss, obj)

                                                return <tr key={index}>
                                                    <td className={`ProposalCol ${
                                                        obj.objetivo_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>{obj.objetivo}</td>
                                                    <td className={`ProposalCol ${
                                                        obj.indicador_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>{obj.indicador}</td>
                                                    <td className={`ProposalCol ${
                                                        obj.comentario_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>{obj.comentario}</td>
                                                    <td className={`ProposalCol ${
                                                        obj.sobre_meta_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>{obj.sobre_meta}</td>
                                                    <td className={`${
                                                        obj.ponderacion_hasChanged && hasChanged
                                                            ? "IndicadorObjetivo" : ""}`
                                                    }>{obj.ponderacion}</td>
                                                    <HasAccess roles={['Administrador']}>
                                                        <td>
                                                            <Actions title={"Acciones"}>
                                                                {
                                                                    isAdmin &&
                                                                    <a className="dropdown-item" onClick={() => this.props.editTurbo(obj)}>
                                                                        <span className="icon">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                        <span>Editar</span>
                                                                    </a>
                                                                }
                                                                {
                                                                    isAdmin &&
                                                                    <a className="dropdown-item" onClick={() => this.props.showHistorial(obj)}>
                                                                        <span className="icon">
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                        <span>Ver historial</span>
                                                                    </a>
                                                                }
                                                            </Actions>
                                                        </td>
                                                    </HasAccess>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ) : (/*<NoItems itemName="propuestas" />*/"")
                }
                {
                    this.state.showCodigo &&
                    <ModalCodigo close={() => this.setState({ showCodigo: false })}
                        indicador={this.state.indicador} />
                }
                {
                    this.state.showComentario &&
                    <ModalComentario show={this.state.showComentario}
                        close={() => this.setState({ showComentario: false })}
                        indicador={this.state.indicador} />
                }
                {
                    this.state.showRelacionados &&
                    <ModalRelacionados close={() => this.setState({ showRelacionados: false })}
                        indicadores={this.state.indicadoresRelacionados} />
                }
                {
                    this.state.showAgregarComentario &&
                    <ModalComentarios
                        change={this.handleComentarioChange}
                        save={this.saveComentario}
                        getPrevious={this.props.getPrevious}
                        toggle={this.toggleModalComentarios}
                        indicador={this.state.indicador}
                        comentario={this.state.comentario}
                        comentarios={this.props.comentarios}
                    />
                }

            </div>
        )
    }
}

const ModalCodigo = (props) => {

    const handleClick = (e) => {
        e.preventDefault()
        e.target.select()
        document.execCommand('copy');
        toast.dismiss()
        toast.success("Código copiado a portapapeles")
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">

                <header className="modal-card-head">
                    <p className="modal-card-title">Código</p>

                    <button onClick={props.close} className="delete" aria-label="close"></button>

                </header>

                <section className="modal-card-body">
                    <div className="field">
                        <div className="control">
                            <input className="input is-large"
                                onClick={handleClick}
                                type="text"
                                placeholder="Código"
                                readOnly={true}
                                value={props.indicador.codigo}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const ModalComentario = (props) => {
    return (
        <div className={props.show ? "modal is-active" : "modal"}>
            <div className="modal-background" />
            <div className="modal-card">

                <header className="modal-card-head">
                    <p className="modal-card-title">Comentario</p>

                    <button onClick={() => props.close()} className="delete" aria-label="close"></button>

                </header>

                <section className="modal-card-body">
                    {
                        props.indicador.comentarios &&
                        props.indicador.comentarios.map((comentario, index) => <Comentario key={index} comentario={comentario} />)
                    }
                </section>
            </div>
        </div>
    )
}

const ModalRelacionados = (props) => {
    return (
        <div className={"modal is-active"}>
            <div className="modal-background" />
            <div className="modal-card" style={{ width: "70%" }}>

                <header className="modal-card-head">
                    <p className="modal-card-title">Indicadores relacionados</p>

                    <button onClick={props.close} className="delete" aria-label="close"></button>

                </header>

                <section className="modal-card-body">
                    {
                        props.indicadores.length > 0 &&
                        <div className="table__wrapper">
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th style={BSCColumnStyle.objetivo}>Usuario</th>
                                        <th style={BSCColumnStyle.objetivo}>Objetivo</th>
                                        <th style={BSCColumnStyle.indicador}>Indicador</th>
                                        <th style={BSCColumnStyle.comentario}>Comentario</th>
                                        <th style={BSCColumnStyle.sm} width="7%"><abbr title="Sobre meta">SM</abbr></th>
                                        <th style={BSCColumnStyle.m}><abbr title="Meta" width="7%">M</abbr></th>
                                        <th style={BSCColumnStyle.bm}><abbr title="Bajo meta" width="7%">BM</abbr></th>
                                        <th style={BSCColumnStyle.i}><abbr title="Inaceptable" width="7%">I</abbr></th>
                                        {/* <th style={ BSCColumnStyle.ponderacion }><abbr title="Ponderación" width="7%">% Pond.</abbr></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.indicadores.map((obj, index) => {
                                            return <tr key={index}>
                                                <td>{obj.usuario_nombre}</td>
                                                <td>{obj.objetivo}</td>
                                                <td>{obj.indicador}</td>
                                                <td>{obj.comentario}</td>
                                                <td>{obj.sobre_meta}</td>
                                                <td>{obj.meta}</td>
                                                <td>{obj.bajo_meta}</td>
                                                <td>{obj.inaceptable}</td>
                                                {/* <td>{obj.ponderacion}</td> */}
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}

Propuestas.defaultProps = {
    propuesta: {},
    showCompensacion: false
}

Propuestas.propTypes = {
    propuesta: PropTypes.object,
    showCompensacion: PropTypes.bool,
    cancel: PropTypes.func,
    save: PropTypes.func,
}

export default Propuestas;